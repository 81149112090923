import {decompress, decrypt} from "./wbUtils";
import {exportToBlob} from "@excalidraw/excalidraw";

export async function generatePreviewImageLocal(noteContent, secretKey) {
	try {
		const content = decompress(decrypt(noteContent, secretKey));
		const formattedData = {};
		Object.keys(content.files).forEach(key => {
			const file = content.files[key];
			formattedData[file.id] = {
				mimeType: file.mimeType,
				id: file.id,
				dataURL: file.dataURL
			};
		});
		const response = await exportToBlob({
			quality: 0.1,
			elements: content.elements,
			files: formattedData,
			mimeType: "image/jpeg",
			exportBackground: false,
		});
		return response;
	} catch (e) {
		return null;
	}
}


//Only to catch exceptions and return null
//Does Await & returns null if exceptions
export const handleAsync = async (fn) => {
	try {
		return await fn();
	} catch (error) {
		console.error('Error:', error);
		return null;
	}
};
export const handleSync = (fn) => {
	try {
		return fn();
	} catch (error) {
		console.error('Error:', error);
		return null;
	}
};

export const loggingMiddleware = (config) => (set, get, api) => config((args) => {
	console.log('Previous state:', get());
	set(args);
	console.log('New state:', get());
}, get, api);
