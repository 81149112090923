// AcehubFeatures.js
import React from "react";
import { useNavigate } from "react-router-dom";
import HeadindText from "./Utils/HeadindText";

const AcehubFeatures = () => {
  const navigate = useNavigate();

  const features = [
    {
      id: "tailored-roadmap",
      title: "Tailored Roadmap",
      description:
        "We custom tailor a roadmap for you for the target track/tracks of job roles.",
      imgSrc:
        "https://img.freepik.com/free-vector/creative-business-timeline-concept-with-road_23-2147852955.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "upskill-at-your-pace",
      title: "Upskill at Your Pace",
      description:
        "We guide you and upskill you at your pace, ensuring your progress is personalized.",
      imgSrc:
        "https://img.freepik.com/free-vector/growing-concept-illustration_114360-506.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "free-project-videos",
      title: "Free Project Videos",
      description:
        "Gain access to free project videos to enhance your learning experience.",
      imgSrc:
        "https://img.freepik.com/free-vector/online-education-background_52683-8090.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "team-demos",
      title: "Team Demos Every Sunday",
      description:
        "Participate in team demos on every Sunday evening to showcase your progress.",
      imgSrc:
        "https://img.freepik.com/free-vector/team-work-concept-with-flat-design_23-2147849273.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "access-to-past-demos",
      title: "Access to Past Demos",
      description:
        "Access previous demos conducted by our interns to learn from their experience.",
      imgSrc:
        "https://img.freepik.com/free-vector/face-recognition-personal-identification-secure-access-profile-entry-data-storage-opening-female-account-holder-cartoon-character-vector-isolated-concept-metaphor-illustration_335657-2852.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "personal-profile-page",
      title: "Personal Profile Page",
      description:
        "Get your personal profile page with your projects hosted for demo purposes.",
      imgSrc:
        "https://img.freepik.com/free-vector/employees-cv-candidates-resume-corporate-workers-students-id-isolate-flat-design-element-job-applications-avatars-personal-information-concept-illustration_335657-1661.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "internship-opportunity",
      title: "Internship Opportunity",
      description:
        "You’ll have the opportunity to intern with us and gain valuable experience.",
      imgSrc:
        "https://img.freepik.com/free-vector/intern-mentor-working-office_23-2148748138.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "full-time-job-opportunity",
      title: "Full-Time Job Opportunities",
      description:
        "Unlock the potential to get a full-time job with our partners after completion.",
      imgSrc:
        "https://img.freepik.com/free-vector/need-new-job-coronavirus-concept_23-2148571178.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
  ];

  return (
    <div className="mt-10 mx-auto max-w-7xl px-6 lg:px-8 flex flex-col items-center">
      <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
        <HeadindText Heading={`How does it work?`} />
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        {features.map((item) => (
          <div
            key={item.id}
            className="relative bg-white shadow-md p-4  rounded-lg flex items-center gap-4 h-[15vh] w-full hover:scale-[1.05] transition-all duration-700 hover:border-2 border-blue-100 cursor-pointer"
            
          >
            <img
              src={item.imgSrc}
              alt={`${item.title} Image`}
              className="h-[100%] object-cover hover:scale-[1.1] transition-all duration-700"
            />
            <div className="pb-4">
              <h4 className="font-semibold text-gray-800 mb-2">{item.title}</h4>
              <p className="text-gray-600">{item.description}</p>
            </div>
            <button className="absolute bottom-2 right-4 px-2 py-1 bg-richYellow text-white font-bold rounded-lg hover:bg-yellow-600 transition transform hover:scale-110 duration-300 ease-in-out" onClick={() => navigate(`/acehub-feature/${item.id}`)}>Explore</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcehubFeatures;
