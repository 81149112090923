import React from "react";
import ProjectCard from "../molecules/ProjectCard";
import { useLocation, useNavigate } from "react-router-dom";

import { Projects } from "../particles/DataLists";

const ProjectPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  function onClickSelfStudyProject(courseId) {
    window.scrollTo(0, 0);
    
    // Get the current pathname and remove the last segment
    const currentPath = location.pathname;
    // const newPath = currentPath.split('/').slice(0, -1).join('/'); // Remove the last segment
    // Navigate to the modified path, then to the project id
    // navigate(newPath); // Navigate to the modified path
    // setTimeout(() => {
    //     navigate(`${newPath}/${courseId}`); // Then navigate to the specific course
    // }, 0);
    setTimeout(() => {
        navigate(`${currentPath}/${courseId}`); // Then navigate to the specific course
    }, 0);
}
  return (
    <section className="py-12 bg-gradient-to-b from-gray-50 to-white sm:py-16 lg:py-20 mt-4">
      <div className="px-4 mx-auto my-8 max-w-[90vw] sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold mb-2">Projects</h1>
        <div className="flex gap-4 flex-wrap">
          {Projects.map((project, index) => (
            <ProjectCard
              onClickSelfStudyProject={onClickSelfStudyProject}
              key={index}
              course={project}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectPage;
