import React, { useState, useEffect } from "react";
import gif1 from "../../../assets/Neugence_Proprietary_VisionTransformers.gif";

const MemorizationCards = () => {
  const trendingGifs = [
    { id: 1, url: gif1 },
    { id: 2, url: gif1, placeholder: "Coming Soon..." },
    { id: 3, url: gif1, placeholder: "Coming Soon..." },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(3);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");

  useEffect(() => {
    const updateVisibleItems = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setVisibleItems(1);
      } else if (width < 900) {
        setVisibleItems(2);
      } else {
        setVisibleItems(3);
      }
    };

    updateVisibleItems(); // Set the initial number of visible items
    window.addEventListener("resize", updateVisibleItems); // Update on window resize

    return () => window.removeEventListener("resize", updateVisibleItems); // Clean up
  }, []);

  const nextGifs = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleItems >= trendingGifs.length ? 0 : prevIndex + visibleItems
    );
  };

  const prevGifs = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - visibleItems < 0 ? trendingGifs.length - visibleItems : prevIndex - visibleItems
    );
  };

  const handleImageClick = (url) => {
    setModalImageUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageUrl("");
  };

  return (
    <div className="trending-carousel-container mb-20">
      <h2 className="text-center text-2xl font-bold mb-4 text-color1">Memorization Cards</h2>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/*<button onClick={prevGifs} style={{ marginRight: "10px", fontSize: "24px" }}>*/}
        {/*  &#8592; /!* Left arrow *!/*/}
        {/*</button>*/}
        <div className="flex justify-center gap-3 flex-col lg:flex-row">
          {trendingGifs.slice(currentIndex, currentIndex + visibleItems).map((gif) => (
            <div
              key={gif.id}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "300px",
                height: "300px",
                border: "1px solid lightgrey",
                borderRadius: "20px", backgroundColor:"rgb(31, 47, 62)"
              }}
            >
              {gif.placeholder ? (
                <div style={{ textAlign: "center", fontSize: "24px", color: "#888" }}>
                  {gif.placeholder}
                </div>
              ) : (
                <img
                  src={gif.url}
                  alt={`Trending GIF ${gif.id}`}
                  onClick={() => handleImageClick(gif.url)}
                  style={{
                    maxWidth: "400px",   // Set the maximum width for each GIF
                    maxHeight: "300px",  // Set the maximum height for each GIF
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    cursor: "pointer",
                    // borderRadius:"12px"
                  }}
                />
              )}
            </div>
          ))}
        </div>
        {/*<button onClick={nextGifs} style={{ marginLeft: "10px", fontSize: "24px" }}>*/}
        {/*  &#8594; /!* Right arrow *!/*/}
        {/*</button>*/}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <img
            src={modalImageUrl}
            alt="Modal GIF"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MemorizationCards;
