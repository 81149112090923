import React, { useState } from 'react';
import './MilestoneTimeline.css';
import { DocumentIcon } from '@heroicons/react/solid';

const gradients = [
  'linear-gradient(to right, #ff7e5f, #feb47b)',
  'linear-gradient(to right, #6a11cb, #2575fc)',
  'linear-gradient(to right, #43e97b, #38f9d7)',
  'linear-gradient(to right, #fa709a, #fee140)',
  'linear-gradient(to right, #30cfd0, #330867)'
];

const MilestoneTimeline = ({ milestones }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="milestone-container">
      {milestones.map((milestone, index) => (
        <React.Fragment key={index}>
          <div className="milestone relative">

              <div
                className="absolute h-0.5 w-[120%] -left-6"
                style={{
                  background: gradients[index % gradients.length]
                }}
              />


            <div style={{ display: "flex", justifyContent: "center", zIndex: "1000" }}>
              <DocumentIcon
                className="pin"
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "-21px",
                  backgroundColor: "white",
                  color: hoveredIndex === index ? "grey" : "#c6c6c673", // Separate hover effect
                  strokeWidth: "0.5",
                  transition: "color 0.3s ease", // Smooth transition effect
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => alert(`Coming Soon : ${milestone.title}`)}
              />
            </div>
	          <div className="milestone-content">
		          {milestone.title.split(' ').reduce((acc, word, i) => {
			          if (i % 2 === 0) acc.push([]);
			          acc[acc.length - 1].push(word);
			          return acc;
		          }, []).map((group, i) => (
			          <React.Fragment key={i}>
				          {group.join(' ')}
				          <br/>
			          </React.Fragment>
		          ))}
	          </div>
	          <div className="text-gray-600 opacity-70">{milestone.year}</div>
          </div>

        </React.Fragment>
      ))}
    </div>
  );
};

export default MilestoneTimeline;
