export function getAiMatchCount(pdfId, pdfIdAndCount) {
	if (pdfId in pdfIdAndCount) {
		if (pdfIdAndCount[pdfId] == 0) {
			return '.'
		}
		return pdfIdAndCount[pdfId]
	} else {
		return '.'
	}
}

export function getInQueueCount(pdfId, pdfIdToAprvdAndApplyStats) {
	if (pdfId in pdfIdToAprvdAndApplyStats) {
		if (pdfIdToAprvdAndApplyStats[pdfId]['approved'] == 0) {
			return '.'
		}
		return pdfIdToAprvdAndApplyStats[pdfId]['approved']
	}
	return '.'
}

export function getAllJobsAppliedCount(pdfIdToAprvdAndApplyStats) {
	let totalApplied = 0;
	Object.values(pdfIdToAprvdAndApplyStats).forEach(stats => {
		if ('applied' in stats) {
			totalApplied += stats['applied'];
		}
	});
	return totalApplied;
}

export function increaseApproveCount(pdfIdToAprvdAndApplyStats, pdfId) {
	pdfIdToAprvdAndApplyStats[pdfId]['approved'] += 1
	return pdfIdToAprvdAndApplyStats
}
export function getAllJobsInQueueCount(pdfIdToAprvdAndApplyStats) {
	let totalApplied = 0;
	Object.values(pdfIdToAprvdAndApplyStats).forEach(stats => {
		if ('approved' in stats) {
			totalApplied += stats['approved'];
		}
	});
	return totalApplied;
}

export function getAppliedCount(pdfId, pdfIdToAprvdAndApplyStats) {
	if (pdfId in pdfIdToAprvdAndApplyStats) {
		return pdfIdToAprvdAndApplyStats[pdfId]['applied']
	}
	return '.'
}