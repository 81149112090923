import React, {useEffect, useMemo, useState} from 'react';
import {Box, Checkbox, Stack} from '@mui/material';
import {MaterialReactTable, useMaterialReactTable,} from 'material-react-table';
import lcStore from "../libStores/lcStore"
import {PlusIcon, StarIcon} from "@heroicons/react/16/solid";
import {DocumentTextIcon, StarIcon as OutlineStar, CalendarIcon} from "@heroicons/react/24/outline";
import ExcalidrawWrapper from "../Excalidraw/ExcalidrawWrapper";
import {toast} from "react-toastify";
import notesStore from "../libStores/notesStore";
import Button from "@mui/material/Button";
import {TrashIcon, HandRaisedIcon, UserGroupIcon} from "@heroicons/react/24/outline";

const GroupingExample = ({verifiedemail, lcIcon, ShareModal}) => {
	const [currNote, setCurrNote] = useState({});
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const [waitingForDocId, setWaitingForDocId] = useState(null);
	const [modalOpen, setmodalOpen] = useState(false);
	
	const {
		notes, createNewButtonEvent, currDocId, usernotes,
		notesSetState, handleSaveButtonEvent
	} = notesStore(state => ({
		notes: state.notes,
		createNewButtonEvent: state.createNewButtonEvent, currDocId: state.currDocId,
		usernotes: state.usernotes, notesSetState: state.setState, handleSaveButtonEvent: state.handleSaveButtonEvent
	}));
	
	
	// const {fetchFriendships} = sharedNotesStore(state => ({
	// 	fetchFriendships:state.fetchFriendships
	// }));
	
	// console.log('notes', notes);
	
	// useEffect(() => {
	// 	usernotes(verifiedemail);
	// 	fetchUserFolders(verifiedemail, 'excalidraw');
	// 	fetchFriendships(verifiedemail);
	// }, [verifiedemail]);
	
	const {
		getUserDSAProblemsData, setUserDSAProbDetailField, tableData, setState, setNotesReferenceToNull
	} = lcStore((state) => ({
		getUserDSAProblemsData: state.getUserDSAProblemsData,
		setUserDSAProbDetailField: state.setUserDSAProbDetailField, tableData: state.tableData,
		setState: state.setState, setNotesReferenceToNull: state.setNotesReferenceToNull
	}));
	
	useEffect(() => {
		getUserDSAProblemsData(verifiedemail);
	}, []);
	
	useEffect(() => {
		if (waitingForDocId !== null && currDocId && currDocId.length > 0) { //TODO : this could have bugs where different notes are clicked multiple times
			// console.log('CurrDocId set to ', currDocId);
			const rowIndex = waitingForDocId;
			const newData = [...tableData];
			newData[rowIndex].note = currDocId;
			setState('tableData', newData);
			setUserDSAProbDetailField(verifiedemail, newData[rowIndex].probId, 'note', currDocId);
			setWaitingForDocId(null);  // Reset the waiting flag
			notesSetState('currDocId', '');  // Reset currDocId
		}
	}, [currDocId, waitingForDocId]);
	
	
	// const averageSalary = useMemo(() => tableData.reduce((acc, curr) => acc + curr.salary, 0) / tableData.length, [tableData]);
	// const maxAge = useMemo(
	//   () => tableData.reduce((acc, curr) => Math.max(acc, curr.age), 0),
	//   [tableData]
	// );
	
	function deleteClicked(cell) {
		window.alert('Delete button clicked for ', cell.row.index);
	}
	
	function deleteProblemAreaClicked(cell) {
		window.alert('Delete problem area clicked ', cell.row.index);
	}
	
	function revisionClickEvent(cell) {
		try {
			const rowIndex = cell.row.index;
			const newData = [...tableData];
			const newValue = !newData[rowIndex].revision;
			newData[rowIndex].revision = newValue;
			setState('tableData', newData);
			setUserDSAProbDetailField(verifiedemail, newData[rowIndex].probId, 'revision', newValue);
		} catch (e) {
			console.log('Exception in checkbox setting', e);
		}
	}
	
	const handleCheckboxChange = (cell, tableData) => {
		try {
			const rowIndex = cell.row.index;
			const newData = [...tableData];
			const newValue = !newData[rowIndex].status;
			newData[rowIndex].status = newValue;
			setState('tableData', newData);
			setUserDSAProbDetailField(verifiedemail, newData[rowIndex].probId, 'status', newValue);
		} catch (e) {
			console.log('Exception happened ', e);
		}
	};
	
	useEffect(() => {
		//Prune the note links that no longer exists in user data
		if (notes.length > 0 && tableData.length > 0) {
			const currentNoteIds = notes.map(note => note.docid);
			const nullList = [];
			const updatedTableData = tableData.map(row => {
				if (row.note != null && !currentNoteIds.includes(row.note)) {
					row.note = null; // Set to null if note no longer exists
					nullList.push(row['probId'])
				}
				return row;
			});
			setState('tableData', updatedTableData);
			setNotesReferenceToNull(verifiedemail, nullList);
		}
	}, [notes]);
	
	const handleNoteClick = (cell) => {
		const noteId = cell.renderValue();
		if (noteId) {
			// console.log('note is not null', noteId);
			// handleExCaliNoteClick(noteId);
			const currNote = notes.filter(n => n.docid === noteId)[0];
			// console.log('notes', notes);
			// console.log('currNote', noteId, currNote);
			notesSetState('currDocId', noteId)
			setCurrNote(currNote);
			setmodalOpen(true);
			// currDocId !== '' && currNote && currNote['title'].length
		} else {
			if (waitingForDocId) { //This will prevent multi fast note creation on button clicks.
				window.alert('Waiting for previous note creation');
			} else {
				try {
					const rowIndex = cell.row.index;
					
					notesSetState('currDocId', '');
					setWaitingForDocId(rowIndex);
					
					createNewButtonEvent(verifiedemail, 'excalidraw', tableData[rowIndex].problem);
				} catch (e) {
					console.log('Exception in checkbox setting', e);
				}
			}
		}
	};
	
	function practiceLinkClckd(cell) {
		const url = `https://leetcode.com/problems/${cell.renderValue()}/description/`;
		window.open(url, '_blank');
	}
	
	const handleAddProblemClick = (category) => {
		// Logic to handle the add problem button click
		console.log(`Add problem for category: ${category}`);
		// You can add your logic to open a modal or any other action here
	};
	const handleAddNewProblemArea = (category) => {
		// Logic to handle the add problem button click
		console.log(`Add problem for category: ${category}`);
		// You can add your logic to open a modal or any other action here
	};
	const columns = useMemo(() => [{
			header: 'Status', accessorKey: 'status', enableGrouping: false, enableSorting: true, enableFiltering: false,
			Cell: ({cell}) => (<Checkbox
				checked={cell.getValue()}
				onChange={() => handleCheckboxChange(cell, tableData)}
				color="primary"
				sx={{
					'& .MuiSvgIcon-root': {fontSize: 18}, // Adjust the size of the checkbox icon
				}}
				style={{width: "15px", height: "15px"}}
			/>), size: 125
		}, {
			header: 'Problem', accessorKey: 'problem', enableGrouping: false, size: 250,
			AggregatedCell: ({cell, table}) => (
				<Box sx={{display: 'flex', alignItems: 'center'}}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<PlusIcon/>}
						onClick={() => handleAddProblemClick(cell.getValue())}
						style={{
							height: "20px", background: "none", color: "grey",
							textDecoration: "none", textTransform: "none"
						}}
					>
						Add problem
					</Button>
				</Box>
			),
		},
			// {
			//   header: 'Age',
			//   accessorKey: 'age',
			//   aggregationFn: 'max', //show the max age in the group (lots of pre-built aggregationFns to choose from)
			//   //required to render an aggregated cell
			//   AggregatedCell: ({ cell, table }) => (
			//     <>
			//       Oldest by{' '}
			//       {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
			//       <Box
			//         sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
			//       >
			//         {cell.getValue()}
			//       </Box>
			//     </>
			//   ),
			//   Footer: () => (
			//     <Stack>
			//       Max Age:
			//       <Box color="warning.main">{Math.round(maxAge)}</Box>
			//     </Stack>
			//   ),
			// },
			{
				header: 'Practice',
				accessorKey: 'practice',
				enableGrouping: false,
				enableSorting: false,
				enableFiltering: false,
				Cell: ({cell}) => (<img src={lcIcon}
				                        style={{width: "25px", height: "25px", cursor: 'pointer'}}
				                        onClick={() => practiceLinkClckd(cell)}
				/>),
				size: 125,
			}, {
				header: 'Note',
				accessorKey: 'note',
				enableSorting: true,
				enableGrouping: false,
				enableFiltering: false,
				Cell: ({cell}) => (<div onClick={() => handleNoteClick(cell)} style={{cursor: "pointer"}}>
					{cell.getValue() ? <DocumentTextIcon style={{width: "20px", height: "20px"}}/> : <PlusIcon style={{width: "20px", height: "20px"}}/>}
				</div>),
				size: 125,
			}, {
				header: 'Difficulty', accessorKey: 'difficulty', enableSorting: true, enableGrouping: false, enableFiltering: true
			},
			{
				header: 'Revision',
				accessorKey: 'revision',
				enableSorting: true,
				enableGrouping: false,
				enableFiltering: true,
				Cell: ({cell}) => (<div style={{width: "15px", height: "15px", cursor: "pointer", color: "#ffa500ba"}}
				                        onClick={() => revisionClickEvent(cell)}>
					{cell.getValue() ? <StarIcon/> : <OutlineStar/>}
				</div>),
				size: 140,
			},
			{
				header: 'Consult',
				accessorKey: 'consult',
				enableSorting: true,
				enableGrouping: false,
				enableFiltering: true,
				Cell: ({cell}) => (
					<div style={{width: "35px", height: "35px", cursor: "pointer", color: "#ffa500ba", display: "flex"}}
					     onClick={() => revisionClickEvent(cell)}>
						<CalendarIcon/>
						<UserGroupIcon/>
					</div>),
				size: 140,
			},
			{
				header: 'Delete',
				accessorKey: 'delete',
				enableSorting: false,
				enableGrouping: false,
				enableFiltering: false,
				Cell: ({cell}) => (<div style={{width: "15px", height: "15px", cursor: "pointer", color: "#362f2d61"}}
				                        onClick={() => deleteClicked(cell)}>
					<TrashIcon/>
				</div>),
				size: 140,
				AggregatedCell: ({cell, table}) => (
					<div style={{width: "15px", height: "15px", cursor: "pointer", color: "#362f2d61"}}
					     onClick={() => deleteProblemAreaClicked(cell)}>
						<TrashIcon/>
					</div>),
			},
			{
				header: 'Problem Area', accessorKey: 'category', size: 185,
				Footer: () => (
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<PlusIcon/>}
							onClick={() => handleAddNewProblemArea(cell.getValue())}
							style={{
								height: "20px", background: "none", color: "grey",
								textDecoration: "none", textTransform: "none"
							}}
						>
							Drop Down List
						</Button>
					</Box>
				),
			},
			// {
			// 	header: 'Salary', accessorKey: 'salary', aggregationFn: 'mean', //required to render an aggregated cell, show the average salary in the group
			// 	AggregatedCell: ({cell, table}) => (<>
			// 		Average by{' '}
			// 		{table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
			// 		<Box sx={{color: 'success.main', fontWeight: 'bold'}}>
			// 			{cell.getValue()?.toLocaleString('en-US', {
			// 				style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
			// 			})}
			// 		</Box>
			// 	</>), //customize normal cell render on normal non-aggregated rows
			// 	Cell: ({cell}) => (<>
			// 		{cell.getValue()?.toLocaleString('en-US', {
			// 			style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
			// 		})}
			// 	</>), Footer: () => (<Stack>
			// 		Average Salary:
			// 		<Box color="warning.main">
			// 			{averageSalary?.toLocaleString('en-US', {
			// 				style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
			// 			})}
			// 		</Box>
			// 	</Stack>),
			// },
		], // [averageSalary, maxAge]
		[tableData]);
	
	const table = useMaterialReactTable({
		columns, data: tableData, displayColumnDefOptions: {
			'mrt-row-expand': {
				enableResizing: true,
			},
		}, enableColumnResizing: true, enableGrouping: true, enableStickyHeader: true, enableStickyFooter: true, initialState: {
			density: 'compact', expanded: true, //expand all groups by default
			grouping: ['category'], //an array of columns to group by default (can be multiple)
			pagination: {pageIndex: 0, pageSize: 20}, sorting: [{id: 'category', desc: false}], //sort by category by default
		}, muiTableBodyRowProps: {
			sx: {
				height: '30px', // Reduce row height
				'&.MuiTableRow-hover:hover': {
					backgroundColor: 'white !Important', // Change hover color to light grey
				},
			},
		}, muiToolbarAlertBannerChipProps: {color: 'primary'}, muiTableContainerProps: {sx: {maxHeight: 700}},
	});
	const handleClose = () => setmodalOpen(false);
	const [excalidrawAPI, setExcalidrawAPI] = useState(null);
	const [owner, setOwner] = useState(verifiedemail);
	const [role, setRole] = useState('');
	const [shareEmailIds, setshareEmailIds] = useState([]);
	
	function deletNoteClicked(useCaseDesc) {
		const confirmDelete = window.confirm("Delete Title: " + currNote['title']);
		if (confirmDelete) {
			// deleteNoteHandle(verifiedemail, currDocId, useCaseDesc);
			// deleteNoteFromFolderHandle(currDocId, verifiedemail, useCaseDesc);
			// handleClose();
		}
	}
	
	function saveButtonTriggered(docId, notetitle) {
		handleSaveButtonEvent(docId, excalidrawAPI, verifiedemail, verifiedemail, notetitle);
		//Notes are not saved when escape button is used.
		//TODO: when the notes are shared they are not saved and this is a wrong indication to the user.
		toast.success('Note Saved !!', {position: "top-right", hideProgressBar: "true", autoClose: 1000, height: "50px"});
	}
	
	return (<div className='ml-5 mr-5' style={{display: "contents", height: "80vh"}}>
		{modalOpen ? <>
				<div style={{display: "contents"}}>
					{currDocId !== '' && currNote && currNote['title'].length > 0 ?
						<ExcalidrawWrapper
							currNote={currNote}
							currDocId={currDocId}
							owner={owner}
							role={role}
							verifiedemail={verifiedemail}
							notetitle={currNote['title']}
							excalidrawAPI={excalidrawAPI}
							setExcalidrawAPI={setExcalidrawAPI}
							deletNoteClicked={deletNoteClicked}
							openModal={() => setShareModalOpen(true)}
							handleClose={handleClose}
							saveButtonTriggered={saveButtonTriggered}
							heightCustom={88}
						/>
						: null
					}
				</div>
				<ShareModal
					currDocId={currDocId}
					verifiedemail={verifiedemail}
					isOpen={shareModalOpen}
					onClose={() => setShareModalOpen(false)}
					sharingCapableEmailIds={shareEmailIds}
				/></> :
			<MaterialReactTable table={table}/>}
	</div>);
};

export default GroupingExample;
