import create from 'zustand';
import axios from 'axios';
import {getAxiosBaseUrl} from "../../config.js";
import {notesChangeFieldValue, updateStructure} from "./api";


axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;


const foldersStore = create((set, get) => {
	return {
		folders: [], openFolders: {}, setFldrState: (key, value) => {
			set(state => ({...state, [key]: value}));
		}, deleteNoteFromFolderHandle: async (docIdWoPrefix, verifiedemail, useCaseDesc) => {
			const {folders} = get();
			const docIdWPrefix = `note-${docIdWoPrefix}`;
			let updatedFolders;
			try {
				updatedFolders = folders.map(folder => {
					return {
						...folder, children: folder.children.filter(note => note.key !== docIdWPrefix)
					};
				});
				set({'folders': updatedFolders})
			} catch (error) {
				console.error("Error updating folder array:", error);
			}
			await axios.post('deleteNoteFromFolder', {
				verifiedemail: verifiedemail, useCaseDesc: useCaseDesc, docid: docIdWoPrefix
			}, {withCredentials: true});
		}, createFolder: async (verifiedEmail) => {
			const {folders} = get();
			const maxId = folders.reduce((max, folder) => Math.max(max, parseInt(folder.key, 10)), 0);
			const newFolder = {
				title: 'New Folder', key: (maxId + 1).toString(), children: []
			};
			const updatedFolders = [...folders, newFolder];
			set({folders: updatedFolders});
			
			updateStructure(verifiedEmail, 'excalidraw', updatedFolders)
		}, fetchUserFolders: async (verifiedemail, useCaseDesc) => { //Must be sync method??
			axios.post('/fetchUserFolders', {verifiedemail, useCaseDesc}, {withCredentials: true}).then(response => {
				const foldersRemote = response.data.record;
				if (foldersRemote.length == 0) { //Gauranteed empty on remote
					const maxId = foldersRemote.reduce((max, folder) => Math.max(max, parseInt(folder.key, 10)), 0);
					const newFolder = {
						title: 'Default', key: (maxId + 1).toString(), children: []
					};
					const updatedFolders = [newFolder];
					get().setFldrState('folders', updatedFolders);
				} else {
					get().setFldrState('folders', foldersRemote); //Possibility of pagination of notes from this sequence.
				}
			})
		}, updateTreeStructure: async (updatedFolders, verifiedEmail) => {
			set({folders: updatedFolders})
			updateStructure(verifiedEmail, 'excalidraw', updatedFolders);
		},
		
		handleRename: async (key, newTitle, verifiedEmail, friendShips) => {
			const docid = key.split('note-')[1];
			const {folders} = get(); //Update name in folder tree structure
			
			const matchedSharedDoc = friendShips.filter(d => d.docid === docid)[0]
			if (matchedSharedDoc) {
				const owner = matchedSharedDoc['email']
				if (owner != verifiedEmail) {
					return
					// const role = matchedSharedDoc['role']
					// if(role != 'Editor'){
					// 	return;
					// }
				}
			}
			
			const updatedFolders = folders.map(folder => {
				if (folder.key === key) {
					return {...folder, title: newTitle};
				}
				if (folder.children) {
					return {
						...folder, children: folder.children.map(child => {
							if (child.key === key) {
								return {...child, title: newTitle};
							}
							return child;
						})
					};
				}
				return folder;
			});
			set({'folders': updatedFolders});
			updateStructure(verifiedEmail, 'excalidraw', updatedFolders);
		}, // UPDATE
		insertNotesMetaInFolders: async (notes, verifiedEmail) => {
			const {folders} = get();
			
			// Ensure folders are initialized correctly
			if (!folders || !Array.isArray(folders) || folders.length === 0) {
				return;
			}
			
			// Filter out notes that are not yet in the folders
			let newNotes = notes.filter(note => {
				return !folders.some(folder => folder.children && folder.children.some(n => n.key === ('note-' + note.docid)));
			});
			
			let updatedFolders = folders;
			if (newNotes.length > 0) {
				const defaultFolderExists = folders.some(folder => folder.title === 'Default');
				
				if (!defaultFolderExists) {
					// Create 'Default' folder if it doesn't exist
					const maxId = folders.reduce((max, folder) => Math.max(max, parseInt(folder.key, 10)), 0);
					const newFolder = {
						title: 'Default', key: (maxId + 1).toString(), children: newNotes.map(note => ({
							key: 'note-' + note.docid, title: note.title, isLeaf: true
						})),
					};
					updatedFolders = [...folders, newFolder];
				} else {
					// Add new notes to the existing 'Default' folder
					updatedFolders = folders.map(folder => {
						if (folder.title === 'Default') {
							const existingChildrenKeys = folder.children ? folder.children.map(child => child.key) : [];
							const newChildren = newNotes.map(note => ({
								key: 'note-' + note.docid, title: note.title, isLeaf: true
							})).filter(child => !existingChildrenKeys.includes(child.key));
							
							return {
								...folder, children: [...(folder.children || []), ...newChildren]
							};
						}
						return folder;
					});
				}
			}
			const notesSet = new Set(notes.map(note => 'note-' + note.docid));
			let notesToRemove = [];

			// // Iterate over the folders to find notes to remove and update the children
			const updatedFolders2 = updatedFolders.map(folder => {
				if (folder.children) {
					const newChildren = folder.children.filter(child => {
						if (!notesSet.has(child.key)) {
							notesToRemove.push(child);
							return false; // Exclude this child
						}
						return true; // Include this child
					});
					return {
						...folder,
						children: newChildren
					};
				}
				return folder;
			});
			
			// Set the updated folders state
			set({folders: updatedFolders2});

			// Update the structure in the backend
			updateStructure(verifiedEmail, 'excalidraw', updatedFolders);
		}, onDeleteFolderHandle: async (key, verifiedEmail, notes) => {
			const {folders} = get();
			let updatedFolders;
			const deleteFolder = (folders, key) => {
				return folders.filter(folder => folder.key !== key).map(folder => {
					return {
						...folder, children: folder.children ? deleteFolder(folder.children, key) : []
					};
				});
			};
			updatedFolders = deleteFolder(folders, key);
			set({folders: updatedFolders});
			updateStructure(verifiedEmail, 'excalidraw', updatedFolders);
			get().insertNotesMetaInFolders(notes, verifiedEmail)
		}
	}
});

export default foldersStore;