import React, { useState, useEffect } from 'react';
import { Tree, message } from 'antd';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import './Demo.css'; // Import the CSS file for custom styling

const DemoJsx = ({
    initialData,
    onStructureChange,
    onRename,
    expandedKeys,
    onNoteClick,
    onDeleteHandle
}) => {
    const [gData, setGData] = useState(initialData);
    const [internalExpandedKeys, setInternalExpandedKeys] = useState(expandedKeys);
    const [draggingKey, setDraggingKey] = useState(null);
    const [editingKey, setEditingKey] = useState(null);
    const [editingTitle, setEditingTitle] = useState('');

    useEffect(() => {
        setGData(initialData);
    }, [initialData]);

    useEffect(() => {
        setInternalExpandedKeys(expandedKeys);
    }, [expandedKeys]);

    const onDragStart = (info) => {
        setDraggingKey(info.node.key);
    };

    const onDragEnd = () => {
        setDraggingKey(null);
    };

    const onDragEnter = (info) => {
        // console.log('Drag Enter:', info.node.key);
    };

    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };

        const data = [...gData];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        // Check if the dragObject is a folder and prevent moving folder under folder
        if (!dragObj.isLeaf && !info.dropToGap) {
            message.error("You can't move a folder under another folder.");
            return;
        }

        // Prevent moving a note into another note or making a folder a child of a note
        if (dragObj.key.startsWith('note-')) {
            if (dropKey.startsWith('note-')) {
                message.error("You can't move a note into another note.");
                return;
            }
            if (!dropKey) {
                message.error("You can't place a note at the root level.");
                return;
            }
        }

        // Check if the target node is a note or root
        if (dropKey.startsWith('note-') || !dropKey) {
            message.error("You can't move a folder into a note or place a note at the root.");
            return;
        }

        if (!info.dropToGap) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
                setInternalExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, dropKey]);
            });
        } else {
            let ar = [];
            let i;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
            setInternalExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, dropKey]);
        }

        // Prevent dropping into a descendant
        const isDescendant = (parentKey, childKey) => {
            const findNode = (nodes, key) => {
                for (const node of nodes) {
                    if (node.key === key) {
                        return node;
                    }
                    if (node.children) {
                        const found = findNode(node.children, key);
                        if (found) return found;
                    }
                }
                return null;
            };

            const parent = findNode(data, parentKey);
            const child = findNode(data, childKey);

            const checkDescendant = (node) => {
                if (!node || !node.children) return false;
                for (const child of node.children) {
                    if (child.key === parentKey || checkDescendant(child)) {
                        return true;
                    }
                }
                return false;
            };

            return checkDescendant(parent);
        };

        if (isDescendant(dragKey, dropKey)) {
            message.error("You can't move a parent folder into one of its descendants.");
            return;
        }

        setGData(data);
        setDraggingKey(null); // Clear dragging state after drop

        // Call the structure change handler
        onStructureChange(data);
    };

    const toggleExpand = (key) => {
        setInternalExpandedKeys((prevExpandedKeys) => {
            if (prevExpandedKeys.includes(key)) {
                return prevExpandedKeys.filter((k) => k !== key);
            } else {
                return [...prevExpandedKeys, key];
            }
        });
    };

    const handleDoubleClick = (key, title) => {
        setEditingKey(key);
        setEditingTitle(title);
    };

    const handleTitleChange = (e) => {
        setEditingTitle(e.target.value);
    };

    const handleTitleBlur = (key) => {
        onRename(key, editingTitle);
        setEditingKey(null);
    };

    const handleTitleKeyDown = (e, key) => {
        if (e.key === 'Enter') {
            handleTitleBlur(key);
        }
    };

    const onNodeSelect = (selectedKeys, info) => {
        const key = info.node.key;
        if (key.startsWith('note-')) {
            onNoteClick(key);
        } else {
            toggleExpand(key);
        }
    };

    const renderTreeNodes = (data, parentIndex = 0) =>
        data.map((item, index) => {
            const opacityStyle = item.key === draggingKey ? { opacity: 0.1 } : {};
            const currentIndex = index + 1; // Index for children always starts from 1
            return (
                <Tree.TreeNode
                    key={item.key}
                    title={
                        editingKey === item.key ? (
                            <input
                                value={editingTitle}
                                onChange={handleTitleChange}
                                onBlur={() => handleTitleBlur(item.key)}
                                onKeyDown={(e) => handleTitleKeyDown(e, item.key)}
                                autoFocus
                            />
                        ) : (
                            <span
                                onDoubleClick={() => handleDoubleClick(item.key, item.title)}
                                className={item.isLeaf ? "leaf-node" : "non-leaf-node"}
                                style={{
                                    display: item.isLeaf ? "block" : "inline",
                                    lineHeight: "22px",
                                    border: item.isLeaf ? "1px solid rgb(240,240,240)" : "none",
                                    paddingLeft: item.isLeaf ? "2px" : "0px",
                                    width: "125px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    borderRadius: "5px",
                                    marginLeft: item.isLeaf ? "-20px" : "0px",
                                    fontSize: item.isLeaf ? "11px" : "12px"
                                }}
                            >
                                <span
                                    style={{
                                        display: item.isLeaf ? 'inline' : 'inline-block',
                                        color: item.isLeaf ? 'blue' : 'black',
                                        width: "120px"
                                    }}
                                >{`${currentIndex}. ${item.title}`}</span>

                                {item.isLeaf ? null : (
                                    <DeleteOutlinedIcon
                                        onClick={() => onDeleteHandle(item.key)}
                                        style={{
                                            height: "14px",
                                            color: "lightgrey"
                                        }}
                                    />
                                )}
                            </span>
                        )
                    }
                    style={opacityStyle}
                    isLeaf={item.isLeaf}
                >
                    {item.children ? renderTreeNodes(item.children, 0) : null}
                </Tree.TreeNode>
            );
        });

    return (
        <Tree
            className="draggable-tree"
            showIcon={false}
            expandedKeys={internalExpandedKeys}
            draggable
            blockNode
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onExpand={(keys) => setInternalExpandedKeys(keys)}
            onSelect={onNodeSelect}
        >
            {renderTreeNodes(gData)}
        </Tree>
    );
};

export default DemoJsx;
