import React from 'react';
import './SpecialPreview.css';
import {ArrowDownIcon, ArrowUpIcon, LockClosedIcon} from "@heroicons/react/16/solid";

const renderIcon = (sharedOutgoingOrIncoming) => {
	if (sharedOutgoingOrIncoming === 'outgoing') {
		return <ArrowUpIcon className="icon-style" style={{color: 'red'}}/>;
	} else if (sharedOutgoingOrIncoming === 'incoming') {
		return <ArrowDownIcon className="icon-style" style={{color: 'blue'}}/>;
	} else {
		// return <LockClosedIcon className="icon-style" style={{color: 'green'}}/>;
        return <span/>;
	}
};

const SpecialPreview = ({title, index, cardWidth, noteKey, notePress, mappedImgObj, sharedOutgoingOrIncoming}) => {
	const shareIcon = renderIcon(sharedOutgoingOrIncoming);
	
	return (
		<div
			onClick={() => notePress(noteKey)}
			style={{
				fontSize: "12px",
				width: `${cardWidth}px`,
				height: "150px",
				boxSizing: "border-box",
				border: "1px solid lightgrey",
				backgroundColor: "white",
				borderRadius: "10px",
				overflow: "hidden",
				position: "relative",
				cursor: "pointer"
			}}
		>
			<div style={{padding: "10px", textAlign: "center"}}>
				{index + 1}. {title}
			</div>
			{mappedImgObj && (
				<img src={URL.createObjectURL(mappedImgObj)} alt={title} style={{
					width: "100%", height: "auto",
					objectFit: "contain"
				}}/>
			)}
			<div className="icon-container">
				{shareIcon}
			</div>
		</div>
	);
};

export default SpecialPreview;
