import React, { useState } from "react";
import { FaLinkedin, FaGithub, FaInstagram, FaFacebook } from "react-icons/fa";
import DriveFileRenameOutlineSharpIcon from '@mui/icons-material/DriveFileRenameOutlineSharp';

const themes = [
  { name: "Default", bgColor: "bg-color2" },
  { name: "Classic", bgColor: "bg-white" },
  { name: "Dark", bgColor: "bg-gray-700" },
  { name: "Ocean", bgColor: "bg-[#0077b6]" },
  { name: "Forest", bgColor: "bg-green-600" },
  { name: "Rose", bgColor: "bg-[#f94144]" },
];

const AuthorSidebar = ({
  author,
  bgColor,
  setBgColor,
  handleProfileImageChange,
  setAuthor,
  isEditingName,
  setIsEditingName,
  isEditingBio,
  setIsEditingBio,
}) => {
  const [hoveredSection, setHoveredSection] = useState(null);

  return (
    <div
    style={{ backgroundColor: bgColor }}
       className={`pt-8 xl:w-[23vw] xl:sticky pb-12 xl:top-[5.2rem] ${bgColor.startsWith("bg") ? bgColor : ``} xl:h-[85vh] w-full flex flex-col pl-10 pr-8 gap-y-4 shadow-lg xl:shadow-xl xl:rounded-br-[4rem] transition-all duration-300`}
    >
      {/* Profile Photo Section */}
      <div
        className="relative group w-32 h-32 md:w-40 md:h-40"
        onMouseEnter={() => setHoveredSection("photo")}
        onMouseLeave={() => setHoveredSection(null)}
      >
        <img
          className="w-full h-full rounded-full border-4 border-white shadow-lg object-cover"
          src={author.profilePhoto}
          alt="Profile"
        />
        {hoveredSection === "photo" && (
          <DriveFileRenameOutlineSharpIcon
            className="absolute top-2 right-2 text-gray-500 bg-white p-1 rounded-full cursor-pointer"
            onClick={() => document.getElementById("profilePhotoInput").click()}
          />
        )}
        <input
          id="profilePhotoInput"
          type="file"
          className="hidden"
          onChange={handleProfileImageChange}
        />
      </div>

      {/* Name Section */}
      <div
        className="relative group text-center md:text-left"
        onMouseEnter={() => setHoveredSection("name")}
        onMouseLeave={() => setHoveredSection(null)}
      >
        {isEditingName ? (
          <input
            type="text"
            value={author.name}
            onChange={(e) => setAuthor({ ...author, name: e.target.value })}
            onBlur={() => setIsEditingName(false)}
            className="text-xl md:text-2xl font-semibold border-2 border-gray-300 p-2 rounded-md shadow-md w-full"
          />
        ) : (
          <h1
            className="text-xl md:text-2xl font-bold cursor-pointer"
            onClick={() => setIsEditingName(true)}
          >
            {author.name}
          </h1>
        )}
        {hoveredSection === "name" && (
          <DriveFileRenameOutlineSharpIcon
            className="absolute top-[-6px] left-0 text-gray-500 bg-white p-1 rounded-full cursor-pointer border-2"
            onClick={() => setIsEditingName(true)}
          />
        )}
      </div>

      {/* Bio Section */}
      <div
        className="relative group text-center md:text-left"
        onMouseEnter={() => setHoveredSection("bio")}
        onMouseLeave={() => setHoveredSection(null)}
      >
        {isEditingBio ? (
          <textarea
            value={author.bio}
            onChange={(e) => setAuthor({ ...author, bio: e.target.value })}
            onBlur={() => setIsEditingBio(false)}
            className="text-md font-medium border-2 border-gray-300 p-2 rounded-md shadow-md w-full h-20"
          />
        ) : (
          <p
            className="text-md font-medium cursor-pointer"
            onClick={() => setIsEditingBio(true)}
          >
            {author.bio}
          </p>
        )}
        {hoveredSection === "bio" && (
          <DriveFileRenameOutlineSharpIcon
            className="absolute top-0 left-0 text-gray-500 bg-white p-1 rounded-full cursor-pointer border-2"
            onClick={() => setIsEditingBio(true)}
          />
        )}
      </div>

      {/* Social Media Links */}
      <div className="flex justify-around w-full max-w-sm mt-4 p-2 py-3 bg-gray-200 rounded-lg shadow-md">
        <a href={author.socials.linkedin} target="_blank" className="text-blue-700">
          <FaLinkedin className="w-6 h-6 hover:scale-110 transition-transform" />
        </a>
        <a href={author.socials.github} target="_blank" className="text-gray-800">
          <FaGithub className="w-6 h-6 hover:scale-110 transition-transform" />
        </a>
        <a href={author.socials.instagram} target="_blank" className="text-pink-600">
          <FaInstagram className="w-6 h-6 hover:scale-110 transition-transform" />
        </a>
        <a href={author.socials.facebook} target="_blank" className="text-blue-600">
          <FaFacebook className="w-6 h-6 hover:scale-110 transition-transform" />
        </a>
      </div>

      {/* Theme Selection */}
      <div className="flex flex-wrap gap-2 mt-6 justify-center md:justify-start">
        {themes.map((theme) => (
          <button
            key={theme.name}
            className={`${theme.bgColor} w-5 h-5 rounded-full border-2 border-white cursor-pointer shadow-md`}
            onClick={() => setBgColor(theme.bgColor)}
            title={theme.name}
          />
        ))}
        <input
          type="color"
          style={{ backgroundColor: bgColor }}
          className={`w-7 h-7 rounded-md cursor-pointer shadow-md border-2 border-white`}
          onChange={(e) => setBgColor(e.target.value)}
          title="Custom"
        />
      </div>

      {/* Save Button */}
      <button
        className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition-colors"
        onClick={() => {
          setIsEditingName(false);
          setIsEditingBio(false);
        }}
      >
        Update Profile
      </button>
    </div>
  );
};

export default AuthorSidebar;
