import React from "react";
import CryptoJS from "crypto-js";
import pako from "pako";
import {exportToBlob} from "@excalidraw/excalidraw";

export function generateDocId() {
	const timestamp = new Date().getTime(); // Current time in milliseconds since the Unix Epoch
	const randomPart = Math.random().toString(36).substring(2, 15); // Generate a random string
	const docId = `${timestamp}-${randomPart}`;
	return docId;
}

export const zoomToFit = (api) => {
	const elements = api.getSceneElements();
	if (elements.length > 0) {
		api.scrollToContent(elements, {
			fitToViewport: true,
			viewportZoomFactor: 0.9,
		});
	}
};


export const calculateAverage = (arr) => arr.reduce((sum, value) => sum + value, 0) / arr.length;

export function encrypt(text, secretKey) {
	return CryptoJS.AES.encrypt(text, secretKey).toString();
}

export function decrypt(encryptedText, secretKey) {
	const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
	const originalText = bytes.toString(CryptoJS.enc.Utf8);
	return originalText ? originalText : null;  // Return null if decryption fails to provide a usable output
}

export const compress = (data) => {
	const binaryData = pako.deflate(JSON.stringify(data));
	let str = binaryData.reduce((acc, val) => acc + String.fromCharCode(val), '');
	return btoa(str);
};

export const decompress = (data) => {
	try {
		const strData = atob(data);
		const charData = strData.split('').map(c => c.charCodeAt(0));
		const binaryData = new Uint8Array(charData);
		const decompressed = pako.inflate(binaryData, {to: 'string'});
		return JSON.parse(decompressed);
	} catch (error) {
		console.error('Decompression failed:', error);
		return null;  // Return null to indicate failure
	}
};

export async function generatePreviewImage(noteContent, email, setBlobURL) {
	try {
		const content = decompress(decrypt(noteContent, email));
		const formattedData = {};
		Object.keys(content.files).forEach(key => {
			const file = content.files[key];
			formattedData[file.id] = {
				mimeType: file.mimeType,
				id: file.id,
				dataURL: file.dataURL
			};
		});
		const response = await exportToBlob({
			quality: 0.1,
			elements: content.elements,
			files: formattedData,
			mimeType: "image/jpeg",
			exportBackground: false,
			// exportPadding: 10
		});
		setBlobURL(URL.createObjectURL(response));
	} catch (e) {
		setBlobURL(null);
	}
}
	