import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { javascript } from '@codemirror/lang-javascript';
import { java } from '@codemirror/lang-java';
import { faCopy, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CodeBlock = ({ language, code , codeFolded=false}) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [isFolded, setIsFolded] = useState(codeFolded); // State to control folding

  const getLanguageExtension = (lang) => {
    switch (lang) {
      case 'python':
        return python();
      case 'javascript':
        return javascript();
      case 'java':
        return java();
      default:
        return python(); // Default to Python if the language is empty or invalid
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleCopy = () => {
    setAlertVisible(true); // Show alert when copy button is clicked
    setTimeout(() => {
      setAlertVisible(false); // Hide alert after 1 second
    }, 1000);
  };

  return (
    <div className="code-block" style={{ position: 'relative' }}>
      <div
        className="toggle-btn"
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '0px',
        }}
        onClick={() => setIsFolded(!isFolded)}
      >
        <FontAwesomeIcon icon={isFolded ? faChevronDown : faChevronUp} style={{ marginLeft: '-11px', fontSize:"14px",
          color:"lightgrey",width:"10px" }} />
        {/*<span style={{fontSize:"12px", margin:"0px", padding:"0px"}}>{capitalizeFirstLetter(language)} Code</span>*/}
      </div>

      {!isFolded && (
        <>
          <CopyToClipboard text={code} onCopy={handleCopy}>
            <button
              className="copy-btn"
              style={{
                position: 'absolute',
                right: '-10px',
                top: '0px',
                height: '20px',
                lineHeight: '20px',
                padding: '0 8px',
                fontSize: '12px',
                zIndex: 10,
              }}
            >
              <FontAwesomeIcon className="copy-icon" icon={faCopy} style={{ color: 'lightgrey' }} />
            </button>
          </CopyToClipboard>

          {alertVisible && (
            <div
              className="alert bg-gray-50"
              style={{
                position: 'absolute',
                top: '5px',
                right: '35px',
                color: 'grey',
                padding: '0px',
                borderRadius: '4px',
                height: '20px',
                lineHeight: '20px',
                zIndex: 100,
              }}
            >
              Copied!
            </div>
          )}

          <div className="code-mirror-container">
            <CodeMirror
              value={code}
              extensions={[getLanguageExtension(language)]}
              readOnly={false}
              className="code-editor"
              style={{ fontSize: '13px', lineHeight: '1.5' }}
              basicSetup={{
                lineNumbers: false,
                foldGutter: false,
                highlightActiveLine: false,
                highlightActiveLineGutter: false,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CodeBlock;
