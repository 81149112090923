import React, {useRef, useState} from 'react';
import axios from 'axios';
import Button from "@mui/material/Button";
import {getBaseUrlNodeServer} from "../../../../../config";

function PdfUpload({getAllPdfData, verifiedemail}) {
	const [file, setFile] = useState(null);
	const fileInputRef = useRef(null);
	
	const handleClick = () => {
		fileInputRef.current.click(); // Open file dialog
	};
	
	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	};
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!file) {
			alert('Please select a file first!');
			return;
		}
		if (file.type !== "application/pdf") {
			alert("Only PDF files are allowed!");
			return;
		}
		// Check if the file size is less than 10MB
		// Note: 1MB = 1024 * 1024 bytes
		const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
		if (file.size > maxSizeInBytes) {
			alert("File size must be less than 10MB!");
			return;
		}
		
		const formData = new FormData();
		formData.append('pdf', file);
		formData.append('verifiedemail', verifiedemail)
		
		try {
			axios.defaults.baseURL = getBaseUrlNodeServer();
			await axios.post('/uploadResumePdf', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			getAllPdfData();
			alert('File uploaded successfully');
			window.location.reload();
		} catch (error) {
			console.error('Error uploading file:', error);
			alert('Error uploading file');
		}
	};
	
	return (
		<div style={{
			color: "grey", marginTop: "0px", marginBottom: "0px",
			height: "40px",
			backgroundColor: "white",
			border: "1px solid lightgrey",
			borderRadius: "10px",
			marginLeft: "10px",
			paddingLeft: "30px",
			paddingRight: "10px",
			paddingTop: "3px",
			// width: "90px",
			paddingBottom: "10px"
		}}>
			<form onSubmit={handleSubmit}>
				<input type="file" id="file" onChange={handleFileChange} accept="application/pdf" style={{
					fontSize: "11px", color: "grey"
				}}/>
				<Button variant='outlined' style={{
					// marginTop: "20px",
					height: "30px",
					textTransform: "none", fontSize: "11px"
				}} type='submit'>Upload
					Pdf</Button>
			</form>
		</div>
	);
}

export default PdfUpload;
