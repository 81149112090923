import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { TestimonialTextData } from "./ReviewData";
import { LinkedIn, GitHub, Instagram } from "@mui/icons-material";
import PortraitIcon from "@mui/icons-material/Portrait";
import TestimonialModal from "./TestimonialModal"; // Import the modal component
import HeadindText from "../Utils/HeadindText";

const Testimonials = () => {
  const location = useLocation();
  const showAllTestimonials = location.pathname.endsWith("/testimonials");

  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const testimonialsToShow = showAllTestimonials
    ? TestimonialTextData.feedBacks
    : TestimonialTextData.feedBacks.slice(0, 5);

  const handleOpenModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTestimonial(null);
  };

  return (
    <div className="w-full">
      <section
        id="testimonials"
        className="py-12 bg-gradient-to-b from-gray-50 to-white sm:py-16 lg:py-20 mt-4"
      >
        <div className="px-4 mx-auto w-[70vw] sm:px-6 lg:px-8">
          <div className="flex flex-col items-center">
            <div className="text-center">
              <p className="text-lg font-medium text-gray-600 font-pj">
                People have said how good AceHub is
              </p>
              <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
                <HeadindText
                  Heading={` Our happy clients share their Success Stories`}
                />
              </h2>
            </div>

            <div className="relative mt-10 md:mt-24">
              <div
                className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-auto`}
              >
                {testimonialsToShow.map((feedback, index) => (
                  <div
                    key={index}
                    className={`flex flex-col shadow-2xl transform transition-transform duration-700 rounded-xl z-[1] group mb-10 w-full`}
                  >
                    <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-6 lg:px-7 rounded-2xl border-2">
                      <blockquote className="flex-1 mb-8 pb-4">
                        <p className="text-md leading-relaxed text-gray-900 font-pj">
                          “{feedback.text.slice(0, 200)}
                          {feedback.text.length > 200 ? "..." : ""}”
                        </p>
                      </blockquote>

                      <div
                        className={`transition-all duration-500 rounded-b-2xl ease-in-out bg-gray-700 text-white -m-6 lg:-my-8 lg:-mx-7 ${
                          showAllTestimonials
                            ? "group-hover:bg-zinc-950"
                            : "group-hover:scale-105 group-hover:bg-zinc-950"
                        }`}
                      >
                        <div className="relative select-none flex items-start gap-3 p-4">
                          <img
                            className="duration-700 select-none group-hover:scale-110 flex-shrink-0 object-cover rounded-full group-hover:rounded-lg w-14 h-14 shadow-2xl"
                            src={feedback.imageUrl}
                            alt={feedback.person}
                          />
                          <div className="flex flex-col items-start w-full">
                            <p className="text-base text-left font-bold text-white cursor-pointer">
                              {feedback.person}
                            </p>
                            <p className="text-sm text-left text-gray-300">
                              {feedback.designation}
                            </p>
                            <p className="text-sm text-left text-gray-300">
                              {feedback.location}
                            </p>
                          </div>
                          <div
                            className={`flex absolute bottom-0 -mb-9 group-hover:bg-zinc-950 bg-gray-700 transition-all duration-200 rounded-b-xl w-full left-0 items-center py-3 justify-around flex-row gap-3 cursor-pointer ${
                              showAllTestimonials
                                ? ""
                                : "opacity-0 group-hover:opacity-100"
                            }`}
                          >
                            <a
                              href={feedback.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkedIn className="transition-all duration-200 text-white hover:text-gray-400" />
                            </a>
                            <a
                              href={feedback.github}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <GitHub className="transition-all duration-200 text-white hover:text-gray-400" />
                            </a>
                            <a
                              // href={feedback.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <PortraitIcon
                                onClick={() => handleOpenModal(feedback)}
                                className="transition-all duration-200 text-white hover:text-gray-400"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Show link to all testimonials if not on the testimonials page */}
            {!showAllTestimonials && (
              <div className="mt-8 text-center md:mt-16">
                <a
                  href="/testimonials"
                  className="pb-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 border-b-2 border-gray-900 hover:border-gray-600 font-pj"
                >
                  See All Testimonials
                </a>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Modal for full testimonial */}
      <TestimonialModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        testimonial={selectedTestimonial}
      />
    </div>
  );
};

export default Testimonials;
