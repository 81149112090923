import { BiBookBookmark } from "react-icons/bi";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FaCartPlus, FaDiscord } from "react-icons/fa";
import { FiLock, FiPlayCircle } from "react-icons/fi";
import { FaFlag } from "react-icons/fa";
import ReactPlayer from "react-player";
import React, { useEffect, useState } from "react";

import { Projects } from "../../Components/particles/DataLists.tsx";
import { useSearchParams } from "react-router-dom";

export default function ProjectComp() {
  // get id from URL
  const [searchParams] = useSearchParams();
  // const { slugId } = useParams();
  const [data, setData] = useState(Projects[0]);

  useEffect(() => {
    const slugId = searchParams.get("id");
    const p = Projects.find((p) => p.id === slugId);
    setData(p || Projects[0]);
  }, []);

  const rating = 4;
  return (
    <main className="pt-24 lg:h-screen h-fit">
      <div className="w-screen h-96 px-12 py-16 bg-gradient-to-br from-color3 to-color7 -mt-2">
        <div className="w-full max-w-[1300px] mx-auto flex flex-col gap-6 md:flex-row justify-between items-start">
          <div className="flex flex-col gap-1">
            <h1 className="text-2xl font-semibold text-white">{data.title}</h1>
            <p className="opacity-60 text-sm text-white">{data.description}</p>
            <div className="flex gap-4 mt-3">
              <span className="flex gap-1 items-center justify-center text-gray-300">
                <BiBookBookmark className="text-gray-400 text-lg" />{" "}
                {data.stats.chapters} lessons
              </span>

              <span className="pl-4 border-l border-gray-500 flex gap-1 items-center justify-center text-gray-300">
                {Array.from({ length: rating }).map((_, index) => (
                  <AiFillStar className="text-color2 text-lg" />
                ))}
                {Array.from({ length: 5 - rating }).map((_, index) => (
                  <AiOutlineStar className="opacity-50 text-lg" />
                ))}
                {rating} (223 ratings)
              </span>
            </div>
          </div>
          <div className="flex gap-3">
            <button className="px-5 py-2 border bg-color2 border-gray-400 text-white font-semibold h-auto rounded-lg border-opacity-10">
              Enroll in Project
            </button>
            <button className="px-3 py-2 border bg-color6 bg-opacity-10 border-gray-400 text-white font-semibold h-auto rounded-lg border-opacity-10">
              <FaCartPlus />
            </button>
            <button className="px-3 py-2 border bg-[#5865F2] bg-opacity-90 border-gray-400 text-white font-semibold h-auto rounded-lg border-opacity-10">
              <FaDiscord />
            </button>
          </div>
        </div>
      </div>
      <div className="max-w-[1400px] overflow-y-auto mx-auto bg-white border border-gray-200 rounded-t-3xl md:h-[72vh] min-h-[72vh] md:-mt-44 -mt-32 pb-24 md:pb-0 px-8 py-8 lg:grid lg:grid-cols-2 flex flex-col-reverse gap-12">
        <div className="w-full bg-white overflow-y-auto mx-auto max-h-[700px]">
          <details open className="mb-2 bg-color2 rounded-xl">
            <summary className="cursor-pointer p-3 font-semibold text-white">
              Module 1: Building TinyGPT from Scratch
            </summary>

            <div>
              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50">
                <div className="flex items-center space-x-3">
                  <FiPlayCircle />
                  <div className="text-sm font-medium">
                    Introduction: What Even is TinyGPT?
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">01:23:45</span>
                  <FiLock />
                </div>
              </div>

              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50">
                <div className="flex items-center space-x-3">
                  <FiPlayCircle />
                  <div className="text-sm font-medium">
                    The Foundation: Neural Nets 101
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">00:45:00</span>
                  <FiLock />
                </div>
              </div>

              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50">
                <div className="flex items-center space-x-3">
                  <FiPlayCircle />
                  <div className="text-sm font-medium">
                    Training TinyGPT: Just a Few Steps
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">02:30:15</span>
                  <FiLock />
                </div>
              </div>

              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50 rounded-b-xl">
                <div className="flex items-center space-x-3">
                  <FaFlag className="text-color2" />
                  <div className="text-sm font-medium">
                    Testing and Tweaking
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">01:10:59</span>
                  <FiLock />
                </div>
              </div>
            </div>
          </details>

          <details className="mb-2 bg-gray-100 rounded-xl">
            <summary className="cursor-pointer p-3 font-semibold text-gray-600">
              Module 2: TinyGPT, But Better
            </summary>

            <div>
              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50">
                <div className="flex items-center space-x-3">
                  <FiPlayCircle />
                  <div className="text-sm font-medium">
                    Making It Faster: Optimizing TinyGPT’s Training
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">00:50:15</span>
                  <FiLock />
                </div>
              </div>

              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50">
                <div className="flex items-center space-x-3">
                  <FiPlayCircle />
                  <div className="text-sm font-medium">
                    Shrinking TinyGPT: Model Compression
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">01:10:30</span>
                  <FiLock />
                </div>
              </div>

              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50 rounded-b-xl">
                <div className="flex items-center space-x-3">
                  <FaFlag className="text-color2" />
                  <div className="text-sm font-medium">
                    Advanced Fine-Tuning
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">01:25:45</span>
                  <FiLock />
                </div>
              </div>
            </div>
          </details>

          <details className="mb-2 bg-gray-100 rounded-xl">
            <summary className="cursor-pointer p-3 font-semibold text-gray-600">
              Module 3: Shipping TinyGPT to Production
            </summary>

            <div>
              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50">
                <div className="flex items-center space-x-3">
                  <FiPlayCircle />
                  <div className="text-sm font-medium">
                    Setting Up Deployment Pipelines
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">00:55:10</span>
                  <FiLock />
                </div>
              </div>

              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50">
                <div className="flex items-center space-x-3">
                  <FiPlayCircle />
                  <div className="text-sm font-medium">
                    Monitoring TinyGPT in the Wild
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">01:15:50</span>
                  <FiLock />
                </div>
              </div>

              <div className="flex px-6 border-y items-center justify-between p-2 py-4 bg-gray-50 rounded-b-xl">
                <div className="flex items-center space-x-3">
                  <FaFlag className="text-color2" />
                  <div className="text-sm font-medium">
                    Scaling TinyGPT to Handle the Masses
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="text-xs text-gray-500">01:45:30</span>
                  <FiLock />
                </div>
              </div>
            </div>
          </details>
        </div>

        <div className="w-full flex flex-col gap-3">
          <h3 className="text-2xl font-semibold mb-3">Introduction</h3>

          <ReactPlayer
            url="https://blogs-images-and-videos.del1.vultrobjects.com/test1@gmail.com/cbedad86-bfb6-46fa-9440-2dd21a32c7ab/output.m3u8"
            controls
            width="100%"
            height="320px" // Adjust height as needed
            className="rounded-lg shadow-lg border border-gray-700"
            config={{
              file: {
                forceHLS: true, // Ensures that HLS is used
                hlsOptions: {
                  debug: false, // Disable debug mode unless needed
                },
              },
            }}
          />

          <div className="flex mt-5 flex-col gap-1 ml-5">
            <h4 className="text-xl font-medium underline">Description</h4>
            <p className="text-sm">
              Get ready to work with your very little own Tiny-GPT made from
              scratch
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
