import React, { useState, useEffect } from 'react';

const UpdatedAudioChatbot = () => {
  const [transcriber, setTranscriber] = useState(null);
  const [generator, setGenerator] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const loadPipelineScript = () => {
      const script = document.createElement("script");
      script.type = "module";
      script.innerHTML = `
        import { pipeline } from 'https://cdn.jsdelivr.net/npm/@xenova/transformers@2.5.4';
        window.pipeline = pipeline;
      `;
      document.body.appendChild(script);
    };

    const initializeModels = async () => {
      try {
        console.log("Loading models...");
        const transcriberPipeline = await window.pipeline(
          'automatic-speech-recognition',
          'Xenova/whisper-tiny.en',
          { useMemoryEfficientWeights: true } 
        );
        const generatorPipeline = await window.pipeline(
          'text2text-generation',
          'Xenova/LaMini-Flan-T5-783M',
          { useMemoryEfficientWeights: true }
        );
        setTranscriber(transcriberPipeline);
        setGenerator(generatorPipeline);
        console.log("Models loaded successfully.");
      } catch (error) {
        console.error("Error loading models:", error);
        addMessage("Error loading models.", 'bot-message');
      }
    };
    

    initializeModels();
  }, []);

  const addMessage = (message, className) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, className },
    ]);
  };

  const handleUserInput = async () => {
    const userInput = document.getElementById('user-input').value.trim();
    if (!userInput) return;

    addMessage("You: " + userInput, 'user-message');
    document.getElementById('user-input').value = "";
    await generateResponse(userInput);
  };

  const toggleRecording = () => {
    setIsRecording((prevIsRecording) => !prevIsRecording);
    if (!isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) =>
        setAudioChunks((prev) => [...prev, event.data]);
      recorder.start();
      setMediaRecorder(recorder);
      console.log("Recording started.");
    } catch (error) {
      console.error("Error starting recording:", error);
      addMessage("Error starting recording.", 'bot-message');
    }
  };

  const stopRecording = () => {
    console.log("Recording stop.");
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks);
        const url = URL.createObjectURL(audioBlob);
        try {
          if (transcriber) {
            const result = await transcriber(url);
            const transcribedText = result?.text || "Error in transcription.";
            addMessage("You (voice): " + transcribedText, 'user-message');
            await generateResponse(transcribedText);
          } else {
            addMessage("Transcriber model not loaded.", 'bot-message');
          }
        } catch (error) {
          console.error("Error in transcription:", error);
          addMessage("Error in transcription.", 'bot-message');
        }
      };
    }
  };

  const generateResponse = async (inputText) => {
    try {
  
      const sanitizedInput = String(inputText);
  
      const result = await generator(sanitizedInput, { max_new_tokens: 100 });
      addMessage("Bot: " + result, 'bot-message');
      speakText(result);
    } catch (error) {
      console.error("Error in response generation:", error);
      addMessage("Error in response generation: " + error.message, 'bot-message');
    }
  };
  

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className="flex flex-col container mx-auto p-5 max-w-7xl mt-[90px]">
      <h1 className="text-center text-2xl font-bold text-gray-800 mb-4">
        Conversational Voice Chatbot
      </h1>

      <div
        id="chat-container"
        className="h-[70vh] p-4 overflow-y-auto border rounded-lg bg-gray-50 shadow-lg"
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`my-2 p-3 rounded-lg max-w-[75%] ${
              message.className === 'user-message'
                ? 'bg-blue-100 text-blue-900 ml-auto text-right'
                : 'bg-green-100 text-green-900 mr-auto text-left'
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>

      <div className="mt-3 flex gap-2">
        <input
          id="user-input"
          type="text"
          placeholder="Type your message..."
          className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
        />
        <button
          onClick={handleUserInput}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          Send
        </button>
        <button
          onClick={toggleRecording}
          className={`px-4 py-2 rounded-md ${
            isRecording ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-600 hover:bg-gray-700'
          } text-white`}
        >
          {isRecording ? '⏹️' : '🎙️'}
        </button>
      </div>
    </div>
  );
};

export default UpdatedAudioChatbot;
