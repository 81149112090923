import React, {useState, useRef, useCallback, useEffect} from "react";
import {Excalidraw, useHandleLibrary} from "@excalidraw/excalidraw";
import {debounce} from 'lodash';
import {default as softwareDiagramComps} from "../libraryItems/architecture-diagram-components";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Button from "@mui/material/Button";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
// import initialData from "./initialData";
import {compress, decompress, decrypt, encrypt, zoomToFit} from "./Utils";
import axios from "axios";
import {getAxiosBaseUrl} from "../../config";
import {toast, ToastContainer} from "react-toastify";
import {XMarkIcon} from "@heroicons/react/24/solid";
import 'react-toastify/dist/ReactToastify.css';

// Must not talk to apis and must get all the required data in the call.
// Must call param functions for state changes or updates.
// Should know about when a button should be active and when a button should be inactive.
// Example if someone makes their friend an editor instead of viewer on a shared document, then this should know.
function ExcalidrawWrapper({
	                           currNote,
	                           currDocId,
	                           owner,
	                           role,
	                           verifiedemail,
	                           notetitle,
	                           excalidrawAPI,
	                           setExcalidrawAPI,
	                           deletNoteClicked,
	                           openModal, handleClose, saveButtonTriggered,
	                           heightCustom=100,widthCustom=100
                           }) {
	const [excalidrawAPIState, setExcalidrawAPIState] = useState(null);
	const useCaseDesc = 'excalidraw';
	const [autoSaveCount, setAutoSaveCount] = useState(0);
	const excalidrawRef = useRef(null);
	const [shouldSave, setShouldSave] = useState(false);
	const [currElements, setCurrElements] = useState([]);
	const [currAppState, setCurrAppState] = useState([]);
	const [imgFiles, setFiles] = useState({});
	const [isEditor, setIsEditor] = useState(false);
	const [sharedByEmail, setSharedByEmail] = useState('');
	const [initialData, setInitialData] = useState({
		elements: [], appState: {}, files: {},
		scrollToContent: {fitToContent: true},
		// libraryItems: softwareDiagramComps['libraryItems']
	});
	const loadNoteById = () => {
		try {
			const content = decompress(decrypt(currNote['content'], owner));
			setCurrElements(content.elements);
			setCurrAppState({});
			
			const formattedData = {};
			Object.keys(content.files).forEach(key => {
				const file = content.files[key];
				formattedData[file.id] = {
					mimeType: file.mimeType, id: file.id, dataURL: file.dataURL, created: file.created,
				};
			});
			setFiles(formattedData);
			
			setInitialData({
				elements: content.elements || [], // appState: [],
				// appState: appState || {
				appState: {
					zenModeEnabled: false, viewBackgroundColor: "#efeff6", currentItemFontFamily: "Helvetica", viewModeEnabled: false
				},
				files: formattedData,
				scrollToContent: {fitToContent: true}, // libraryItems: softwareDiagramComps['libraryItems'].concat(initialData['libraryItems'])
			});
		} catch (e) {
			console.error("Error loading note:", e);
		}
	};
	
	useEffect(() => {
		loadNoteById();
	}, [currNote]);
	
	const saveUserNoteUtility = (verifiedemail, useCaseDesc, flightData, currDocId, notetitle, pngBlob) => {
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('saveusernotes', {
			verifiedemail: verifiedemail, useCaseDesc: useCaseDesc, docid: currDocId, content: flightData, notetitle: notetitle, bgcolor: 'lightblue'
		}, {
			withCredentials: true
		}).then((response) => {
		});
	};
	
	useHandleLibrary({excalidrawAPI});
	
	useEffect(() => {
		if (shouldSave) {
			saveButtonTriggered(currDocId, notetitle);
			setShouldSave(false); // Reset the flag
		}
	}, [shouldSave]);
	
	const handleChange = useCallback((elements, state, files) => {
		setCurrElements(elements);
		setCurrAppState(state);
		setFiles(files);
		setAutoSaveCount(prevCount => prevCount + 1);
	}, []);
	
	const debouncedHandleChange = useCallback(debounce(handleChange, 1000), [handleChange]);
	
	useEffect(() => {
		return () => {
			debouncedHandleChange.cancel(); // Cancel debounced calls on unmount
		};
	}, [debouncedHandleChange]);
	
	useEffect(() => {
		const handleKeyDown = (event) => {
			if ((event.ctrlKey || event.metaKey) && event.key === 's') {
				event.preventDefault();
				event.stopPropagation(); //This prevent excalidraw event to happen
				saveButtonTriggered(currDocId, notetitle);
			}
		};
		
		const excalidrawElement = excalidrawRef.current;
		if (excalidrawElement) {
			excalidrawElement.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			if (excalidrawElement) {
				excalidrawElement.removeEventListener('keydown', handleKeyDown);
			}
		};
	}, [saveButtonTriggered]);
	
	function getSaveCapability() {
		console.log('isEditor', isEditor);
		console.log('sharedByEmail', sharedByEmail);
		console.log('verifiedemail', verifiedemail);
		
		if (isEditor) {
			return true;
		}
		if (sharedByEmail.length > 0 && sharedByEmail !== verifiedemail) {
			return false;
		}
		return true;
	}
	async function handleCloseLocal(){
		handleClose();
	}
	useEffect(() => {
		if (excalidrawAPIState) {
			// Use a small delay to ensure Excalidraw has finished loading
			const timeout = setTimeout(() => {
				zoomToFit(excalidrawAPIState);
			}, 10);
			return () => clearTimeout(timeout);
		}
	}, [excalidrawAPIState]);
	
	
	return (<>
		<ToastContainer/>
		{/*<div style={{display: "flex"}}>*/}
		{/*	<div style={{*/}
		{/*		display: "flex",*/}
		{/*		textAlign: "left",*/}
		{/*		color: "grey",*/}
		{/*		fontSize: "12px",*/}
		{/*		borderRadius: "10px",*/}
		{/*		width: "200px",*/}
		{/*		maxHeight: "30px",*/}
		{/*		marginBottom: "5px",*/}
		{/*		overflow: "scroll"*/}
		{/*	}}>*/}
		{/*		<span style={{color: "lightgrey", marginLeft: "10px"}}>Title: </span> {notetitle.length === 0 ? 'no title' : notetitle}*/}
		{/*	</div>*/}
		{/*	{owner && owner.length > 0 ?*/}
		{/*		<div style={{*/}
		{/*			textAlign: "left",*/}
		{/*			color: "grey",*/}
		{/*			fontSize: "12px",*/}
		{/*			width: "auto",*/}
		{/*			maxHeight: "30px",*/}
		{/*		}}>*/}
		{/*			<span style={{fontStyle: "italic", marginLeft: "100px", marginRight: "20px"}}>Role: {role}</span>*/}
		{/*			<span>Shared By: </span>*/}
		{/*			<span style={{textDecoration: "underline", fontStyle: "italic"}}>{owner}</span>*/}
		{/*		</div>*/}
		{/*		: <></>*/}
		{/*	}*/}
		{/*</div>*/}
		{/*	Left Top Icons*/}
		<div style={{position: "fixed", zIndex: 10000, marginTop: "-5px"}}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0'}}>
				{/*<XMarkIcon onClick={() => handleClose()} style={{width: "25px", height: "25px"}}/>*/}
				<Button
					style={{
						color: 'black',
						backgroundColor: 'white',
						width: '50px',
						fontWeight: 'bold',
						borderRadius: '10px',
						padding: '5px', fontSize: "12px",
						border: '1px dotted lightgrey',
					}}
					onClick={() => handleCloseLocal()}
				>
					<XMarkIcon style={{height: "20px"}}/>
				</Button>
				<Button
					style={{
						color: 'black',
						backgroundColor: 'white',
						width: '50px',
						fontWeight: 'bold',
						borderRadius: '10px',
						padding: '5px', fontSize: "12px",
						border: '1px dotted lightgrey',
					}}
					onClick={() => zoomToFit(excalidrawAPI)}
				>
					Fit
				</Button>
				<Button
					disabled={!(owner === '' || owner === verifiedemail || role === 'Editor')}
					style={{
						color: 'black',
						backgroundColor: 'white',
						width: '50px',
						fontWeight: 'bold',
						borderRadius: '10px',
						padding: '5px',
						border: '1px dotted lightgrey',
					}}
					onClick={() => saveButtonTriggered(currDocId, notetitle)}
				>
					<SaveOutlinedIcon style={{height: '16px'}}/>
				</Button>
				<Button
					disabled={!(owner === '' || owner === verifiedemail)}
					style={{
						fontWeight: 'bold',
						borderRadius: '10px',
						padding: '5px',
						border: '1px dotted lightgrey',
						backgroundColor: 'white',
					}}
					onClick={() => deletNoteClicked(useCaseDesc)}
				>
					<DeleteOutlinedIcon style={{height: '16px'}}/>
				</Button>
				<Button
					disabled={!(owner === '' || owner === verifiedemail)}
					style={{
						width: '50px', borderRadius: '10px', padding: '5px', border: '1px dotted lightgrey', backgroundColor: 'white',
					}}
					onClick={() => openModal()}
				>
					<IosShareOutlinedIcon style={{height: '16px'}}/>
				</Button>
				<Button
					disabled={true}
					style={{
						width: '50px', borderRadius: '10px', padding: '5px', border: '1px dotted lightgrey', backgroundColor: 'white',
					}}
					onClick={() => shareButtonEvent()}
				>
					<RadioButtonCheckedOutlinedIcon style={{height: '16px'}}/>
				</Button>
				<span style={{color: "grey", marginLeft: "20px", display: "flex", marginTop: "-10px"}}>
					<p>Owner: {owner}</p>
					<p style={{paddingLeft: "20px"}}>Role: {role}</p>
				</span>
			</div>
		</div>
		
		{/*<div ref={excalidrawRef} style={{width: '100%', height: '100%', zIndex: 1002, display: "contents"}}>*/}
		<div ref={excalidrawRef} style={{ width: `${widthCustom}%`, height: `${heightCustom}%`,
			zIndex: 1002}}>

			{initialData ?
				<Excalidraw
					key={currDocId}
					name={notetitle}
					isCollaborating={false}
					excalidrawAPI={(api) => {
						setExcalidrawAPI(api);
						setExcalidrawAPIState(api);
					}}
					UIOptions={{
						canvasActions: {
							save: true, saveAs: true, loadScene: true, export: false, saveAsImage: true, toggleTheme: false, clearCanvas: false,
						},
					}}
					initialData={initialData}
				>
				</Excalidraw> : <div/>}
		</div>
	</>);
}

export default ExcalidrawWrapper;
