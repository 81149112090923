import React from "react";
// @ts-ignore
import SimpleSideMenuProvider from "../SimpleFolderMenu/SimpleSideMenuProvider";

function SideMenu({ useCaseDesc, verifiedemail, folders, noteClickHandle, handleCreateFolder, handleRename, createNewButtonEvent,
    updateTreeStructure, handleRefresh, onDeleteFolderHandle
}){
  return (
    <div
      style={{
        // width: notesMenuCollapsed ? '50px' : isEditing ? '1040px' : '170px',
        width: '170px',
        background: 'white',
        paddingLeft: '0px',
        paddingRight: "0px",
        position: "fixed",
        height: "90vh",
        display: 'flex',
        flexDirection: 'column',
        // border: "1px solid lightgrey",
        overflow: 'scroll',
        marginTop: "0px",
      }}
    >
      <SimpleSideMenuProvider useCaseDesc={useCaseDesc} verifiedemail={verifiedemail} folders={folders} noteClickHandle={noteClickHandle}
                              handleCreateFolder={handleCreateFolder} handleRename={handleRename} createNewButtonEvent={createNewButtonEvent}
                              updateTreeStructure={updateTreeStructure} handleRefresh={handleRefresh} onDeleteFolderHandle={onDeleteFolderHandle}
      />
    </div>
  );
};

export default SideMenu;
