import React, { useState, useEffect } from 'react';

const CustomImage = ({ alt, src, title }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Default width and height
    let width = '600px';
    let height = '500px';

    // Parse the title for width and height (e.g., "width=300;height=200")
    if (title) {
        const dimensions = title.split(';');
        dimensions.forEach(dimension => {
            const [key, value] = dimension.split('=');
            if (key === 'width') {
                width = value;
            } else if (key === 'height') {
                height = value;
            }
        });
    }

    const handleImageClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup the event listener on component unmount or when modal closes
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    return (
        <>
            <img
                onClick={handleImageClick}
                src={src}
                alt={alt}
                style={{
                    width: width,
                    height: height,
                    display: 'block',
                    margin: '0 auto',
                    cursor: 'pointer' // Change cursor to pointer to indicate it's clickable
                }}
            />

            {isModalOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
                    onClick={closeModal}
                >
                    <div className="relative">
                        <img
                            src={src}
                            alt={alt}
                            style={{
                                maxWidth: '90vw',
                                maxHeight: '90vh',
                                minWidth: '50vw',  // Ensures the image is at least 50% of the viewport width
                                minHeight: '50vh', // Ensures the image is at least 50% of the viewport height
                                display: 'block',
                                margin: '0 auto',
                                objectFit: 'contain' // Maintain aspect ratio
                            }}
                        />
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-2 text-white text-lg"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomImage;
