// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDwXxK1u5MB1qa64gztK8yMULouyzBQzxA",
  authDomain: "applyjobs-b1d45.firebaseapp.com",
  projectId: "applyjobs-b1d45",
  storageBucket: "applyjobs-b1d45.appspot.com",
  messagingSenderId: "488713343562",
  appId: "1:488713343562:web:b19991c44c148af8b6ccb3",
  measurementId: "G-JY2K0Q38D9",
  databaseURL: "https://applyjobs-b1d45-default-rtdb.firebaseio.com/"
};


export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
