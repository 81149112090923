import create from "zustand";
import axios from "axios";
import {getAxiosBaseUrl} from "../../config";

axios.defaults.baseURL = getAxiosBaseUrl();

const lcStore = create((set, get) => ({
	problemAreas: [], problemDetails: [], tableData: [],
	setState: (key, values) => {
		set((state) => ({...state, [key]: values}));
	},
	setNotesReferenceToNull: (verifiedemail, probeIdList) => {
		if (probeIdList.length > 0) {
			axios.post('setNotesReferenceToNull', {
				verifiedemail: verifiedemail, probeIdList: probeIdList
			}, {withCredentials: true})
		}
	},
	getUserDSAProblemsData: (verifiedemail) => {
		//Get the user's dsa problem set from DSA_Practice_Collection
		axios.post('fetcUserLcSet', {
			verifiedemail: verifiedemail
		}, {withCredentials: true}).then((response) => {
			const problemAreas1 = response.data;
			const problemAreas = problemAreas1['problemAreas']['problemAreas'];
			const problemDetails = problemAreas1['problemDetails']['problems'];
			
			const newRows = [];
			Object.keys(problemAreas).map(area => problemAreas[area].map(questionId => {
				const matchRecord = problemDetails[questionId]
				const rowData = {
					status: (matchRecord && matchRecord['status']) || false,
					problem: (matchRecord && matchRecord['Question Title']) || '',
					practice: (matchRecord && matchRecord['Question Slug']),
					category: area,
					note: (matchRecord && matchRecord['note']) || null,
					difficulty: (matchRecord && matchRecord['Difficulty Level']),
					revision: (matchRecord && matchRecord['revision']) || false,
					probId: questionId,
				}
				newRows.push(rowData);
			}));
			set({tableData: newRows});
			set({problemAreas: problemAreas});
			set({problemDetails: problemDetails});
		})
		
	},
	setUserDSAProbDetailField: (verifiedemail, problemId, fieldName, fieldValue) => {
		// console.log(verifiedemail, problemId, fieldName, fieldValue);
		axios.post('setUserLcProblemFieldValue', {
			verifiedemail: verifiedemail,
			problemId: problemId,
			fieldName: fieldName,
			fieldValue: fieldValue
		})
	}
}));

export default lcStore;