import "./mailchamp.css";
import React, { useState } from "react";

export default function EmailSubscribe({
  buttonText = "Subscribe For Updates",
  transientStateMsg = "Subscribing",
  btnBackgroundColor = "white",
  btnColor = "black",
  btnMarginTop = "0px",
  sourceInfo = "Not Provided",
  userMsg = "Not Provided",
  alignItems = "center",
  emaildata
}) {
  const [formData, setFormData] = useState({
    email: emaildata,
  });
  const [subscribing, setSubscribing] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  async function handleSubmit(event) {
    event.preventDefault();
    setSubscribing(true);
    document.getElementById("subscribeMailChampBtn").innerText =
      transientStateMsg;

    try {
      const response = await fetch(
        "https://mailchamp.ai:8080/clientsubscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      document.getElementById("subscribeMailChampBtn").innerText =
        "Confirmed !";
      setFormData({ email: "" });
    } catch (error) {
      console.error("There was an error!", error);
      document.getElementById("subscribeMailChampBtn").innerText = "Subscribe"; // Reset button text on error
    } finally {
      setSubscribing(false); // Ensure subscribing state is reset
    }
  }

  return (
    <div style={{ textAlign: alignItems }}>
      <form onSubmit={handleSubmit}>
        {/*<label className='label'>Email</label>*/}
        <input
          id="email"
          name="email"
          type="email"
          className="input rounded-lg my-2"
          placeholder={"email"}
          onChange={handleChange}
          value={formData.email}
          required={true}
        />
        <button
          id="subscribeMailChampBtn"
          name="subscribeMailChampBtn"
          type="submit"
          style={{
            backgroundColor: btnBackgroundColor,
            marginTop: btnMarginTop,
            color: btnColor,
            fontSize: "14px",
          }}
          className="subscribeButton py-2 px-4 font-bold my-2"
          disabled={subscribing}
        >
          <span>{buttonText}</span>
        </button>
      </form>
    </div>
  );
}
