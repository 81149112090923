import React from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <main className="flex items-center justify-center h-screen flex-col gap-4">
      <h1 className="font-bold text-black text-6xl text-center mb-6">
        Oops. Seems like you&apos;re lost.
      </h1>
      <button
        className="px-4 py-2 rounded-2xl font-semibold bg-[#FA9C10] text-white text-xl"
        onClick={() => navigate("/")}
      >
        Go home
      </button>
    </main>
  );
}
