import React from "react";
import Dropdown from "./Dropdown";
import { useNavigate } from "react-router-dom";

const DropdownItem = ({ label, item, setOpen }) => {
  const hasChild = typeof item === "object" && !item.slug;

  const navigate = useNavigate();

  const handleNavigation = () => {
    setOpen(false); // Close the dropdown
    if (item.slug) {
      navigate(`/article/${item.slug}`); // Navigate to the home location / slug
    }
  };

  return (
    <div
      className="relative px-4 hover:bg-gray-100 rounded-md z-20 py-2"
      onClick={handleNavigation}
    >
      {!hasChild && item.slug && (
        <div>
          <div className="flex gap-3 font-semibold text-md leading-snug capitalize">
            {label}
            {item.premium && <span className="badge-plus">👑</span>}
          </div>
          {item.description && (
            <span className="text-gray-500 font-medium text-sm">
              {item.description}
            </span>
          )}
        </div>
      )}
      {hasChild && (
        <div className="absolute left-full top-0 bg-white shadow-lg rounded-lg mt-2">
          <Dropdown setOpen={setOpen} items={item} />
        </div>
      )}
    </div>
  );
};

export default DropdownItem;
