import React, { useEffect, useState } from 'react';
import { notesStore, sharedNotesStore } from "neugencelib";
import './ShareModal.css';

const ShareModal = ({ verifiedemail, currDocId, isOpen, onClose, sharingCapableEmailIds = [] }) => {
  
  // Hook for fetching friendships, should be called unconditionally
  const { allFriendships, fetchFriendships, roles, selections, docRelatedFriendships, setState } = sharedNotesStore(state => ({
    allFriendships: state.friendShips,
    fetchFriendships: state.fetchFriendships,
    roles: state.roles,
    selections: state.selections,
    docRelatedFriendships: state.docRelatedFriendships,
    setState: state.setState
  }));

  const { handleShareNoteSave } = notesStore(state => ({
    handleShareNoteSave: state.handleShareNoteSave
  }));

  useEffect(() => {
    if (verifiedemail) {
      fetchFriendships(verifiedemail);
    }
  }, [verifiedemail, fetchFriendships]);

  useEffect(() => {
    if (allFriendships.length > 0 && currDocId) {
      const initialSelections = {};
      const docRltdFriendShips = allFriendships.filter(record => record.docid === currDocId);
      sharingCapableEmailIds.forEach(user => {
        const friendship = docRltdFriendShips.find(record => record.to === user);
        if (friendship) {
          initialSelections[user] = {
            selected: true,
            role: friendship.role || 'viewer',
          };
        } else {
          initialSelections[user] = {
            selected: false,
            role: 'viewer',
          };
        }
      });
      setState('selections', initialSelections);
    }
  }, [allFriendships, currDocId, sharingCapableEmailIds, setState]);

  // Early return for when the modal is not open, ensuring hooks above are still executed
  if (!isOpen) return null;

  const handleCheckboxChange = (user) => {
    const prevSelections = selections;
    const newSelections = {
      ...prevSelections,
      [user]: {
        selected: !prevSelections[user]?.selected,
        role: prevSelections[user]?.role || 'viewer',
      },
    };
    setState('selections', newSelections);
  };

  const handleRoleChange = (user, role) => {
    const prevSelections = selections;
    const newSelections = {
      ...prevSelections,
      [user]: {
        ...prevSelections[user],
        role,
      },
    };
    setState('selections', newSelections);
  };

  const handleShareNoteSaveLocal = () => {
    handleShareNoteSave(verifiedemail, currDocId, selections);
    onClose(); // Optionally close the modal after saving
  };

  return (
    <div className="modal-overlay1" onClick={onClose}>
      <div className="modal-content1" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <div style={{ display: "flex" }}>
          <h2 style={{ marginTop: "5px", marginRight: "10px" }}>Select Users</h2>
          <button onClick={handleShareNoteSaveLocal}
            style={{ border: "1px solid grey", borderRadius: "10px", padding: "5px" }}>
            Save
          </button>
        </div>
        <ul>
          {sharingCapableEmailIds.filter(x => x !== verifiedemail).map((user) => (
            <li key={user}>
              <label style={{ width: "300px", overflow: "hidden", paddingLeft: "20px" }}>
                <input
                  type="checkbox"
                  checked={selections[user]?.selected || false}
                  onChange={() => handleCheckboxChange(user)}
                />{' '}
                {user}
              </label>
              <select
                className="dropdown"
                value={selections[user]?.role || ''}
                style={{ border: "1px solid grey", borderRadius: "10px", padding: "5px", color: "black" }}
                onChange={(e) => handleRoleChange(user, e.target.value)}
              >
                <option value="" disabled>Select role</option>
                {roles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ShareModal;
