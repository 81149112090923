import {create}  from "zustand";
import axios from "axios";
import { getAxiosBaseUrl } from "../../../config";

axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;

const useMenuStore = create((set, get) => ({
  HomeMenuData: {},

  updateState: (key, value) => {
    set((state) => ({ ...state, [key]: value }));
  },

  // Updates menu data via verified email
  updatemenudata: async (email, menuToUpdate) => {
    if (!email || !menuToUpdate) {
      console.error("Email and menu data are required for updating.");
      return;
    }
    try {
      const response = await axios.post(`/menu/update_menu_data/${email}`, {
        updatedData: menuToUpdate,
      });
      console.log("Menu data updated successfully for:", email);
      return response.data;
    } catch (error) {
      console.error(`Error updating menu data for ${email}:`, error);
      throw error;
    }
  },

  // Fetches menu data based on verified email
  fetchMenuData: async (email) => {
    if (!email) return;
    try {
      const response = await axios.post(
        `/menu/fetch_menu_data/${email}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      set({ HomeMenuData: response.data });
      console.log("Menu data fetched successfully", response.data);
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  },
}));

export default useMenuStore;
