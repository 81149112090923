var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ModalPopup } from "./ModalPopup";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
// @ts-ignore
var ForgotPassword = function (_a) {
    var onClose = _a.onClose;
    var _b = useState(""), email = _b[0], setEmail = _b[1];
    var _c = useState(false), emailSent = _c[0], setEmailSent = _c[1];
    var auth = getAuth();
    var handleResetPassword = function () {
        sendPasswordResetEmail(auth, email)
            .then(function () {
            toast.success("Password reset email sent!");
            setEmailSent(true);
        })
            .catch(function (error) {
            var errorMessage = error.message;
            toast.error(errorMessage);
        });
    };
    return (_jsx(ModalPopup, __assign({ onClose: onClose }, { children: _jsxs("div", __assign({ className: " flex-col flex" }, { children: [_jsx("h2", __assign({ className: "text-4xl my-8 font-bold text-center " }, { children: "Forgot your Password?" })), _jsx("p", __assign({ className: "text-center text-l px-4 my-4 text-gray-500" }, { children: "Enter your email address and we'll send you a link to set your password." })), _jsx("label", __assign({ htmlFor: "email", className: "text-lg text-start mx-1 font-bold text-gray-600" }, { children: "Email" })), _jsx("input", { type: "email", placeholder: "Enter your email", value: email, onChange: function (e) { return setEmail(e.target.value); }, className: "border rounded-lg px-4 py-2 outline-none focus:ring-2 focus:ring-blue-500 mb-4 mt-2" }), _jsx("button", __assign({ onClick: handleResetPassword, className: "border w-full justify-center outline-none bg-color1 py-2 px-6 text-white  text-base rounded-lg  hover:transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black font-bold hover:shadow-lg" }, { children: "Send" })), emailSent && (_jsxs("p", __assign({ className: "text-center text-l px-4 my-4 text-gray-500" }, { children: ["A password reset link has been sent to your", _jsx("span", __assign({ className: " underline block text-color1 font-bold" }, { children: email }))] }))), _jsxs("div", __assign({ className: "flex justify-center my-8" }, { children: [_jsx("h1", { children: "know your password?" }), _jsx("button", __assign({ className: "text-color1 font-bold hover:text-color2 mx-2", onClick: onClose }, { children: "Sign in" }))] }))] })) })));
};
export default ForgotPassword;
