import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const categories = ['UI Fix', 'Backend Fix', 'Architecture', 'Design', 'Other'];

const TaskRL = () => {
  const [inputText, setInputText] = useState('');
  const [groupedData, setGroupedData] = useState({});
  const [accuracyData, setAccuracyData] = useState([
    { iteration: 1, accuracy: 0.65 },
    { iteration: 2, accuracy: 0.70 },
    { iteration: 3, accuracy: 0.75 },
    { iteration: 4, accuracy: 0.80 },
    { iteration: 5, accuracy: 0.85 },
  ]);

  const handleProcessItems = () => {
    const itemsArray = inputText
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line !== '');

    if (itemsArray.length > 0) {
      // Simulate model feedback with dummy data
      const responses = itemsArray.map((item) => {
        // Generate dummy category and score
        const randomCategory =
          categories[Math.floor(Math.random() * categories.length)];
        const randomScore = Math.random(); // Random score between 0 and 1

        return {
          id: `item-${Math.random().toString(36).substring(2, 11)}`, // Unique ID
          item,
          category: randomCategory,
          score: randomScore,
          checked: false, // Initialize checked state
        };
      });

      // Group items by category, including empty categories
      const grouped = categories.reduce((acc, category) => {
        acc[category] = responses.filter((item) => item.category === category);
        return acc;
      }, {});
      setGroupedData(grouped);
    } else {
      setGroupedData({});
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside any droppable area
    if (!destination) {
      return;
    }

    // If the item was dropped back to the same position
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceCategory = source.droppableId;
    const destCategory = destination.droppableId;

    const sourceItems = Array.from(groupedData[sourceCategory]);
    const [movedItem] = sourceItems.splice(source.index, 1);
    movedItem.category = destCategory; // Update the category if moved to a different category

    const destItems = Array.from(groupedData[destCategory]);
    destItems.splice(destination.index, 0, movedItem);

    setGroupedData({
      ...groupedData,
      [sourceCategory]: sourceItems,
      [destCategory]: destItems,
    });
  };

  // Function to handle checkbox changes
  const handleCheckChange = (itemId, newChecked) => {
    setGroupedData((prevGroupedData) => {
      const newGroupedData = { ...prevGroupedData };
      for (const category of categories) {
        const index = newGroupedData[category].findIndex(
          (item) => item.id === itemId
        );
        if (index !== -1) {
          newGroupedData[category][index].checked = newChecked;
          break;
        }
      }
      return newGroupedData;
    });
  };

  function handleFinalizePlan() {
    window.alert('Sending Plan to RL Backend for Learning !!');
    // Simulate updating accuracy data
    const newIteration = accuracyData.length + 1;
    const lastAccuracy = accuracyData[accuracyData.length - 1].accuracy;
    const newAccuracy = Math.min(1, lastAccuracy + Math.random() * 0.05);
    setAccuracyData([
      ...accuracyData,
      { iteration: newIteration, accuracy: newAccuracy },
    ]);
  }

  return (
    <div style={{ display: 'flex', marginTop: '100px', marginLeft: '100px' }}>
      {/* Left 20% for input field */}
      <div style={{ width: '20%', padding: '10px' }}>
        <h3>Enter Items:</h3>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          rows={15}
          style={{
            width: '100%',
            resize: 'vertical',
            border: '1px solid lightgrey',
            padding: '10px',
          }}
          placeholder="Enter items, one per line"
        ></textarea>
        <button
          className="p-2"
          onClick={handleProcessItems}
          style={{
            marginTop: '10px',
            border: '1px solid lightgrey',
            borderRadius: '20px',
          }}
        >
          Process Items
        </button>
      </div>
      {/* Right 80% for output */}
      <div style={{ width: '80%', padding: '10px' }}>
        <div style={{ display: 'flex' }}>
          <h2>Categorized & Prioritized</h2>
          <button
            className="ml-20 pl-5 pr-5"
            style={{
              borderRadius: '20px',
              border: '1px solid lightgrey',
              marginTop: '-4px',
              fontSize: '16px',
              maxHeight: '40px',
              marginLeft: '20px',
              padding: '5px 10px',
            }}
            onClick={handleFinalizePlan}
          >
            Finalize Plan
          </button>
        </div>
        <div
          style={{
            maxWidth: '70vw',
            padding: '10px',
            maxHeight: '60vh',
            overflow: 'hidden', // Change to prevent outer scrolling
            border: '1px solid lightgrey',
            display: 'flex',
            marginTop: '10px',
          }}
        >
          {Object.keys(groupedData).length === 0 ? (
            <p>Please enter items and click "Process Items".</p>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              {categories.map((category) => (
                <Droppable droppableId={category} key={category}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        margin: '0 10px',
                        flex: '1',
                        border: '1px solid lightgrey',
                        borderRadius: '4px',
                        padding: '10px',
                        backgroundColor: snapshot.isDraggingOver
                          ? '#f0f0f0'
                          : 'white',
                        minHeight: '100px',
                        maxHeight: '50vh', // Set a max height for the category column
                        overflowY: 'auto', // Enable internal scrolling
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <h3 style={{ textAlign: 'center' }}>{category}</h3>
                      <div style={{ flex: '1' }}>
                        {groupedData[category].map((data, index) => (
                          <Draggable
                            key={data.id}
                            draggableId={data.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={(e) => {
                                  // Prevent click event if dragging
                                  if (snapshot.isDragging) return;
                                  handleCheckChange(data.id, !data.checked);
                                }}
                                style={{
                                  userSelect: 'none',
                                  margin: '0 0 8px 0',
                                  padding: '8px',
                                  border: '1px solid lightgrey',
                                  borderRadius: '4px',
                                  backgroundColor: data.checked
                                    ? '#c8deff'
                                    : snapshot.isDragging
                                    ? '#e0e0e0'
                                    : 'white',
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p
                                    style={{
                                      wordWrap: 'break-word',
                                      whiteSpace: 'normal',
                                      margin: 0,
                                    }}
                                  >
                                    {`${index + 1}. ${data.item}`}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          )}
        </div>
        {/* RL Model Accuracy Graph */}
        <div style={{ marginTop: '20px' }}>
          <h2>RL Model Accuracy Over Iterations</h2>
          <LineChart
            width={600}
            height={300}
            data={accuracyData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="iteration"
              label={{ value: 'Iteration', position: 'insideBottom', offset: -5 }}
            />
            <YAxis
              domain={[0, 1]}
              label={{ value: 'Accuracy', angle: -90, position: 'insideLeft' }}
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="accuracy"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </div>
      </div>
    </div>
  );
};

export default TaskRL;
