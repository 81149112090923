export function getFormatted(jobCount) {
	const formattedNumber = new Intl.NumberFormat('en-US',
		{style: 'decimal', maximumFractionDigits: 0}).format(jobCount);
	return formattedNumber
}

export function capitalizeFirst(word) {
	return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export function isRemoteOrOffsiteJob(record) {
	if ('isremotejobflag' in record) {
		if (record.isremotejobflag) { //It's a remote job
			// return record.isremotejobflag.toString()
			return "Remote"
		}
	}
	if ('isoffsitejobflag' in record) {
		if (record.isoffsitejobflag) { //It's not a remote job
			//return record.isoffsitejobflag.toString()
			return "Offsite"
		}
	}
	return '-'
}

export function getLocationsInfo(record) {
	if ('locations' in record) {
		const locs = record.locations
		
		let joinedString = ''
		if (Array.isArray(locs)) {
			joinedString = locs.map(loc => capitalizeFirst(loc)).join(', ')
		} else {
			joinedString = capitalizeFirst(locs);
		}
		
		if (joinedString.length > 50) {
			return 'Many, USA'
		} else {
			return joinedString
		}
	} else {
		return '-'
	}
}

export function timeStampToDaysWeeksAgoStr(timestamp) {
	const today = new Date();
	const postedDate = new Date(timestamp);
	const diffTime = Math.abs(today - postedDate);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	
	if (diffDays < 7) {
		if (diffDays === 1) {
			return '1 day ago';
		} else {
			return `${diffDays} days ago`;
		}
	} else {
		const diffWeeks = Math.floor(diffDays / 7);
		if (diffWeeks === 1) {
			return '1 week ago';
		} else {
			return `${diffWeeks} weeks ago`;
		}
	}
}
export function getDatePosted(record) {
	if ('date_posted' in record) {
		return timeStampToDaysWeeksAgoStr(record['date_posted'])
	} else {
		return '-';
	}
}


export function getTitleText(record) {
	if ('title' in record) {
		return record.title
	}
	return '-'
}

export function extractCompanyName(url) {
	let hostname = ''
	try {
		hostname = new URL(url).hostname;
	} catch {
		return '-'
	}
	if (url.includes('Samsung')) {
		return 'Samsung'
	}
	if (hostname.includes('intel')) {
		return capitalizeFirst('intel')
	}
	if (hostname.includes('zebra')) {
		return capitalizeFirst('zebra')
	}
	if (hostname.includes('t-mobile')) {
		return capitalizeFirst('tmobile')
	}
	if (hostname.includes('comcast')) {
		return capitalizeFirst('comcast')
	}
	if (hostname.includes('cisco')) {
		return capitalizeFirst('cisco')
	}
	if (hostname.includes('verizon')) {
		return capitalizeFirst('verizon')
	}
	if (hostname.includes('fa-evmr-saasfaprod1.fa.ocs')) {
		return capitalizeFirst('Nokia')
	}
	if (hostname.includes('americantower')) {
		return capitalizeFirst('americantower')
	}
	if (hostname.includes('assurant')) {
		return capitalizeFirst('assurant')
	}
	if (hostname.includes('qualcomm')) {
		return capitalizeFirst('qualcomm')
	}
	if (hostname.includes('netflix')) {
		return capitalizeFirst('netflix')
	}
	
	if (hostname.includes('ebay')) {
		return capitalizeFirst('eBay')
	}
	
	if (hostname.includes('apple')) {
		return capitalizeFirst('apple')
	}
	if (hostname.includes('walmart')) {
		return capitalizeFirst('walmart');
	}
	try {
		const parts = hostname.split('.');
		const companyName = parts[0] === 'www' ? parts[1] : parts[0];
		return capitalizeFirst(companyName)
	} catch {
		return '-'
	}
}