// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BlogComp.css */
.markdown {
  font-size: 14px;
  height: fit-content;
  padding-bottom: 20px;
  max-width: 100vw;
  overflow-y: hidden;
  background-color: white;
  padding-right: 4px;
}

.markdown img {
  cursor: pointer;
}

.markdown table {
  border-collapse: collapse;
}

.markdown th,
.markdown td {
  padding: 0px;
  border: 1px solid black;
}

.markdown p {
  line-height: 1.5;
}

.markdown code {
  color: black;
  background-color: transparent;
  font-weight: normal;
}
`, "",{"version":3,"sources":["webpack://./src/Components/pages/BlogComp2.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,mBAAmB;AACrB","sourcesContent":["/* BlogComp.css */\n.markdown {\n  font-size: 14px;\n  height: fit-content;\n  padding-bottom: 20px;\n  max-width: 100vw;\n  overflow-y: hidden;\n  background-color: white;\n  padding-right: 4px;\n}\n\n.markdown img {\n  cursor: pointer;\n}\n\n.markdown table {\n  border-collapse: collapse;\n}\n\n.markdown th,\n.markdown td {\n  padding: 0px;\n  border: 1px solid black;\n}\n\n.markdown p {\n  line-height: 1.5;\n}\n\n.markdown code {\n  color: black;\n  background-color: transparent;\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
