import create from "zustand";
import axios from "axios";
import { getAxiosBaseUrl } from "../../../config";
import { markdownData } from "./BlogData/data";
import CryptoJS from "crypto-js";

export function generateDocId(verifiedemail) {
  const timestamp = new Date().getTime(); // Current time in milliseconds since the Unix Epoch
  const randomPart = Math.random().toString(36).substring(2, 15); // Generate a random string
  const hash = CryptoJS.SHA256(verifiedemail)
    .toString(CryptoJS.enc.Hex)
    .substring(0, 10); // Create a hash of the email and take the first 10 characters
  const docId = `${hash}-${timestamp}-${randomPart}`;
  return docId;
}
axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;
const BLOGS_URL = "";
// Blog also means Article ... means default it's private / personal.
const blogsStore = create((set, get) => ({
  articles: [],
  article: null,
  slugArticleContent: "",
  userEmail: "",
  courseAccordian: [],
  LandingSlug:"",
  setState: (key, value) => {
    set((state) => ({ ...state, [key]: value }));
  },
  fetchArticleFromSlug: async (slug_id) => {
    //TODO Cache this in local storage too with some timeout
    try {
      const response = await axios.get(`/getArticleWithSlugId/${slug_id}`);
      // console.log('response:', response.data.blog_data.content);
      set({ slugArticleContent: response.data.blog_data.content }); // This is used in the solo article navigation rendering
      set({ article: response.data.blog_data }); // This is used in the accordian blog comp rendering
      set({ LandingSlug: slug_id });
      // console.log("response",response);
    } catch (err) {
      // setError("Error fetching article content.");
    } finally {
      // setLoading(false);
    }
  },
  fetchCourseData: async (courseId) => {
    if (courseId) {
      axios.defaults.baseURL = getAxiosBaseUrl();
      try {
        const response = await axios.post(
          `/blogs/fetch_course_data/${courseId}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // const accordian = response.data.accordian;
        // if(accordian && accordian.length > 0){
        //   get().fetchArticleFromSlug(Object.entries(accordian[0].body)[0][1]['slug']);
        // }
        
        set({ courseAccordian: response.data.accordian });
        
        // const body = response.data.accordian[0]?.body;
        // const firstKey = Object.keys(body)[0]; // Get the first key in the body object
        // console.log(body[firstKey]?.slug);
        // set({ LandingSlug: body[firstKey].slug });
        // console.log("LandingSlug",body[firstKey].slug);

        // console.log("Accordian", response.data.accordian[0]);
      } catch (err) {
        // setError("Error fetching article content.");
      } finally {
        // setLoading(false);
      }
    }
  },
  fetchPublishedBlogsForUser: (userEmail, category) => {
    //Fetch currently published blogs when the app starts
    //Ownership information and slugs to the articles
    //All Meta Data :
    //This will have the information about the authors, shared with, published / unpublished states
    //This will also have the blog id, blog name, title, description, tags list,
    //Article Category information (different from the tags information)
    //axios.defaults.baseURL = 'http://localhost:5002';
    axios
      .post("/blogs/fetch_blogs_for_user", {
        verifiedemail: userEmail,
      })
      .then((response) => {
        const blogsData = response.data.data;
        const blogsArray = Object.entries(blogsData).map(([id, blog]) => ({
          id,
          ...blog,
        }));
        // const filteredArticles = response.data.filter((blog) => blog.category === "public");
        set({ articles: blogsArray });
        if (blogsArray.length > 0) {
          set({ article: blogsArray[0] });
        }
      })
      .catch((error) => {
        console.error("Error fetching public blogs:", error);
      });
  },
  createNewBlogForUser: (
    userEmail,
    name,
    title,
    authors,
    content,
    tags,
    category
  ) => {
    // TODO do some sanity check on the user passed data
    //axios.defaults.baseURL = 'http://localhost:5002';

    const new_blogId = generateDocId(userEmail);
    axios
      .post("/blogs/create_blog_for_user", {
        verifiedemail: userEmail,
        blogId: new_blogId,
        name: name,
        title: title,
        authors: authors,
        content: content,
        tags: tags,
        category: category,
      })
      .then((response) => {
        set((state) => ({
          articles: [
            ...state.articles,
            {
              id: new_blogId,
              name: name,
              title: title,
              authors: authors,
              content: content,
              tags: tags,
              category: category,
            },
          ],
        }));
        // console.log(response);
      })
      .catch((error) => {
        console.error("Error creating new blog:", error);
      });
  },
  deleteBlogForUser: (userEmail, blogId) => {
    //axios.defaults.baseURL = 'http://localhost:5002';
    axios
      .post("/blogs/delete_blog_for_user", {
        verifiedemail: userEmail,
        blogId: blogId,
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.error("Error fetching public blogs:", error);
      });
  },
  updateBlogForUser: (
    userEmail,
    blogId,
    name,
    title,
    authors,
    content,
    tags,
    category,
    published
  ) => {
    //axios.defaults.baseURL = 'http://localhost:5002';
    axios
      .post("/blogs/update_blog_for_user", {
        verifiedemail: userEmail,
        blogId: blogId,
        name: name,
        title: title,
        authors: authors,
        content: content,
        tags: tags,
        category: category,
        published: published, //published is a boolean
      })
      .then((response) => {
        set((state) => ({
          articles: state.articles.map((article) =>
            article.id === blogId
              ? {
                  ...article,
                  name,
                  title,
                  authors,
                  content,
                  tags,
                  category,
                  published,
                }
              : article
          ),
        }));
        // console.log(response);
      })
      .catch((error) => {
        console.error("Error fetching public blogs:", error);
      });
  },

  fetchBlogDataBasedOnId: (blogId) => {
    //Check if the user has permissions ... then fetch the blog data from the backend
    const { userEmail } = get();
    axios
      .get(`${BLOGS_URL}/${blogId}`, {
        params: { verifiedemail: userEmail },
      })
      .then((response) => {
        const blogData = response.data;
        if (blogData.author.includes(userEmail)) {
          set({ article: blogData });
        } else {
          console.error("User does not have permission to access this blog.");
          set({ article: null });
        }
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
        set({
          article: {
            id: blogId,
            name: "Blog1234",
            title: "Blog Dummy Title",
            author: userEmail,
            blogContent: `${markdownData}`,
            tags: ["Java", "blog"],
            category: "public",
          },
        });
      });
  },
  fetchBlogAssociatedDataFromBlogId: (blogId) => {
    //Get appreciate data, claps count, shared counts, comments (optional)
    axios
      .get(`${BLOGS_URL}/${blogId}/associated-data`)
      .then((response) => {
        set({ associatedData: response.data });
      })
      .catch((error) => {
        console.error("Error fetching associated blog data:", error);
        set({
          associatedData: {
            appreciates: 10,
            claps: 5,
            shares: 2,
            comments: ["Nice blog!"],
          },
        });
      });
  },
  fetchDocsByGivenTag: (tag) => {
    //Search the tags database (tag recor has tag_id, tag_name, blog_ids for that particular tag)
    axios
      .get(`${BLOGS_URL}/tags/${tag}`)
      .then((response) => {
        set({ taggedDocs: response.data });
      })
      .catch((error) => {
        console.error("Error fetching documents by tag:", error);
        set({
          taggedDocs: [
            {
              id: 1,
              name: `${tag}`,
              blog_ids: ["ids"],
            },
          ],
        });
      });
  },
  fetchDocsOwnedByCurrUser: () => {
    const { userEmail } = get();
    get()
      .fetchPublishedBlogsForUser()
      .then(() => {
        const { articles } = get();
        const ownedDocs = articles.filter((blog) =>
          blog.author.includes(userEmail)
        );
        if (ownedDocs.length > 0) {
          set({ ownedDocs });
        } else {
          set({
            ownedDocs: [
              {
                id: 1,
                name: "Blog1234",
                title: "Blog Dummy Title",
                author: `${userEmail}`,
                blogContent: `${markdownData}`,
                tags: ["Java", "blog"],
                category: "public",
              },
            ],
          });
        }
      });
  },
  fetchDocsByCategoryForUser: (category) => {
    //Premium / non premium based attribute fetching happens here
    const { userEmail } = get();
    axios
      .get(`${BLOGS_URL}/category/${category}`, {
        params: { verifiedemail: userEmail },
      })
      .then((response) => {
        set({ categoryDocs: response.data });
      })
      .catch((error) => {
        console.error("Error fetching documents by category:", error);
        set({
          categoryDocs: [
            {
              id: 1,
              title: "Dummy Category Document",
              description: `This document belongs to ${category} category`,
              category: "Premium",
              author: userEmail,
            },
          ],
        });
      });
  },
}));
export default blogsStore;
