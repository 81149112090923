// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
    color: white;
    padding-right: 10px;
    letter-spacing: .04rem;
}

.input {
    text-align: center;
    border: 1px solid grey;
    width: 250px;
    font-size: 15px;
    min-height: 38px;
    /*background-color: lightgrey;*/
}

.subscribeButton {
    min-width: 130px;
    min-height: 38px;
    letter-spacing: .03rem;
    margin-left: 10px;
    border-radius: 10px;
    height: 28px;
    font-size: 12px;
    /*border: 1px solid grey;*/
}

.subscribeButton:hover {
    background-color: lightskyblue;
    color: #00100b;
    /*box-shadow: 0 3px #999;*/
}

.subscribeButton:disabled {
    background-color: lightgrey;
}

.subscribeButton:active {
    background-color: lightblue;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}`, "",{"version":3,"sources":["webpack://./src/Components/pages/MailChamp/mailchamp.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,+BAA+B;AACnC;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;IAC9B,cAAc;IACd,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,0BAA0B;AAC9B","sourcesContent":[".label {\n    color: white;\n    padding-right: 10px;\n    letter-spacing: .04rem;\n}\n\n.input {\n    text-align: center;\n    border: 1px solid grey;\n    width: 250px;\n    font-size: 15px;\n    min-height: 38px;\n    /*background-color: lightgrey;*/\n}\n\n.subscribeButton {\n    min-width: 130px;\n    min-height: 38px;\n    letter-spacing: .03rem;\n    margin-left: 10px;\n    border-radius: 10px;\n    height: 28px;\n    font-size: 12px;\n    /*border: 1px solid grey;*/\n}\n\n.subscribeButton:hover {\n    background-color: lightskyblue;\n    color: #00100b;\n    /*box-shadow: 0 3px #999;*/\n}\n\n.subscribeButton:disabled {\n    background-color: lightgrey;\n}\n\n.subscribeButton:active {\n    background-color: lightblue;\n    box-shadow: 0 3px #666;\n    transform: translateY(4px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
