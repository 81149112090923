import React, { useEffect, useRef, useState } from 'react';

const AudioChatbot = () => {
    const [transcribedText, setTranscribedText] = useState("Waiting for transcription...");
    const [generatedText, setGeneratedText] = useState("Awaiting generated text...");
    const [isRecording, setIsRecording] = useState(false);
    const [isSpeakEnabled, setIsSpeakEnabled] = useState(false);
    const [modelsLoaded, setModelsLoaded] = useState(false);

    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    useEffect(() => {
        const initializeModels = async () => {
            try {
                const transcriber = await window.pipeline('automatic-speech-recognition', 'Xenova/whisper-tiny.en');
                const generator = await window.pipeline('text2text-generation', 'Xenova/LaMini-Flan-T5-783M');
                window.transcriber = transcriber;
                window.generator = generator;
                setModelsLoaded(true);
                console.log("Models initialized successfully");
            } catch (error) {
                console.error("Error initializing models:", error);
            }
        };

        initializeModels();
    }, []);

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();
        setIsRecording(true);

        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = async () => {
            const audioBlob = new Blob(audioChunksRef.current);
            const url = URL.createObjectURL(audioBlob);
            audioChunksRef.current = [];
            await transcribeAudio(url);
        };
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    const transcribeAudio = async (url) => {
        if (!window.transcriber) {
            console.error("Transcriber is not initialized yet");
            return;
        }
        try {
            const result = await window.transcriber(url);
            setTranscribedText(result.text);
            await generateText(result.text);
        } catch (error) {
            console.error("Error transcribing audio:", error);
        }
    };

    const generateText = async (inputText) => {
        if (!window.Enumerator) {
            console.error("Generator is not initialized yet");
            return;
        }
        try {
            const result = await window.generator(inputText, { max_new_tokens: 100 });
            setGeneratedText(result[0].generated_text || "No text generated");
            setIsSpeakEnabled(true);
        } catch (error) {
            console.error("Error generating text:", error);
        }
    };

    const speakText = () => {
        const utterance = new SpeechSynthesisUtterance(generatedText);
        window.speechSynthesis.speak(utterance);
    };

    return (
        <div className='mt-20 mx-auto' style={{  maxWidth: '900px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#3b82f6' }}>Audio-to-Audio Chatbot</h1>
                <h3 style={{ color: '#6b7280' }}>Powered by Transformers.js</h3>
            </div>
            
            <hr style={{ marginBottom: '20px', borderColor: '#e5e7eb' }} />

            {/* Live Audio Input Section */}
            <div style={{ padding: '20px', backgroundColor: '#f3f4f6', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
                <h5>Live Audio Input</h5>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <button
                        style={{
                            backgroundColor: modelsLoaded ? '#3b82f6' : '#9ca3af',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            fontSize: '16px',
                            cursor: modelsLoaded ? 'pointer' : 'not-allowed',
                            transition: 'all 0.3s ease',
                            transform: isRecording ? 'scale(1.05)' : 'scale(1)'
                        }}
                        onClick={startRecording}
                        disabled={isRecording || !modelsLoaded}
                    >
                        Start Recording
                    </button>
                    <button
                        style={{
                            backgroundColor: '#ef4444',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            fontSize: '16px',
                            cursor: isRecording ? 'pointer' : 'not-allowed',
                            transition: 'all 0.3s ease',
                            transform: isRecording ? 'scale(1)' : 'scale(1.05)'
                        }}
                        onClick={stopRecording}
                        disabled={!isRecording}
                    >
                        Stop Recording
                    </button>
                </div>
                <div style={{ marginTop: '10px' }}>
                    <h6>Transcribed Text:</h6>
                    <pre style={{
                        backgroundColor: '#e5e7eb',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #d1d5db',
                        fontFamily: 'monospace',
                        whiteSpace: 'pre-wrap'
                    }}>
                        {transcribedText}
                    </pre>
                </div>
            </div>

            <hr style={{ margin: '20px 0', borderColor: '#e5e7eb' }} />

            <div style={{ padding: '20px', backgroundColor: '#f3f4f6', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
                <h5>Text-to-Text Generation</h5>
                <div style={{ marginTop: '10px' }}>
                    <h6>Generated Output:</h6>
                    <pre style={{
                        backgroundColor: '#e5e7eb',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #d1d5db',
                        fontFamily: 'monospace',
                        whiteSpace: 'pre-wrap'
                    }}>
                        {generatedText}
                    </pre>
                    <button
                        style={{
                            backgroundColor: '#10b981',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            fontSize: '16px',
                            cursor: isSpeakEnabled ? 'pointer' : 'not-allowed',
                            marginTop: '15px',
                            transition: 'transform 0.3s ease',
                            transform: isSpeakEnabled ? 'scale(1)' : 'scale(0.98)'
                        }}
                        onClick={speakText}
                        disabled={!isSpeakEnabled}
                    >
                        Speak
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AudioChatbot;
