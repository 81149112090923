import React from "react";
import './LoadingPage.css';

export default function LoadingPage() {
  return (
      <div>
          {/*<main className="flex items-center justify-center h-screen">*/}
          {/*    <div className="loader"></div>*/}
          {/*</main>*/}
          <main className="flex items-center justify-center h-screen">
              <div className="loading-bar"></div>
          </main>
      </div>
  );
}
