import React, {useEffect, useState} from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import ApprovalIcon from "@mui/icons-material/Approval";
import ClearIcon from "@mui/icons-material/Clear";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import TableSkeleton from "./Loader.jsx";
import Tooltip from "@mui/material/Tooltip";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import TagsInput from "./account/TagsInput.jsx";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";

import "./myapplication.css";
import * as jobUtils from "../utlis/jobUtils";
import {
	extractCompanyName,
	getDatePosted,
	getFormatted,
	getLocationsInfo,
	getTitleText,
	isRemoteOrOffsiteJob,
} from "../utlis/jobUtils";
import {getAxiosBaseUrl} from "../../../../../config";


export default function JobPool({verifiedEmail}) {
	const [fltrdjoburls, setfltrdjoburls] = useState([]);
	const [stashedFltrdJobUrls, setstashedFltrdJobUrls] = useState([]);
	const [searchedLocation, setSearchedLocation] = useState([]);
	const [searchedCompany, setSearchedCompany] = useState([]);
	const [searchedPosition, setSearchedPosition] = useState([]);
	const [loader, setloader] = useState(true);
	const [sortOrder, setSortOrder] = useState(true);
	const [jobTitle, setJobTitle] = useState(true);
	const [jobLocation, setJobLocation] = useState(true)
	const [error, setError] = useState(false);
	const [viaTimeOrder, setviaTimeOrder] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	
	function getDictionaryForCompanyData(allData) {
		const count = {};
		const countProcessed = {};
		allData.forEach((rec) => {
			const name = extractCompanyName(rec["jobUrl"]);
			count[name] = (count[name] || 0) + 1;
			if ("date_posted" in rec) {
				countProcessed[name] = (countProcessed[name] || 0) + 1;
			}
		});
		
		const companyChartData = Object.keys(count).map((key) => {
			if (key == "Amazon") {
				return {name: key, availableCount: 0, processedCount: 0};
			}
			return {
				name: key,
				processedCount: countProcessed[key] || 0,
				availableCount: count[key] - (countProcessed[key] || 0),
			};
		});
		return companyChartData;
	}
	
	useEffect(() => {
		function getAndSetJobData() {
			try {
				axios.defaults.baseURL = getAxiosBaseUrl();
				axios
					.post("fetchJobDataForUser", {
						verifiedemail: verifiedEmail,
					}, {
						withCredentials: true
					})
					.then((response) => {
						const allData = new Set(response.data);
						// console.log("allData", allData);
						setMyJobSet(allData);
					});
				axios.defaults.baseURL = getAxiosBaseUrl();
				axios
					.post("fetchJobUrlsEsWLatLongs", {
						verifiedemail: verifiedEmail,
					}, {
						withCredentials: true
					})
					.then((response) => {
						const allData = response.data.map((rec) => {
							const source = rec._source;
							source["_id"] = rec._id;
							return source;
						});
						setAllDataLength(allData.length);
						
						const filteredData = allData.filter((rec) => "date_posted" in rec);
						
						let companyChartData = getDictionaryForCompanyData(allData);
						setcompanyChartData(companyChartData);
						setjoburls(filteredData);
						setfltrdjoburls(filteredData);
						setstashedFltrdJobUrls(filteredData);
						const allLatLongs = [];
						filteredData.map((rec) => {
							if ("latlongs" in rec) {
								rec["latlongs"].split(";").map((latLong) => {
									const lat_ = latLong.split(",")[0];
									const long_ = latLong.split(",")[1];
									allLatLongs.push({
										title: rec.title,
										company: extractCompanyName(rec.jobUrl),
										jobUrl: rec.jobUrl,
										lat: lat_,
										lon: long_,
									});
								});
							}
						});
						setjobLocations(allLatLongs);
						setloader(false);
					});
			} catch (err) {
				setloader(true);
			}
		}
		
		getAndSetJobData();
	}, []);
	
	const updateFilteredJobs = (searchType) => {
		let result = [...stashedFltrdJobUrls];
		let isSearchApplied = false;
		
		if (searchedCompany.length > 0) {
			isSearchApplied = true;
			result = result.filter((job) =>
				searchedCompany.some((companyName) =>
					jobUtils
						.extractCompanyName(job.jobUrl)
						.toLowerCase()
						.includes(companyName)
				)
			);
		}
		
		if (searchedLocation.length > 0) {
			isSearchApplied = true;
			result = result.filter((job) =>
				searchedLocation.some((location) =>
					getLocationsInfo(job).toLowerCase().trim().includes(location.trim())
				)
			);
		}
		
		if (searchedPosition.length > 0) {
			isSearchApplied = true;
			result = result.filter((job) =>
				searchedPosition.some((position) =>
					getTitleText(job).toLowerCase().trim().includes(position.trim())
				)
			);
		}
		
		if (isChecked) {
			console.log("i am clicked");
			isSearchApplied = true;
			result = result.filter((job) => isRemoteOrOffsiteJob(job) === "Remote");
			console.log("Remote", result);
		}
		
		if (searchType === "name") {
			result.sort((a, b) => {
				return sortOrder
					?
					extractCompanyName(b.jobUrl).localeCompare(
						extractCompanyName(a.jobUrl)
					)
					: extractCompanyName(a.jobUrl).localeCompare(
						extractCompanyName(b.jobUrl)
					)
			});
		}
		if (searchType === "postdate") {
			result.sort((a, b) => {
				isSearchApplied = true;
				const dateA = new Date(a.date_posted);
				const dateB = new Date(b.date_posted);
				return viaTimeOrder ? dateB - dateA : dateA - dateB;
			});
		}
		
		if (searchType === "jobtitle") {
			result.sort((a, b) => {
				return jobTitle
					? getTitleText(b)
						.toLowerCase()
						.trim()
						.localeCompare(getTitleText(a).toLowerCase().trim())
					: getTitleText(a)
						.toLowerCase()
						.trim()
						.localeCompare(getTitleText(b).toLowerCase().trim());
			});
		}
		if (searchType === "joblocation") {
			result.sort((a, b) => {
				console.log(getLocationsInfo(a))
				return jobLocation
					? getLocationsInfo(b)
						.toLowerCase()
						.trim()
						.localeCompare(getLocationsInfo(a).toLowerCase().trim())
					: getLocationsInfo(a)
						.toLowerCase()
						.trim()
						.localeCompare(getLocationsInfo(b).toLowerCase().trim());
			});
		}
		
		if (result.length > 0 || !isSearchApplied) {
			setError(false);
			console.log(result);
			setfltrdjoburls(result);
		} else if (isSearchApplied && result.length === 0) {
			setError(true);
		}
	};
	
	useEffect(() => {
		updateFilteredJobs("name");
	}, [sortOrder]);
	useEffect(() => {
		updateFilteredJobs("postdate");
	}, [viaTimeOrder]);
	useEffect(() => {
		updateFilteredJobs("jobtitle");
	}, [jobTitle]);
	useEffect(() => {
		updateFilteredJobs("joblocation");
	}, [jobLocation]);
	useEffect(() => {
		updateFilteredJobs();
	}, [searchedCompany, searchedLocation, searchedPosition, isChecked]);
	
	const toggleSortOrder = () => {
		setSortOrder((prev) => !prev);
	};
	
	const toggleTimeOrder = () => {
		setviaTimeOrder((prev) => !prev);
	};
	const togglejobOrder = () => {
		setJobTitle((prev) => !prev);
	};
	const toggleLocationOrder = () => {
		setJobLocation((prev) => !prev)
	}
	
	const textStyle = {
		fontSize: "12px",
		fontWeight: "bold",
		backgroundImage:
			"linear-gradient(50deg, rgb(0, 66, 0), rgb(75, 75, 241), rgb(0, 0, 0))",
		WebkitBackgroundClip: "text",
		color: "transparent",
		backgroundClip: "text",
		marginTop: "8px",
		width: "100px",
	};
	const divStyle = {
		display: "flex",
		marginTop: "10px",
		padding: "10px",
		alignItems: "center",
	};
	
	return (
		<div
			style={{
				marginTop: "0px",
				marginLeft: "0px",
				minHeight: "0px",
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "100vh",
				// flexDirection:"column"
			}}
		>
			<div style={divStyle}>
				{/* <div style={leftDivStyle}> */}
				<div style={{width: "100%", height: "30vh", border: "1px solid black", display: "flex", alignItems: "center", borderRadius: "15px"}}>
					<div style={{fontSize: "5x", color: "grey", width: "10%"}}>
						{" "}
						<span
							style={{
								fontSize: "13px",
								fontWeight: "bold",
								backgroundImage:
									"linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))",
								WebkitBackgroundClip: "text",
								color: "transparent",
								backgroundClip: "text",
							}}
						>
              {" "}
							Available Jobs:{" "}
            </span>{" "}
						{getFormatted(fltrdjoburls.length)}
					</div>
					<div
						style={{
							display: "flex",
							width: "80%",
							alignItems: "center",
							gap: "20px",
						}}
					>
						<div style={{width: "30%", padding: "5px", margin: "2px"}}>
							<label
								style={{
									textAlign: "left",
									fontSize: "13px",
									fontWeight: "bold",
									backgroundImage:
										"linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))",
									WebkitBackgroundClip: "text",
									color: "transparent",
									backgroundClip: "text",
									marginTop: "10px",
								}}
							>
								Company
							</label>
							<TagsInput
								initialTags={searchedCompany}
								placeholderText={"Enter Company Name"}
								onTagsChanged={setSearchedCompany}
							/>
						</div>
						<div style={{width: "30%"}}>
							<label
								style={{
									textAlign: "left",
									fontSize: "13px",
									fontWeight: "bold",
									backgroundImage:
										"linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))",
									WebkitBackgroundClip: "text",
									color: "transparent",
									backgroundClip: "text",
								}}
							>
								Location
							</label>
							<TagsInput
								initialTags={searchedLocation}
								onTagsChanged={setSearchedLocation}
								placeholderText={"Enter Job Locations"}
							></TagsInput>
						</div>
						<div style={{width: "30%"}}>
							<label
								style={{
									textAlign: "left",
									fontSize: "13px",
									fontWeight: "bold",
									backgroundImage:
										"linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))",
									WebkitBackgroundClip: "text",
									color: "transparent",
									backgroundClip: "text",
								}}
							>
								Title
							</label>
							<TagsInput
								initialTags={searchedPosition}
								onTagsChanged={setSearchedPosition}
								placeholderText={"Enter Title"}
							></TagsInput>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					// marginTop: "30px",
					marginLeft: "10px",
					marginBottom: "0px",
					// height: "80px",
				}}
			>
				<div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginTop: "20px",
							width: "100%",
						}}
					>
						<div
							style={{
								backgroundColor: "white",
								// width: "100px",
								fontSize: "12px",
								// overflow: "scroll",
								// paddingLeft: "10px",
								paddingRight: "10px",
								marginLeft: "80px",
								// marginRight: "100px",
								color: "grey",
							}}
						>
							Company
							<Tooltip
								title={sortOrder ? "Sort via A2Z" : "Sort via Z2A"}
								arrow
								placement="top"
							>
                  <span
	                  style={{
		                  borderRadius: "10px",
		                  padding: "5px",
		                  margin: "4px",
	                  }}
	                  onClick={() => toggleSortOrder()}
                  >
                    <SortByAlphaIcon
	                    fontSize="small"
	                    sx={{cursor: "pointer"}}
                    />
                  </span>
							</Tooltip>
						</div>
						<div
							style={{
								width: "510px",
								// overflow: "scroll",
								paddingLeft: "80px",
								marginLeft: "70px",
								fontSize: "12px",
								color: "grey",
							}}
						>
							Job Title
							<Tooltip
								title={jobTitle ? "Sort via A2Z" : "Sort via Z2A"}
								arrow
								placement="top"
							>
                  <span
	                  style={{
		                  borderRadius: "10px",
		                  padding: "5px",
		                  margin: "4px",
	                  }}
	                  onClick={() => togglejobOrder()}
                  >
                    <SortByAlphaIcon
	                    fontSize="small"
	                    sx={{cursor: "pointer"}}
                    />
                  </span>
							</Tooltip>
						</div>
						<div
							style={{
								width: "100px",
								// overflow: "scroll",
								fontSize: "12px",
								color: "grey",
								marginLeft: "50px",
							}}
						>
							Location
							<Tooltip
								arrow
								placement="top"
								title={jobLocation ? "Sort via A2Z" : "Sort via Z2A"}
							>
                  <span
	                  style={{
		                  borderRadius: "10px",
		                  padding: "2px",
		                  margin: "4px",
	                  }}
	                  onClick={() => toggleLocationOrder()}
                  >
                    <SwapVertIcon sx={{cursor: "pointer"}}/>
                  </span>
							</Tooltip>
						</div>
						<div
							style={{
								// width: "100px",
								// overflow: "scroll",
								marginLeft: "50px",
								fontSize: "12px",
								color: "grey",
							}}
						>
							Post Time
							<Tooltip
								arrow
								placement="top"
								title={viaTimeOrder ? "Sort via Oldest" : "Sort via Newest"}
							>
                  <span
	                  style={{
		                  borderRadius: "10px",
		                  padding: "2px",
		                  margin: "4px",
	                  }}
	                  onClick={() => toggleTimeOrder()}
                  >
                    <SwapVertIcon sx={{cursor: "pointer"}}/>
                  </span>
							</Tooltip>
						</div>
						<div
							style={{
								width: "100px",
								// overflow: "scroll",
								marginLeft: "50px",
								fontSize: "12px",
								color: "grey",
							}}
						>
							<input
								type="checkbox"
								checked={isChecked}
								onChange={(e) => setIsChecked(e.target.checked)}
							/>
							<label
								style={{
									marginTop: "10px",
									marginLeft: "10px",
									color: "grey",
								}}
							>
								Remote
							</label>
						</div>
					</div>
				</div>
				<div
					style={{
						height: "500px",
						width: "100%",
						overflowY: loader ? "hidden" : "scroll",
						scrollbarWidth: "thin",
						scrollbarColor: "darkgrey lightgrey",
						border: "1px solid lightgrey",
						borderRadius: "10px",
						paddingBottom: "10px",
						backgroundColor: "#d7d7d7b5",
						display: "block",
						textAlign: "center",
						marginTop: "0px",
					}}
				>
					{loader ? (
						<TableSkeleton height={"100vh"} column={9}/>
					) : error ? (
						<div
							style={{
								display: "flex",
								width: "98%",
								height: "100%",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<SavedSearchIcon fontSize="large"/>
							<p>No match Found</p>{" "}
						</div>
					) : (
						<>
							{fltrdjoburls.map((record, index) => {
								return (
									<div
										key={index}
										style={{
											marginTop: "10px",
											marginLeft: "10px",
											display: "flex",
										}}
									>
										<div
											style={{
												// backgroundColor: myJobSet.has(record._id + "_" + currentPdfId) ? "#d3d3d361" : "white",
												backgroundColor: "white",
												boxShadow: "1px 1px 1px 1px rgba(1,1,1,0.1)",
												border: "1px solid lightgrey",
												borderRadius: "10px", // padding: "10px",
												width: "100%",
												// overflowY: "scroll",
												paddingLeft: "10px",
												paddingRight: "10px",
												paddingBottom: "5px",
											}}
										>
											<div
												className="aiMatchesTable"
												style={{display: "flex", paddingLeft: "10px"}}
											>
                          <span
	                          style={{
		                          marginTop: "8px",
		                          marginRight: "10px",
		                          width: "30px",
	                          }}
                          >
                            {index + 1}.
                          </span>
												<span style={textStyle}>
                            {extractCompanyName(record.jobUrl)}
                          </span>
												<span
													style={{
														color: "lightgrey",
														marginLeft: "10px",
														marginRight: "15px",
														marginTop: "8px",
													}}
												>
                            |
                          </span>
												<div
													style={{
														fontSize: "14px",
														marginTop: "6px",
														width: "400px",
														textAlign: "left",
														color: "rgb(155, 66, 255)",
													}}
												>
													{getTitleText(record)}
												</div>
												<div
													style={{
														display: "inline-block",
														marginLeft: "20px",
														marginTop: "0px",
													}}
												>
													<span style={{color: "lightgrey"}}>|</span>
													<Tooltip title="Approve">
														<Button
															variant="none"
															style={{
																height: "16px",
																width: "10px",
																textTransform: "none",
																paddingLeft: "0px",
																paddingRight: "0px",
																paddingTop: "20px",
																paddingBottom: "20px",
																marginRight: "0px",
																fontSize: "12px",
															}}
															// onClick={() => handleApprovalClick(record)}
														>
															<ApprovalIcon
																style={{color: "darkgoldenrod"}}
															/>
														</Button>
													</Tooltip>
													<Tooltip title="Clear">
														<Button
															variant="none"
															style={{
																height: "16px",
																width: "10px",
																textTransform: "none",
																paddingLeft: "0px",
																paddingRight: "0px",
																paddingTop: "20px",
																paddingBottom: "20px",
																marginRight: "0px",
															}}
															// onClick={() => handleIrrelevantClick(record)}
														>
															<ClearIcon
																style={{
																	color: "#f49494",
																	height: "18px",
																	marginRight: "0px",
																	fontWeight: "bold",
																}}
															></ClearIcon>
														</Button>
													</Tooltip>
													<span
														style={{
															color: "lightgrey",
															marginLeft: "5px",
															marginRight: "10px",
														}}
													>
                              |
                            </span>
													
													<FmdGoodOutlinedIcon style={{height: "18px"}}/>
													<div
														style={{
															fontSize: "12px",
															width: "100px",
															display: "inline-block",
														}}
													>
														{getLocationsInfo(record)}
													</div>
													<div
														style={{
															borderRadius: "10px",
															textAlign: "center",
															display: "inline-block",
															paddingLeft: "10px",
															paddingRight: "10px",
															fontSize: "12px",
															marginLeft: "20px",
															width: "140px",
														}}
													>
														{getDatePosted(record)}
													</div>
													<div
														style={{
															textAlign: "center",
															display: "inline-block",
															paddingLeft: "10px",
															paddingRight: "10px",
															fontSize: "12px",
															marginLeft: "20px",
															width: "140px",
															color: "black",
															marginTop: "4px",
															textDecoration: "underline",
														}}
													>
														<a href={record.jobUrl.trim()} target="_blank">
															Job Link
														</a>
													</div>
													{isRemoteOrOffsiteJob(record) !== "-" && (
														<div
															style={{
																backgroundColor: "rgb(220,241,248)",
																borderRadius: "10px",
																textAlign: "center",
																display: "inline-block",
																paddingLeft: "10px",
																paddingRight: "10px",
																fontSize: "12px",
																marginLeft: "20px",
															}}
														>
															{isRemoteOrOffsiteJob(record)}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</>
					)}
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center ",
						width: "100%",
						marginTop: "10px",
					}}
				>
					{Array.from({length: 4}, (_, i) => (
						<button
							style={{
								border: "1px solid black",
								borderRadius: "5px",
								padding: "10px",
								cursor: "pointer",
							}}
							key={i}
						>
							{i + 1}
						</button>
					))}
				</div>
			</div>
		</div>
	);
}
