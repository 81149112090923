import React, { useEffect, useState } from "react";
import circle from "./Utils/circlesvg.svg";
import SkeletonStucture from "./Utils/Skeleton.js";
import blogsState from "./store/blogState.js";
import blogsStore from "./store/blogsStore";
import { useLocation } from "react-router-dom";

const AccordionNew = ({ courseId, setSelectedBlogs, setVisitedBlogs, visitedBlogs, handleBlogClick, selectedBlogs }) => {
  const location = useLocation();
  const [expandedSections, setExpandedSections] = useState({});
  
  const [currentBlog, setCurrentBlog] = useState(null);
  
  const colors = ["bg-indigo-200"];
  const [currentCategory, setCurrentCategory] = useState(null);

  const {
    fetchArticleFromSlug,
    slugArticleContent,
    courseAccordian,
    fetchCourseData,
    article,
    LandingSlug,
  } = blogsStore((state) => ({
    fetchArticleFromSlug: state.fetchArticleFromSlug,
    slugArticleContent: state.slugArticleContent,
    courseAccordian: state.courseAccordian,
    fetchCourseData: state.fetchCourseData,
    article: state.article,
    LandingSlug: state.LandingSlug,
  }));

  useEffect(() => {
    if (courseId) {
      fetchCourseData(courseId);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseAccordian.length > 0) {
      const firstSection = courseAccordian[0];
      const firstBlogKey = Object.keys(firstSection.body)[0];
      const firstBlog = firstSection.body[firstBlogKey];

      setExpandedSections({ [firstSection.title]: true });
      setSelectedBlogs({ [firstSection.title]: firstBlog });

      setVisitedBlogs({ [firstSection.title]: { [firstBlog.slug]: true } });
      if (LandingSlug !== firstBlog.slug) {
        fetchArticleFromSlug(firstBlog.slug);
      }
      setBlogContent(slugArticleContent);
    }
  }, [courseAccordian]);

  const toggleAccordion = (title) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };


  const getColorForTitle = (title) => {
    const index = courseAccordian.findIndex(
      (section) => section.title === title
    );
    return colors[index % colors.length];
  };

  const getBlogColor = (title, blogSlug) => {
    const isVisited = visitedBlogs[title]?.[blogSlug];
    return isVisited ? getColorForTitle(title) : "";
  };

  const { setBlogContent } = blogsState((state) => ({
    setBlogContent: state.setBlogContent,
  }));

  useEffect(() => {
    if (article) {
      const category = findCategoryBySlug(article.slug);
      setCurrentCategory(category);
      setCurrentBlog(article);
    }
  }, [article]);

  function findCategoryBySlug(slug) {
    for (const category of courseAccordian) {
      for (const item of Object.values(category.body)) {
        if (item.slug === slug) {
          return category.title
        }
      }
    }
    return null;
  }


  

  return (
    <div className="mb-12 lg:max-w-[200px]">
      {courseAccordian.map((section, accordIndex) => (
        <div key={accordIndex}>
          <div
            onClick={() => toggleAccordion(section.title)}
            className={`mt-2 px-1 py-1 rounded-lg text-sm font-semibold cursor-pointer ${
              currentCategory === section.title
                ? "bg-gray-900 text-white"
                : expandedSections[section.title]
                ? "bg-color5 text-gray-900"
                : "bg-color5 text-gray-700"
            }`}
          >
            <div className="flex justify-between items-center pr-4">
              <span className="py-1 px-2">{accordIndex+1}. {section.title}</span>
              <span
                style={{
                  fontSize: "8px", // Reduce the size of the arrows
                }}
              >
                {expandedSections[section.title] ? "▼" : "►"}
              </span>
            </div>
          </div>

          {expandedSections[section.title] && (
            <div className="px-2 overflow-hidden mt-3">
              {Object.keys(section.body).length > 0 ? (
                Object.entries(section.body).map(([key, blog], articleIndex) => (
                  <div
                    key={articleIndex}
                    className={`flex rounded-lg pr-4 text-sm font-light hover:bg-indigo-100 relative z-100 ${
                      selectedBlogs[section.title]?.slug === blog.slug
                        ? "bg-indigo-50 bg-opacity-100 text-black hover:bg-indigo-100 font-semibold"
                        : ""
                    }`}
                    style={{ marginLeft: "0rem", cursor: "pointer" }}
                    onClick={() => handleBlogClick(section.title, blog)}
                  >
                    <div className="absolute top-[10px] left-[12px] z-10">
                      <img
                        src={circle}
                        className={`mr-2 bg-gray-100 rounded-full ${
                          selectedBlogs[section.title]?.slug === blog.slug
                            ? getColorForTitle(section.title)
                            : getBlogColor(section.title, blog.slug)
                        }`}
                      />
                    </div>
                    <div style={{height: "-webkit-fill-available" }} className="border-l-2 absolute left-5 min-h-16 border-gray-300 mr-2 z-1"></div>
                    <div className="pl-10 py-2">{accordIndex+1}{"."}{articleIndex+1} {key}</div>
                  </div>
                ))
              ) : (
                <SkeletonStucture size={200} count={6} />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AccordionNew;
