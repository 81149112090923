import './ImageUpload.css'; // Import your CSS for styles

import React, {useEffect, useState} from 'react';
// import profilepic from "../assets/profilepic.jpg";
import useMainStore from '../../../../../libStores/mainStore';

const ImageUpload = ({verifiedEmail}) => {
	const profilepic = null;
	const {uploadImage, profilePic, initializeProfilePic} = useMainStore(state => ({
		uploadImage: state.uploadImage, profilePic: state.profilePic, initializeProfilePic: state.initializeProfilePic,
	}));
	const [imageSrc, setImageSrc] = useState(null); // State to hold the image URL
	const [selectedFile, setSelectedFile] = useState(null); // State to hold the selected file
	const [resolution, setResolution] = useState(100); // Default resolution
	
	// Initialize profile picture on component mount
	useEffect(() => {
		if (verifiedEmail) {
			initializeProfilePic(verifiedEmail);
		}
	}, [verifiedEmail, initializeProfilePic]);
	
	// Update imageSrc when profilePic changes
	useEffect(() => {
		if (profilePic) {
			setImageSrc(profilePic);
		}
	}, [profilePic]);
	
	
	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setSelectedFile(file);
			const reader = new FileReader();
			reader.onload = (e) => {
				setImageSrc(e.target.result);
			};
			reader.readAsDataURL(file);
		}
	};
	
	const handleUpload = async () => {
		if (selectedFile) {
			const response = await uploadImage(verifiedEmail, selectedFile, resolution);
			if (response) {
				console.log('Image uploaded successfully:', response.data);
			}
		}
	};
	
	return (<div className="image-upload-container" style={{marginBottom: "15px"}}>
		<img src={imageSrc || profilepic} alt="" className="round-image"/>
		<label htmlFor="image-input" className="overlay-button">
			<i className="fas fa-plus"></i> {/* Font Awesome icon */}
		</label>
		<input
			id="image-input"
			type="file"
			accept="image/*"
			onChange={handleImageChange}
			style={{display: 'none'}}
		/>
		<button onClick={handleUpload} className="upload-button" style={{border: "1px solid lightgrey", borderRadius: "10px", padding: "2px"}}>
			Upload Image
		</button>
	</div>);
};

export default ImageUpload;
