var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
export var ModalPopup = function (_a) {
    var children = _a.children, heading = _a.heading, description = _a.description, onClose = _a.onClose;
    var _b = useState(true), showModal = _b[0], setShowModal = _b[1];
    function modalClickHandler() {
        setShowModal(false);
        onClose();
    }
    var closeCombinedModal = function () {
        setShowModal(false);
        onClose();
    };
    var handleModalContentClick = function (e) {
        e.stopPropagation();
    };
    return (_jsx(_Fragment, { children: showModal && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "py-6 no-scrollbar animate-fade-in fixed inset-0 z-50 flex w-full items-center justify-center overflow-x-hidden outline-none backdrop-blur-sm focus:outline-none overflow-y-scroll ", onClick: closeCombinedModal, style: {
                        scrollbarWidth: "none",
                        msOverflowStyle: "none", // For Internet Explorer
                    } }, { children: [_jsx("style", { children: "\n                .no-scrollbar::-webkit-scrollbar {\n                  display: none; // Hide scrollbar for WebKit browsers\n                }\n              " }), _jsxs("div", __assign({ className: "py-6 relative mt-28 sm:mt-0 flex w-full mx-6 sm:w-[70%] md:w-[50%] lg:w-[40%] flex-col items-center justify-start isolate gap-2 rounded-[20px] bg-white shadow-lg h-[100vh] sm:h-full overflow-y-scroll no-scrollbar", onClick: handleModalContentClick }, { children: [_jsx("div", __assign({ className: "absolute top-2 right-2 " }, { children: _jsx("div", __assign({ className: "cursor-pointer", onClick: modalClickHandler }, { children: _jsx(RxCross2, { className: "bg-color1 rounded-full text-4xl p-1 m-2 sm:m-4 hover:scale-125 duration-300 hover:shadow-md hover:rotate-12" }) })) })), _jsx("div", __assign({ className: "mb-4 font-bold text-xl uppercase flex w-full justify-center" }, { children: heading })), _jsx("h1", __assign({ className: "font-bold text-2xl mt-8 sm:mt-12" }, { children: "Welcome to Acehub.Ai" })), _jsx("div", __assign({ className: "mb-4 px-8 max-w-[470px] text-center" }, { children: description })), _jsx("div", __assign({ className: "mb-4 px-8 max-w-full text-center" }, { children: children }))] }))] })), _jsx("div", { className: "fixed inset-0 z-40 bg-black opacity-25" })] })) }));
};
