import {getAxiosBaseUrl} from "../../config";
import axios from "axios";
import {debounce} from "lodash";

axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;

export function updateStructure(verified_email, useCaseDesc, updatedFolders) {
	axios.post('/updateTreeStructure', {
		verifiedemail: verified_email, useCaseDesc: useCaseDesc, newStructure: updatedFolders
	}).catch(err => {
		console.error("Error updating tree structure:", err);
	})
}


export async function notesChangeFieldValue(verifiedEmail, useCaseDesc, docid, fieldName, fieldValue, sharedColl = false) {
	if (sharedColl) {
		try {
			axios.post('/updatesharedusernoteField', {
				verifiedemail: verifiedEmail, docid: docid, fieldName: fieldName, fieldValue: fieldValue,
			});
		} catch (error) {
			console.error("Error updating note title:", error);
		}
	} else {
		try {
			axios.post('/updateusernotesdoc', {
				verifiedemail: verifiedEmail, useCaseDesc: useCaseDesc, docid: docid, fieldName: fieldName, fieldValue: fieldValue,
			});
		} catch (error) {
			console.error("Error updating note title:", error);
		}
	}
}

export async function fetchSharedNotes(verifiedemail) {
	const response = await axios.post('/getSharedDocs', {verifiedemail});
	const documents = response.data.documents;
	return documents.filter(doc => doc !== null)
}

export function saveUserNoteUtility(verifiedemail, useCaseDesc, flightData, currDocId, notetitle, sharedNote = false) {
	if (sharedNote) {
		axios.post('savesharedusernote', {
			verifiedemail: verifiedemail, useCaseDesc: useCaseDesc, docid: currDocId, content: flightData,
			notetitle: notetitle, bgcolor: 'lightblue'
		});
	} else {
		axios.post('saveusernotes', {
			verifiedemail: verifiedemail, useCaseDesc: useCaseDesc, docid: currDocId, content: flightData,
			notetitle: notetitle, bgcolor: 'lightblue'
		});
	}
}


export const updateBackend = async (key, value) => {
	let endpoint = '';
	switch (key) {
		case 'noteTitle':
			endpoint = '/api/updateNoteTitle';
			break;
		case 'anotherKey':
			endpoint = '/api/updateAnotherKey';
			break;
		// Add more cases as needed
		default:
			console.error(`No endpoint defined for key: ${key}`);
			return;
	}
	try {
		const response = await axios.post(endpoint, {[key]: value});
		console.log(`Backend updated for ${key}:`, response.data);
	} catch (error) {
		console.error(`Error updating backend for ${key}:`, error);
	}
};
export const debouncedUpdateBackend = debounce(updateBackend, 1000); // Adjust the delay as needed
