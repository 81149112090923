import { remark } from "remark";
import { visit } from "unist-util-visit";
export const extractHeadings = (markdown) => {
    const headings = [];
    remark()
      .use(() => (tree) => {
        visit(tree, "heading", (node) => {
          const text = node.children
            .filter((n) => n.type === "text")
            .map((n) => n.value)
            .join("");
          const id = text.toLowerCase().replace(/\s+/g, "-");
          headings.push({ depth: node.depth, text, id });
        });
      })
      .processSync(markdown);
    return headings;
  };