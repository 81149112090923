// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jiggle-button {
    animation: jiggle 1.9s infinite alternate;
  }

  @keyframes jiggle {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-1px);
    }
    100% {
      transform: translateX(1px);
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/pages/home.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;EAC3C;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B;IAC7B;IACA;MACE,0BAA0B;IAC5B;EACF","sourcesContent":[".jiggle-button {\n    animation: jiggle 1.9s infinite alternate;\n  }\n\n  @keyframes jiggle {\n    0% {\n      transform: translateX(0);\n    }\n    50% {\n      transform: translateX(-1px);\n    }\n    100% {\n      transform: translateX(1px);\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
