import React, { useEffect, useState } from "react";

const Markdowntoaudio = ({
  handleSubmit,
  markdownInput,
  handleInputChange,
  loading,
  generatedText,
  audioUrl,
}) => {
  const [rowsLength, setRowsLength] = useState(6);
  // useEffect(() => {
  //   if(audioUrl!=null){
  //   setRowsLength(5);}
  // }, [audioUrl]);

  return (
    <div>
      <form onSubmit={handleSubmit} className="space-y-2 mt-2 lg:mt-2 flex flex-col justify-around">
        <span className="mb-1 text-2xl font-bold  text-gradient-to-r from-blue-500 to-purple-500 mb-2">
          Demo
        </span>
        <textarea
          rows={rowsLength}
          placeholder="DEMO MODEL : Enter your markdown text here..."
          value={markdownInput}
          onChange={handleInputChange}
          className="w-full border border-gray-700 p-2 px-4 rounded-lg bg-gray-800 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          disabled={loading}
          onClick={() => setRowsLength(6)}
          className={`mt-2 rounded-full p-2 min-h-[30px] min-w-[100px] flex justify-center items-center relative overflow-hidden text-sm ${
            loading
              ? "bg-transparent cursor-default w-1/2"
              : "bg-blue-600 hover:bg-blue-700 w-1/2 rounded-md"
          }`}
        >
          {loading ? (
            <div className="cool-ai-spinner"></div>
          ) : (
            "Convert to Speech"
          )}
        </button>
        {audioUrl && (
          <div className=" bg-gray-800 bg-opacity-40 backdrop-blur-lg rounded-lg shadow-lg text-white mt-4">
            <h3 className="font-semibold mb-2">Generated Speech:</h3>
            <audio controls className="w-full">
              <source src={audioUrl} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
      </form>
      {/* {!audioUrl &&<div className="p-2 bg-gray-700 bg-opacity-40 backdrop-blur-lg rounded-lg shadow-lg text-white mt-4 pb-1">
        <h3 className="font-semibold mb-1">Generated Text:</h3>
        <textarea
          rows={rowsLength/3+1}
          value={generatedText}
          readOnly
          placeholder="give markdown to get text"
          className="w-full border border-gray-700 p-2 px-4 rounded-lg bg-gray-800 bg-opacity-50 text-white"
        />
      </div>} */}

      {/* {audioUrl && (
        <div className="p-4 bg-gray-800 bg-opacity-40 backdrop-blur-lg rounded-lg shadow-lg text-white mt-4">
          <h3 className="font-semibold mb-2">Generated Speech:</h3>
          <audio controls className="w-full">
            <source src={audioUrl} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )} */}
    </div>
  );
};

export default Markdowntoaudio;
