// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Overlay */
.modal-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*border: 1px solid lightblue;*/
  /*background: white;*/
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}

/* Modal content */
.modal-content1 {
  text-align: left;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  max-height: 95%;
  padding: 10px;
  overflow-y: auto;
  position: relative;
  background-color: #b3c5e8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

h2 {
  margin-top: 0;
}

ul {
  /*list-style: none;*/
  padding: 0;
}

li {
  margin: 10px 0;
  /*display: flex; DO NOT DO THIS*/
  align-items: center;
}

.dropdown {
  margin-left: 10px;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Workspace/Sharing/ShareModal.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,+BAA+B;EAC/B,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA,kBAAkB;AAClB;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["/* Overlay */\n.modal-overlay1 {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  /*border: 1px solid lightblue;*/\n  /*background: white;*/\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000000;\n}\n\n/* Modal content */\n.modal-content1 {\n  text-align: left;\n  border-radius: 8px;\n  max-width: 400px;\n  width: 100%;\n  max-height: 95%;\n  padding: 10px;\n  overflow-y: auto;\n  position: relative;\n  background-color: #b3c5e8;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.close-button {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background: none;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n}\n\nh2 {\n  margin-top: 0;\n}\n\nul {\n  /*list-style: none;*/\n  padding: 0;\n}\n\nli {\n  margin: 10px 0;\n  /*display: flex; DO NOT DO THIS*/\n  align-items: center;\n}\n\n.dropdown {\n  margin-left: 10px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
