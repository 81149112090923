// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Cool AI Loading Animation */
.cool-ai-spinner {
  width: 100%;  /* Takes full width of the button */
  height: 100%;  /* Takes full height of the button */
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  border-radius: 50%;
  animation: morph 2s infinite ease-in-out, color-change 4s infinite;
  position: absolute;  /* Absolutely positioned to center it */
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;  /* Increased transparency */
}

.cool-ai-spinner::before,
.cool-ai-spinner::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: inherit;
  animation: inherit;
  mix-blend-mode: multiply;
  opacity: 0.5;  /* Increased transparency for a more subtle effect */
}

.cool-ai-spinner::before {
  filter: blur(10px);
}

.cool-ai-spinner::after {
  filter: blur(20px);
}

@keyframes morph {
  0%, 100% {
    border-radius: 40% 60% 55% 45% / 60% 40% 50% 50%;
  }
  50% {
    border-radius: 70% 30% 50% 50% / 30% 70% 40% 60%;
  }
}

@keyframes color-change {
  0%, 100% {
    background: linear-gradient(45deg, #6a11cb, #2575fc);
  }
  50% {
    background: linear-gradient(45deg, #ff6f61, #ff9671);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Projects/AudioGen/MarkdownToSpeech.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,WAAW,GAAG,mCAAmC;EACjD,YAAY,GAAG,oCAAoC;EACnD,oDAAoD;EACpD,kBAAkB;EAClB,kEAAkE;EAClE,kBAAkB,GAAG,uCAAuC;EAC5D,MAAM;EACN,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY,GAAG,2BAA2B;AAC5C;;AAEA;;EAEE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,wBAAwB;EACxB,YAAY,GAAG,oDAAoD;AACrE;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,gDAAgD;EAClD;EACA;IACE,gDAAgD;EAClD;AACF;;AAEA;EACE;IACE,oDAAoD;EACtD;EACA;IACE,oDAAoD;EACtD;AACF","sourcesContent":["/* Cool AI Loading Animation */\n.cool-ai-spinner {\n  width: 100%;  /* Takes full width of the button */\n  height: 100%;  /* Takes full height of the button */\n  background: linear-gradient(45deg, #6a11cb, #2575fc);\n  border-radius: 50%;\n  animation: morph 2s infinite ease-in-out, color-change 4s infinite;\n  position: absolute;  /* Absolutely positioned to center it */\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0.5;  /* Increased transparency */\n}\n\n.cool-ai-spinner::before,\n.cool-ai-spinner::after {\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background: inherit;\n  animation: inherit;\n  mix-blend-mode: multiply;\n  opacity: 0.5;  /* Increased transparency for a more subtle effect */\n}\n\n.cool-ai-spinner::before {\n  filter: blur(10px);\n}\n\n.cool-ai-spinner::after {\n  filter: blur(20px);\n}\n\n@keyframes morph {\n  0%, 100% {\n    border-radius: 40% 60% 55% 45% / 60% 40% 50% 50%;\n  }\n  50% {\n    border-radius: 70% 30% 50% 50% / 30% 70% 40% 60%;\n  }\n}\n\n@keyframes color-change {\n  0%, 100% {\n    background: linear-gradient(45deg, #6a11cb, #2575fc);\n  }\n  50% {\n    background: linear-gradient(45deg, #ff6f61, #ff9671);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
