function DropdownList({labelText, selectedValue, setSelectedValue, options, paddingLeft = "20px",
	                      defaultValue = 'None', disabled = false, marginLeft = "0px",
	                      textColor="rgb(155, 66, 255)"}) {
	const handleSelectChange = (e) => {
		setSelectedValue(e.target.value);
	};
	
	return (<div style={{
		maxWidth: "500px",
		marginTop: "5px",
		display: "flex",
		marginLeft: marginLeft,
		// paddingLeft: "10px",
		paddingRight: "10px", // color: "rgb(155, 66, 255)",
		paddingBottom: "5px"
	}}>
		{/*<div style={{border: "none", marginRight: "20px", marginTop: "4px"}}>{labelText}</div>*/}
		<div style={{border: '1px solid lightgrey', borderRadius: '5px', minWidth: '150px', padding: "2px"}}>
			<select disabled={disabled} value={selectedValue} onChange={handleSelectChange}
			        style={{
				        width: "100%", textAlign: "left", paddingLeft: paddingLeft, color: textColor,
				        borderRadius: "5px"
			        }}>
				
				<option value="">{defaultValue}</option>
				
				{options.map((option) => (<option key={option} value={option}>
					{option}
				</option>))}
			</select>
		</div>
		{/*<p>Selected Value: {selectedValue}</p>*/}
	</div>);
}

export default DropdownList;
