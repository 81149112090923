var hostPath = window.location.host
hostPath = hostPath.replace(/:.*/, "");
const isLocalHost = ['127.0.0.1', 'localhost'].includes(hostPath)


export function getGisBaseUrl() {
	if (isLocalHost) {
		return 'http://149.248.21.233';
	} else {
		return 'http://149.248.21.233';
	}
}
export function getAxiosBaseUrl() {
	// return 'https://applyjobs.ai:' + getPythonPort();
	if (isLocalHost) {
		return 'http://localhost:' + getPythonPort(); //keep it localhost for cookie based testing
	} else {
		return 'https://applyjobs.ai:' + getPythonPort();
	}
}

export function getBaseUrlNodeServer() {
	if (isLocalHost) {
		return 'http://localhost:8080/'
	} else {
		return 'https://mailchamp.ai:8080/'
	}
}



export function getPythonPort() {
	return '5001'
}

export const API_URL = process.env.NODE_ENV === 'production'
  ? 'http://quantussoftware.in:8080'
  : 'http://localhost:8080'