import React, {useEffect, useState} from 'react';
import './tags.css'

const TagsInput = ({initialTags, onTagsChanged}) => {
	const [tags, setTags] = useState(initialTags);
	
	useEffect(() => {
		setTags(initialTags);
	}, [initialTags]);
	
	const removeTags = indexToRemove => {
		const newTags = tags.filter((_, index) => index !== indexToRemove);
		setTags(newTags);
		onTagsChanged(newTags);
	};
	
	const addTags = event => {
		if (event.target.value !== "") {
			const newTags = [...tags, event.target.value];
			setTags(newTags);
			onTagsChanged(newTags);
			event.target.value = "";
		}
	};
	
	return (
		<div className="tags-input">
			<ul id="tags">
				{tags.map((tag, index) => (
					<li key={index} className="tag">
						<span className='tag-title'>{tag}</span>
						<span className='tag-close-icon' onClick={() => removeTags(index)}>x</span>
					</li>
				))}
			</ul>
			<input style={{height: "30px", marginTop: "5px", borderRadius: "10px", paddingLeft: "10px", marginBottom: "5px", paddingBottom: "5px"}}
				type="text"
				onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
				placeholder="Press enter to add tags"
			/>
		</div>
	);
};

export default TagsInput;