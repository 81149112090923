import React from 'react';

const courseData = {
  title: "Why Opt for Acehub’s Certificate Course in Machine Learning?",
  marketGrowth: "The global machine-learning market is experiencing explosive growth, with its value projected to soar from $8 billion in 2019 to an estimated $117 billion by 2027, reflecting a remarkable CAGR of 39%. As artificial intelligence and machine learning become integral to business operations, with 46% of companies deploying ML in multiple areas and 58% running models in production, the demand for skilled professionals is skyrocketing. Acehub’s Certificate Course in Machine Learning is designed to equip you with the expertise to excel in this rapidly expanding field. Here’s why you should consider enrolling in Acehub's Machine Learning course:",
  benefits: [
    {
      title: "Industry-Relevant Expertise",
      description: "Learn from data scientists and machine learning engineers who are experts in machine learning and its real-world applications across various industries. Acehub's course modules align with industry standards, ensuring that you acquire skills that are immediately applicable in the workplace."
    },
    {
      title: "Comprehensive Curriculum",
      description: "The course covers everything from machine learning fundamentals to advanced topics, including model deployment and optimization. This ensures you're well-prepared to meet the growing demands of businesses increasingly relying on machine learning for applications like audio processing and chatbot functionalities."
    },
    {
      title: "Hands-On Projects",
      description: "Acehub emphasizes a learning-by-doing approach. You'll engage in practical, hands-on projects that simulate real-world machine-learning scenarios, giving you the confidence and experience needed to implement machine learning models for solving industry problems."
    },
    {
      title: "Flexibility and Convenience",
      description: "Acehub’s online course allows you to learn at your own pace, fitting your studies around your schedule. This flexibility ensures that you can progress through the material at a speed that suits you, making it easier to balance learning with other commitments."
    },
    {
      title: "Recognition and Certification",
      description: "Upon completing the Acehub Machine Learning course, you’ll receive a certification that validates your skills and knowledge in machine learning. This certification will help you stand out in the competitive job market, demonstrating your ability to contribute to the growing field of AI and data science."
    }
  ],
  skills: [
    "Understanding and Implementing Core Machine Learning Algorithms: Such as linear regression, logistic regression, decision trees, and support vector machines, which are fundamental for developing predictive models and extracting insights from data.",
    "Programming Proficiency in Python and R: These are the go-to programming languages for machine learning. The course ensures you gain proficiency in these languages, enabling you to write efficient code for data analysis, model development, and algorithm implementation.",
    "Data Cleaning and Transformation Techniques: Master techniques for improving model accuracy through effective data preprocessing, handling missing values, and performing feature engineering.",
    "Familiarity with Popular Machine Learning Libraries: Like Scikit-learn, TensorFlow, and PyTorch. The course offers hands-on projects that allow you to apply these tools to real-world datasets, ensuring you’re job-ready.",
    "Evaluating Model Performance: Learn metrics such as accuracy, precision, and recall. You’ll perform hyperparameter tuning and cross-validation to optimize your models effectively.",
    "Data Visualization Skills: Necessary for interpreting model results and communicating findings using libraries like Matplotlib and Seaborn to create insightful visualizations.",
    "Deploying Machine Learning Models: Gain skills in tools and platforms like Docker, Kubernetes, and cloud-based solutions to manage and monitor deployed models efficiently.",
    "Advanced Techniques: Such as ensemble learning, deep learning, and natural language processing (NLP). You’ll learn to tackle complex tasks like audio analysis and chatbot responses.",
    "Experiential Learning Through Hands-On Projects: These projects help you build a robust portfolio, demonstrating your ability to solve practical problems using machine learning.",
    "Continuous Learning and Adaptation: Foster problem-solving skills and stay updated with the latest advancements in machine learning technologies and methodologies."
  ],
  syllabus: [
    {
      module: "Module 1: Fundamentals of Machine Learning",
      topics: [
        "Supervised Learning: Regression and classification algorithms.",
        "Unsupervised Learning: Clustering and dimensionality reduction techniques.",
        "Anomaly Detection: Techniques to identify outliers."
      ]
    },
    {
      module: "Module 2: Data Cleaning and Preprocessing",
      topics: [
        "Techniques for handling missing data and outliers.",
        "Normalization and standardization.",
        "Encoding categorical variables."
      ]
    },
    {
      module: "Module 3: Model Evaluation Metrics",
      topics: [
        "Metrics like accuracy, precision, recall, and F1-Score.",
        "Confusion matrix analysis and ROC-AUC for model assessment."
      ]
    },
    {
      module: "Module 4: Advanced Topics in Machine Learning",
      topics: [
        "Ensemble learning techniques such as bagging and boosting.",
        "Gradient Boosting Machines (GBM) and XGBoost."
      ]
    },
    {
      module: "Module 5: Neural Networks and Deep Learning",
      topics: [
        "Basics of neural networks and advanced architectures like CNNs, RNNs, and transformers."
      ]
    },
    {
      module: "Module 6: Model Deployment and Maintenance",
      topics: [
        "Techniques for saving and loading models.",
        "Deploying models in production environments and monitoring their performance."
      ]
    }
  ]
};

const CourseComponent = () => {
  return (
    <div className="container flex flex-col mx-auto px-4 py-8 w-10/12">
      <h1 className="text-3xl font-bold mb-4">{courseData.title}</h1>
      <p className="mb-4">{courseData.marketGrowth}</p>
      
      <h2 className="text-2xl font-semibold mt-6">Why You Should Consider This Course:</h2>
      {courseData.benefits.map((benefit, index) => (
        <div key={index} className="my-4 p-4 border rounded shadow-md">
          <h3 className="text-xl font-semibold">{benefit.title}</h3>
          <p>{benefit.description}</p>
        </div>
      ))}
      
      <h2 className="text-2xl font-semibold mt-6">Master Machine Learning Skills from Acehub’s Machine Learning Online Course</h2>
      <p>As the adoption of machine learning becomes increasingly integral to business operations, mastering the necessary skills is essential for anyone looking to excel in this transformative field. Acehub’s Machine Learning Course is designed to provide you with the technical knowledge, hands-on experience, and practical expertise needed to become proficient in machine learning. Here’s what you can expect to learn from this comprehensive course:</p>
      
      <ul className="list-disc list-inside mt-4">
        {courseData.skills.map((skill, index) => (
          <li key={index} className="mb-4">{skill}</li>
        ))}
      </ul>
      
      <h2 className="text-2xl font-semibold mt-6">Acehub Machine Learning Certification Course Syllabus</h2>
      <p className="mb-4">Acehub’s Machine Learning course modules are meticulously designed to provide a deep understanding of machine learning principles, algorithms, and applications. Each module is structured to cover essential topics, from the fundamentals of machine learning to advanced techniques like deep learning and model deployment.</p>
      
      {courseData.syllabus.map((moduleData, index) => (
        <div key={index} className="my-4 p-4 border rounded shadow-md">
          <h3 className="text-xl font-semibold">{moduleData.module}</h3>
          <ul className="list-disc list-inside mt-2">
            {moduleData.topics.map((topic, idx) => (
              <li key={idx} className="mb-2">{topic}</li>
            ))}
          </ul>
        </div>
      ))}
      
      <div className="text-center my-6">
        <h2 className="text-2xl font-semibold">Start Your Journey with Acehub Today!</h2>
        <p className="mt-2">Enroll in the Acehub Machine Learning Certification Course now to gain industry-relevant skills and take your first step toward a successful career in machine learning!</p>
        <button className="mt-4 px-6 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 transition duration-200">Start Now</button>
      </div>
    </div>
  );
};

export default CourseComponent;
