import './myapplication.css'
// import 'leaflet/dist/leaflet.css';

import React, {useEffect, useState} from "react";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";

import PdfUpload from "./PdfUpload.jsx";
import {getAxiosBaseUrl, getBaseUrlNodeServer} from "../../../../../config";
import {getAllJobsAppliedCount, getAllJobsInQueueCount, getAppliedCount, getInQueueCount} from "./userStatsUtil";
import TagsInput from "./account/TagsInput.jsx";


export default function MyResumes({signedin, verifiedEmailContext, firebaseUid, isLocalRun}) {
	const verifiedemail = verifiedEmailContext;
	const [pdfUrl, setPdfUrl] = useState('');
	const [skillText, setSkillText] = useState('');
	const [technicalSkillText, settechnicalSkillText] = useState('');
	const [pdfNamesAndIds, setpdfNamesAndIds] = useState(null);
	const [currentPdfId, setcurrentPdfId] = useState('');
	const [pdfIdToAprvdAndApplyStats, setPdfIdToAprvdAndApplyStats] = useState({});
	const [titleTags, setTitleTags] = useState([]);
	const [locationTags, setlocationTags] = useState([]);
	
	function loadPdfFile(rec) {
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getPdf/' + rec._id, {responseType: 'blob'}).then(response => {
			const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
			const pdfUrl = URL.createObjectURL(pdfBlob);
			setPdfUrl(pdfUrl);
		})
		setcurrentPdfName(rec.filename)
		setcurrentPdfId(rec._id)
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getPdfText/' + rec._id).then(response => {
			// document.getElementById('resumeText') ? document.getElementById('resumeText').value = response.data : null;
			setpdfText(response.data);
		});
		
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.get('/getMatchedJobs/' + rec._id, {
			withCredentials: true
		}).then(response => {
			setMatchedJobs(response.data);
		});
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getSkillText/' + rec._id).then(response => {
			// document.getElementById('resumeText') ? document.getElementById('resumeText').value = response.data : null;
			setSkillText(response.data);
		});
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getTechnicalSkillText/' + rec._id).then(response => {
			// document.getElementById('resumeText') ? document.getElementById('resumeText').value = response.data : null;
			settechnicalSkillText(response.data);
		});
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getExperienceText/' + rec._id).then(response => {
			// document.getElementById('resumeText') ? document.getElementById('resumeText').value = response.data : null;
			setexperienceText(response.data);
		});
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getEducationText/' + rec._id).then(response => {
			seteducationText(response.data);
		});
	}
	
	function getAllPdfData() {
		try{
			axios.defaults.baseURL = getBaseUrlNodeServer();
			axios.post('/getAllPdfs', {'verifiedemail': verifiedemail}).then(async response => {
				// console.log(response.data)
				setpdfNamesAndIds(response.data)
				if (response.data.length > 0) {
					loadPdfFile(response.data[0])
				}
				
				// setpdfIdAndCount
				const promises = response.data.map(async (rec) => {
					try {
						axios.defaults.baseURL = getAxiosBaseUrl();
						const res = await axios.get(`/getMatchedJobsCount/${rec._id}`, {
							withCredentials: true
						});
						return {[rec._id]: res.data};
					} catch (error) {
						return {[rec._id]: 0}; // Handle error case
					}
				});
				const results = await Promise.all(promises);
				const pdfIdAndCountDict = Object.assign({}, ...results);
				setpdfIdAndCount(pdfIdAndCountDict);
			}).catch(error=>{
			
			});
			
			axios.defaults.baseURL = getAxiosBaseUrl();
			axios.post('/getUserAccountData', {
				'verifiedemail': verifiedemail
			}, {
				withCredentials: true
			}).then(response => {
				const userProfileDataResp = response.data['record']
				// console.log('userProfileDataResp', userProfileDataResp)
				if (userProfileDataResp && 'titleTags' in userProfileDataResp) {
					setTitleTags(userProfileDataResp['titleTags'])
				}
				if (userProfileDataResp && 'locationTags' in userProfileDataResp) {
					setlocationTags(userProfileDataResp['locationTags'])
				}
			}).catch((exception) => {
				if (isLocalRun) {
					window.location.href = 'http://localhost:3000/'
				} else {
					window.location.href = 'https://applyjobs.ai';
				}
			})
		}catch (e){
			console.log('Exception')
		}
	}
	
	function getUserApplicationStats() {
		axios.defaults.baseURL = getAxiosBaseUrl()
		if (verifiedemail !== undefined) {
			axios.post('getPdfInQueueCount', {
				'verifiedemail': verifiedemail
			}, {
			withCredentials: true
		}).then(response => {
				setPdfIdToAprvdAndApplyStats(response.data['pdfIdToApplicationStats']);
			});
		}
	}
	
	useEffect(() => {
		getAllPdfData();
		getUserApplicationStats()
	}, []);
	
	useEffect(() => {
		// getAllPdfData();
		// getUserApplicationStats()
		// getGisData();
	}, titleTags);
	
	function deletePdfFromDb(rec) {
		const confirmDelete = window.confirm("Are you sure you want to delete this PDF, All Associated data will be deleted?");
		if (confirmDelete) {
			axios.defaults.baseURL = getBaseUrlNodeServer();
			axios.get('/deletePdf/' + rec._id).then(response => {
				getAllPdfData();
				alert(response.data.message);
			})
		}
	}
	
	function saveTextChangeToDb(e) {
		setpdfText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/setPdfText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function skillChangeEvent(e) {
		setSkillText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/updateSkillText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function technicalSkillChangeEvent(e) {
		settechnicalSkillText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/updateSkillText/' + currentPdfId, {'newTechnicalSkillText': e.target.value})
	}
	
	function experienceChangeEvent(e) {
		setexperienceText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/updateExperienceText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function educationChangeEvent(e) {
		seteducationText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/updateEducationText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function handleInputChange(e) {
		const localToken = e.target.value
		// setaccessToken(localToken);
		// upsertToDb('userdata', {'email': verifiedemail}, {'shopifyStoreName': localToken})
	}
	
	const divStyle = {
		display: "flex", marginLeft: "0px", marginTop: "0px", height: "98vh"
	};
	
	const leftDivStyle = {
		flexBasis: "40%", // Set the base width to 30% of the flex container
		backgroundColor: "#f2f2f2", // Uncommented for visibility
		padding: "20px", // Uncommented for spacing
		borderRadius: "20px"
	};
	
	const rightDivStyle = {
		flexBasis: "60%", // Set the base width to 70% of the flex container
		paddingLeft: "20px", // Adjusted for spacing between the left and right divs
		backgroundColor: "#e0e0e0", // Uncommented for visibility
		borderRadius: "20px", marginLeft: "1px"
		// marginTop: "20px" // Removed since it's unnecessary with flex layout
	};
	
	const handleTitleTagsChange = tags => {
		setTitleTags(tags)
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('/setUserProfileAndPreferences', {
			'verifiedemail': verifiedemail, 'titleTags': tags
		}, {
			withCredentials: true
		})
	}
	const handleLocationTagsChange = tags => {
		setlocationTags(tags)
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('/setUserProfileAndPreferences', {
			'verifiedemail': verifiedemail, 'locationTags': tags
		}, {
			withCredentials: true
		})
	}
	
	const [isVisible, setIsVisible] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(false);
		}, 4000); // 3000 milliseconds = 3 seconds
		return () => clearTimeout(timer);
	}, []);
	
	function getTimeReclaimed() {
		//avg 30 minutes per application
		return getAllJobsAppliedCount(pdfIdToAprvdAndApplyStats) * 0.50 + " hrs"
	}
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			{/*<SigninForm/>*/}
		</div>
	} else {
		return (<div style={{marginTop: "0px", marginLeft: "-20px"}}>
			<div style={divStyle}>
				<div style={leftDivStyle}>
					<div style={{marginTop: "-20px", display: "flex", verticalAlign: "middle", alignItems: "center",}}>
						<div style={{marginTop: "0px"}}>
							<PdfUpload getAllPdfData={getAllPdfData} verifiedemail={verifiedemail}/>
							<div style={{
								marginLeft: "-2px",
								display: "flex", // verticalAlign: "middle",
								// alignItems: "center",
								marginTop: "-0px",
								width: "590px",
								// backgroundColor: "white",
								borderRadius: "20px",
								paddingBottom: "10px",
								paddingRight: "0px",
								maxWidth: "650px"
							}}>
								<div style={{
									marginLeft: "13px",
									border: "1px solid lightgrey",
									marginTop: "10px", // marginBottom:"10px",
									padding: "10px",
									paddingTop: "5px",
									borderRadius: "10px",
									height: "150px",
									backgroundColor: "white",
									overflowY: "scroll", // marginRight: "20px"
								}}>
									<div style={{display: "flex", marginLeft: "40px"}}>
										<p style={{
											marginTop: "-5px", textAlign: "left", marginLeft: "-16px", minWidth: "90px", marginBottom: "5px"
										}}><span
											style={{color: "grey", fontSize: "12px"}}>FileName</span></p>
										{/*<p style={{*/}
										{/*	marginTop: "-5px", textAlign: "left", marginLeft: "155px", minWidth: "90px", marginBottom: "5px"*/}
										{/*}}><span*/}
										{/*	style={{color: "grey"}}>Matches</span></p>*/}
										<p style={{
											marginTop: "-5px", textAlign: "left", marginLeft: "0px", minWidth: "90px", marginBottom: "5px"
										}}>
										<span
											style={{color: "grey", fontSize: "12px", marginLeft: "130px"}}>Applying To</span></p>
										<p style={{
											marginTop: "-5px", textAlign: "left", marginLeft: "0px", minWidth: "90px", marginBottom: "5px"
										}}>
										<span
											style={{color: "grey", marginLeft: "30px", fontSize: "12px"}}> Applied</span></p>
									</div>
									{pdfNamesAndIds ? pdfNamesAndIds.map((rec, index) => {
										return (<div style={{
											display: "flex",
											fontSize: "12px",
											backgroundColor: currentPdfId === rec._id ? "lightblue" : "transparent",
										}} className='aiMatchesTable'>
											<p style={{marginTop: "0px", borderTop: "1px dotted lightgrey", color: "grey"}}>{index + 1}.</p>
											<p style={{
												marginLeft: "10px",
												fontSize: "12px",
												paddingLeft: "5px",
												paddingBottom: "0px",
												height: "22px", // borderRadius: "5px",
												borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", width: "205px",
												marginTop: "0px", overflow: "scroll", borderTop: "1px dotted lightgrey"
											}}
											   className='pdfListItem'
											   onClick={() => loadPdfFile(rec)}>
												{rec.filename}
											</p>
											<p style={{
												// backgroundColor: currentPdfId === rec._id ? "lightblue" : "transparent",
												marginTop: "-0px", textAlign: "center", marginBottom: "1px", // marginLeft: "10px",
												minWidth: "90px", paddingTop: "1px", marginLeft: "0px",
												borderTop: "1px dotted lightgrey", // borderBottom: "1px dotted lightgrey",
											}} className='pdfListItem' onClick={() => loadPdfFile(rec)}>
												<span style={{fontWeight: "normal"}}>{getInQueueCount(rec._id, pdfIdToAprvdAndApplyStats)}</span>
											</p>
											<p style={{
												// backgroundColor: currentPdfId === rec._id ? "lightblue" : "transparent",
												marginTop: "0px", textAlign: "center", marginBottom: "1px", // marginLeft: "10px",
												minWidth: "90px", paddingTop: "1px", marginLeft: "1px",
												borderTop: "1px dotted lightgrey", // borderBottom: "1px dotted lightgrey",
												// borderRight: "1px dotted lightgrey",
											}} className='pdfListItem' onClick={() => loadPdfFile(rec)}>
												<span style={{fontWeight: "normal"}}>{getAppliedCount(rec._id, pdfIdToAprvdAndApplyStats)}</span>
											</p>
											<Tooltip title='Delete CV & Associated Data' enterDelay={1000} leaveDelay={0}>
												<DeleteOutlined className='deleteButton' onClick={() => deletePdfFromDb(rec)}
												                style={{
													                height: "16px",
													                color: "#93a1a9",
													                marginLeft: "5px",
													                minWidth: "20px",
													                marginTop: "4px"
												                }}/>
											</Tooltip>
										</div>)
									}) : <li>Loading Names...</li>}
								</div>
							</div>
						</div>
					</div>
					<div>
						{pdfUrl ? (<div style={{display: "flex", marginTop: "0px"}}>
							<div style={{width: '100%', minWidth: "600px", maxWidth: "700px"}}>
								<iframe src={pdfUrl} style={{
									width: "100%", height: '75vh', borderRadius: "10px", // border: "1px solid green",
									// padding: "20px",
									paddingTop: "0px"
								}} frameBorder="0"></iframe>
							</div>
						</div>) : (<p style={{marginLeft: "25px", color: "grey", fontSize: "12px"}}>Start by uploading PDF</p>)}
					</div>
				</div>
				<div style={rightDivStyle}>
					<div style={{display: "flex", marginTop: "20px", marginLeft: "10px"}}>
						<p>Your CVs: <span style={{marginLeft: "2px"}}>{pdfNamesAndIds ? pdfNamesAndIds.length : 0}</span></p>
						<p style={{marginLeft: "30px", color: "darkgreen", fontWeight: "bold"}}>Jobs Applied: <span
							style={{
								marginLeft: "1px", color: "darkgreen", fontWeight: "bold"
							}}>{getAllJobsAppliedCount(pdfIdToAprvdAndApplyStats)}</span>
						</p>
						<p style={{marginLeft: "20px", color: "darkcyan", fontWeight: "normal"}}> Jobs Applying To: <span
							style={{
								marginLeft: "1px", color: "darkcyan", fontWeight: "normal"
							}}>{getAllJobsInQueueCount(pdfIdToAprvdAndApplyStats)}</span>
						</p>
						<p style={{
							marginLeft: "20px",
							backgroundImage: 'linear-gradient(90deg, rgb(176 146 205), rgb(155 141 156), rgb(235, 123, 163))',
							WebkitBackgroundClip: 'text', // Note the camelCase and the Webkit prefix
							color: 'transparent',
							backgroundClip: 'text',
							fontWeight: "normal"
						}}> Time Reclaimed:
							<span
								style={{
									marginLeft: "2px", fontWeight: "normal"
								}}>
									{getTimeReclaimed()}
									</span>
						</p>
					</div>
					<div style={{marginTop: "0px"}}>
						<div style={{marginRight: "10px", marginTop: "10px", marginLeft: "10px"}}>
							<div style={{display: "block", maxWidth: "573px"}}>
								<label style={{
									textAlign: "left",
									fontSize: '13px',
									fontWeight: 'bold',
									backgroundImage: 'linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))',
									WebkitBackgroundClip: 'text',
									color: 'transparent',
									backgroundClip: 'text',
									marginTop: "10px"
								}}>Titles</label>
								<TagsInput initialTags={titleTags}
								           onTagsChanged={handleTitleTagsChange}/>
								<label style={{
									textAlign: "left",
									fontSize: '13px',
									fontWeight: 'bold',
									backgroundImage: 'linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))',
									WebkitBackgroundClip: 'text',
									color: 'transparent',
									backgroundClip: 'text',
								}}>Locations</label>
								{/*{['Mountain View, CA', 'San Jose, CA']}*/}
								<TagsInput initialTags={locationTags} onTagsChanged={handleLocationTagsChange}/>
							</div>
							
							<div style={{marginTop: "20px", color: "grey"}}>Different for each Resume:</div>
							<div style={{display: "flex"}}>
								<div style={{width: "250px"}}>
									<label style={{
										fontSize: '13px',
										fontWeight: 'bold',
										backgroundImage: 'linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))',
										WebkitBackgroundClip: 'text', // Note the camelCase and the Webkit prefix
										color: 'transparent', backgroundClip: 'text', display: "block", textAlign: "left"
									}}>Professional Skills</label>
									<textarea onChange={(e) => skillChangeEvent(e)} style={{
										border: "1px solid lightgrey",
										height: "300px",
										borderRadius: "5px",
										padding: "10px",
										minWidth: "250px",
										fontSize: "12px"
									}}
									          placeholder='Add Professional Skills Here' value={skillText}/>
								</div>
								<div style={{marginLeft: "10px"}}>
									<div style={{
										fontSize: '13px',
										fontWeight: 'bold',
										backgroundImage: 'linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))',
										WebkitBackgroundClip: 'text', // Note the camelCase and the Webkit prefix
										color: 'transparent',
										backgroundClip: 'text',
										display: "block",
										textAlign: "left"
									}}>Technical Skills
									</div>
									<textarea onChange={(e) => technicalSkillChangeEvent(e)} style={{
										border: "1px solid lightgrey",
										height: "300px",
										borderRadius: "5px",
										padding: "10px",
										minWidth: "250px",
										fontSize: "12px"
									}}
									          placeholder='Add Technical Skills Here' value={technicalSkillText}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>)
	}
}