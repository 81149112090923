import React, { useState, useEffect } from "react";
import EmailSubscribe from "../MailChamp/EmailSubscribe";

export default function ConfettiGame() {
  const images = [
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_2492f7e8bd014316be46535c2351e8f0_yoloimg.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_8b081f5525a6439fac0beee42b2a7b8f_gpt_arch_img_2.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_3dacd5cebfd9481db9f3dd1ecf93c601_vit_arch_img_1.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_e5d845175d92472aadf1c62214f94233_vit_arch_img_9.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_4f9963a1945e4d79b1cc46d444f27292_bert_arch_img_1.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_28e88b80b5864da0a9e4760d72c14c29_bert_arch_img_2.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_53705dea9bfa497fa17678780e3fa8b4_vit_arch_img_6.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_dc40887e44804b16af0947140181d1ef_bert_arch_img_4.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_1c5d2dff686e48a88645b5ec203c20c4_yolov2.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_83e8ff2991a94ea2a84fcca336ce0e76_YoloArchitecture.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_65ed320fe35a43dab4e0efd2a3565db6_YoloV3.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_d0e74460f2ec4c588b9f1b750e562600_yolov4.png",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_517c7f71446c4f6c9607564c54f9d10d_yolov5.jpg",
    "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_f0922e47b6a543b6a086a2f83dcfba3c_dalle_img_1.png",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [revealed, setRevealed] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [gridCols, setGridCols] = useState(25);
  const [gridRows, setGridRows] = useState(25);

  const gridSize = 20;

  const updateGridSize = () => {
    const containerWidth = Math.min(window.innerWidth * 0.88, 600); // Max width of 600px and 90% of the viewport width
    const containerHeight = Math.min(window.innerHeight * 0.7, 500); // Max height of 500px and 70% of the viewport height
    setGridCols(Math.floor(containerWidth / gridSize));
    setGridRows(Math.floor(containerHeight / gridSize));
  };

  useEffect(() => {
    updateGridSize();
    window.addEventListener("resize", updateGridSize);
    return () => window.removeEventListener("resize", updateGridSize);
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    handleReveal(e); // Reveal the initial click position
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      handleReveal(e); // Reveal as the mouse moves while dragging
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleReveal = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const newLeft = Math.floor((e.clientX - rect.left) / gridSize) * gridSize;
    const newTop = Math.floor((e.clientY - rect.top) / gridSize) * gridSize;

    const clampedLeft = Math.max(
      0,
      Math.min(newLeft, (gridCols - 1) * gridSize)
    );
    const clampedTop = Math.max(0, Math.min(newTop, (gridRows - 1) * gridSize));

    setPosition({ top: clampedTop, left: clampedLeft });

    const gridPosition = `${clampedTop}-${clampedLeft}`;
    if (!revealed.includes(gridPosition)) {
      setRevealed((prevRevealed) => [...prevRevealed, gridPosition]);
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    setRevealed([]); // Reset revealed cells
  };

  const handleRevealAll = () => {
    const allPositions = [];
    for (let i = 0; i < gridRows; i++) {
      for (let j = 0; j < gridCols; j++) {
        allPositions.push(`${i * gridSize}-${j * gridSize}`);
      }
    }
    setRevealed(allPositions);
  };

  const handleHideAll = () => {
    setRevealed([]); // Hide the entire image
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "150px",
      }}
    >
        <div style={{display:"block", alignItems:"center", textAlign:"center"}}>
          <span
          className="font-bold"
            style={{
              marginBottom: "50px",
              color: "lightsteelblue",
              fontSize: "30px",
            }}
          >
            Coming Soon !!
          </span>
          <EmailSubscribe />
      </div>
      <span style={{ marginTop:"80px", marginBottom: "10px", color: "black", fontSize: "14px" }}>
        To Play Game,<span style={{color:"blue", opacity:"0.30"}}> click and drag brush pixel</span>
      </span>
      <div style={{ display: "flex", gap: "10px" }}>
        <button
          onClick={handleNextImage}
          style={{
            padding: "0px 10px",
            fontSize: "12px",
            borderRadius: "20px",
            border: "1px solid lightgrey",
            height:"40px"
          }}
        >
          Next Image
        </button>
        <button
          onClick={handleRevealAll}
          style={{
            padding: "0px 10px",
            fontSize: "12px",
            borderRadius: "20px",
            border: "1px solid lightgrey",
            height:"40px"
          }}
        >
          Reveal
        </button>
        <button
          onClick={handleHideAll}
          style={{
            padding: "0px 10px",
            fontSize: "12px",
            borderRadius: "20px",
            border: "1px solid lightgrey",
            height:"40px"
          }}
        >
          Hide
        </button>
      </div>
      <div
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp} // Stop dragging when the mouse leaves the grid
        style={{
          width: `${gridCols * gridSize}px`,
          height: `${gridRows * gridSize}px`,
          position: "relative",
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundSize: "contain", // Ensures the image fits within the container
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          border: "2px solid lightgrey",
          marginBottom: "10px",
          marginTop: "10px",
          cursor: isDragging ? "grabbing" : "grab",
        }}
      >
        {Array.from({ length: gridRows * gridCols }).map((_, index) => {
          const top = Math.floor(index / gridCols) * gridSize;
          const left = (index % gridCols) * gridSize;
          const gridPosition = `${top}-${left}`;

          return (
            <div
              key={index}
              style={{
                width: `${gridSize}px`,
                height: `${gridSize}px`,
                backgroundColor: "rgba(211, 211, 211, 0.95)", // Reduced visibility
                position: "absolute",
                top: `${top}px`,
                left: `${left}px`,
                opacity: revealed.includes(gridPosition) ? 0 : 1,
                transition: "opacity 0.3s ease-out",
              }}
            ></div>
          );
        })}
        <div
          style={{
            width: `${gridSize}px`,
            height: `${gridSize}px`,
            backgroundColor: "blue",
            position: "absolute",
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
        ></div>
      </div>
    </div>
  );
}
