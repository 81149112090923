import create from 'zustand';
import axios from 'axios';
import {getAxiosBaseUrl} from "../../config.js";
import localforage from 'localforage';
import {compress, encrypt, generateDocId} from "./wbUtils";
import {generatePreviewImageLocal, handleAsync, handleSync} from "./utils";
import {updateStructure, notesChangeFieldValue, saveUserNoteUtility, fetchSharedNotes} from "./api";
import {subscribeWithSelector} from 'zustand/middleware';

localforage.config({
	driver: localforage.INDEXEDDB, // Ensure IndexedDB is used
	name: 'applyJobsUserNotesOnlyApp',
	storeName: 'notes'
});

axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;


class Note {
	constructor(noteId, content, color = 'lightblue', title = 'untitled', useCaseDesc = '') {
		this.noteId = noteId;
		this.content = content;
		this.color = color;
		this.title = title;
		this.useCaseDesc = useCaseDesc;
	}
}


const notesStore = create(subscribeWithSelector((set, get) => {
		return {
			// YOU ARE ONLY GOING TO CHANGE THESE VARIABLES
			verifiedEmail: '', notes: [], notesPreviews: [], noteTitle: '', currDocId: '',
			modalOpen: false,
			
			setState: (key, value) => {
				set(state => ({...state, [key]: value}));
				//debouncedUpdateBackend(key, value);// Debounced backend call based on the specific key
			},
			
			// SETTERS
			setPreviewImagesFromNotesArr: async (verifiedemail, notes, updateVarName = 'notesPreviews') => {
				const notesPreviews = await Promise.all(
					notes.filter(note => note && (note['useCaseDesc'] || note['shared']))
						.map(async note => {
							let previewImg = null;
							if (note?.shared) {
								previewImg = await generatePreviewImageLocal(note['content'], note['email']);
							} else {
								previewImg = await generatePreviewImageLocal(note['content'], verifiedemail);
							}
							// await localforage.setItem(`note_preview_img_${note.docid}`, previewImg); //do i need in local storage??
							return {docid: note.docid, previewImg};
						})
				);
				set({[updateVarName]: notesPreviews});
			},
			
			handleShareNoteSave: async (verifiedemail, docid, selections) => {
				const {notes} = get()
				const updatedNotes = notes.map(note => {
					if (note.docid === docid) {
						return {...note, shared: true};
					}
					return note;
				});
				
				// Update state with the new list of notes
				set({notes: updatedNotes});
				
				//Mark Note shared to true, in local
				const matchedNote = notes.filter(n => n.docid === docid)[0]
				matchedNote['shared'] = true
				localforage.setItem(`note_${docid}`, matchedNote);
				
				
				//Update Social Connection, also marks the document shared in backend
				axios.post('updateFriendship', {
					verifiedemail: verifiedemail, selections: selections, docid: docid
				}, {withCredentials: true});
			},
			deleteNoteHandle: async (verifiedemail, docIdWoPrefix, useCaseDesc) => {
				const {notes} = get();
				try {
					await localforage.removeItem(`note_${docIdWoPrefix}`);
				} catch (error) {
					console.error("Error deleting from local storage:", error);
				}
				let updatedNotes;
				try {
					updatedNotes = notes.filter(note => note.docid !== docIdWoPrefix);
					set({notes: updatedNotes});
				} catch (error) {
					console.error("Error updating notes:", error);
				}
				await axios.post('deleteusernotes', {
					verifiedemail: verifiedemail, useCaseDesc: useCaseDesc, docid: docIdWoPrefix
				}, {withCredentials: true});
			},
			loadNotesFromDB:
				async (verifiedemail) => {
					try {
						const keys = await localforage.keys();
						const notesAll = await Promise.all(
							keys.filter(key => key.startsWith('note_')).map(key => localforage.getItem(key))
						);
						
						const notes = notesAll.filter(note => note && note.content && note.content.trim().length > 0);
						set({notes});
						get().setPreviewImagesFromNotesArr(verifiedemail, notes);
					} catch (error) {
						console.error('Error loading notes from DB:', error);
					}
				},
			usernotes:
				async (verifiedemail) => {
					const tipTapNotesUseDesc = 'usernotes';
					const excalidrawUseDesc = 'excalidraw';
					try {
						const [exCaliDrawNotes, sharedNotes] = await Promise.all([
							// handleSync(() => axios.post('getusernotesDocIdAndTitleOnly', {
							// 	verifiedemail: verifiedemail, useCaseDesc: tipTapNotesUseDesc
							// }).then(response => response.data.record)),
							
							handleSync(() => axios.post('getusernotesDocIdAndTitleOnly', {
								verifiedemail: verifiedemail, useCaseDesc: excalidrawUseDesc
							}).then(response => response.data.record)),
							fetchSharedNotes(verifiedemail)
						]);
						// const sharedNotes2 = sharedNotes//.map(({content, ...rest}) => rest)
						// const combinedNotes = [...tipTapNotes, ...exCaliDrawNotes, ...sharedNotes2];
						
						const combinedNotes = [...exCaliDrawNotes, ...sharedNotes];
						
						const uniqueNotes = combinedNotes//.filter((note, index, self) => index === self.findIndex((n) => n.docid === note.docid));
						await get().mergeNotes(verifiedemail, uniqueNotes);
					} catch (error) {
						set({notes: []});
					}
				},
			mergeNotes: async (verifiedemail, remoteNotes) => {
				const localNotes = get().notes;
				let mergedNotes = [...localNotes]; // Start with local notes
				
				const localNotesMap = new Map(localNotes.map(note => [note.docid, note]));
				const remoteNotesMap = new Map(remoteNotes.map(note => [note.docid, note]));
				
				// Update or add remote notes
				for (const [docid, remoteNote] of remoteNotesMap.entries()) {
					if (localNotesMap.has(docid)) {
						const localNote = localNotesMap.get(docid);
						// If the local note is shared, use the remote note
						if (localNote.shared) {
							const index = mergedNotes.findIndex(note => note.docid === docid);
							mergedNotes[index] = remoteNote;
						}
					} else {
						// If the note does not exist locally, add the remote note
						mergedNotes.push(remoteNote);
					}
				}
				
				// Remove local shared notes that are not present in remote notes
				mergedNotes = mergedNotes.filter(note => {
					if (note.shared) {
						localforage.removeItem(`note_${note.docid}`);
						return remoteNotesMap.has(note.docid);
					}
					return true;
				});
				
				set({notes: mergedNotes});
				await Promise.all(mergedNotes.map(note => localforage.setItem(`note_${note.docid}`, note)));
				get().setPreviewImagesFromNotesArr(verifiedemail, mergedNotes);
			},
			handleRenameNote: (key, newTitle, verifiedEmail, friendShips) => {
				const {notes} = get();
				if (key.startsWith('note-')) {
					const docid = key.split('note-')[1]
					set({noteTitle: newTitle})
					
					const matchedNote = notes.filter(n => n.docid === docid)[0]
					matchedNote['title'] = newTitle
					localforage.setItem(`note_${docid}`, matchedNote);
					
					const [sourceLoc, actualNote] = get().getNoteAndCollLocByKey(key);
					
					// console.log(sourceLoc, actualNote, newTitle, matchedNote);
					
					let useCaseDesc = 'na';
					if (sourceLoc === 'tiptap') {
						useCaseDesc = 'usernotes'
						notesChangeFieldValue(verifiedEmail, useCaseDesc, docid, 'title', newTitle);
					} else if (sourceLoc === 'excalidraw') {
						useCaseDesc = 'excalidraw'
						notesChangeFieldValue(verifiedEmail, useCaseDesc, docid, 'title', newTitle);
					} else if (sourceLoc === 'shared') {
						notesChangeFieldValue(verifiedEmail, null, docid, 'title', newTitle, true)
					}
				}
			},
			getNoteAndCollLocByKey:
				(key) => {
					const {notes} = get();
					const docid = key.split('note-')[1]; //Set the name change in backend
					//But there are 3 backends for notes (tiptap, excalidraw & shared notes)
					try {
						const matchedNote = notes.filter(n => n.docid === docid)[0]
						if (matchedNote) {
							if ('useCaseDesc' in matchedNote) {
								if (matchedNote['useCaseDesc'] === 'usernotes') {
									return ['tiptap', matchedNote];
								} else if (matchedNote['useCaseDesc'] === 'excalidraw') {
									return ['excalidraw', matchedNote];
								}
							} else {
								return ['shared', matchedNote];
							}
						}
						return [];
					} catch (e) {
						return []
					}
				},
			fetchUseCaseNotes:
				async (verifiedemail, useCaseDesc) => {
					const response = axios
						.post("getusernotesonlyusecasespecific", {
							verifiedemail: verifiedemail, useCaseDesc: useCaseDesc,
						})
					return response.data.record[0][useCaseDesc];
				},
			
			wipeLocalData: async () => {
				try {
					await localforage.clear();
					console.log('All localforage data wiped successfully.');
				} catch (error) {
					console.error('Error wiping localforage data:', error);
				}
			},
			// CREATE
			createNewButtonEvent:
				(verifiedemail, useCaseDesc, newTitle = 'untitled') => {
					const {notes} = get();
					const newDocId = generateDocId();
					set({currDocId: newDocId});
					set({noteTitle: newTitle});
					
					let data = {
						elements: [], appState: [], files: [], type: 'json'
					};
					const flightData = encrypt(compress(data), verifiedemail);
					const updatedNote = {
						content: flightData,
						title: newTitle,
						docid: newDocId,
						useCaseDesc: useCaseDesc,
						bgcolor: "lightblue"
					}
					
					const updatedNotes = [...notes, updatedNote];
					set({notes: updatedNotes});
					
					try {
						get().updateLocalNoteAndPreview(newDocId, updatedNote, verifiedemail);
						
						//get().loadNotesFromDB(verifiedemail);
						
						saveUserNoteUtility(verifiedemail, useCaseDesc, flightData, newDocId, newTitle)
					} catch (error) {
						console.error("An error occurred:", error);
					}
				},
			
			updateLocalNoteAndPreview: async (docidWoPrefix, updatedNote, secretKey) => {
				const {notesPreviews} = get();
				try {
					
					localforage.setItem(`note_${docidWoPrefix}`, updatedNote);
					
					//Previews strictly tied to local forage update
					const previewImg = await generatePreviewImageLocal(updatedNote['content'], secretKey);
					const updatedNotesPreviews = notesPreviews.map(preview => {
						if (preview.docid === updatedNote.docid) {
							return {
								...preview,
								previewImg: previewImg
							};
						}
						return preview;
					});
					set({notesPreviews: updatedNotesPreviews});
					
				} catch (error) {
					console.error('Error adding note:', error);
				}
			},
			handleSaveButtonEvent: (docid, excalidrawAPI, secretKey, verifiedemail, notetitle) => {
				const {notes} = get();
				try {
					const matchedNote = notes.filter(n => n.docid === docid)[0]
					if (matchedNote?.shared && verifiedemail !== matchedNote['email']) {
						return 'No Permission'
					}
					let data = {
						elements: excalidrawAPI.getSceneElements(), appState: excalidrawAPI.getAppState(),
						files: excalidrawAPI.getFiles(), type: 'json'
					};
					const flightData = encrypt(compress(data), secretKey);
					
					const updatedNotes = notes.map(note => {
						if (note.docid === docid) {
							return {
								...note,
								content: flightData,
								title: notetitle
							};
						}
						return note;
					});
					set({notes: updatedNotes});
					get().updateLocalNoteAndPreview(docid, updatedNotes.find(note => note.docid === docid), secretKey)
					
					//Change Backend ... if it's a shared note it must be saved in the shared collection
					saveUserNoteUtility(verifiedemail, 'excalidraw', flightData, docid, notetitle, matchedNote?.shared);
					return 'success'
				} catch (error) {
					console.log(error.message);
				}
			},
			
			// EVENTS RESPONSES
			handleNoteClick:
				(noteIdWPrefixNote, hasPrefix = true) => {
					if (hasPrefix) {
						set({currDocId: noteIdWPrefixNote.split('note-')[1]});
						// const docid = noteIdWPrefixNote.split('note-')[1];
						// const [sourceLoc, actualNote] = get().getNoteAndCollLocByKey(noteIdWPrefixNote);
					} else {
						set({currDocId: noteIdWPrefixNote});
					}
				}
		}
	}
))

export default notesStore;
