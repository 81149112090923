import React, {useState} from "react";
import EmailSubscribe from "../MailChamp/EmailSubscribe";

export default function GroupClassRequest({isOpen, onClose, course}) {
	const [userMsg, setUserMsg] = useState("");
  const [selectedPriceBucket, setSelectedPriceBucket] = useState("");
	
	if (!isOpen) return null;
	const priceBuckets = [
		{label: "Tier 1", value: "Rs. 3000"},
		{label: "Tier 2", value: "Rs. 5000"},
		{label: "Tier 3", value: "Rs. 8000"},
		{label: "Tier 4", value: "Rs. 10000"},
	];
	 const handlePriceBucketChange = (value) => {
    setSelectedPriceBucket(value);
  };
	
	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
			<div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
				<div className="flex justify-between items-center border-b pb-3">
					<h2 className="text-xl font-semibold text-gray-400">Group Class: Limited Paid Seats</h2>
					{/*{course.title} - */}
					<button onClick={onClose} className="text-gray-400 hover:text-gray-600">&times;</button>
				</div>
				
				<div className="mt-4">
					<p className="text-sm text-gray-700">
						Duration:{" "}<span className="font-semibold">1.5 hours (class + coding + questions)</span>
					</p>
					<p className="text-sm text-gray-700 mt-4">
						Location:{" "}<span className="font-semibold">Full Remote</span>
					</p>
					<p className="text-sm text-gray-700 mt-4">
						{/*<strong>Timing:</strong> {course.timing || "TBD (Notified when cohort of 10 is full)"}*/}
						Timing:{" "}{" "}<span className="font-semibold">6:00 PM to 10:00 PM Indian Standard Time</span>
						{/*{course.timing || "Notified when cohort of 10 is ready."}*/}
					</p>
					<p className="text-sm text-gray-700 mt-4">
						{/*<strong>Timing:</strong> {course.timing || "TBD (Notified when cohort of 10 is full)"}*/}
						Frequency:{" "}{" "}<span className="font-semibold">3 days / week</span>
						{/*{course.timing || "Notified when cohort of 10 is ready."}*/}
					</p>
					<p className="text-sm text-gray-700 mt-4">
						<strong>Pricing:</strong>
						{/*<strong>Pricing:</strong>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "1 Seat / Rs 5,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "2 Seats / Rs 8,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "4 Seat / Rs 15,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "10 Seat / Rs 35,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						
						{/*<strong>Pricing:</strong>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "1st to 2nd Seat / Rs 3,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "3rd to 5th Seats / Rs 4,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "6rd to 8th Seats / Rs 5,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						{/*<ul style={{marginLeft: "60px", marginTop: "4px"}}>*/}
						{/*    {course.pricing || "9th to 10th Seats / Rs 7,000 / month / 4+ classes"}*/}
						{/*</ul>*/}
						{" "} Select a Tier
					</p>
					
					<div className="mt-2">
						{priceBuckets.map((bucket) => (
							<div key={bucket.value} className="flex items-center mt-1">
								<input
									type="radio"
									id={bucket.value}
									name="priceBucket"
									value={bucket.value}
									checked={selectedPriceBucket === bucket.value}
									onChange={() => handlePriceBucketChange(bucket.value)}
									className="mr-2"
								/>
								<label htmlFor={bucket.value} className="text-sm text-gray-700">
									{bucket.label} {" "} {bucket.value} {" / mon"}
								</label>
							</div>
						))}
					</div>
					{/*<p className="text-sm text-gray-700 mt-4">*/}
					{/*<strong>Availability:</strong> {course.waitlistAvailable ? "Available" : "10"}*/}
					{/*<strong>Availability:</strong> {"September 2024 Onwards"}*/}
					{/*</p>*/}
					{/*<p className="text-sm text-gray-700 mt-4">*/}
					{/*    <strong>Your Objective:</strong>*/}
					{/*</p>*/}
					{/*<textarea*/}
					{/*    className="w-full min-h-20 p-2 border border-gray-300 mt-2"*/}
					{/*    placeholder={"Tell us in few words why do you want to join our group class for "+ course.title}*/}
					{/*    onChange={setUserMsg}*/}
					{/*    style={{overflowWrap: "break-word", whiteSpace: "pre-wrap", fontSize:"13px"}}*/}
					{/*/>*/}
					<div className="mt-2">
						<EmailSubscribe buttonText="Join Waitlist"
						                btnMarginTop="10px"
						                transientStateMsg="Requesting"
						                sourceInfo={"Group Class Interest for courseid " + course.id}
						                alignItems="left"
						                btnBackgroundColor="rgb(105 154 228)"
						                btnColor="white"
							// userMsg={userMsg}
						/>
					</div>
				</div>
				
				<div className="mt-6 flex justify-end">
					<button
						onClick={onClose}
						className="bg-blue-200 text-gray-600 px-2 py-0 rounded hover:bg-blue-600"
					>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}
