import React from "react";
import HeadindText from "../Utils/HeadindText";

const ProjectCertification = () => {
  return (
    <div className=" py-10 px-6 md:px-16 lg:px-32 mt-12">
      <div className="mx-auto max-w-4xl sm:text-center mb-8">
       
        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
          <HeadindText Heading={`Certifications`} />
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Get certified in cutting-edge AI technologies with hands-on projects like Speech-to-Speech AI Chatbots, real-time voice conversion, and more.
        </p>
      </div>
      
      <div className="flex flex-col md:flex-row items-center justify-between gap-10">
        <div className="md:w-8/12">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Become Certified in Speech-to-Speech AI Chatbot Development
          </h1>
          <ul className="space-y-4 text-lg text-gray-700">
            <li className="flex items-center">
              <span className="text-blue-500 mr-2">✔</span>
              Master Speech-to-Speech AI Chatbot development with real-world project experience.
            </li>
            <li className="flex items-center">
              <span className="text-blue-500 mr-2">✔</span>
              Learn to build an AI-driven chatbot that responds to speech in real-time, using advanced voice synthesis.
            </li>
            <li className="flex items-center">
              <span className="text-blue-500 mr-2">✔</span>
              Follow a structured course designed to fit your schedule, typically spanning 4-6 weeks.
            </li>
            <li className="flex items-center">
              <span className="text-blue-500 mr-2">✔</span>
              Work on an end-to-end project that involves AI, speech recognition, and speech synthesis, showcasing your skills upon completion.
            </li>
            <li className="flex items-center">
              <span className="text-blue-500 mr-2">✔</span>
              Get your project evaluated by AI industry experts and receive personalized feedback to help you succeed.
            </li>
          </ul>
          <button className="mt-6 px-8 py-4 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-700 transition">
            Start Now
          </button>
        </div>

        <div className="md:w-1/3 flex justify-center">
          <img
            src="https://plus.unsplash.com/premium_photo-1682765673139-40958c79e647?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGNlcnRpZmljYXRpb258ZW58MHwwfDB8fHww" // Replace with actual image URL or use import if locally available
            alt="Certificate"
            className="w-full shadow-lg rounded-lg"
          />
        </div>
      </div>

      <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto mb-8">
          <h3 className="text-2xl font-bold tracking-tight text-gray-900">AI Certification Courses</h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Choose the course duration that best fits your schedule, and gain hands-on experience with speech AI projects that can be applied across industries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCertification;
