import React from 'react';

const Modal = ({ src, onClose, position, onMouseEnter }) => {
  // console.log('I got a source and position to show the iamge here : ',src, position);
  return (
    <div
      style={{
        position: "fixed",
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 10000,
        backgroundColor: 'rgba(211, 211, 211, 0.8)', // Light grey with 80% opacity
        display: 'flex', // Use flexbox
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        border: '1px solid lightgrey',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        width: `${position.width}`,
        height: `${position.height}`,
        padding: "5px",
        overflow: "hidden" // Ensure the image does not overflow the div
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onClose}
    >
      <img
        src={src}
        alt="Preview"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain' // Ensure the image scales correctly within the modal
        }}
      />
    </div>
  );
};

export default Modal;
