// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* In your CSS file or within a <style> tag */
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Loading bar CSS */
.loading-bar {
  width: 100px;
  height: 10px;
  background: #f3f3f3;
  position: relative;
  overflow: hidden;
}

.loading-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #3498db;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% { left: -100%; }
  50% { left: 0; }
  100% { left: 100%; }
}
`, "",{"version":3,"sources":["webpack://./src/Components/pages/Helpers/LoadingPage.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;EACE,yBAAyB,EAAE,eAAe;EAC1C,6BAA6B,EAAE,SAAS;EACxC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;;AAGA,oBAAoB;AACpB;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,KAAK,WAAW,EAAE;EAClB,MAAM,OAAO,EAAE;EACf,OAAO,UAAU,EAAE;AACrB","sourcesContent":["/* In your CSS file or within a <style> tag */\n.loader {\n  border: 8px solid #f3f3f3; /* Light grey */\n  border-top: 8px solid #3498db; /* Blue */\n  border-radius: 50%;\n  width: 60px;\n  height: 60px;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n\n/* Loading bar CSS */\n.loading-bar {\n  width: 100px;\n  height: 10px;\n  background: #f3f3f3;\n  position: relative;\n  overflow: hidden;\n}\n\n.loading-bar::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  background: #3498db;\n  animation: loading 2s infinite;\n}\n\n@keyframes loading {\n  0% { left: -100%; }\n  50% { left: 0; }\n  100% { left: 100%; }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
