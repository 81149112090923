import React, {useEffect, useState} from 'react';
import DemoJsx from './DemoJsx';
import Button from '@mui/material/Button';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
function SimpleSideMenuProvider({verifiedemail, useCaseDesc, folders, noteClickHandle,
	                                handleCreateFolder, handleRename, createNewButtonEvent,
	                                updateTreeStructure, handleRefresh, onDeleteFolderHandle}) {
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [toggleCollapse, settoggleCollapse] = useState(false);
	
	function handleNoteClick(noteId){
		noteClickHandle(noteId)
	}
	
	const toggleExpandAll = (expand) => {
		if (expand) {
			setExpandedKeys([]);
			const allKeys = [];
			const getAllKeys = (folders) => {
				folders.forEach(node => {
					allKeys.push(node.key);
					if (node.children) {
						getAllKeys(node.children);
					}
				});
			};
			getAllKeys(folders);
			setExpandedKeys(allKeys);
		} else {
			setExpandedKeys([]);
		}
	};
	
	function collapseToggle() {
		toggleExpandAll(!toggleCollapse);
		settoggleCollapse(!toggleCollapse);
	}
	
	
	return (<div style={{marginTop: "5px"}}>
		{/*TOP 4 BUTTONS*/}
		<div style={{display: "flex", gap: "5px", marginBottom: "5px", marginTop: "5px", marginLeft: "0px"}}>
			<Button style={{minWidth: "auto", padding: "5px", border: "1px dotted lightgrey", backgroundColor:"white"}}
			        onClick={collapseToggle}>
				{toggleCollapse ? <UnfoldLessIcon style={{color: "black", height: "16px"}}/> :
					<UnfoldMoreIcon style={{color: "black", height: "16px"}}/>}</Button>
			
			<Button style={{minWidth: "auto", padding: "5px", border: "1px dotted lightgrey", backgroundColor:"white"}}
			        onClick={() => createNewButtonEvent(verifiedemail, useCaseDesc)}>
				<NoteAddIcon style={{height: "16px", color: "black"}}/></Button>
			
			<Button style={{minWidth: "auto", padding: "5px", border: "1px dotted lightgrey", backgroundColor:"white"}}
			        onClick={handleCreateFolder}>
				<CreateNewFolderIcon style={{color: "black", height: "16px"}}/></Button>
			
			<Button style={{minWidth: "auto", padding: "5px", border: "1px dotted lightgrey", backgroundColor:"white"}}
			        onClick={handleRefresh}><AutorenewIcon
				style={{color: "black", height: "16px"}}/></Button>
		</div>
		
		{/*BODY OF THE TREE*/}
		<div style={{marginBottom: "10px"}}></div>
			<DndProvider backend={HTML5Backend}>
				<DemoJsx initialData={folders}
				      onStructureChange={updateTreeStructure}
				      onRename={handleRename}
				      expandedKeys={expandedKeys}
				      onNoteClick={handleNoteClick} // Pass the handler
					onDeleteHandle={onDeleteFolderHandle}
				/>
				
			</DndProvider>
	</div>);
}

export default SimpleSideMenuProvider;
