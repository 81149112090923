export const webImages = [
    {
      src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_d16dc4fe07bc4b85ba63f2fb13146b6f_WhatsApp_Image_2024-09-28_at_9.17.57_PM.jpeg",
      description: "Web Image 1 Description",
      buttonText: "View Web Image 1",
    },
    {
      src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_acc8367ff70f458a999631cc5a822131_WhatsApp_Image_2024-09-28_at_9.14.35_PM.jpeg",
      description: "Web Image 2 Description",
      buttonText: "View Web Image 2",
    },
    {
      src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_4016437ff6b24bc0a28711cb1cc51d36_WhatsApp_Image_2024-09-28_at_8.50.57_PM.jpeg",
      description: "Web Image 3 Description",
      buttonText: "View Web Image 3",
    },
    {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_acc8367ff70f458a999631cc5a822131_WhatsApp_Image_2024-09-28_at_9.14.35_PM.jpeg",
        description: "Web Image 2 Description",
        buttonText: "View Web Image 2",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_4016437ff6b24bc0a28711cb1cc51d36_WhatsApp_Image_2024-09-28_at_8.50.57_PM.jpeg",
        description: "Web Image 3 Description",
        buttonText: "View Web Image 3",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_acc8367ff70f458a999631cc5a822131_WhatsApp_Image_2024-09-28_at_9.14.35_PM.jpeg",
        description: "Web Image 2 Description",
        buttonText: "View Web Image 2",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_4016437ff6b24bc0a28711cb1cc51d36_WhatsApp_Image_2024-09-28_at_8.50.57_PM.jpeg",
        description: "Web Image 3 Description",
        buttonText: "View Web Image 3",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_acc8367ff70f458a999631cc5a822131_WhatsApp_Image_2024-09-28_at_9.14.35_PM.jpeg",
        description: "Web Image 2 Description",
        buttonText: "View Web Image 2",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_4016437ff6b24bc0a28711cb1cc51d36_WhatsApp_Image_2024-09-28_at_8.50.57_PM.jpeg",
        description: "Web Image 3 Description",
        buttonText: "View Web Image 3",
      },
  ];
  
  export const androidImages = [
    {
      src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_b6682282d31945918ddbfce866294a0c_WhatsApp_Image_2024-09-28_at_9.19.30_PM_(1).jpeg",
      description: "Android Image 1 Description",
      buttonText: "View Android Image 1",
    },
    {
      src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_97805e4c3af34620b982b8f7f4a0ff6a_WhatsApp_Image_2024-09-28_at_9.19.30_PM.jpeg",
      description: "Android Image 2 Description",
      buttonText: "View Android Image 2",
    },
    {
      src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_284fd7b503d64168b8966a1f25ea41fd_WhatsApp_Image_2024-09-28_at_9.19.29_PM_(1).jpeg",
      description: "Android Image 3 Description",
      buttonText: "View Android Image 3",
    },
    {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_97805e4c3af34620b982b8f7f4a0ff6a_WhatsApp_Image_2024-09-28_at_9.19.30_PM.jpeg",
        description: "Android Image 2 Description",
        buttonText: "View Android Image 2",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_284fd7b503d64168b8966a1f25ea41fd_WhatsApp_Image_2024-09-28_at_9.19.29_PM_(1).jpeg",
        description: "Android Image 3 Description",
        buttonText: "View Android Image 3",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_97805e4c3af34620b982b8f7f4a0ff6a_WhatsApp_Image_2024-09-28_at_9.19.30_PM.jpeg",
        description: "Android Image 2 Description",
        buttonText: "View Android Image 2",
      },
      {
        src: "https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_284fd7b503d64168b8966a1f25ea41fd_WhatsApp_Image_2024-09-28_at_9.19.29_PM_(1).jpeg",
        description: "Android Image 3 Description",
        buttonText: "View Android Image 3",
      },
  ];
  