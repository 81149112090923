import React from "react";
import HeadindText from "../Utils/HeadindText";

const certificationsData = [
  {
    title: "Machine Learning Certification",
    description:
      "ProjectPro's Machine Learning Certification is equivalent to real-world industry experience, preparing you for the demands of the industry.",
    duration: "16-24 weeks",
    features: [
      "Follow a structured learning path designed to fit your schedule.",
      "Complete an End-to-End Machine Learning project that showcases your skills.",
      "Receive evaluation and constructive feedback from industry experts.",
    ],
  },
  {
    title: "Natural Language Processing (NLP) Certification",
    description:
      "Our NLP Certification provides you with hands-on experience in processing and analyzing text data, preparing you for careers in AI.",
    duration: "12-20 weeks",
    features: [
      "Learn key NLP techniques, including sentiment analysis and chatbots.",
      "Work on real-world projects, applying NLP to solve business problems.",
      "Get mentorship from seasoned AI professionals.",
    ],
  },
  {
    title: "Computer Vision Certification",
    description:
      "Get certified in Computer Vision and learn how to build applications that interpret visual data.",
    duration: "14-22 weeks",
    features: [
      "Hands-on projects involving image recognition and video analysis.",
      "Collaborate with peers on team projects for practical experience.",
      "Receive feedback from industry experts to enhance your skills.",
    ],
  },
  // Add more certifications as needed
];

const Certification = () => {
  return (
    <div className="py-10 px-6 md:px-16 lg:px-32 mt-12">
      <div className="mx-auto max-w-4xl sm:text-center mb-8">
        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
          <HeadindText Heading={`Certifications`} />
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Get certified in AI with hands-on projects and upskill across various AI tracks with us.
        </p>
      </div>

      {/* Flexbox layout for different screen sizes */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-14">
        {certificationsData.map((certification, index) => (
          <div
            key={index}
            className="pb-24 pt-6 relative p-8 bg-white shadow-lg rounded-lg hover:shadow-xl transform transition duration-300 hover:scale-105 ease-in-out"
          >
            {/* Coming Soon Tag */}
            <span className="absolute top-0 right-0 bg-richYellow text-white text-lg px-3 py-1 rounded-bl-lg rounded-tr-lg  text-sm font-bold">
              Coming Soon
            </span>
            <h1 className="text-3xl font-bold text-gray-800 mb-4">
              {certification.title}
            </h1>
            <p className="text-lg text-gray-700 mb-4">
              {certification.description}
            </p>
            <h3 className="font-semibold">Duration: {certification.duration}</h3>
            <ul className="space-y-4 text-lg text-gray-700 mt-4">
              {certification.features.map((feature, idx) => (
                <li key={idx} className="flex items-center">
                  <span className="text-richYellow mr-2">✔</span>
                  {feature}
                </li>
              ))}
            </ul>
            {/* Join Waitlist Button with onClick */}
            <button
              className="absolute bottom-4 left-4  px-8 py-4 bg-richYellow text-white font-bold rounded-lg hover:bg-yellow-600 transition transform hover:scale-110 duration-300 ease-in-out"
              onClick={() =>
                window.open("https://discord.gg/y9pmAxp79v", "_blank")
              }
            >
              Join Waitlist
            </button>
          </div>
        ))}
      </div>

      <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto mb-8">
          <h3 className="text-2xl font-bold tracking-tight text-gray-900">AI Certification Courses</h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Choose from various AI tracks and enjoy flexible options for hands-on projects tailored to your schedule.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Certification;
