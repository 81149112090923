import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

function TableSkeleton({ height,column }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "#FEFEFA" ,opacity:1, width: "100%", height: height ,overflow:"hidden"}}
    >
      <Table aria-label="skeleton table">
        <TableBody>
          {[...Array(column)].map(
            (
              _,
              index // Assuming you want 6 rows
            ) => (
              <TableRow key={index} sx={{ height: "58px" }}>
                <TableCell width={"1%"}>
                  <Skeleton animation="wave" height={"20px"} width="10px" />
                </TableCell>
                <TableCell width={"5%"}>
                  <Skeleton animation="wave" height={"20px"} width="100%" />
                </TableCell>
                <TableCell width={"8%"}>
                  <Skeleton animation="wave" height={"20px"} width="90%" /> 
                </TableCell>
                <TableCell width={"3.5%"}>
                  <Skeleton animation="wave" height={"20px"} width="90%" />
                </TableCell>
                <TableCell style={{width:"2%"}}>
                  <Skeleton animation="wave" height={"20px"} width="100%" style={{marginRight:"1rem"}}/>
                </TableCell>
                <TableCell style={{width:"8%" }}>
                  <Skeleton animation="wave" height={"20px"} width="10%" style={{marginLeft:"3rem"}} />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableSkeleton;
