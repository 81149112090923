import {create} from "zustand";
import axios from "axios";
import {getAxiosBaseUrl} from "../../../config";

axios.defaults.baseURL = getAxiosBaseUrl();

const menuStore = create((set, get) => ({
	menuData: {},
	// Actions
	setState: (key, values) => {
		set((state) => ({...state, [key]: values}));
	},
	fetchMenuData: async () => {
		// console.log('I am going to fetch menu data');
	    try {
	        axios.defaults.baseURL = getAxiosBaseUrl();
	        const response = await axios.get("/get_acehub_menu_data", {
	            withCredentials: true,
	        });
	        const menuData = response.data;

	        // Function to recursively sort menu data and remove orderIndex
	        const sortAndCleanMenuData = (data) => {
	            if (Array.isArray(data)) {
	                return data.map(item => {
	                    if (typeof item === 'object' && item !== null) {
	                        return sortAndCleanMenuData(item);
	                    }
	                    return item;
	                });
	            } else if (typeof data === 'object' && data !== null) {
	                // Sort the data based on orderIndex
	                const orderedDataArray = Object.entries(data).sort(
	                    ([, a], [, b]) => a.orderIndex - b.orderIndex
	                );
	                const orderedData = Object.fromEntries(orderedDataArray);

	                // Recursively sort nested objects or arrays and remove orderIndex
	                for (const key in orderedData) {
	                    if (typeof orderedData[key] === 'object' && orderedData[key] !== null) {
	                        orderedData[key] = sortAndCleanMenuData(orderedData[key]);
	                    }
	                }

	                // Remove orderIndex from the final output
	                delete orderedData['orderIndex'];

	                return orderedData;
	            }
	            return data;
	        };

	        const orderedMenuData = sortAndCleanMenuData(menuData);
	        set({ menuData: orderedMenuData });
	    } catch (error) {
	        console.error("Error fetching data:", error);
	    }
	},
	saveData: async (newData) => {
		try {
			await axios.post("/readiness/coding_practice_data_save", newData, {
				withCredentials: true,
			});
			set({data: newData, originalData: newData});
		} catch (error) {
			console.error("Error saving data:", error);
		}
	}
}));

export default menuStore;
