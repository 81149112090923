import React from "react";

const curriculumData = [
  {
    module: "Module 1",
    title: "Fundamentals of Audio AI",
    topics: [
      "Introduction to Audio Processing",
      "Basics of Machine Learning",
      "Supervised Learning Overview",
      "Key Algorithms: Regression and Classification",
      "Unsupervised Learning in Audio Data",
      "Clustering Algorithms for Audio Analysis",
    ],
  },
  {
    module: "Module 2",
    title: "Audio Data Preparation",
    topics: [
      "Data Cleaning and Preprocessing Techniques",
      "Handling Noise and Distortion",
      "Normalization and Standardization of Audio Features",
      "Encoding Categorical Audio Variables",
      "Train-Test Split in Audio Datasets",
      "Cross-validation Techniques for Audio Models",
    ],
  },
  {
    module: "Module 3",
    title: "Evaluating Audio Models",
    topics: [
      "Model Evaluation Metrics for Audio AI",
      "Accuracy, Precision, Recall, and F1-Score",
      "Confusion Matrix for Audio Classifiers",
      "ROC and AUC in Audio Performance Evaluation",
    ],
  },
  {
    module: "Module 4",
    title: "Advanced Techniques in Audio AI",
    topics: [
      "Ensemble Learning for Improved Accuracy",
      "Bagging and Boosting Techniques",
      "Gradient Boosting Machines (GBM) and XGBoost for Audio Data",
    ],
  },
  {
    module: "Module 5",
    title: "Neural Networks for Audio Processing",
    topics: [
      "Basics of Neural Networks Applied to Audio",
      "Convolutional Neural Networks (CNNs) for Audio Feature Extraction",
      "Recurrent Neural Networks (RNNs) for Sequential Audio Data",
      "Transformers and Attention Mechanism in Audio Processing",
    ],
  },
  {
    module: "Module 6",
    title: "Deployment and Maintenance of Audio AI Models",
    topics: [
      "Saving and Loading Audio Models",
      "Deploying Models in Production Environments",
      "Monitoring Model Performance in Real-Time",
      "Handling Model Drift in Audio Applications",
    ],
  },
];

const AudioToAudioChatbotCurriculum = () => {
  return (
    <div className="container flex flex-col my-10 mx-auto py-10 w-10/12">
      <h1 className="text-3xl font-bold my-8 text-center">
        <span
          style={{
            background:
              "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Audio-to-Audio AI Chatbot Curriculum
        </span>
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {curriculumData.map((module, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg p-8 transition-transform duration-300 hover:shadow-lg my-4"
          >
            <div className="text-xl font-semibold mb-2 flex flex-col justify-center items-center">
              <div className="my-2">
              <span
          style={{
            background:
              "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {module.module}
        </span></div>
              <div>
              
                {module.title}
              </div>{" "}
            </div>
            <ul className="list-disc pl-5 text-gray-700">
              {module.topics.map((topic, idx) => (
                <li key={idx} className="mb-4">
                  {topic}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="mt-8 text-center">
        <button className="px-6 py-3 bg-color2 text-white font-semibold rounded-lg hover:bg-color3 transition">
          Start Now
        </button>
      </div>
    </div>
  );
};

export default AudioToAudioChatbotCurriculum;
