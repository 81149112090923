import React from "react";
import BuildIcon from "@mui/icons-material/Build";
import PersonIcon from "@mui/icons-material/Person";
import CodeIcon from "@mui/icons-material/Code";
import UpdateIcon from "@mui/icons-material/Update";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloudIcon from "@mui/icons-material/Cloud";

const benefitsData = [
  {
    title: "Build a Job-Ready Portfolio",
    description:
      "With ProjectPro, you'll tackle enterprise-grade business use cases, creating a project portfolio that demonstrates your expertise to deliver practical solutions.",
    icon: <BuildIcon />,
  },
  {
    title: "Unlimited 1:1 Personalised Mentorship",
    description:
      "Our unlimited 1:1 mentor sessions are your secret weapon! Connect with industry pros who've been there, done that, and are excited to guide you on troubleshooting projects, career strategy, or interview preparation every step of the way.",
    icon: <PersonIcon />,
  },
  {
    title: "Project-Based Course Curriculum",
    description:
      "ProjectPro's course curriculum stands out in the world of online learning. Our courses are entirely online and completely project-based that allows you to apply what you learn in real-world scenarios.",
    icon: <CodeIcon />,
  },
  {
    title: "Curriculum Updated with New Projects",
    description:
      "We partner with industry experts to keep our curriculum updated with new projects released every month to ensure you learn with access to the latest tools and frameworks.",
    icon: <UpdateIcon />,
  },
  {
    title: "Gain Industry-Equivalent Machine Learning Certification",
    description:
      "Our certification signifies more than just completion; they represents real-world experience gained through hands-on projects with skills and knowledge that employers value.",
    icon: <VerifiedIcon />,
  },
  {
    title: "Cloud Lab Workspace",
    description:
      "Our Cloud Lab Workspace provides a ready-to-use environment for running practical projects tailored specifically for your learning needs.",
    icon: <CloudIcon />,
  },
];

const AudioToAudioChatbotBenefits = () => {
  return (
    <div className="container mx-auto py-10 flex flex-col gap-8 w-10/12">
      <h1 className="text-3xl font-bold my-8 text-center">
        <span
          style={{
            background:
              "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Audio to Audio Chatbot Course Benefits
        </span>
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {benefitsData.map((benefit, index) => (
          <div
            key={index}
            className="bg-white shadow-md hover:scale-[1.05] rounded-lg p-6 transition-transform duration-300 hover:shadow-xl "
            
          >
            <div className="flex items-center mb-4">
              <div className="text-3xl text-color3 mr-4">{benefit.icon}</div>
              <h2 className="text-xl font-semibold">{benefit.title}</h2>
            </div>
            <p className="text-gray-700">{benefit.description}</p>
          </div>
        ))}
      </div>

      <div className="mt-8 text-center">
        <button className="px-8 py-3 bg-gradient-to-r from-yellow-400 to-red-500 text-white font-semibold rounded-lg hover:scale-105 transition-transform duration-300 shadow-lg">
          Start Now
        </button>
      </div>
    </div>
  );
};

export default AudioToAudioChatbotBenefits;
