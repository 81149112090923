import React, { useState } from "react";
import HeroImage from "./HeroImage.jpeg";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BrainIcon from "@mui/icons-material/Psychology";
import RobotIcon from "@mui/icons-material/SmartToy";
import EducationIcon from "@mui/icons-material/School";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import axios from "axios";
import {getAxiosBaseUrl} from "../../config";
import "./Hero.css";
import { useNavigate } from "react-router-dom";

const Hero = ({ scrollToEmailSubscribe, setEmailData }) => {
  const navigate = useNavigate();
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [isDemoModalOpen, setDemoModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });

  const handleEmailChange = (e) => setEmailData(e.target.value);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVideoModalToggle = () => setVideoModalOpen(!isVideoModalOpen);
  const handleDemoModalToggle = () => setDemoModalOpen(!isDemoModalOpen);

  const handleDemoSubmit = () => {
    if (formData.name && formData.email) {
        axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('/acehubdemorequest', {
			name: formData.name,
			email: formData.email,
            source: 'acehub.ai'
		})
		.then(response => {
              alert("Congratulations! 🎉 Your form has been submitted.");
              setDemoModalOpen(false);
              setFormData({ name: "", email: "" });
			}
		).catch(err => console.log(err));
    } else {
      alert("Please fill out all fields.");
    }
  };
  const handleCloseModal = () => {
    setVideoModalOpen(false); // Assuming this state controls modal visibility
    setDemoModalOpen(false); // Adjust based on which modal is open
  };

  return (
    <div className="flex items-center justify-center w-full relative min-h-[70vh] bg-gradient-to-tr from-[#212f4c] to-[#1f2b45] text-white shadow-lg overflow-hidden xl:flex-col md:flex-col">
      <div className="absolute inset-0 grid grid-cols-12 grid-rows-6 opacity-25">
        {Array.from({ length: 80 }).map((_, colIdx) => (
          <div
            key={colIdx}
            className="border-[1.5px] border-white  border-opacity-[0.15]"
          ></div>
        ))}
      </div>

      <div className="absolute top-10 left-5 w-60 h-60 bg-white opacity-10 rounded-full filter blur-2xl "></div>
      <div className="absolute top-2 right-10 w-64 h-64 bg-white opacity-20 rounded-full filter blur-3xl "></div>
      <div className="absolute top-40 left-[40vw] w-64 h-64 bg-white opacity-20 rounded-full filter blur-3xl "></div>

      {isVideoModalOpen && (
        <div onClick={handleCloseModal}  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 modal-overlay">
          <div onClick={(e) => e.stopPropagation()} className="relative w-[80%] max-w-5xl bg-gray-200 rounded-lg  flex shadow-2xl  modal-content">
            <button
              onClick={handleVideoModalToggle}
              className="absolute top-4 right-4 text-white text-2xl font-bold hover:text-red-500"
            >
              ✖
            </button>

            <div className="w-3/5 pr-6 p-8 flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/uew-IM2Ed7Y"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg shadow-lg"
              ></iframe>
            </div>

            <div className="w-2/5 flex flex-col justify-center text-white pl-6 bg-gradient-to-tr from-[#212f4c] to-[#1f2b45] border-2 ">
              <h3 className="text-3xl font-bold text-center mb-2 text-richYellow">
                Explore AceHub
              </h3>
              <ul className=" text-white">
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300 p-2 px-4">
                  <span className="text-2xl">📚</span>
                  <span>
                    Comprehensive{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      AI learning experiences
                    </span>
                  </span>
                </li>
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300  p-2 px-4">
                  <span className="text-2xl">🚀</span>
                  <span>
                    Hands-on real-world{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      AI/ML projects
                    </span>
                  </span>
                </li>
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300  p-2 px-4">
                  <span className="text-2xl">🤝</span>
                  <span>
                    Mentorship from{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      industry experts
                    </span>
                  </span>
                </li>
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300  p-2 px-4">
                  <span className="text-2xl">💼</span>
                  <span>
                    Career-oriented programs with{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      job-ready skills
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isDemoModalOpen && (
        
            <div  onClick={handleCloseModal} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 modal-overlay">
              <div onClick={(e) => e.stopPropagation()} className=" modal-content relative w-[80%] max-w-md bg-gradient-to-r from-gray-100 to-gray-300 rounded-lg p-8 shadow-2xl">
                <button
                  onClick={handleDemoModalToggle}
                  className="absolute top-4 right-4 text-gray-800 text-2xl font-bold hover:text-red-500"
                >
                  ✖
                </button>

                <h2 className="text-3xl font-bold text-richYellow mb-6 text-center">
                  Add Your Details
                </h2>

                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="space-y-4"
                >
                  <label className="block text-gray-700 text-lg">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                      className="w-full mt-2 p-2 px-4 border border-gray-300 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-purple-100"
                      required
                    />
                  </label>

                  <label className="block text-gray-700 text-lg">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      className="w-full mt-2 p-2 px-4 border border-gray-300 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-purple-100"
                      required
                    />
                  </label>

                  <button
                    onClick={handleDemoSubmit}
                    className="w-full py-3 bg-gradient-to-r from-yellow-500 to-orange-500 text-white font-semibold text-lg rounded-lg shadow-md hover:bg-yellow-600 hover:scale-105 transition-all duration-300"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
        
      )}

      <div className="relative z-10 w-screen lg:w-[88vw] flex flex-col md:flex-row justify-between items-center px-10 py-6 md:py-10 md:w-[100vw]">
        <div className="text-center md:text-left md:w-7/12 p-6">
          <h1 className="text-3xl font-bold mb-4 text-left">
            Upskill in AI with industry level projects — not just watch videos but write code in GPUs.
          </h1>
          <p
              style={{
                background:
                    "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              className="text-lg mb-8 text-left"
          >
            A true work experience style learning environment. (Limited seats: only 5 / 27 left)
            <ul style={{listStyleType: "none", paddingLeft: 0}}>
              <li>4 Tracks (Choose one):</li>
              <ul style={{listStyleType: "none", paddingLeft: "20px"}}>
                <li>1. 1st to 3rd Year</li>
                <li>2. Final Year</li>
                <li>3. Working Professional</li>
                <li>4. Industry Partners</li>
              </ul>
            </ul>
          </p>
          <ul className="list-none mb-8 text-base text-left space-y-2 text-gray-400">
            <li className="hover:text-gray-200">
              {"_"} <strong>Weekly</strong> demos, project tracks, group discussions.
            </li>
            <li className="hover:text-gray-200">
              {"_"} <strong>Learn</strong> at your pace, weekly modules with low to high difficulty progression.
            </li>
            <li className="hover:text-gray-200">
              {"_"} <strong>Expert </strong> mentorship and valuable insights from industry leaders.
            </li>
            <li className="hover:text-gray-200">
              {"_"} <strong>Live AI/ML</strong> projects that mirror the demands of top tech companies.
            </li>
            {/*<li className="hover:text-gray-200">*/}
            {/*  {"_"} <strong>Build</strong> a portfolio that showcases your ability to solve real-world problems with AI.*/}
            {/*</li>*/}
          </ul>
          <div className="flex justify-center flex-col xl:flex-row gap-2 items-center md:justify-start space-x-4 relative w-full">
            <div className="relative flex items-center ">
              {/* <input
                type="text"
                placeholder="Enter your mail..."
                onChange={handleEmailChange} // Event handler for input changes
                className="px-4 py-4 w-full bg-white/30 backdrop-blur-md border border-gray-300 text-black rounded-full shadow-md placeholder-white focus:outline-none text-gray-200"
              /> */}

              <button
                // onClick={scrollToEmailSubscribe}
                onClick={handleDemoModalToggle}
                className=" bg-yellow-400 text-black p-2 xl:pl-6 rounded-full hover:bg-yellow-300 flex items-center xl:space-x-2 jiggle-button"
              >
                {/*<span className="font-bold text-lg xl:inline-block hidden">*/}
                <span className="flex items-center justify-center font-bold text-lg xl:inline-block px-3 py-1 pr-5">
                  Join Wait List
                </span>
                {/*<span className="flex items-center justify-center text-lg bg-gray-700 text-white font-bolder rounded-full px-3 py-1">*/}
                {/*  Join Wait List*/}
                {/*</span>*/}
              </button>
            </div>
            <a
              onClick={()=>navigate(`/projects`)}
              className="px-6 py-4  xl:mx-0 mx-auto rounded-full text-white font-medium hover:scale-105 bg-gradient-to-r from-blue-500 to-purple-500 transition-transform duration-500 shadow-lg  my-auto hover:scale-[1.1]  w-[160px] cursor-pointer"
            >
              Show Projects
            </a>
          </div>

          <div className="mt-6 grid grid-cols-1 md:grid-cols-5 text-center md:text-left text-gray-300 p-4">
            <div className="bg-gray-100 bg-opacity-[0.1] p-4 rounded-tl-lg rounded-bl-lg border-gray-500 border-r-[2px] flex-col items-center">
              <p className="text-3xl font-bold">70+ </p>
              <p className="text-sm">real-world projects in AI/ML domains.</p>
            </div>
            <div className="bg-gray-100 bg-opacity-[0.1] p-4 border-gray-500 border-r-[2px]">
              <p className="text-3xl font-bold">100+</p>
              <p className="text-sm">movies equivalent learning content.</p>
            </div>
            <div className="bg-gray-100 bg-opacity-[0.1] p-4 border-gray-500 border-r-[2px]">
              <p className="text-3xl font-bold">90% </p>
              <p className="text-sm">
                students improved their AI job readiness.
              </p>
            </div>
            <div className="bg-gray-100 bg-opacity-[0.1] p-4 border-gray-500 border-r-[2px]">
              <p className="text-3xl font-bold">10+ </p>
              <p className="text-sm">industry mentors.</p>
            </div>
            <div className="bg-gray-100 bg-opacity-[0.1] p-4 rounded-tr-lg rounded-br-lg">
              <p className="text-3xl font-bold">200+ </p>
              <p className="text-sm">Blogs for Upskilling.</p>
            </div>
          </div>
        </div>

        <div className="lg:w-1/2 md:w-3/4 sm:w-full mt-10 lg:mt-0 md:mt-5 sm:mt-3 p-8 h-[80vh] md:h-[70vh] sm:h-[60vh]">
          <div className="relative mx-auto shadow-inner-lg h-full">
            <img
                src={HeroImage}
                alt="Person"
                className="transform hover:scale-105 transition duration-300 w-full h-full object-cover rounded-[10rem] rounded-tr-[14rem] md:rounded-[5rem] sm:rounded-[2rem]"
            />
            <div
                onClick={handleVideoModalToggle}
                className="absolute top-[-20px] lg:right-10 md:right-6 sm:right-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg xl:px-10 md:px-6 px-4 xl:py-4 md:py-3 py-2 flex items-center space-x-2 sm:space-x-1 text-lg md:text-base sm:text-sm shadow-lg hover:scale-105 transition-transform duration-500 cursor-pointer"
            >
              <span className="mr-2">Easy Project</span>
              <button
                  onClick={handleVideoModalToggle}
                  className="flex items-center space-x-2 bg-transparent border-2 border-white px-4 py-2 rounded-full text-white shadow-lg hover:bg-white hover:text-blue-500 transition-colors duration-300"
              >
                {/*<span>Play</span>*/}
                <PlayArrowIcon />
                {/*<span className="text-2xl">🎥</span>*/}
              </button>
            </div>
            
            {/*<div className="absolute bottom-10 left-[-20px] lg:left-[-30px] md:left-[-15px] sm:left-[-10px] bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-xl p-4 md:p-6 sm:p-3 shadow-lg font-medium hover:scale-105 transition-transform duration-300 text-sm md:text-base">*/}
            {/*  <h2 className="text-lg lg:text-xl font-bold mb-2 md:mb-4">*/}
            {/*    Total Users 👥*/}
            {/*  </h2>*/}
            {/*  <p className="text-gray-100 mb-2 text-xs md:text-sm">*/}
            {/*    The Users leveraging our AI assets*/}
            {/*  </p>*/}
            {/*  <div className="flex justify-between items-center">*/}
            {/*    <div>*/}
            {/*      <p className="text-xs md:text-sm">This Week</p>*/}
            {/*      <h3 className="text-xl md:text-2xl font-bold">20+</h3>*/}
            {/*      <p className="text-green-400 text-xs">+16.66% than last</p>*/}
            {/*    </div>*/}
            {/*    <div className="ml-4 md:ml-8">*/}
            {/*      <p className="text-xs md:text-sm">This Month 📅</p>*/}
            {/*      <h3 className="text-xl md:text-2xl font-bold">400+</h3>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            
            {/* Icons */}
            {/*<div*/}
            {/*    className="rotate-animation bg-gradient-to-r from-purple-500 to-pink-500 absolute top-[25vh] right-[-10px] md:right-[-15px] transform hover:scale-110 transition duration-300 p-2 sm:p-3 rounded-full">*/}
            {/*  <span className="text-white">*/}
            {/*    <EducationIcon style={{fontSize: 25}}/>*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*    className="rotate-animation bg-gradient-to-r from-purple-500 to-pink-500 absolute md:top-[20px] top-[60px] sm:top-[50px] left-[-5px] md:left-[-10px] transform hover:scale-110 transition duration-300 p-2 sm:p-3 rounded-full">*/}
            {/*  <span className="text-white">*/}
            {/*    <RobotIcon style={{fontSize: 25}}/>*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<div className="rotate-animation bg-gradient-to-r from-purple-500 to-pink-500 absolute bottom-[10px] md:bottom-[15px] right-0 transform hover:scale-110 transition duration-300 p-2 sm:p-3 rounded-full">*/}
            {/*  <span className="text-white">*/}
            {/*    <LightbulbIcon style={{ fontSize: 25 }} />*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
