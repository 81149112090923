export const audioGenerationModels = [
  { "title": "WaveNet", "year": "2016" },
  { "title": "Tacotron", "year": "2017" },
  { "title": "Deep Voice", "year": "2017" },
  { "title": "VoiceLoop", "year": "2017" },
  { "title": "Tacotron 2", "year": "2018" },
  { "title": "Deep Voice 2", "year": "2018" },
  { "title": "Parallel WaveGAN", "year": "2019" },
  { "title": "MelGAN", "year": "2019" },
  { "title": "FastSpeech", "year": "2019" },
  { "title": "FastSpeech 2", "year": "2020" },
  { "title": "Jukebox", "year": "2020" },
  { "title": "VALL-E", "year": "2022" },
  { "title": "MusaNet", "year": "2022" }
];


export const videoGenerationModels = [
  { "title": "Video Pixel Networks", "year": "2016" },
  { "title": "VGAN (Video GAN)", "year": "2016" },
  { "title": "MoCoGAN", "year": "2018" },
  { "title": "TGAN (Temporal GAN)", "year": "2018" },
  { "title": "SV2P (Stochastic Video Prediction)", "year": "2018" },
  { "title": "VID2VID", "year": "2018" },
  { "title": "DVP (Deep Video Prior)", "year": "2019" },
  { "title": "VideoGPT", "year": "2021" },
  { "title": "DreamFusion", "year": "2022" },
  { "title": "Imagen Video", "year": "2022" },
  { "title": "Make-A-Video", "year": "2022" },
  { "title": "Phenaki", "year": "2022" },
  { "title": "Latent Diffusion Models for Video", "year": "2023" }
];


export const cnnArchitectures = [
      { "title": "LeNet-5", "year": "1998" },
      { "title": "AlexNet", "year": "2012" },
      { "title": "ZFNet", "year": "2013" },
      { "title": "VGGNet", "year": "2014" },
      { "title": "GoogLeNet/Inception", "year": "2014" },
      { "title": "ResNet", "year": "2015" },
      { "title": "Inception-v3", "year": "2015" },
      { "title": "ResNeXt", "year": "2017" },
      { "title": "MobileNet", "year": "2017" },
      { "title": "DenseNet", "year": "2017" },
      { "title": "NASNet", "year": "2018" },
      { "title": "EfficientNet", "year": "2019" },
      { "title": "RegNet", "year": "2020" }
    ]
;
  
export   const seqToSeq = [
      { "title": "Sutskever et al. Seq2Seq", "year": "2014" },
      { "title": "Cho et al. (GRU-based Seq2Seq)", "year": "2014" },
      { "title": "Attention Mechanism", "year": "2015" },
      { "title": "Luong Attention", "year": "2015" },
      { "title": "Bahdanau Attention", "year": "2015" },
      { "title": "Transformer", "year": "2017" },
      { "title": "BERT", "year": "2018" },
      { "title": "GPT", "year": "2018" },
      { "title": "T5 (Text-To-Text Transfer Transformer)", "year": "2019" },
      { "title": "BART", "year": "2019" },
      { "title": "GPT-2", "year": "2019" },
      { "title": "GPT-3", "year": "2020" },
      { "title": "mT5 (Multilingual T5)", "year": "2020" },
      { "title": "GPT-3.5", "year": "2022" },    // Incremental improvement with better fine-tuning
      { "title": "ChatGPT", "year": "2022" },   // Application of GPT-3.5 in a conversational AI
      { "title": "Switch Transformer", "year": "2021" }, // Efficient scaling of Transformers with Mixture of Experts
      { "title": "DALL-E", "year": "2021" },   // Multi-modal text-to-image generation
      { "title": "Gato", "year": "2022" },     // Generalist agent for multi-task learning
      { "title": "PaLM (Pathways Language Model)", "year": "2022" } // Large-scale, general-purpose model
    ];
  
export   const reinforcementLearningModels = [
  { "title": "Q-Learning", "year": "1989" },
  { "title": "Deep Q-Network (DQN)", "year": "2013" },
  { "title": "Trust Region Policy Optimization (TRPO)", "year": "2015" },
  { "title": "Asynchronous Advantage Actor-Critic (A3C)", "year": "2016" },
  { "title": "Proximal Policy Optimization (PPO)", "year": "2017" },
  { "title": "Soft Actor-Critic (SAC)", "year": "2018" },
  { "title": "AlphaGo", "year": "2016" },
  { "title": "AlphaZero", "year": "2017" },
  { "title": "MuZero", "year": "2019" }
];
  
export   const generativeModels = [
  { "title": "Restricted Boltzmann Machines (RBM)", "year": "1986" },
  { "title": "Variational Autoencoders (VAE)", "year": "2013" },
  { "title": "Generative Adversarial Networks (GANs)", "year": "2014" },
  { "title": "DCGAN", "year": "2015" },
  { "title": "StyleGAN", "year": "2018" },
  { "title": "BigGAN", "year": "2018" },
  { "title": "VQ-VAE-2", "year": "2019" },
  { "title": "StyleGAN2", "year": "2020" },
  { "title": "DALL-E", "year": "2021" },  // Text-to-image generation
  { "title": "Stable Diffusion", "year": "2022" }, // Text-to-image generation with diffusion models
  { "title": "Imagen", "year": "2022" } // Google's text-to-image diffusion model
];

  
export   const unsupervisedLearningModels = [
  { "title": "K-Means Clustering", "year": "1967" },
  { "title": "Hierarchical Clustering", "year": "1973" },
  { "title": "Principal Component Analysis (PCA)", "year": "1901" },
  { "title": "Independent Component Analysis (ICA)", "year": "1995" },
  { "title": "t-SNE", "year": "2008" },
  { "title": "Autoencoders", "year": "1986" },
  { "title": "Deep Autoencoders", "year": "2006" },
  { "title": "Self-Organizing Maps (SOM)", "year": "1982" },
  { "title": "Generative Adversarial Networks (GANs)", "year": "2014" }, // Dual-purpose
  { "title": "Contrastive Predictive Coding (CPC)", "year": "2018" }
];
export const transferLearningModels = [
  { "title": "ImageNet Pretrained CNNs", "year": "2012" },
  { "title": "AlexNet Transfer Learning", "year": "2012" },
  { "title": "VGGNet Transfer Learning", "year": "2014" },
  { "title": "ResNet Transfer Learning", "year": "2015" },
  { "title": "BERT Transfer Learning", "year": "2018" },
  { "title": "GPT Transfer Learning", "year": "2018" },
  { "title": "T5 Transfer Learning", "year": "2019" },
  { "title": "EfficientNet Transfer Learning", "year": "2019" },
  { "title": "XLM-R (Cross-Lingual Model)", "year": "2019" },
  { "title": "CLIP (Contrastive Language-Image Pretraining)", "year": "2021" }
];


export const explainableAIModels = [
  { "title": "LIME (Local Interpretable Model-agnostic Explanations)", "year": "2016" },
  { "title": "SHAP (SHapley Additive exPlanations)", "year": "2017" },
  { "title": "InterpretML", "year": "2018" },
  { "title": "TCAV (Testing with Concept Activation Vectors)", "year": "2018" },
  { "title": "Explainable Boosting Machine (EBM)", "year": "2019" },
  { "title": "Integrated Gradients", "year": "2017" },
  { "title": "Anchors: High-Precision Model-Agnostic Explanations", "year": "2018" },
  { "title": "Model Agnostic Meta-Interpretability", "year": "2020" }
];

export const multimodalModels = [
  { "title": "Visual Question Answering (VQA)", "year": "2015" },
  { "title": "LXMERT", "year": "2019" },
  { "title": "VisualBERT", "year": "2019" },
  { "title": "UNITER", "year": "2019" },
  { "title": "ViLBERT", "year": "2019" },
  { "title": "CLIP (Contrastive Language-Image Pretraining)", "year": "2021" },
  { "title": "DALL-E", "year": "2021" },
  { "title": "ALIGN", "year": "2021" },
  { "title": "Florence", "year": "2022" },
  { "title": "Gato", "year": "2022" },
  { "title": "Flamingo", "year": "2022" }
];