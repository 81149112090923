export var NavButtons = [
    {
        name: "Home",
        url: "/",
    },
    // {
    //     name: "Flows", url: "/flows"
    // },
    // {
    //     name: "Blogs", url: "/blog"
    // },
    // {
    //     name: "Pricing", url: "/pricing"
    // },
    // {
    //     name: "About", url: "/about"
    // },
    {
        name: "Login",
        url: "/",
    },
    {
        name: "Signup",
        url: "/",
    },
];
export var Courses = [
    {
        id: "python_course",
        title: "Python 3",
        description: "Basic to advanced python course with code emphasis, ai libraries and practical applications.",
        level: "Easy",
        image: "https://images.pexels.com/photos/255377/pexels-photo-255377.jpeg?auto=compress&cs=tinysrgb&w=800",
        // image: bg12,
        stats: { sections: 7, chapters: 23, projects: 4 },
        hours: 10,
        progress: 72,
        category: "Text Generation",
        // background:"linear-gradient(rgb(84 154 137) 0%, rgb(84 116 109) 100%)"
        background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))"
        // background:"linear-gradient(180deg, #4CAF97 0%, #51C6AB 100%)"
    },
    {
        id: "stats_and_maths",
        title: "Statistics & Maths",
        description: "Essential Stats and Math for AI & ML programming and projects development. Basic to Advanced.",
        level: "Intermediate",
        image: "https://images.pexels.com/photos/960137/pexels-photo-960137.jpeg?auto=compress&cs=tinysrgb&w=800",
        stats: { sections: 6, chapters: 30, projects: 5 },
        hours: 10,
        progress: 20,
        category: "Machine Learning",
        background: "linear-gradient(283deg, rgb(111 21 96) 0%, rgb(45 142 111) 100%) center center / cover no-repeat"
    },
    {
        id: "machine_learning",
        title: "Machine Learning",
        description: "Slow difficulty rampup on Machine Learning algorithms, data processing, coding and programming techniques.",
        level: "Intermediate",
        image: "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&w=800",
        stats: { sections: 6, chapters: 34, projects: 5, certifications: 1 },
        hours: 10,
        progress: 53,
        category: "Text Generation",
        background: "radial-gradient(circle at left top, rgb(33, 37, 22), rgb(179 149 142)) center center / cover no-repeat"
    },
    {
        id: "artificial_intelligence",
        title: "Artificial Intelligence",
        description: "Categorical coverage in areas of AI like NLP, Computer Vision, Reinforcement Learning, Ethical Ai, Autonomous Systems.",
        level: "Intermediate",
        // image: "https://images.pexels.com/photos/1509534/pexels-photo-1509534.jpeg?auto=compress&cs=tinysrgb&w=800",
        image: "https://del1.vultrobjects.com/blogs-images-and-videos/KQGxV9Sk/KQGxV9Sk_4bbb22559a894b98909ad60e1d9db8a2_lp_background_1.jpg",
        stats: { sections: 5, chapters: 31, projects: 0 },
        hours: 10,
        progress: 1,
        category: "Text Generation",
        background: "radial-gradient(circle at right top, rgb(130 81 115), rgb(21 12 16)) center center / cover no-repeat"
    },
    {
        id: "pytorch_for_ai",
        title: "PyTorch",
        description: "PyTorch with an emphasis on comprehensive coverage of functionalities, tutorials, and deployment.",
        level: "Easy",
        image: "https://images.pexels.com/photos/4585185/pexels-photo-4585185.jpeg?auto=compress&cs=tinysrgb&w=800",
        stats: { sections: 1, chapters: 20, projects: 5 },
        hours: 10,
        progress: 1,
        category: "Text Generation",
        background: "radial-gradient(circle at right top, rgb(92 165 96), rgb(167 107 0)) center center / cover no-repeat"
        // background: "radial-gradient(circle at left top, #be67b6 , #280885)"
    },
    {
        id: "ml_system_design",
        title: "ML System Design",
        description: "Coming Soon",
        level: "Intermediate",
        image: "https://images.pexels.com/photos/262713/pexels-photo-262713.jpeg?auto=compress&cs=tinysrgb&w=800",
        stats: { sections: 0, chapters: 0, projects: 0 },
        hours: 10,
        progress: 1,
        category: "Machine Learning",
        background: "radial-gradient(circle at right top, #7302c7 , #3af3ec)"
    },
    {
        id: "computer_vision_extensive",
        title: "Computer Vision Evolution & Advancement",
        description: "Coming Soon",
        level: "Intermediate",
        image: "https://images.pexels.com/photos/129731/pexels-photo-129731.jpeg?auto=compress&cs=tinysrgb&w=800",
        stats: { sections: 0, chapters: 0, projects: 0 },
        hours: 10,
        progress: 1,
        category: "Image Generation",
        background: "linear-gradient(135deg, #2166cf 0%, #a98c82 100%)"
    },
    {
        id: "1239",
        title: "LLM Models Fine tuning & Applications",
        description: "Coming Soon",
        level: "Advanced",
        image: "https://images.pexels.com/photos/2775840/pexels-photo-2775840.jpeg?auto=compress&cs=tinysrgb&w=800",
        stats: { sections: 0, chapters: 0, projects: 0 },
        hours: 10,
        progress: 1,
        category: "Machine Learning",
    },
    // {
    //   id: "1242",
    //   title: "Course 9",
    //   description: "This is a course description",
    //   level: "Advanced",
    //   image: "https://images.pexels.com/photos/262713/pexels-photo-262713.jpeg?auto=compress&cs=tinysrgb&w=800",
    //   stats: { sections: 0, chapters: 10, projects: 5 },
    //   hours: 10,
    //   progress: 1,
    //   category: "Text Generation",
    // },
    // {
    //   id: "1243",
    //   title: "Course 10",
    //   description: "This is a course description",
    //   level: "Easy",
    //   image: "https://images.pexels.com/photos/949587/pexels-photo-949587.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //   stats: { sections: 0, chapters: 10, projects: 5 },
    //   hours: 10,
    //   progress: 1,
    //   category: "Text Generation",
    // },
    // {
    //   id: "1244",
    //   title: "Course 11",
    //   description: "This is a course description",
    //   level: "Intermediate",
    //   image: "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&w=800",
    //   stats: { sections: 0, chapters: 10, projects: 5 },
    //   hours: 10,
    //   progress: 1,
    //   category: "Machine Learning",
    // },
    // {
    //   id: "1245",
    //   title: "Course 12",
    //   description: "This is a course description",
    //   level: "Advanced",
    //   image: "https://images.pexels.com/photos/333634/pexels-photo-333634.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //   stats: { sections: 0, chapters: 10, projects: 5 },
    //   hours: 10,
    //   progress: 1,
    //   category: "Text Generation",
    // },
    //   {
    //       id: "1246",
    //       title: "Course 13",
    //       description: "This is a course description",
    //       level: "Easy",
    //       image: "https://images.pexels.com/photos/1070536/pexels-photo-1070536.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Text Generation",
    //   },
    //   {
    //       id: "1247",
    //       title: "Course 14",
    //       description: "This is a course description",
    //       level: "Intermediate",
    //       image: "https://images.pexels.com/photos/6063469/pexels-photo-6063469.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Machine Learning",
    //   },
    //   {
    //       id: "1248",
    //       title: "Course 15",
    //       description: "This is a course description",
    //       level: "Advanced",
    //       image: "https://images.pexels.com/photos/23510864/pexels-photo-23510864/free-photo-of-deep-blue.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Text Generation",
    //   },
    //   {
    //       id: "1249",
    //       title: "Course 16",
    //       description: "This is a course description",
    //       level: "Easy",
    //       image: "https://images.pexels.com/photos/2414036/pexels-photo-2414036.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Text Generation",
    //   },
    //   {
    //       id: "1250",
    //       title: "Course 17",
    //       description: "This is a course description",
    //       level: "Intermediate",
    //       image: "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Machine Learning",
    //   },
    //   {
    //       id: "1251",
    //       title: "Course 18",
    //       description: "This is a course description",
    //       level: "Advanced",
    //       image: "https://images.pexels.com/photos/963278/pexels-photo-963278.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Text Generation",
    //   },
    //   {
    //       id: "1252",
    //       title: "Course 19",
    //       description: "This is a course description",
    //       level: "Easy",
    //       image: "https://images.pexels.com/photos/9454915/pexels-photo-9454915.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Text Generation",
    //   },
    //   {
    //       id: "1253",
    //       title: "Course 20",
    //       description: "This is a course description",
    //       level: "Intermediate",
    //       image: "https://images.pexels.com/photos/1671643/pexels-photo-1671643.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Machine Learning",
    //   },
    //   {
    //       id: "1254",
    //       title: "Course 21",
    //       description: "This is a course description",
    //       level: "Advanced",
    //       image: "https://images.pexels.com/photos/3427774/pexels-photo-3427774.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Text Generation",
    //   },
    //   {
    //       id: "1255",
    //       title: "Course 22",
    //       description: "This is a course description",
    //       level: "Easy",
    //       image: "https://images.pexels.com/photos/354939/pexels-photo-354939.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5 },
    //       hours: 10,
    //       progress: 1,
    //       category: "Text Generation",
    //   },
    //   {
    //       id: "1256",
    //       title: "Course 23",
    //       description: "This is a course description",
    //       level: "Intermediate",
    //       image: "https://images.pexels.com/photos/1458377/pexels-photo-1458377.jpeg?auto=compress&cs=tinysrgb&w=800",
    //       stats: { sections: 0, chapters: 10, projects: 5, certifications: 1},
    //       hours: 10,
    //       progress: 1,
    //       category: "Machine Learning"
    //   },
];
export var Projects = [
    {
        id: "Speech_to_speech_Ai_chatbot",
        title: "Audio To Audio Assistant (Generative Ai)",
        description: "Deep dive into the capabilities of AI with our audio-to-audio project, enabling real-time dialogue understanding, answering questions with NLP (natural language processing) and audio generation.",
        level: "Medium",
        stats: { sections: 4, chapters: 11, projects: 2 },
        hours: 10,
        progress: 72,
        ratings: 223,
        rating: 4.1,
        category: "Speech Generation",
        background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))",
        image: "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&w=800",
        modules: [
            {
                title: "Module 1: Project Overview, Setups and Running Demo",
                chapters: [
                    {
                        "title": "Introduction: Project Overview",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Setups: Setting up Environment, Dependencies and Git Repo",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00"
                    },
                    {
                        "title": "Demo Run: Play in Browser",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:15:00",
                        locked: true
                    },
                    {
                        "title": "Testing and Tweaking",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:20:00",
                        locked: true
                    }
                ]
            },
            {
                title: "Module 2: Audio To Text Models Deep Dive",
                chapters: [
                    {
                        "title": "Architecture: Audio to Text Model (Whisper Architecture)",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Architecture: Whisper usage code",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00"
                    },
                    {
                        "title": "Architecture: Write Audio to Text Model from Scratch",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:15:00",
                        locked: true
                    }
                ]
            },
            {
                title: "Module 3: Text to Text Model (T5) Deep Dive",
                chapters: [
                    {
                        "title": "Setting Up Deployment Pipelines",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Monitoring TinyGPT in the Wild",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00",
                        locked: true
                    }
                ]
            }
        ]
    },
    {
        id: "progen_project",
        title: "Image Generation, Professional Photo Generation (Gen AI)",
        description: "Learn GAN models, Diffusion models, generate marketing images, generate professional photos and many more tricks and working generative ai code and architecture details.",
        level: "Medium",
        stats: { sections: 2, chapters: 3, projects: 4 },
        hours: 10,
        progress: 72,
        ratings: 184,
        rating: 4.2,
        category: "Image Generation",
        background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))",
        image: "https://images.pexels.com/photos/129731/pexels-photo-129731.jpeg?auto=compress&cs=tinysrgb&w=800",
        modules: [
            {
                title: "Module 1: Project Overview, Setups and Running Demo",
                chapters: [
                    {
                        "title": "Introduction: (HINDI) Image Generation - GANs To Diffusion Architecture Series - Part1",
                        "video_url": "https://www.youtube.com/watch?v=5GdUqMm-tu8",
                        "video_len": "00:03:48"
                    },
                    {
                        "title": "Setups: Setting up Environment, Dependencies and Git Repo",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00"
                    },
                    {
                        "title": "Demo Run: Play in Browser",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:15:00",
                        locked: true
                    },
                    {
                        "title": "Testing and Tweaking",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:20:00",
                        locked: true
                    }
                ]
            },
            {
                title: "Module 2: Audio To Text Models Deep Dive",
                chapters: [
                    {
                        "title": "Architecture: Audio to Text Model (Whisper Architecture)",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Architecture: Whisper usage code",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00"
                    },
                    {
                        "title": "Architecture: Write Audio to Text Model from Scratch",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:15:00",
                        locked: true
                    }
                ]
            },
            {
                title: "Module 3: Text to Text Model (T5) Deep Dive",
                chapters: [
                    {
                        "title": "Setting Up Deployment Pipelines",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Monitoring TinyGPT in the Wild",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00",
                        locked: true
                    }
                ]
            }
        ]
    },
    {
        id: "markdow_to_audio",
        title: "Github Markdown to Audio Generation (Gen AI)",
        description: "Learn text processing, custom data preparation challenges, generate data with claude, chatgpt etc. " +
            "Train T5 model to adapt text with mathematical formulas, image urls, custom html tags and " +
            "other unstructured difficult to understand data type.",
        level: "Medium",
        stats: { sections: 2, chapters: 3, projects: 4 },
        hours: 10,
        ratings: 91,
        rating: 4.6,
        category: "Audio Generation",
        background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))",
        image: "https://images.pexels.com/photos/4585185/pexels-photo-4585185.jpeg?auto=compress&cs=tinysrgb&w=800",
        modules: [
            {
                title: "Module 1: Project Overview, Setups and Running Demo",
                chapters: [
                    {
                        "title": "Introduction: Project Overview",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Setups: Setting up Environment, Dependencies and Git Repo",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00"
                    },
                    {
                        "title": "Demo Run: Play in Browser",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:15:00",
                        locked: true
                    },
                    {
                        "title": "Testing and Tweaking",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:20:00",
                        locked: true
                    }
                ]
            },
            {
                title: "Module 2: Audio To Text Models Deep Dive",
                chapters: [
                    {
                        "title": "Architecture: Audio to Text Model (Whisper Architecture)",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Architecture: Whisper usage code",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00"
                    },
                    {
                        "title": "Architecture: Write Audio to Text Model from Scratch",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:15:00",
                        locked: true
                    }
                ]
            },
            {
                title: "Module 3: Text to Text Model (T5) Deep Dive",
                chapters: [
                    {
                        "title": "Setting Up Deployment Pipelines",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:02:21"
                    },
                    {
                        "title": "Monitoring TinyGPT in the Wild",
                        "video_url": "https://www.youtube.com/watch?v=uew-IM2Ed7Y",
                        "video_len": "00:10:00",
                        locked: true
                    }
                ]
            }
        ]
    },
    // {
    //   id: "markdown_to_audio",
    //   title: "Markdown text to audio",
    //   description: "Convert any github .md / markdown file into a speakable audio. Take care of mathematical formulas, images, headings etc. variations.",
    //   level: "Easy",
    //   stats: { sections: 2, chapters: 3, projects: 4 },
    //   hours: 10,
    //   progress: 72,
    //   category: "Text Generation",
    //   background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))",
    //   image:"https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&w=800"
    // },
    //   {
    //   id: "markdown_to_audio",
    //   title: "Markdown text to audio",
    //   description: "Convert any github .md / markdown file into a speakable audio. Take care of mathematical formulas, images, headings etc. variations.",
    //   level: "Easy",
    //   stats: { sections: 2, chapters: 3, projects: 4 },
    //   hours: 10,
    //   progress: 72,
    //   category: "Text Generation",
    //   background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))",
    //   image:"https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&w=800"
    // },
    //   {
    //   id: "markdown_to_audio",
    //   title: "Markdown text to audio",
    //   description: "Convert any github .md / markdown file into a speakable audio. Take care of mathematical formulas, images, headings etc. variations.",
    //   level: "Easy",
    //   stats: { sections: 2, chapters: 3, projects: 4 },
    //   hours: 10,
    //   progress: 72,
    //   category: "Text Generation",
    //   background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))",
    //   image:"https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&w=800"
    // },
    //   {
    //   id: "markdown_to_audio",
    //   title: "Markdown text to audio",
    //   description: "Convert any github .md / markdown file into a speakable audio. Take care of mathematical formulas, images, headings etc. variations.",
    //   level: "Easy",
    //   stats: { sections: 2, chapters: 3, projects: 4 },
    //   hours: 10,
    //   progress: 72,
    //   category: "Text Generation",
    //   background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))",
    //   image:"https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&w=800"
    // }
    //   {
    //   id: "python_course",
    //   title: "Python 3",
    //   description: "Basic to advanced python course with code emphasis, ai libraries and practical applications.",
    //   level: "Easy",
    //   stats: { sections: 7, chapters: 23, projects: 4 },
    //   hours: 10,
    //   progress: 72,
    //   category: "Image Generation",
    //   background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))"
    // },
    //   {
    //   id: "python_course",
    //   title: "Python 3",
    //   description: "Basic to advanced python course with code emphasis, ai libraries and practical applications.",
    //   level: "Easy",
    //   stats: { sections: 7, chapters: 23, projects: 4 },
    //   hours: 10,
    //   progress: 72,
    //   category: "Audio Generation",
    //   background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))"
    // },
    //   {
    //   id: "python_course",
    //   title: "Python 3",
    //   description: "Basic to advanced python course with code emphasis, ai libraries and practical applications.",
    //   level: "Easy",
    //   stats: { sections: 7, chapters: 23, projects: 4 },
    //   hours: 10,
    //   progress: 72,
    //   category: "Video Generation",
    //   background: "radial-gradient(circle at left top, rgb(107 149 104), rgb(41 75 176))"
    // }
];
export var HeroTexts = {
    firstText: "YOUR JOB APPLICATION ASSISTANT",
    secondText: "20x Your Interviews",
    thirdText: "Tired of tedius job search and application process! Preserve your energy and focus on interviews instead. We at ApplyJobs.ai find you the most relevant jobs to your resume and apply on your behalf. We can apply 300 or more jobs per month on your behalf so that you remain focused on interviews and make your next career move.",
    firstButton: "START FOR FREE",
};
export var ServiceTexts = {
    firstText: "CATEGORY",
    secondText: "Why choose us?",
    cards: [
        {
            firstText: "Rich jobs information",
            secondText: "Choose from our jobs pool, filter by location, remote, salary and many more.",
        },
        {
            firstText: "Matching done with AI",
            secondText: "You don't have to spend hours finding the right job, company, location, level and end up losing focus on the interview. Let the AI find top 20 jobs every day.",
        },
        {
            firstText: "We apply what you choose",
            secondText: "You are in control of where you applications are submitted.",
        },
        {
            firstText: "No Commitments",
            secondText: "You can choose our application service or just leverage our matching benefits at no commitments.",
        },
    ],
};
export var ReviewTexts = {
    firstText: "Our Customers Reviews",
    cards: [
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            rating: "4",
        },
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            rating: "4",
        },
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            rating: "4",
        },
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            rating: "4",
        },
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            rating: "4",
        },
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            rating: "4",
        },
    ],
};
export var TopBlogsTexts = {
    secondText: "Top Blogs",
    cards: [
        {
            country: "Rome, Italy",
            price: "5.42k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        },
        {
            country: "London, UK",
            price: "4.2k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        },
        {
            country: "Paris, France",
            price: "15k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        },
        {
            country: "New York, USA",
            price: "4.5k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        },
        {
            country: "Warsaw, Poland",
            price: "3.9k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        },
        {
            country: "Dubai, UAE",
            price: "9.2k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        },
    ],
};
export var OnboardingTexts = {
    firstText: "MailChamp Onboarding Flow",
    listOfSteps: [
        {
            text: "Signup with your Email",
        },
        {
            text: "You choose template from our pool",
        },
        {
            text: "You bring your contacts and add in our system",
        },
        {
            text: "We help you launch a mini campaign",
        },
        {
            text: "Review dashboard and stats",
        },
        {
            text: "We work with you on finding the right content",
        },
        {
            text: "We launch bigger campaign for you",
        },
    ],
};
export var BookDemoTexts = {
    firstText: "Book a demo with our Specialists",
    listOfSteps: [
        {
            text: "Get a tour of the platform catered to your needs.",
        },
        {
            text: "See real examples of successfull automation and Support",
        },
        {
            text: "Discover effective tactics to boost email marketing",
        },
        {
            text: "Understand which pricing plan is best for you",
        },
    ],
};
export var TemplatesTexts = {
    firstText: "Choose a Template",
    description: "Browse through collections of our catalogue or request us for your needs. Click to edit the template you want to send to your audience. Drag and drop the required modifications and preview the desktop version, mobiile version and send test email to verify the output. Go to campaigns and run. You can edit, pause, delete, run your campaigns. Look at the stats how your campaign is doing. State of the art dashboard to monitor your clients actions and use feedback to create more appealing and engaging campaigns.",
};
export var TestimonialTexts = {
    firstText: "TESTIMONIALS",
    secondText: "What people say about Us.",
    feedBacks: [
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            location: "Lahore, Pakistan",
        },
        {
            text: "Jadoo was great with the entire process from planning to updates during the trip. We had 11 people and everything was perfectly executed. We appreciate all of her hard work. It was truly the trip of a lifetime. Thank you!",
            person: "Gina",
            location: "Owerri, Nigeria",
        },
        {
            text: "Booking through you was very easy and made our lives so much easier. I have nothing bad to say! Thank you for giving us tips and guidance before we left on what to bring and such, that was very helpful!",
            person: "Emma",
            location: "Vancouver, Canada",
        },
        {
            text: "Thank you for your recommendation and putting the trip together. The resort was absolutely beautiful. The infinity pools, the palm trees in the main pool, the infinity pool in out preferred area overlooking the gulf and the golf course were exceptional....",
            person: "Loveth",
            location: "Lagos, Nigeria",
        },
    ],
};
export var ContactUsTexts = {
    firstText: "Book a demo with our Specialists",
    emailPlaceholderText: "Your email",
    phonePlaceholderText: "Your phone number",
    namePlaceholderText: "Your Full Name",
    messagePlaceholderText: "Your Message",
    buttonText: "Submit",
};
export var RegisterModalTexts = {
    emailPlaceholderText: "Your email",
    buttonText: "Register",
};
export var SignInModalTexts = {
    emailPlaceholderText: "Enter verification ID",
    buttonText: "Login",
};
export var FooterTexts = {
    underLogoText: "Our (CIN) company identification number is U62099HR2024PTC120675.",
    quickLinks: {
        caption: "Quick Links",
        links: [
            {
                name: "About",
                url: "https://neugence.ai",
            },
            {
                name: "Features",
                url: "#features",
            },
            {
                name: "Blogs",
                url: "/",
            },
            {
                name: "Flows",
                url: "/",
            },
            {
                name: "Pricing",
                url: "/",
            },
            {
                name: "MisUse Reporting",
                url: "https://forms.gle/agdkAxM5yym4kxKC9",
            },
        ],
    },
    contacts: {
        caption: "Contacts",
        links: [
            {
                name: "FAQs",
                url: "/",
            },
            {
                name: "Help",
                url: "/",
            },
            {
                name: "Policies",
                url: "/",
            },
            {
                name: "Terms & Conditions",
                url: "/",
            },
        ],
    },
    more: {
        caption: "More",
        links: [
            {
                name: "Career",
                url: "/",
            },
            {
                name: "Airlines",
                url: "/",
            },
            {
                name: "Airline Fees",
                url: "/",
            },
            {
                name: "Low Fare Tips",
                url: "/",
            },
        ],
    },
};
