import ReactPlayer from "react-player";
import React from "react";

function VideoComp(){
	return (
		<ReactPlayer
              url={'https://blogs-images-and-videos.del1.vultrobjects.com/test1@gmail.com/02e01ede-c30b-4c3b-9aab-d5bbbc2fdb30/output.m3u8'}
              controls
              width="100%"
              height="220px"
              // onError={handleTestUrlError}
            />
	)
}


export default VideoComp;