import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import blogsStore, { generateDocId } from "./store/blogsStore.js";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { BiSolidUpvote } from "react-icons/bi";
import { extractHeadings } from "./Utils/blogUtils.js";
import blogsState from "./store/blogState.js";
import SkeletonStucture from "./Utils/Skeleton.js";
import { useParams } from "react-router-dom";
import AccordionNew from "./Accordian.js";
import MobileAccordion from "./MobileAcc";
import { components } from "./CustomComponents/CopyCustom.js";

import "./BlogComp2.css";
import "./BlogComp.css";
import "highlight.js/styles/github.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaArrowRightLong, FaGithub, FaUps } from "react-icons/fa6";

// import lowlight from 'lowlight';
// import python from 'highlight.js/lib/languages/python';
// lowlight.registerLanguage('python', python);
import { createLowlight } from "lowlight";
import python from "highlight.js/lib/languages/python";

// // Create a lowlight instance with the necessary language(s)
// const lowlight = createLowlight({
//   languages: {
//     python
//   }
// });
// // import { lowlight } from 'lowlight';
// // import python from 'highlight.js/lib/languages/python';
// lowlight.registerLanguage('python', python);
// import { lowlight } from 'lowlight/lib/core';
// import python from 'highlight.js/lib/languages/python';
// import python from 'highlight.js/lib/languages/python';
// lowlight.registerLanguage('python', python);

const BlogComp = ({ useremail }) => {
  const { courseId } = useParams();
  const [headings, setHeadings] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedHeading, setSelectedHeading] = useState("");
  const [isScroll, setIsScroll] = new useState(false);
  const markdownRef = useRef(null);
  const contentRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);

  const [selectedBlogs, setSelectedBlogs] = useState({});
  const [visitedBlogs, setVisitedBlogs] = useState({});

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const timeoutRef = useRef(null);

  const {
    article,
    fetchPublishedBlogsForUser,
    fetchCourseData,
    slugArticleContent,
  } = blogsStore((state) => ({
    article: state.article,
    fetchPublishedBlogsForUser: state.fetchPublishedBlogsForUser,
    fetchCourseData: state.fetchCourseData,
    slugArticleContent: state?.slugArticleContent,
  }));

  const {
    setBlogName,
    setBlogContent,
    setBlogTags,
    setBlogId,
    blogName,
    blogContent,
  } = blogsState((state) => ({
    setBlogName: state.setBlogName,
    setBlogContent: state.setBlogContent,
    setBlogTags: state.setBlogTags,
    setBlogId: state.setBlogId,
    blogName: state.blogName,
    blogContent: state.blogContent,
  }));

  const { courseAccordian, fetchArticleFromSlug } = blogsStore((state) => ({
    courseAccordian: state.courseAccordian,
    fetchArticleFromSlug: state.fetchArticleFromSlug,
  }));

  useEffect(() => {
    if (courseId) {
      fetchCourseData(courseId);
    }
  }, [courseId]);

  useEffect(() => {
    const category = "aiml";
    fetchPublishedBlogsForUser(useremail, category);
  }, [fetchPublishedBlogsForUser, useremail]);

  useEffect(() => {
    if (article) {
      setSelectedBlog(article);
    }
  }, [article]);

  useEffect(() => {
    const renderer = document.querySelector(".renderer");
    if (renderer) renderer.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [blogContent]);

  useEffect(() => {
    if (selectedBlog) {
      setBlogName(selectedBlog.name);
      setBlogContent(selectedBlog.content);
      setBlogTags(selectedBlog.tags || []);
      setBlogId(selectedBlog.id);

      const extractedHeadings = extractHeadings(selectedBlog.content);
      setHeadings(extractedHeadings);
      setSelectedHeading(extractedHeadings[0]?.id || "");
    }
  }, [selectedBlog, setBlogName, setBlogContent, setBlogTags, setBlogId]);

  useEffect(() => {
    if (!isScroll) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setSelectedHeading(entry.target.id);
            }
          });
        },
        {
          root: markdownRef.current,
          rootMargin: "0px 0px -95% 0px",
          threshold: 0.1,
        }
      );

      const headingsElements = headings.map((heading) =>
        document.getElementById(heading.id)
      );
      headingsElements.forEach((element) => {
        if (element) observer.observe(element);
      });

      return () => {
        headingsElements.forEach((element) => {
          if (element) observer.unobserve(element);
        });
      };
    }
  }, [headings, isScroll]);

  const handleBlogClick = (title, blog) => {
    setSelectedBlogs((prevState) => ({
      ...prevState,
      [title]: prevState[title] === blog ? null : blog,
    }));

    console.log("blogs", selectedBlogs);

    setVisitedBlogs((prevState) => ({
      ...prevState,
      [title]: {
        ...prevState[title],
        [blog?.slug]: true,
      },
    }));

    fetchArticleFromSlug(blog?.slug);
    setBlogContent(slugArticleContent);
  };

  useEffect(() => {
    const handleNextPrev = (direction) => {
      if (!selectedBlog) return;

      let currentSectionIndex = -1;
      let currentBlogIndex = -1;

      courseAccordian.forEach((section, sIndex) => {
        Object.values(section.body).forEach((blog, bIndex) => {
          if (blog?.slug === selectedBlog?.slug) {
            currentSectionIndex = sIndex;
            currentBlogIndex = bIndex;
          }
        });
      });

      if (currentSectionIndex === -1 || currentBlogIndex === -1) return;

      let newBlog;
      if (direction === "next") {
        const currentSection = courseAccordian[currentSectionIndex];
        const sectionBlogs = Object.values(currentSection.body);
        if (currentBlogIndex < sectionBlogs.length - 1) {
          newBlog = sectionBlogs[currentBlogIndex + 1];
        } else if (currentSectionIndex < courseAccordian.length - 1) {
          const nextSection = courseAccordian[currentSectionIndex + 1];
          newBlog = Object.values(nextSection.body)[0];
        }
        handleBlogClick(currentSection.title, newBlog);
      } else {
        const currentSection = courseAccordian[currentSectionIndex];
        const sectionBlogs = Object.values(currentSection.body);
        if (currentBlogIndex > 0) {
          newBlog = sectionBlogs[currentBlogIndex - 1];
        } else if (currentSectionIndex > 0) {
          const prevSection = courseAccordian[currentSectionIndex - 1];
          const sectionBlogs = Object.values(prevSection.body);
          newBlog = sectionBlogs[sectionBlogs.length - 1];
        }
        handleBlogClick(currentSection.title, newBlog);
      }

      if (newBlog) {
        setSelectedBlog(newBlog);
        fetchArticleFromSlug(newBlog?.slug);
      }
    };

    let touchstartX = 0;
    let currentX = 0;

    function handleGesture() {
      const screenWidth = window.innerWidth;
      const swipeThreshold = screenWidth / 3;

      if (currentX < touchstartX - swipeThreshold) {
        handleNextPrev("next");
      }

      if (currentX > touchstartX + swipeThreshold) {
        handleNextPrev("prev");
      }

      setShowLeftArrow(false);
      setShowRightArrow(false);
    }

    function startTouch(event) {
      touchstartX = event.touches[0].screenX;
    }

    function moveTouch(event) {
      currentX = event.touches[0].screenX;
      const screenWidth = window.innerWidth;
      const swipeThreshold = screenWidth / 3;
      const diff = currentX - touchstartX;

      if (Math.abs(diff) >= swipeThreshold) {
        if (diff < 0) {
          setShowLeftArrow(true);
          setShowRightArrow(false);
        } else {
          setShowLeftArrow(false);
          setShowRightArrow(true);
        }
      } else {
        setShowLeftArrow(false);
        setShowRightArrow(false);
      }

      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        setShowLeftArrow(false);
        setShowRightArrow(false);
      }, 500);
    }

    function stopTouch() {
      handleGesture();
    }

    const element = contentRef.current;
    if (element) {
      element.addEventListener("touchstart", startTouch, false);
      element.addEventListener("touchmove", moveTouch, false);
      element.addEventListener("touchend", stopTouch, false);

      return () => {
        element.removeEventListener("touchstart", startTouch);
        element.removeEventListener("touchmove", moveTouch);
        element.removeEventListener("touchend", stopTouch);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    }
  }, [contentRef, courseAccordian, fetchArticleFromSlug, selectedBlog]);

  const handleHeadingClick = (headingId) => {
    setIsScroll(true);
    setSelectedHeading(headingId);
  };

  const handleNextPrev = (direction) => {
    if (!selectedBlog) return;
    let currentSectionIndex = -1;
    let currentBlogIndex = -1;

    courseAccordian.forEach((section, sIndex) => {
      Object.values(section.body).forEach((blog, bIndex) => {
        if (blog?.slug === selectedBlog?.slug) {
          currentSectionIndex = sIndex;
          currentBlogIndex = bIndex;
        }
      });
    });

    if (currentSectionIndex === -1 || currentBlogIndex === -1) return;

    let newBlog;
    if (direction === "next") {
      const currentSection = courseAccordian[currentSectionIndex];
      const sectionBlogs = Object.values(currentSection.body);
      if (currentBlogIndex < sectionBlogs.length - 1) {
        newBlog = sectionBlogs[currentBlogIndex + 1];
      } else if (currentSectionIndex < courseAccordian.length - 1) {
        const nextSection = courseAccordian[currentSectionIndex + 1];
        newBlog = Object.values(nextSection.body)[0];
      }

      handleBlogClick(currentSection.title, newBlog);
    } else {
      const currentSection = courseAccordian[currentSectionIndex];
      const sectionBlogs = Object.values(currentSection.body);
      if (currentBlogIndex > 0) {
        newBlog = sectionBlogs[currentBlogIndex - 1];
      } else if (currentSectionIndex > 0) {
        const prevSection = courseAccordian[currentSectionIndex - 1];
        const sectionBlogs = Object.values(prevSection.body);
        newBlog = sectionBlogs[sectionBlogs.length - 1];
      }
      handleBlogClick(currentSection.title, newBlog);
    }

    if (newBlog) {
      setSelectedBlog(newBlog);
      fetchArticleFromSlug(newBlog?.slug);
    }
  };

  useEffect(() => {
    if (isScroll && selectedHeading) {
      const element = document.getElementById(selectedHeading);
      if (element && element.parentNode) {
        element.parentNode.scrollTop =
          element.offsetTop - element.parentNode.offsetTop;
      }
      setTimeout(() => {
        setIsScroll(false);
      }, 1000);
    }
  }, [selectedHeading, isScroll]);

  function searchCateg(item) {
    if (!item) return;
    const lowerCaseItem = item?.toLowerCase();
    for (const category of courseAccordian) {
      for (const key in category.body) {
        if (key?.toLowerCase() === lowerCaseItem) {
          return category.title;
        }
      }
    }
  }

  function findCategoryBySlug(slug) {
    for (const category of courseAccordian) {
      for (const item of Object.values(category.body)) {
        if (item.slug === slug) {
          return category.title;
        }
      }
    }
    return null;
  }

  return (
    <div
      className="h-[89vh] max-h-[89vh] flex flex-col lg:grid lg:grid-cols-[0.23fr_1fr] w-full
    pb-0 lg:gap-16 justify-self-center absolute top-24 left-0 right-0 pt-4"
      style={{ maxWidth: "1400px", fontFamily: "charter" }}
    >
      {/* Navbar */}
      <div className="toc min-w-[200px] px-1.5 py-4 lg:block hidden h-[85vh]">
        {/*w-[18vw]*/}
        <div className="flex justify-between items-center mb-1">
          <h3 className="font-bold text-sm mx-4 text-gray-500">Articles</h3>
        </div>

        <div
          style={{ overflowY: "auto", overflow: "scroll" }}
          className="scrollbar pr-2"
        >
          <AccordionNew
            courseId={courseId}
            visitedBlogs={visitedBlogs}
            setSelectedBlogs={setSelectedBlogs}
            setVisitedBlogs={setVisitedBlogs}
            handleBlogClick={handleBlogClick}
            selectedBlogs={selectedBlogs}
          />
        </div>
      </div>

      <MobileAccordion
        handleNextPrev={handleNextPrev}
        courseId={courseId}
        visitedBlogs={visitedBlogs}
        setSelectedBlogs={setSelectedBlogs}
        setVisitedBlogs={setVisitedBlogs}
        handleBlogClick={handleBlogClick}
        selectedBlogs={selectedBlogs}
        selectedBlog={selectedBlog}
        searchCateg={searchCateg}
      />

      {/* Content ... blogName ... blogContent*/}

      <div
        ref={contentRef}
        className="relative flex flex-col h-[89vh] max-h-[89vh] overflow-auto pb-0 lg:pb-5 max-w-[846px] mt-[-36px] lg:mt-5"
      >
        <div
          className="hidden lg:block"
          style={{
            marginTop: "-41px",
            marginLeft: "-65px",
            zIndex: 0,
            position: "fixed",
          }}
        >
          <div style={{ display: "flex", borderLeft: "1px solid lightgrey" }}>
            <div>
              <button
                onClick={() => handleNextPrev("prev")}
                className="p-0 rounded-lg focus:outline-none text-gray-500"
              >
                <span style={{ display: "flex" }}>
                  <FaChevronLeft
                    style={{ marginTop: "5px", marginRight: "1px" }}
                  />
                  Prev
                </span>
              </button>

              <button
                onClick={() => handleNextPrev("next")}
                className="p-0 rounded-lg focus:outline-non pl-2 text-gray-500"
                style={{ position: "absolute" }}
              >
                <span style={{ display: "flex" }}>
                  Next
                  <FaChevronRight
                    style={{ marginTop: "5px", marginLeft: "1px" }}
                  />
                </span>
              </button>
            </div>
            <div style={{ marginLeft: "80px", marginTop: "3px" }}>
              {selectedBlog && selectedBlog.title ? (
                <h3 className="opacity-40 text-sm mb-5 flex gap-2 items-center">
                  {findCategoryBySlug(selectedBlog?.slug)} <FaArrowRightLong />{" "}
                  {selectedBlog.title === "Probability"
                    ? "Basic Probability"
                    : selectedBlog.title}
                </h3>
              ) : (
                <h3 className="opacity-40 text-sm mb-5 flex gap-2 items-center">
                  NA
                </h3>
              )}
            </div>
          </div>
          <hr style={{ display: "flex", width: "100vw", marginTop: "-10px" }} />
        </div>
        <div className="fixed top-[5.5rem] h-1 left-0 w-screen flex items-start justify-start">
          <div
            className="bg-[#c7d2fe]"
            style={{ width: scrollTop + "%", height: "2px" }}
          />
        </div>

        <div
          className="prose custom-prose prose-headings:text-sm lg:prose-headings:text-base pl-2 h-[85vh] pt-12 overflow-y-auto scrollbar py-0 relative"
          id="check"
          style={{
            maxWidth: "100vw",
            overflowY: "auto",
            height: "100%",
            "--tw-prose-headings": "rgb(6 54 158 / 91%)",
            "--tw-prose-pre-bg": "white",
          }}
          onScroll={(e) => {
            setScrollTop(
              (Math.ceil(e.target.scrollTop) /
                (e.target.scrollHeight - e.target.clientHeight)) *
                100
            );
          }}
          ref={markdownRef}
        >
          {selectedBlog && (
            <div className="flex flex-col gap-2 mb-10">
              <h1 className="font-semibold text-4xl !block !mb-0">
                {selectedBlog?.title}
              </h1>
              <p className="text-sm">
                Published at{" "}
                {new Date(selectedBlog?.created).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                })}
              </p>
              <div className="flex flex-row my-3 gap-4">
                <div className="flex gap-3 items-center justify-center px-3 cursor-pointer py-2 rounded-xl hover:bg-gray-50 hover:border-gray-100 border-transparent border">
                  <img className="bg-gray-200 w-12 h-12 rounded-full !m-0" />
                  <div className="flex flex-col gap-1">
                    <p className="!text-xs underline">neugence</p>
                    <h3 className="!text-sm font-medium !m-0">Neugence</h3>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-row flex-col sm:items-center sm:justify-start gap-3">
                <button className="px-4 sm:my-3 py-2 border rounded-lg hover:bg-gray-100 transition duration-200 bg-gray-50 flex w-fit items-center justify-center gap-3 ">
                  <FaGithub className="text-lg" /> Update on Github
                </button>
                <button className="flex w-fit px-4 sm:my-3 py-2 text-white transition duration-200 hover:bg-color3 bg-color2 items-center justify-center gap-2 rounded-lg border-gray-200 border">
                  <BiSolidUpvote className="text-lg" />
                  <span className="font-bold">32</span>
                </button>
              </div>
            </div>
          )}
          {selectedBlog ? (
            <ReactMarkdown
              // className={`markdown h-full relative scrollbar renderer list-disc px-4  ${styles.markdown} `}
              className="markdown h-full relative scrollbar renderer list-disc px-4"
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeSlug, rehypeHighlight]}
              components={components}
            >
              {blogContent}
            </ReactMarkdown>
          ) : (
            <SkeletonStucture size={940} count={10} />
          )}
        </div>
      </div>
      {showLeftArrow && (
        <div className="fixed left-4 top-1/2 transform -translate-y-1/2 bg-gray-900 bg-opacity-50 rounded-full p-2">
          <FaChevronLeft className="text-white" size={24} />
        </div>
      )}
      {showRightArrow && (
        <div className="fixed right-4 top-1/2 transform -translate-y-1/2 bg-gray-900 bg-opacity-50 rounded-full p-2">
          <FaChevronRight className="text-white" size={24} />
        </div>
      )}
    </div>
  );
};

export default BlogComp;
