import React from "react";
import Box from "@mui/material/Box";
import MuiSkeleton from "@mui/material/Skeleton";
const SkeletonStucture = ({size,count}) => {
    const items = new Array(count).fill(null);
  return (
    <Box sx={{ width: `${size}px` }}>
      {items.map((_, idx) => (
        
        <MuiSkeleton
          key={idx}
          className="mb-2"
          variant="rectangular"
          width={`${size - 20}px`}
          height={30}
        />
        
      ))}
    </Box>
  );
};

export default SkeletonStucture;
