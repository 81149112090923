import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UploadCard({ onUpload, verifiedemail }) {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);
  const [fileSize, setFileSize] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);
  const CHUNK_SIZE = 20 * 1024 * 1024; // 4MB
  const CONCURRENT_UPLOADS = 6; // Number of chunks to upload concurrently
  const MAX_RETRIES = 4; // Maximum retry attempts for each chunk

  useEffect(() => {
    let interval;
    if (isUploading) {
      interval = setInterval(() => {
        setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isUploading, startTime]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      try {
        if (selectedFile instanceof File || selectedFile instanceof Blob) {
          setFile(selectedFile);
          setFileSize((selectedFile.size / (1024 * 1024)).toFixed(2));
          const objectUrl = URL.createObjectURL(selectedFile);
          setVideoUrl(objectUrl);
        } else {
          throw new Error('Selected file is not a valid File or Blob.');
        }
      } catch (error) {
        console.error('Error handling file:', error);
        alert('Error displaying the video preview. Please try another file.');
      }
    } else {
      alert('No file selected. Please choose a valid video file.');
    }
  };

  const initializeUpload = async () => {
    try {
      const response = await axios.post('/initialize-upload', {
        email: verifiedemail,
      });
      if (response.status === 200) {
        return { folder_path: response.data.folder_path, session_id: response.data.session_id };
      } else {
        throw new Error('Failed to initialize upload');
      }
    } catch (error) {
      console.error('Error initializing upload:', error);
      alert('Failed to initialize upload process');
      setIsUploading(false);
      throw error;
    }
  };

  const uploadChunk = async (chunk, chunkIndex, totalChunks, cancelToken, folder_path, session_id, retries = 0) => {
    const formData = new FormData();
    formData.append('video', chunk);
    formData.append('email', verifiedemail);
    formData.append('chunkIndex', chunkIndex);
    formData.append('totalChunks', totalChunks);
    formData.append('folder_path', folder_path);
    formData.append('session_id', session_id);

    try {
      await axios.post('/upload-video', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: cancelToken.token,
      });

      setUploadProgress((prevProgress) => prevProgress + (100 / totalChunks));
      return true;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload canceled by the user.');
      } else {
        console.error(`Error uploading chunk ${chunkIndex}:`, error);
        if (retries < MAX_RETRIES) {
          console.log(`Retrying chunk ${chunkIndex}... Attempt ${retries + 1}`);
          return uploadChunk(chunk, chunkIndex, totalChunks, cancelToken, folder_path, session_id, retries + 1);
        } else {
          alert(`Failed to upload chunk ${chunkIndex} after ${MAX_RETRIES} attempts.`);
        }
      }
      return false;
    }
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const checkUploadStatus = async (folder_path) => {
    try {
      const response = await axios.post('/check-upload-status', {
        verifiedemail:verifiedemail,
        folder_path: folder_path,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Error checking chunk status:', error);
    }
    return { uploaded_chunks: [], total_chunks: 0 };
  };

  const handleMissingChunks = async (missingChunks, chunkPromises, source, folder_path, session_id) => {
    for (let i = 0; i < missingChunks.length; i += CONCURRENT_UPLOADS) {
      const chunkBatch = missingChunks.slice(i, i + CONCURRENT_UPLOADS);
      await Promise.all(
        chunkBatch.map((chunkIndex) =>
          uploadChunk(
            ...chunkPromises[chunkIndex],
            source,
            folder_path,
            session_id
          )
        )
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Please select a video file to upload.');
      return;
    }

    setIsUploading(true);
    setStartTime(Date.now());
    setUploadProgress(0);
    setElapsedTime(0);

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let offset = 0;
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const { folder_path, session_id } = await initializeUpload();

      const chunkPromises = [];
      let currentChunk = 0;

      while (offset < file.size) {
        const chunk = file.slice(offset, offset + CHUNK_SIZE);
        chunkPromises.push([chunk, currentChunk, totalChunks, source, folder_path, session_id]);
        currentChunk++;
        offset += CHUNK_SIZE;
      }

      for (let i = 0; i < chunkPromises.length; i += CONCURRENT_UPLOADS) {
        const chunkBatch = chunkPromises.slice(i, i + CONCURRENT_UPLOADS);
        await Promise.all(chunkBatch.map((chunkData) => uploadChunk(...chunkData)));
      }

      let uploadStatus = await checkUploadStatus(folder_path);
      while (uploadStatus.uploaded_chunks.length !== uploadStatus.total_chunks) {
        const missingChunks = Array.from({ length: uploadStatus.total_chunks }, (_, i) => i).filter(
          (i) => !uploadStatus.uploaded_chunks.includes(i)
        );
        console.log(`Missing chunks: ${missingChunks}`);
        await handleMissingChunks(missingChunks, chunkPromises, source, folder_path, session_id);

        // await sleep(2000); // Wait for 2 seconds before checking again
        uploadStatus = await checkUploadStatus(folder_path);
      }

      await finalizeUpload(folder_path, session_id);

      if (!source.token.reason) {
        alert('File uploaded successfully');
        onUpload({
          id: Date.now(),
          url: videoUrl,
          title,
          category,
          updatedAt: new Date().toISOString(),
          likedBy: [],
        });
      }
    } catch (error) {
      console.error('Error during upload:', error);
    } finally {
      setIsUploading(false);
      setCancelToken(null);
    }

    setTitle('');
    setCategory('');
    setFile(null);
    setVideoUrl('');
    setUploadProgress(0);
    setElapsedTime(0);
    setFileSize(0);
  };

  const finalizeUpload = async (folder_path, session_id) => {
    try {
      const response = await axios.post('/finalize-upload', {
        email: verifiedemail,
        folder_path: folder_path,
        session_id: session_id,
      });
      if (response.status !== 200) {
        throw new Error('Failed to finalize upload');
      }
    } catch (error) {
      console.error('Error finalizing upload:', error);
      alert('Failed to finalize upload process');
      setIsUploading(false);
      throw error;
    }
  };

  const handleCancelUpload = () => {
    if (cancelToken) {
      cancelToken.cancel('Upload canceled by the user.');
      setIsUploading(false);
      setUploadProgress(0);
      setElapsedTime(0);
    }
  };

  return (
    <div className="border border-gray-300 p-4 rounded-lg shadow-sm bg-white">
      <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
        <input
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          required
          className="border border-gray-300 p-2 rounded"
        />
        {videoUrl && (
          <video src={videoUrl} controls width="100%" className="mt-2"></video>
        )}
        <div className="flex space-x-2">
          <button
            type="submit"
            className="text-white bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded"
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Add Video'}
          </button>
          {isUploading && (
            <button
              type="button"
              onClick={handleCancelUpload}
              className="text-white bg-red-500 hover:bg-red-600 px-3 py-1 rounded"
            >
              Cancel Upload
            </button>
          )}
        </div>
      </form>
      {file && (
        <p className="text-sm text-gray-600 mt-2">
          Total File Size: {fileSize} MB
        </p>
      )}
      {isUploading && (
        <div className="mt-2">
          <p className="text-sm text-gray-600">
            Uploading: {uploadProgress.toFixed(2)}%
          </p>
          <p className="text-sm text-gray-600">
            Time Elapsed: {elapsedTime} seconds
          </p>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-500 h-2.5 rounded-full"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadCard;
