import React, { useState, useEffect } from 'react';

const PrivacyModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const consentData = localStorage.getItem('privacyConsent');
    if (!consentData || isConsentExpired(JSON.parse(consentData).expiry)) {
      setShowModal(true);
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((data) => {
          if (data.country !== 'IN') {
            setShowModal(true);
          } else {
            setShowModal(false);
          }
        })
        .catch((error) => {
          setShowModal(true); //default behavior
          console.error('Error fetching location data:', error);
        });
    }
  }, []);

  const isConsentExpired = (expiryDate) => {
    return new Date(expiryDate) < new Date();
  };

  const handleAccept = () => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30); // Set expiry to 30 days
    localStorage.setItem(
      'privacyConsent',
      JSON.stringify({ consent: true, expiry: expiryDate })
    );
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div
          className="fixed bottom-0 left-0 right-0 z-50 flex items-center justify-center pt-2"
          style={{ backgroundColor: 'white' }}
        >
          <div
            className="bg-white rounded-lg shadow-2xl max-w-7xl mx-2 md:mx-auto md:p-6 flex flex-col md:flex-row items-center justify-between transition-transform duration-500 transform translate-y-0"
            style={{ backgroundColor: 'white', padding: '0.1rem' }}
          >
            <div className="flex items-center space-x-4">
              <div>
                <p
                  className="text-sm text-gray-600 md:block"
                  style={{
                    fontSize: '14px',
                    marginTop: '-10px',
                  }}
                >
                  This website is using cookies to ensure you get the best experience. By continuing to use this site, you
                  consent to our{' '}
                  <a href="/privacy-policy" className="text-blue-600 underline">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="/privacy-policy" className="text-blue-600 underline">
                    Cookies Policy
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4 mt-4 md:mt-0 ml-4 mb-1">
              <button
                onClick={handleAccept}
                className="bg-blue-500 text-white px-6 py-1 rounded-full shadow hover:bg-blue-600 transition duration-300"
                style={{ fontSize: '12px'}}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyModal;
