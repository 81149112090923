import React, { useState } from 'react';
import HeadindText from '../Utils/HeadindText';

const faqData = [
  {
    question: "What is UpSkill In AI?",
    answer: "It's an advanced learning platform we created to offer you various self-paced / interactive / evaluation courses in artificial intelligence and related fields, catering to different educational levels from Bachelors to PhD and professional tracks. Check out our Acehub.ai website to get started.",
  },
  {
    question: "What types of courses do you offer?",
    answer: "We offer courses in AI, Machine Learning, Data Structures and Algorithms, System Design, and more, including hands-on projects and real-world applications.",
  },
  {
    question: "What are the prerequisites for enrolling in a course?",
    answer: "Prerequisites vary by course. Some beginner courses require no prior knowledge, while advanced courses may require high school knowledge in programming and mathematics.",
  },
  {
    question: "Do you offer certifications?",
    answer: "Yes, we provide certifications upon successful completion of our courses.",
  },
  {
    question: "How are the courses structured?",
    answer: "Courses include video lectures, quizzes, take-home exercises, real-world projects, and 1:1 mentorship sessions to ensure comprehensive learning.",
  },
  {
    question: "How does the mentorship program work?",
    answer: "Our mentorship program offers long-term guidance from industry experts who provide personalized learning paths and career advice.",
  },
  {
    question: "What is cohort matching?",
    answer: "We match you with a study group of similar skill levels to ensure collaborative learning and support throughout your course.",
  },
  {
    question: "How does the Job Search and Application Assistant work?",
    answer: "Our Job Search and Application Assistant automates the job application process by finding relevant jobs and applying on your behalf, allowing you to focus on interviews.",
  },
  {
    question: "What tools and technologies will I learn?",
    answer: "You will learn tools and technologies such as PyTorch, Keras, TensorFlow, Hugging Face, OpenAI APIs, and Python, among others.",
  },
  {
    question: "How can I attend live sessions and seminars?",
    answer: "You can attend live sessions and seminars through our built-in audio/video conferencing feature on the website.",
  },
  {
    question: "How do I enroll in a course?",
    answer: "You can enroll in a course by subscribing to our email list and signing up on our website, then selecting the course you wish to take.",
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept various payment methods, including credit/debit cards, PayPal, and bank transfers.",
  },
  {
    question: "Do you offer any discounts or scholarships?",
    answer: "Yes, we offer discounts and scholarships based on various criteria. Please check our website for more details.",
  },
  {
    question: "How do you ensure the quality of your courses?",
    answer: "Our courses are designed and taught by industry veterans and researchers, with continuous updates based on feedback and industry trends.",
  },
  {
    question: "Can I get a refund if I’m not satisfied with a course?",
    answer: "Yes, we offer a refund policy within a specified period. Please refer to our refund policy on the website for more details.",
  },
  {
    question: "How can I contact support?",
    answer: "You can contact our support team via email contact@acehub.ai, phone, or live chat on our website.",
  },
];

const FaqSection = () => {

  return (
    <section className="py-10 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-3xl xl:mx-0 mx-auto text-left">

          <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
            <HeadindText Heading={`Frequently Asked Questions`}/>
          </h2>
          <p className="mt-4 text-base leading-relaxed text-gray-600">
            Ask us the doubts and Frequently Asked Questions
          </p>
        </div>

        <div className="relative after:h-[70vh] after:absolute after:bottom-0 after:bg-gradient-to-t after:from-white after:via-white hover:after:opacity-0 after:transition-all after:duration-150 after:to-transparent after:w-full w-full xl:mx-auto flex xl:flex-row flex-col gap-3 flex-wrap mt-8 md:mt-16">

          {faqData.map((item, index) => (
            <div
              key={index}
              className="transition-all xl:w-[49%] w-full duration-700 bg-white p-3 hover:bg-gray-50 rounded-lg p-4 cursor-pointer"
            >

                <span className="flex text-lg text-left font-bold lg:my-2 my-3 text-black ">
                  {/* {} */}
                  <HeadindText Heading={item.question}/>
                </span>

              <div
                className={`text-left ml-4 block opacity-70 border-l border-gray-600 px-4 sm:px-6`}
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>

        <p className="text-center text-gray-600 text-base mt-9">
          Didn’t find the answer you are looking for?{" "}
          <a
            href="https://neugence.ai/"
            target='_blank'
            title=""
            className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
          >
            Contact our support
          </a>
        </p>
      </div>
    </section>
  );
};

export default FaqSection;
