import React, {useEffect, useRef, useState} from "react";
import {Button, Switch} from "@fluentui/react-components";
import {toast} from "react-toastify";
import {SideMenu, CardCarousel, ExcalidrawWrapper} from "neugencelib";
import usePreventBackNavigation from './usePreventBackNavigation';
import ShareModal from "./Sharing/ShareModal";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useMainStore, foldersStore, sharedNotesStore, notesStore} from "neugencelib";

function MegaNotes({verifiedemail}) {
	const modalRef = useRef(null);
	const [reloadKey, setReloadKey] = useState(0);
	const {initializeProfilePic, sharingCapableEmailIds, initializeUserMultipleDataSets} = useMainStore(state => ({
		initializeProfilePic: state.initializeProfilePic,
		sharingCapableEmailIds: state.sharingCapableEmailIds,
		initializeUserMultipleDataSets: state.initializeUserMultipleDataSets
	}));
	const {
		fetchUserFolders,
		insertNotesMetaInFolders,
		createFolder,
		handleRename,
		updateTreeStructure,
		deleteNoteFromFolderHandle,
		folders,
		openFolders,
		setFldrState,
		onDeleteFolderHandle
	} = foldersStore(state => ({
		fetchUserFolders: state.fetchUserFolders,
		insertNotesMetaInFolders: state.insertNotesMetaInFolders,
		createFolder: state.createFolder,
		handleRename: state.handleRename,
		updateTreeStructure: state.updateTreeStructure,
		deleteNoteFromFolderHandle: state.deleteNoteFromFolderHandle,
		folders: state.folders,
		openFolders: state.openFolders,
		setFldrState: state.setFldrState,
		onDeleteFolderHandle: state.onDeleteFolderHandle
	}));
	const {noteOwner, noteRole, friendShips, getOwnerAndRole, fetchFriendships, setOwnerAndRole} = sharedNotesStore(state => ({
		noteOwner: state.noteOwner,
		noteRole: state.noteRole,
		friendShips: state.friendShips,
		getOwnerAndRole: state.getOwnerAndRole,
		fetchFriendships: state.fetchFriendships,
		setOwnerAndRole: state.setOwnerAndRole
	}))
	const {
		notes, notesPreviews,
		loadNotesFromDB,
		setState,
		modalOpen,
		handleNoteClick,
		createNewButtonEvent,
		deleteNoteHandle,
		handleSaveButtonEvent,
		wipeLocalData,
		usernotes,
		handleRenameNote
	} = notesStore(state => ({
		notes: state.notes, notesPreviews: state.notesPreviews,
		loadNotesFromDB: state.loadNotesFromDB,
		setState: state.setState,
		modalOpen: state.modalOpen,
		handleNoteClick: state.handleNoteClick,
		createNewButtonEvent: state.createNewButtonEvent,
		deleteNoteHandle: state.deleteNoteHandle,
		handleSaveButtonEvent: state.handleSaveButtonEvent,
		wipeLocalData: state.wipeLocalData,
		usernotes: state.usernotes,
		handleRenameNote: state.handleRenameNote
	}));
	
	function handleRefresh() {
		setReloadKey(reloadKey + 1);
	}
	
	const [hoverPreviewChecked, sethoverPreviewChecked] = useState(false);
	
	function onPreviewCheckChange() {
		sethoverPreviewChecked(!hoverPreviewChecked);
	}
	
	const [currNote, setCurrNote] = useState({});
	const [owner, setOwner] = useState('');
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const [bgcolor, setbgcolor] = useState("white");
	const [role, setRole] = useState('');
	const [currDocId, setcurrDocId] = useState('');
	
	const fixedNotesRef = usePreventBackNavigation(); // Use the custom hook
	const [excalidrawAPI, setExcalidrawAPI] = useState(null);
	const [allNotesFltrd, setallNotesFltrd] = useState([]);
	
	function handleCloseLocal() {
		saveButtonTriggered(currDocId, currNote['title']);
		handleClose();
	}
	
	function handleTitleClick(noteId) {
		const docid = noteId.split('note-')[1];
		setcurrDocId(docid);
		handleNoteClick(noteId);
		setOwnerAndRole(docid, verifiedemail);
		const [owner, role] = getOwnerAndRole(noteId, verifiedemail);
		setOwner(owner);
		setRole(role);
		const currNote = notes.filter(n => n.docid === docid)[0];
		setCurrNote(currNote);
		setState('modalOpen', true);
	}
	
	useEffect(() => {
		setState('verifiedEmail', verifiedemail);
	}, [setState, verifiedemail]);
	
	function saveButtonTriggered(docId, notetitle) {
		handleSaveButtonEvent(docId, excalidrawAPI, verifiedemail, verifiedemail, notetitle);
		//Notes are not saved when escape button is used.
		//TODO: when the notes are shared they are not saved and this is a wrong indication to the user.
		toast.success('Note Saved !!', {position: "top-right", hideProgressBar: "true", autoClose: 1000, height: "50px"});
	}
	
	const handleClose = () => setState('modalOpen', false);
	
	function deletNoteClicked(useCaseDesc) {
		const confirmDelete = window.confirm("Delete Title: " + currNote['title']);
		if (confirmDelete) {
			deleteNoteHandle(verifiedemail, currDocId, useCaseDesc);
			deleteNoteFromFolderHandle(currDocId, verifiedemail, useCaseDesc);
			handleClose();
		}
	}
	
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				handleClose();
			}
		};
		
		const handleClickOutside = (event) => {
			if (modalRef && modalRef.current && !modalRef.current.contains(event.target)) {
				handleClose();
			}
		};
		
		if (modalOpen) {
			window.addEventListener('keydown', handleKeyDown);
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			window.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('mousedown', handleClickOutside);
		}
		
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalOpen, handleClose]);
	
	const [shareEmailIds, setshareEmailIds] = useState([]);
	// useEffect(() => {
	// 	NProgress.start();
	// }, []);
	
	useEffect(() => {
		initializeUserMultipleDataSets(verifiedemail);
	}, [initializeUserMultipleDataSets, verifiedemail]);
	
	useEffect(() => {
		setshareEmailIds(sharingCapableEmailIds);
	}, [sharingCapableEmailIds]);
	
	useEffect(() => {
		if (verifiedemail) {
			initializeProfilePic(verifiedemail);
		}
	}, [initializeProfilePic, verifiedemail]);
	
	
	const toggleFolderItems = (folderId) => {
		const newOpenFolders = {
			...openFolders, [folderId]: !openFolders[folderId],
		};
		setFldrState('openFolders', newOpenFolders);
	};
	
	const [previewsMap, setPreviewsMap] = useState({});
	useEffect(() => {
		const previewsMapLocal = notesPreviews.reduce((map, preview) => {
			map[preview.docid] = preview.previewImg;
			return map;
		}, {});
		setPreviewsMap(previewsMapLocal);
	}, [notesPreviews]);
	
	
	return (
		<div className="flex items-center justify-center">
			<div key={reloadKey} className={'parentPane flex'}>
				{/*CAROUSEL*/}
				<div className={'fixedNotesMegaPane'} style={{
					maxHeight: "94vh",
				}}
				     ref={fixedNotesRef}
				>
					<Switch checked={hoverPreviewChecked} onChange={onPreviewCheckChange}
					        label={hoverPreviewChecked ? "Disable Hover Preview" : "Enable Hover Preview"}/>
					{/*DO NOT REMOVE , THIS REQUIRED TO DEBUG LOCAL STORAGE WIPING OUT*/}
					{/*<Button style={{border: "1px solid lightgrey", borderRadius: "10px"}} onClick={() => wipeLocalData()}>Clear Cache</Button>*/}
					{modalOpen ? <>
							<div style={{display: "contents"}}>
								{currDocId !== '' && currNote && currNote['title'].length > 0 ? <ExcalidrawWrapper
									currNote={currNote}
									currDocId={currDocId}
									owner={owner}
									role={role}
									verifiedemail={verifiedemail}
									notetitle={currNote['title']}
									excalidrawAPI={excalidrawAPI}
									setExcalidrawAPI={setExcalidrawAPI}
									deletNoteClicked={deletNoteClicked}
									openModal={() => setShareModalOpen(true)}
									handleClose={handleCloseLocal}
									saveButtonTriggered={saveButtonTriggered}
								/> : null}
							</div>
							<ShareModal
								currDocId={currDocId}
								verifiedemail={verifiedemail}
								isOpen={shareModalOpen}
								onClose={() => setShareModalOpen(false)}
								sharingCapableEmailIds={shareEmailIds}
							/></> :
						
						// Big Window Showing All Folders, Their Carousels & Cards in Each Carousel
						folders.map((folder) => (
							<div key={folder.key} style={{display: "block"}}>
								<div onClick={() => toggleFolderItems(folder.key)} style={{cursor: "pointer"}}>
									{/*Down / Side Icon*/}
									{openFolders[folder.key] ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightOutlinedIcon/>}
									
									{/*Folder Name*/}
									{folder.title}
									
									{/*Folder Children Length*/}
									<span style={{
										color: "grey", marginLeft: "10px", fontSize: "12px"
									}}>{folder.children ? folder.children.length : 0}</span>
								
								</div>
								
								{openFolders[folder.key] &&
									<CardCarousel
										verifiedEmail={verifiedemail}
										folder={folder}
										fltrdSet={allNotesFltrd}
										previewsMap={previewsMap}
										notePress={handleTitleClick}
										numRows={2} hoverPreviewChecked={hoverPreviewChecked}
									/>}
							</div>))}
				</div>
			</div>
		</div>
	)
}

export default MegaNotes;