import React from "react";

const EditModal = ({ currentSection, modalContent, setModalContent, handleSave, setShowModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">
          {currentSection === "projects" ? "Project" : "Experience"} Details
        </h2>

        {/* Render form based on section */}
        {currentSection === "aboutMe" && (
          <textarea
            className="w-[40vw] h-40 p-2 border"
            value={modalContent}
            onChange={(e) => setModalContent(e.target.value)}
          />
        )}
        {currentSection === "projects" && (
          <>
            <input
              type="text"
              placeholder="Project Title"
              className="w-full p-2 border mb-4"
              value={modalContent.title || ""}
              onChange={(e) =>
                setModalContent({ ...modalContent, title: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Project Description"
              className="w-full p-2 border mb-4"
              value={modalContent.description || ""}
              onChange={(e) =>
                setModalContent({
                  ...modalContent,
                  description: e.target.value,
                })
              }
            />
          </>
        )}
        {currentSection === "pastExperience" && (
          <>
            <input
              type="text"
              placeholder="Role"
              className="w-full p-2 border mb-4"
              value={modalContent.role || ""}
              onChange={(e) =>
                setModalContent({ ...modalContent, role: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Company"
              className="w-full p-2 border mb-4"
              value={modalContent.company || ""}
              onChange={(e) =>
                setModalContent({
                  ...modalContent,
                  company: e.target.value,
                })
              }
            />
            <input
              type="text"
              placeholder="Duration"
              className="w-full p-2 border mb-4"
              value={modalContent.duration || ""}
              onChange={(e) =>
                setModalContent({
                  ...modalContent,
                  duration: e.target.value,
                })
              }
            />
          </>
        )}
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 bg-red-500 text-white rounded shadow mr-2"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded shadow"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
