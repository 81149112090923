import axios from "axios";
import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Tooltip} from "@mui/material";
import {LuPencil} from "react-icons/lu";
import RadarRoundedIcon from '@mui/icons-material/RadarRounded';
import WcRoundedIcon from '@mui/icons-material/WcRounded';

import DropdownList from "../DropdownList.jsx";
import {getAxiosBaseUrl} from "../../../../../../config";
import TagsInput from "./TagsInput.jsx";
import ImageUpload from "./ImageUpload.jsx";

import useMainStore from '../../../../../libStores/mainStore';

export default function Account({verifiedEmailContext, isLocal}) {
	const {fullName, initializeUserFullName, setUserFullName, initializeUserMultipleDataSets, titleTags, locationTags} = useMainStore(state => ({
		fullName: state.fullName,
		initializeUserFullName: state.initializeUserFullName,
		setUserFullName: state.setUserFullName,
		initializeUserMultipleDataSets: state.initializeUserMultipleDataSets,
		titleTags: state.titleTags,
		locationTags: state.locationTags
	}));
	const verifiedemail = verifiedEmailContext;
	const [agreeTermsPrivacyCheckBox, setagreeTermsPrivacyCheckBox] = useState(false);
	const [agreeToEmailOtherCommunication, setagreeToEmailOtherCommunication] = useState(false);
	const [visaRequiredFlag, setvisaRequiredFlag] = useState(false);
	const [jobTypeInternship, setjobTypeInternship] = useState(false);
	const [jobTypeFullTime, setjobTypeFullTime] = useState(false);
	const [jobTypeRemote, setjobTypeRemote] = useState(false);
	const [genderQuestionAnswer, setgenderQuestionAnswer] = useState("");
	const [raceEthnicAnswer, setraceEthnicAnswer] = useState("");
	const [disability, setdisability] = useState("");
	const [transgenderExperience, settransgenderExperience] = useState("");
	const [sexualOrientationAnswer, setsexualOrientationAnswer] = useState("");
	const [veteranStatus, setveteranStatus] = useState("");
	const [name, setname] = useState("Enter Your Name");
	
	useEffect(() => {
		if (verifiedEmailContext) {
			initializeUserFullName(verifiedEmailContext);
			initializeUserMultipleDataSets(verifiedEmailContext);
		}
	}, [verifiedEmailContext, initializeUserFullName, initializeUserMultipleDataSets]);
	
	useEffect(() => {
		if (fullName) {
			setname(fullName);
		}
	}, [fullName]);
	
	
	const [editing, setediting] = useState(false);
	const [title, setTitle] = useState([]);
	useEffect(() => {
		if (titleTags) {
			setTitle(titleTags);
		}
	}, [titleTags]);
	
	const [location, setLocation] = useState([])
	useEffect(() => {
		if (locationTags) {
			setLocation(locationTags);
		}
	}, [locationTags]);
	
	const [demographicEdit, setdemopgraphicEdit] = useState(true)
	
	const genderIdentityOptions = ["Agender", "Genderfluid", "Gender non-conforming", "Genderqueer", "Man", "Non-binary", "Woman", "Not Listed", "I choose to not disclose",
	];
	const raceOrEthnicIdentities = ["American Indian or Alaska Native", "Asian", "Black or African American", "Hispatic or Latinx", "Middle Eastern or North African", "Native Hawaiian or Other pacific Islander", "White", "I choose to not disclose",
	];
	const disabilityAnswer = ["Yes", "No", "I choose to not disclose"];
	const transgenderQuestion = ["Yes", "No", "I choose to not disclose"];
	const sexualOrientation = ["Asexual", "Bisexual", "Gay", "Heterosexual", "Lesbian", "Pansexual", "Queer", "Not Listed", "I choose to not disclose",
	];
	const veteranQuestion = ["Serving in Reserves/National Guard", "No Military Service", "Protected Veteran", "Non-Protected Veteran", "Not Listed", "I choose to not disclose",
	];
	const [isChecked, setIsChecked] = useState(false);
	const handleCheckboxChange = (event) => {
		setIsChecked(event.target.checked);
	};
	const [formData, setFormData] = useState({
		fname: "", lname: "", phone: "",
	});
	const setters = {
		agreeTermsPrivacyCheckBox: setagreeTermsPrivacyCheckBox,
		agreeToEmailOtherCommunication: setagreeToEmailOtherCommunication,
		visaRequiredFlag: setvisaRequiredFlag,
		jobTypeInternship: setjobTypeInternship,
		jobTypeFullTime: setjobTypeFullTime,
		jobTypeRemote: setjobTypeRemote,
		genderQuestionAnswer: setgenderQuestionAnswer,
		raceEthnicAnswer: setraceEthnicAnswer,
		disability: setdisability,
		transgenderExperience: settransgenderExperience,
		sexualOrientationAnswer: setsexualOrientationAnswer,
		veteranStatus: setveteranStatus,
	};
	
	function getUserAccountData() {
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios
			.post("/getUserAccountData", {
				verifiedemail: verifiedemail
			}, {
				withCredentials: true
			})
			.then((response) => {
				const userProfileDataResp = response.data["record"];
				let answeredQuestions = 0;
				let totalQuestions = 0;
				for (const [key, setValue] of Object.entries(setters)) {
					totalQuestions += 1;
					if (key in userProfileDataResp) {
						setters[key](userProfileDataResp[key]);
						answeredQuestions += 1;
					}
				}
			})
			.catch((exception) => {
				if (isLocal) {
					window.location.href = "http://localhost:3000";
				} else {
					window.location.href = 'https://applyjobs.ai';
				}
			});
	}
	
	useEffect(() => {
		getUserAccountData();
	}, []);
	
	function handleDropDownAnswers(value, fieldName) {
		setters[fieldName](value);
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post("/setUserProfileAndPreferences", {
			verifiedemail: verifiedemail, [fieldName]: value,
		}, {
			withCredentials: true
		});
	}
	
	function handleCheckBoxChange(e, fieldName) {
		let targetValue = e.target.checked;
		setters[fieldName](targetValue);
		
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post("/setUserProfileAndPreferences", {
			verifiedemail: verifiedemail, [fieldName]: targetValue,
		}, {
			withCredentials: true
		});
	}
	
	function handleProfileDataAndAnswerChange(e, fieldName) {
		e.preventDefault();
		let targetValue = e.target.value;
		setters[fieldName](targetValue);
		
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post("/setUserProfileAndPreferences", {
			verifiedemail: verifiedemail, [fieldName]: targetValue,
		}, {
			withCredentials: true
		});
	}
	
	function handleDeleteAccount() {
		const confirmDelete = window.confirm("Are you sure you want to delete user Account?");
		if (confirmDelete) {
			// DO NOT DELETE - THIS NEEDS TO BE DONE IN THE BACKEND
			//Delete firebase login
			// const user = auth.currentUser;
			// deleteUser(user)
			// 	.then(() => {
			// 		axios.defaults.baseURL = getAxiosBaseUrl();
			// 		axios.post("deleteUserAccountApplyJobs", {
			// 			verifiedemail: verifiedemail, user_email: verifiedemail, firebaseUid: firebaseUid,
			// 		}, {
			// 			withCredentials: true
			// 		});
			// 		localStorage.removeItem("quant_user");
			// 		// toast.info("Successfully deleted your account!!");
			// 		alert("Successfully deleted your account!!");
			// 		window.location.href = "https://applyjobs.ai";
			// 	})
			// 	.catch((error) => {
			// 		const errorCode = error.code;
			// 		const errorMessage = error.message;
			// 		console.log(errorCode, errorMessage);
			// 		if (error.code === "auth/requires-recent-login") {
			// 			alert("You need to re-authenticate. Logout and then login again to delete account.");
			// 			toast.error("You need to re-authenticate. Logout and then login again to delete account.");
			// 		} else {
			// 			toast.error(errorMessage);
			// 			alert(errorMessage);
			// 		}
			// 	});
		}
	}
	
	//Edit name
	const handleEdit = () => {
		setediting(true);
	};
	const handleNameEdit = (e) => {
		setUserFullName(verifiedEmailContext, name);
		e.preventDefault();
		setediting(false);
	};
	const handleNameChange = (e) => {
		setname(e.target.value);
	};
	
	//demographic edit
	const handleDemographicEdit = () => {
		setdemopgraphicEdit(false)
	}
	
	const handleChange = (e) => {
		const {name, value} = e.target;
		setFormData({
			...formData, [name]: value,
		});
	};
	const divStyle = {
		display: "flex", width: "100%",
	};
	
	const leftDivStyle = {
		padding: "10px",  // Uncommented for spacing
	};
	
	const textStyle = {
		fontSize: "13px",
		fontWeight: "bold",
		backgroundImage: "linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))",
		WebkitBackgroundClip: "text", // Note the camelCase and the Webkit prefix
		color: "transparent",
		backgroundClip: "text",
		marginTop: "10px", // display: 'flex',
	};
	const textStyle2 = {
		fontSize: "13px", // fontWeight: 'bold',
		backgroundImage: "linear-gradient(90deg, rgb(180 132 233), rgb(13 127 82), rgb(198 147 165))", WebkitBackgroundClip: "text", // Note the camelCase and the Webkit prefix
		color: "transparent", backgroundClip: "text", marginTop: "10px", // display: 'flex',
	};
	const handleSubmit = (e) => {
		// e.preventDefault();
		// Add your form submission logic here
		// console.log("Form Data Submitted:", formData);
	};
	
	return (<div className="bg-[rgba(241,204,189,0.3)]" style={{marginTop: "-10px", marginLeft: "-10px", display: "flex", width: "100vw", height:"100vh"}}>
		<div style={divStyle} className="lg:flex-row flex-col ">
			<div style={leftDivStyle} className="lg:w-[37%] md:w-[100%] w-[100%] m-[10px] p-[6px]">
				<div
					style={{
						border: "2px solid rgb(228,234,159)", borderRadius: "20px", padding: "20px", textAlign: "center", marginTop: "0px",
					}}
				>
					<div style={{display: "flex"}}>
						<ImageUpload verifiedEmail={verifiedEmailContext}/>
						<div
							style={{
								clear: "both", textAlign: "left", display: "block", fontWeight: "bold", marginTop: "20px", marginLeft: "10px",
							}}
						>
							{editing ? (<div>
								<form onSubmit={handleNameEdit}>
									<input
										className="p-2 m-2"
										value={name}
										onChange={handleNameChange}
										autoFocus
									/>
									<button type="submit">Save</button>
								</form>
							</div>) : (<p style={{marginLeft: "10px"}}>{name}</p>)}
							<div
								style={{
									clear: "both",
									display: "flex",
									fontWeight: "normal",
									marginTop: "5px",
									marginLeft: "10px",
									fontSize: "14px",
									float: "left",
								}}
							>
								{verifiedemail}
								{editing ? (<></>) : (<Tooltip title="Edit Your Name" placement="top">
									<LuPencil
										style={{
											display: "flex",
											marginTop: "-15px",
											height: "20px",
											width: "18px",
											marginLeft: "20px",
											color: "rgb(171 182 45)",
											cursor: "pointer",
										}}
										onClick={() => handleEdit()}
									/>
								</Tooltip>)}
							</div>
						</div>
					</div>
					<div
						style={{
							border: "1px solid lightgrey",
							borderRadius: "20px",
							margin: "auto",
							padding: "20px",
							maxWidth: "400px",
							marginTop: "0px",
							height: demographicEdit ? "140px" : "500px",
							textAlign: "left",
							overflowY: demographicEdit ? "auto" : "scroll",
							backgroundColor: "white",
						}}
					>
						<div className="flex justify-between">
							<p style={{fontWeight: "bold", marginLeft: "10px"}}>Demographic</p>
							{demographicEdit ? <Tooltip title="Edit Your Name" placement="top">
								<LuPencil
									style={{
										height: "20px", width: "18px", marginLeft: "20px", color: "rgb(171 182 45)", cursor: "pointer",
									}}
									onClick={() => handleDemographicEdit()}
								/>
							</Tooltip> : <button onClick={() => setdemopgraphicEdit(true)}
							                     style={{borderRadius: "10px", border: "1px solid lightgrey", padding: "2px"}}>Save</button>}
						
						</div>
						{demographicEdit ? <>
							<div className="flex justify-between items-center h-[50px]">
								<p style={{color: "grey"}}><span className="m-1"><RadarRoundedIcon/></span> Status :</p>
								
								<div className="  p-[3px]"><span>Active</span></div>
							</div>
							<div className=" flex justify-between items-center h-[50px]">
								<p style={{color: "grey"}}><span className="m-1"><WcRoundedIcon/></span>Gender : </p>
								<div className="  p-[3px]"><span>{genderQuestionAnswer}</span></div>
							</div>
						</> : <div className=" flex ">
							<form>
								<div>
									<div style={textStyle}>Visa Sponsorship:</div>
									<div
										style={{
											marginTop: "0px", textAlign: "left", display: "flex",
										}}
									>
										<input
											type="checkbox"
											checked={visaRequiredFlag}
											onChange={(e) => handleCheckBoxChange(e, "visaRequiredFlag")}
											style={{
												marginRight: "20px", marginLeft: "10px",
											}}
										/>
										<div>
											<label
												style={{textAlign: "left", fontSize: "12px"}}
											>
												I need H1B sponsorship
											</label>
										</div>
									</div>
								</div>
								<div>
									<div style={textStyle}>Job Type:</div>
									<div
										style={{
											marginTop: "0px", textAlign: "left", display: "flex",
										}}
									>
										<input
											type="checkbox"
											checked={jobTypeInternship}
											onChange={(e) => handleCheckBoxChange(e, "jobTypeInternship")}
											style={{
												marginRight: "20px", marginLeft: "10px",
											}}
										/>
										<div>
											<label
												style={{textAlign: "left", fontSize: "12px"}}
											>
												Internship
											</label>
										</div>
									</div>
									<div
										style={{
											marginTop: "0px", textAlign: "left", display: "flex",
										}}
									>
										<input
											type="checkbox"
											checked={jobTypeFullTime}
											onChange={(e) => handleCheckBoxChange(e, "jobTypeFullTime")}
											style={{
												marginRight: "20px", marginLeft: "10px",
											}}
										/>
										<div>
											<label
												style={{textAlign: "left", fontSize: "12px"}}
											>
												Full Time
											</label>
										</div>
									</div>
									
									<div
										style={{
											marginTop: "0px", textAlign: "left", display: "flex",
										}}
									>
										<input
											type="checkbox"
											checked={jobTypeRemote}
											onChange={(e) => handleCheckBoxChange(e, "jobTypeRemote")}
											style={{
												marginRight: "20px", marginLeft: "10px",
											}}
										/>
										<div>
											<label
												style={{textAlign: "left", fontSize: "12px"}}
											>
												Remote / Offsite
											</label>
										</div>
									</div>
								</div>
								<div>
									<div style={{marginTop: "10px"}}>
										What gender identity do you most closely identify with?
									</div>
									<DropdownList
										id="resumeNameForCustomJob"
										labelText=""
										options={genderIdentityOptions}
										selectedValue={genderQuestionAnswer}
										setSelectedValue={(e) => handleDropDownAnswers(e, "genderQuestionAnswer")}
										defaultValue="Please Select"
									/>
								</div>
								<div>
									<div style={{paddingTop: "10px"}}>
										Please select the race and/or ethnic identities you
										identify with
									</div>
									<DropdownList
										id="resumeNameForCustomJob"
										labelText=""
										options={raceOrEthnicIdentities}
										selectedValue={raceEthnicAnswer}
										setSelectedValue={(e) => handleDropDownAnswers(e, "raceEthnicAnswer")}
										defaultValue="Please Select"
									/>
								</div>
								<div>
									<div style={{paddingTop: "10px"}}>
										Do you live with a disability (as outlined by the ADA)?
									</div>
									<DropdownList
										id="resumeNameForCustomJob"
										labelText=""
										options={disabilityAnswer}
										selectedValue={disability}
										setSelectedValue={(e) => handleDropDownAnswers(e, "disability")}
										defaultValue="Please Select"
									/>
								</div>
								<div>
									<div style={{paddingTop: "10px"}}>
										Are you a person of transgender experience?
									</div>
									<DropdownList
										id="resumeNameForCustomJob"
										labelText=""
										options={transgenderQuestion}
										selectedValue={transgenderExperience}
										setSelectedValue={(e) => handleDropDownAnswers(e, "transgenderExperience")}
										defaultValue="Please Select"
									/>
								</div>
								<div>
									<div style={{paddingTop: "10px"}}>
										What sexual orientation do you most closely identify
										with?
									</div>
									<DropdownList
										id="sexualOrientation"
										labelText=""
										options={sexualOrientation}
										selectedValue={sexualOrientationAnswer}
										setSelectedValue={(e) => handleDropDownAnswers(e, "sexualOrientationAnswer")}
										defaultValue="Please Select"
									/>
								</div>
								<div>
									<div style={{paddingTop: "10px"}}>
										Are you a veteran/have you served in the military?
									</div>
									<DropdownList
										id="veteranQuestion"
										labelText=""
										options={veteranQuestion}
										selectedValue={veteranStatus}
										setSelectedValue={(e) => handleDropDownAnswers(e, "veteranStatus")}
										defaultValue="Please Select"
									/>
								</div>
							</form>
						</div>}
					
					</div>
					<form onSubmit={handleSubmit}>
						<div
							style={{
								marginTop: "30px", textAlign: "left", display: "flex",
							}}
						>
							<input
								type="checkbox"
								checked={agreeTermsPrivacyCheckBox}
								onChange={(e) => handleCheckBoxChange(e, "agreeTermsPrivacyCheckBox")}
								style={{
									marginRight: "20px", marginLeft: "10px",
								}}
							/>
							<div>
								<label style={{textAlign: "left", fontSize: "12px"}}>
									By signing up you agree to ApplyJobs'
									<span
										style={{
											color: "blue", textDecoration: "underline", marginRight: "5px",
										}}
									>
                        {" "}
										<a
											href="https://applyjobs.ai/TermsOfService"
											target="_blank"
											rel="noopener noreferrer"
										>
                          Terms of Service
                        </a>
                      </span>
									and{" "}
									<span
										style={{
											color: "blue", textDecoration: "underline", marginRight: "5px",
										}}
									>
                        {" "}
										<a
											href="https://applyjobs.ai/TermsOfService"
											target="_blank"
											rel="noopener noreferrer"
										>
                          Privacy Policy
                        </a>
                      </span>
									, which outline your rights and obligations with respect
									to your use of the Service and processing of your data.
								</label>
							</div>
						</div>
						<div
							style={{
								marginTop: "10px", textAlign: "left", display: "flex",
							}}
						>
							<input
								type="checkbox"
								checked={agreeToEmailOtherCommunication}
								onChange={(e) => handleCheckBoxChange(e, "agreeToEmailOtherCommunication")}
								style={{
									marginRight: "20px", marginLeft: "10px",
								}}
							/>
							<div>
								<label style={{textAlign: "left", fontSize: "12px"}}>
									You agree to receive subsequent email and third-party
									communications, which you may opt out of, or unsubscribe
									from, at any time. ApplyJobs is committed to protecting
									your data privacy. Permissions are part of our continuing
									compliance efforts. We will provide reasonable
									accommodations with regard to use of this site. If you
									require an accommodation while using our site, please
									contact admin@mailchamp.ai to request assistance.
								</label>
							</div>
						</div>
					</form>
				</div>
				<div style={{display: "flex", marginTop: "50px"}}>
					<p style={{color: "grey"}}>Danger Zone</p>
					<hr/>
					<Button
						style={{
							border: "1px solid", height: "25px", textTransform: "none", marginLeft: "10px",
						}}
						disabled={false}
						onClick={handleDeleteAccount}
					>
						Delete Account
					</Button>
				</div>
			</div>
			<div style={{border: "2px solid rgb(228,234,159)", borderRadius: "20px"}}
			     className="w-[90%] m-[10px] h-[40vh] mt-[20px] p-[20px] flex flex-col items-center ">
				<div className="h-[170px] w-[90%]">
					<p>Title</p>
					<TagsInput initialTags={title} onTagsChanged={setTitle}/>
				</div>
				<div className="h-[170px] w-[90%]">
					<p>Location</p>
					<TagsInput initialTags={location} onTagsChanged={setLocation}/>
				</div>
			</div>
		</div>
		
		<div style={{marginTop: "20px"}}></div>
	</div>);
}
