import React, {useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Mousewheel, FreeMode} from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import SpecialPreview from "./SpecialPreview"; // Import your special preview component
import Modal from './Modal'; // Import the Modal component

const CardCarousel = ({verifiedEmail, folder, numRows, notePress, fltrdSet, previewsMap, hoverPreviewChecked}) => {
	
	const containerWidth = 1200; // Adjust based on the container width
	const cardWidth = 220; // Width of each card
	const hoverTriggerWaitTime = 800; // 200ms for hover trigger
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [hoverPosition, setHoverPosition] = useState({left: 0, top: 0});
	const hoverTimeout = useRef(null);
	
	const [debugBounds, setDebugBounds] = useState(null);
	const [modalBounds, setModalBounds] = useState(null);
	
	const handleMouseEnterCard = (index, event) => {
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;
		
		const rect = event.currentTarget.getBoundingClientRect();
		const cardTop = rect.top;
		const cardLeft = rect.left;
		const cardBottom = rect.bottom;
		const cardRight = rect.right;
		const cardWidth = rect.width;
		const cardHeight = rect.height;
		
		setDebugBounds({x: cardLeft, y: cardTop, width: cardWidth, height: cardHeight});
		
		// Calculate the areas
		const topArea = cardTop * viewportWidth;
		const bottomArea = (viewportHeight - cardBottom) * viewportWidth;
		const leftArea = cardLeft * viewportHeight;
		const rightArea = (viewportWidth - cardRight) * viewportHeight;
		
		// Find the largest area
		const areas = [
			{name: 'top', value: topArea, style: {top: 0, left: 0, width: '100%', height: cardTop - 5}},
			{name: 'bottom', value: bottomArea, style: {top: cardBottom, left: 0, width: '100%', height: viewportHeight - cardBottom}},
			{name: 'left', value: leftArea, style: {top: 0, left: 0, width: cardLeft - 5, height: '100%'}},
			{name: 'right', value: rightArea, style: {top: 0, left: cardRight, width: viewportWidth - cardRight, height: '100%'}},
		];
		
		const largestArea = areas.reduce((prev, current) => (prev.value > current.value ? prev : current));
		
		setHoverPosition(largestArea.style);
		setModalBounds({
			x: largestArea.style.left,
			y: largestArea.style.top,
			width: largestArea.style.width,
			height: largestArea.style.height
		});
		
		hoverTimeout.current = setTimeout(() => {
			setHoveredIndex(index);
		}, hoverTriggerWaitTime);
	};
	
	const handleMouseLeaveCard = () => {
		clearTimeout(hoverTimeout.current);
		setHoveredIndex(null);
		setDebugBounds(null);
		setModalBounds(null);
	};
	
	const handleMouseEnterModal = () => {
		clearTimeout(hoverTimeout.current);
	};
	
	const handleMouseLeaveModal = () => {
		setHoveredIndex(null);
	};
	
	// Calculate how many items can fit in a single row
	const maxItemsPerRow = Math.floor(containerWidth / cardWidth);
	const itemsPerRow = Math.min(maxItemsPerRow, folder.children ? folder.children.length : 0);
	
	// try{
	//   console.log(hoveredIndex, previewsMap[folder.children[hoveredIndex].key.split('note-')[1]])
	// }catch (e){
	//
	// }
	
	const previewCards = folder.children ? folder.children.map((note, index) => {
		const specialNote = fltrdSet.find(filteredNote => filteredNote.docid === note.key);
		
		const sharedOutgoingOrIncoming = specialNote?.shared ?
			verifiedEmail == specialNote['email'] ? 'outgoing' : 'incoming' : null;
		
		let mappedImgObj = null;
		
		if (specialNote && specialNote.docid) {
			mappedImgObj = previewsMap[specialNote.docid.split('note-')[1]]
		}
		
		return (
			<div
				key={index}
				style={{
					fontSize: "12px",
					width: `${cardWidth}px`,
					boxSizing: "border-box",
					marginBottom: "10px",
					marginRight: "5px",
					position: "relative",
					overflow: "scroll"
				}}
				onMouseEnter={(e) => handleMouseEnterCard(index, e)}
				onMouseLeave={handleMouseLeaveCard}
			>
				{specialNote ? (
					<SpecialPreview
						id={specialNote.docid}
						index={index}
						title={specialNote.title}
						noteKey={specialNote.docid}
						mappedImgObj={mappedImgObj}
						cardWidth={cardWidth}
						notePress={notePress}
                        sharedOutgoingOrIncoming={sharedOutgoingOrIncoming}
					/>
				) : <div/>}
			</div>
		);
	}) : [];
	
	// Split cards into rows
	const splitCardsIntoRows = (cards, itemsPerRow) => {
		const rows = [];
		for (let i = 0; i < cards.length; i += itemsPerRow) {
			rows.push(cards.slice(i, i + itemsPerRow));
		}
		return rows;
	};
	
	const rows = splitCardsIntoRows(previewCards, itemsPerRow);
	
	// Combine rows into slides with the correct number of rows per slide
	const slides = [];
	for (let i = 0; i < rows.length; i += numRows) {
		slides.push(rows.slice(i, i + numRows));
	}
	
	return (
		<div style={{
			position: "relative",
			maxWidth: `${containerWidth}px`,
			marginTop: "5px",
			overflow: "hidden",
			paddingLeft: "10px",
			paddingTop: "10px"
		}}>
			<Swiper
				modules={[Pagination, Mousewheel, FreeMode]}
				spaceBetween={10}
				slidesPerView="auto"
				freeMode={true}
				pagination={{clickable: true}}
				mousewheel={{forceToAxis: true}}
				style={{overflow: "hidden", maxHeight: "500px"}}
			>
				{slides.map((slideRows, slideIndex) => (
					<SwiperSlide key={slideIndex}>
						{slideRows.map((row, rowIndex) => (
							
							<div key={rowIndex} style={{display: "flex", flexWrap: "wrap", width: "100%"}}>
								{row}
							</div>
						))}
					</SwiperSlide>
				))}
			</Swiper>
			
			{hoverPreviewChecked && hoveredIndex !== null && previewsMap[folder.children[hoveredIndex].key.split('note-')[1]] && (
				<Modal
					src={URL.createObjectURL(previewsMap[folder.children[hoveredIndex].key.split('note-')[1]])}
					onClose={handleMouseLeaveModal}
					onMouseEnter={handleMouseEnterModal}
					position={hoverPosition} // Pass the position to the modal
				/>
			)}
			
			{/*/!* Debug rectangles *!/*/}
			{/*{debugBounds && (*/}
			{/*  <div style={{*/}
			{/*    position: 'fixed',*/}
			{/*    top: debugBounds.y,*/}
			{/*    left: debugBounds.x,*/}
			{/*    width: debugBounds.width,*/}
			{/*    height: debugBounds.height,*/}
			{/*    border: '2px solid lightgrey',*/}
			{/*    borderRadius:"10px",*/}
			{/*    // color: 'red',*/}
			{/*    zIndex: 9999,*/}
			{/*    pointerEvents: 'none'*/}
			{/*  }}>*/}
			{/*    /!*<div style={{ position: 'absolute', top: -20, left: 0 }}>x: {debugBounds.x}, y: {debugBounds.y}</div>*!/*/}
			{/*    /!*<div style={{ position: 'absolute', top: -20, right: 0 }}>w: {debugBounds.width}, h: {debugBounds.height}</div>*!/*/}
			{/*  </div>*/}
			{/*)}*/}
			
			{/*DO NOT DELETE, THIS IS HELPFUL DEBUG CODE TO SHOW WHICH SIDE THE PREVIEW IS SHOWN*/}
			{/*{modalBounds && (*/}
			{/*  <div style={{*/}
			{/*    position: 'fixed',*/}
			{/*    top: modalBounds.y,*/}
			{/*    left: modalBounds.x,*/}
			{/*    width: modalBounds.width,*/}
			{/*    height: modalBounds.height,*/}
			{/*    border: '1px solid blue',*/}
			{/*    color: 'blue',*/}
			{/*    backgroundColor:"lightgrey",*/}
			{/*    zIndex: 9998,*/}
			{/*    pointerEvents: 'none'*/}
			{/*  }}>*/}
			{/*    <div style={{ position: 'absolute', top: -20, left: 0 }}>x: {modalBounds.x}, y: {modalBounds.y}</div>*/}
			{/*    <div style={{ position: 'absolute', top: -20, right: 0 }}>w: {modalBounds.width}, h: {modalBounds.height}</div>*/}
			{/*  </div>*/}
			{/*)}*/}
		</div>
	);
};

export default CardCarousel;
