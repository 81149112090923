
function PrivacyNotice() {
  return (
    <div className="mt-40 ml-20 mr-20 flex justify-center">
      <div className="max-w-3xl text-center">
        <h1 className="text-4xl font-bold mb-6 text-gray-800">Privacy Policy</h1>
        <p className="text-sm text-gray-500 mb-4">Last Updated: 2024-06-01</p>
        <p className="text-gray-600 mb-6">
          Welcome to AceHub.ai, a product of Neugence Technology Pvt. Ltd. (“we,” “us,” “our”). This Privacy Policy
          explains how we collect, use, share, and protect your personal data when you use our website, services, and
          applications (collectively, the “Services”). Your privacy is important to us, and we are committed to ensuring
          that your personal data is handled securely and transparently.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">1. Information We Collect</h2>
        <p className="text-gray-600 mb-4">We collect information from you directly, automatically, and through third parties:</p>
        <ul className="list-disc list-inside text-left text-gray-600 mb-6">
          <li>
            <strong>Personal Information:</strong> Information you provide directly, such as your name, email address,
            phone number, job title, and company details.
          </li>
          <li>
            <strong>Device and Usage Data:</strong> Information about your device, including IP address, browser type,
            operating system, device identifiers, and information about how you use our Services.
          </li>
          <li>
            <strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and other technologies to
            collect data about your use of the Services and to improve user experience. You can control cookies through
            your browser settings.
          </li>
          <li>
            <strong>Location Data:</strong> With your permission, we may collect data about your geographical location to
            provide location-based services.
          </li>
          <li>
            <strong>Analytics Data:</strong> Information gathered from analytics services, such as Google Analytics, to
            help us understand usage patterns and improve our Services.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">2. How We Use Your Information</h2>
        <p className="text-gray-600 mb-4">We use your personal data for the following purposes:</p>
        <ul className="list-disc list-inside text-left text-gray-600 mb-6">
          <li>
            <strong>Providing Services:</strong> To provide, maintain, and improve our Services, including personalizing
            your experience and processing transactions.
          </li>
          <li>
            <strong>Communication:</strong> To communicate with you about updates, promotions, security alerts, support
            messages, and other relevant information.
          </li>
          <li>
            <strong>Analytics and Improvements:</strong> To analyze data to improve our Services, troubleshoot issues, and
            enhance user experience.
          </li>
          <li>
            <strong>Security and Compliance:</strong> To detect, prevent, and respond to fraud, unauthorized access, legal
            requests, and compliance with applicable laws.
          </li>
          <li>
            <strong>Marketing:</strong> To send promotional content based on your preferences. You can opt-out at any
            time.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">3. How We Share Your Information</h2>
        <p className="text-gray-600 mb-4">We do not sell your personal data. We may share your data in the following circumstances:</p>
        <ul className="list-disc list-inside text-left text-gray-600 mb-6">
          <li>
            <strong>Service Providers:</strong> With trusted third-party service providers who assist us in operating our
            Services, such as payment processors, cloud storage providers, and analytics services.
          </li>
          <li>
            <strong>Legal Compliance:</strong> When required by law, regulation, or legal request, we may disclose your
            data to comply with legal obligations.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your personal
            data may be transferred to the new owner.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">4. Data Storage and Security</h2>
        <ul className="list-disc list-inside text-left text-gray-600 mb-6">
          <li>
            <strong>Data Storage:</strong> Your data may be stored on servers located in various countries. We ensure that
            data is stored securely and in compliance with applicable data protection laws.
          </li>
          <li>
            <strong>Security Measures:</strong> We use industry-standard security measures, including encryption,
            firewalls, and access controls, to protect your data. However, no method of transmission over the internet is
            100% secure.
          </li>
          <li>
            <strong>Data Retention:</strong> We retain your personal data only as long as necessary for the purposes
            outlined in this policy or as required by law.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">5. Your Rights</h2>
        <p className="text-gray-600 mb-4">Depending on your location, you have rights under applicable privacy laws, including:</p>
        <ul className="list-disc list-inside text-left text-gray-600 mb-6">
          <li><strong>Access:</strong> Request access to your personal data.</li>
          <li><strong>Correction:</strong> Request correction of inaccurate or incomplete data.</li>
          <li><strong>Deletion:</strong> Request deletion of your personal data.</li>
          <li>
            <strong>Objection and Restriction:</strong> Object to or request the restriction of processing your data.
          </li>
          <li>
            <strong>Portability:</strong> Request a copy of your personal data in a structured, commonly used format.
          </li>
          <li>
            <strong>Withdraw Consent:</strong> Withdraw your consent for data processing at any time.
          </li>
        </ul>
        <p className="text-gray-600 mb-6">
          To exercise these rights, please contact us at{' '}
          <a href="mailto:contact@neugence.ai" className="text-blue-500">contact@neugence.ai</a>.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">6. International Data Transfers</h2>
        <p className="text-gray-600 mb-6">
          Your data may be transferred to, stored, and processed in countries outside of your country of residence. We
          ensure that such transfers comply with applicable data protection laws and that appropriate safeguards are in
          place.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">7. Cookies and Tracking Technologies</h2>
        <p className="text-gray-600 mb-6">
          Our Services use cookies and similar technologies to enhance user experience and collect data. You can control
          cookies through your browser settings, but disabling them may affect your use of the Services.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">8. Children’s Privacy</h2>
        <p className="text-gray-600 mb-6">
          Our Services are not intended for children under 13 years of age. We do not knowingly collect personal data from
          children under 13. If you believe we have collected such data, please contact us immediately.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">9. Changes to This Privacy Policy</h2>
        <p className="text-gray-600 mb-6">
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
          We will notify you of any significant changes through our Services or via email.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">10. Contact Us</h2>
        <p className="text-gray-600 mb-4">
          If you have any questions, concerns, or complaints about this Privacy Policy, please contact us at:
        </p>
        <ul className="list-none text-gray-600 mb-6">
          <li>Email: <a href="mailto:contact@neugence.ai" className="text-blue-500">contact@neugence.ai</a></li>
          <li>Address: Neugence Technology Pvt. Ltd., HNo. 290, Sector 9, Bahadurgarh, Haryana, IN</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">11. Cookie Policy</h2>
        <p className="text-gray-600 mb-4">
          We use cookies and similar technologies to enhance your experience on AceHub.ai. Cookies are small text files
          that are stored on your device when you access our Services. They help us recognize your preferences, improve
          functionality, and provide personalized content.
        </p>
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Types of Cookies We Use</h3>
        <ul className="list-disc list-inside text-left text-gray-600 mb-6">
          <li>
            <strong>Essential Cookies:</strong> These cookies are necessary for the proper functioning of our Services,
            allowing you to navigate and use essential features.
          </li>
          <li>
            <strong>Performance and Analytics Cookies:</strong> These cookies collect information about how you use our
            Services, such as which pages you visit most often. This data helps us improve the functionality and
            performance of our Services.
          </li>
          <li>
            <strong>Functional Cookies:</strong> These cookies enable us to remember your preferences and provide enhanced
            features, such as remembering login details and language settings.
          </li>
          <li>
            <strong>Targeting and Advertising Cookies:</strong> These cookies are used to deliver advertisements that are
            relevant to your interests and to measure the effectiveness of ad campaigns.
          </li>
        </ul>
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Managing Cookies</h3>
        <p className="text-gray-600 mb-6">
          You can manage your cookie preferences through your browser settings. Most browsers allow you to refuse cookies
          or alert you when cookies are being sent. However, disabling cookies may impact your experience and limit the
          functionality of our Services.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Acceptance</h2>
        <p className="text-gray-600 mb-6">
          By using our Services, you acknowledge that you have read, understood, and agree to this Privacy and Cookie
          Policy. If you do not agree with this policy, please do not use our Services.
        </p>
      </div>
    </div>
  );
}

export default PrivacyNotice;
