import create from "zustand";
import axios from "axios";
import { getAxiosBaseUrl } from "../../../config";

axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;
axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;
// Blog also means Article ... means default it's private / personal.
const blogsState = create((set, get) => ({
  authorModalOpen: false,
  userEmail: "",
  blogName:"",
  blogContent:"",
  blogTags:[],
  blogId:"",
  setState: (key, value) => {
    set((state) => ({ ...state, [key]: value }));
  },
  AuthorModalOpen: (value) => {
    set({ authorModalOpen: value });
  },
  setBlogName: (name) => {
    set({ blogName: name });
  },
  setBlogContent: (content) => {
    set({ blogContent: content });
  },
  setBlogTags: (tags) => {
    set({ blogTags: tags });
  },
  setBlogId: (Id) => {
    set({ blogId: Id });
  },
}));
export default blogsState;
