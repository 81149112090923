var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FaPlay } from "react-icons/fa";
import "./Ribbon.css";
export default function CourseCard(_a) {
    var course = _a.course, onClickSelfStudy = _a.onClickSelfStudy, onClickGroupClass = _a.onClickGroupClass;
    var id = course.id, image = course.image, stats = course.stats, background = course.background, progress = course.progress, hours = course.hours, title = course.title, description = course.description, level = course.level;
    var handleSelfStudyClick = function () {
        if (onClickSelfStudy && id) {
            onClickSelfStudy(id);
        }
    };
    var handleGroupClassClick = function (course) {
        if (onClickGroupClass && course) {
            onClickGroupClass(course);
        }
    };
    return (_jsxs("div", __assign({ className: "ribbon select-none cursor-default shadow-md hover:brightness-110 duration-150 hover:shadow-xl hover:bg-[linear-gradient(rgba(255,255,160,0.1),rgba(0,0,150,0.9))] transition-all bg-origin-content rounded-3xl max-w-[350px] p-0 flex flex-col justify-between min-h-64 min-w-80", style: {
            backgroundImage: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.4)), url(".concat(image, ")"),
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        } }, { children: [_jsx("div", __assign({ className: "px-5 py-4 flex flex-row items-center gap-4 justify-between text-white" }, { children: _jsxs("div", __assign({ className: "flex flex-col gap-1 min-w-52" }, { children: [_jsx("h1", __assign({ className: "font-semibold text-3xl pt-0 pl-0", style: { marginTop: "0px", textAlign: "left" } }, { children: title })), _jsx("h3", __assign({ className: "font-semibold text-sm text-gray-300 text-left" }, { children: description }))] })) })), _jsx("div", __assign({ className: "bg-white shadow-[inset_0_-2px_5px_rgba(0,0,0,0.15)] min-h-[60px] items-center justify-between rounded-b-[20px] pl-3 pr-2 py-2 flex" }, { children: _jsx("div", __assign({ className: "flex flex-col gap-2 px-1 py-0.5" }, { children: _jsxs("div", __assign({ className: "flex flex-row gap-8" }, { children: [_jsxs("div", __assign({ className: "flex flex-col items-center justify-center gap-2" }, { children: [_jsx("span", __assign({ className: "text-color3 font-semibold", style: { fontSize: "30px" } }, { children: stats.sections })), _jsx("h4", __assign({ className: "text-[#FA9C10] font-medium text-sm" }, { children: "Chapters" }))] })), _jsxs("div", __assign({ className: "flex flex-col items-center justify-center gap-2" }, { children: [_jsx("span", __assign({ className: "text-color3 font-semibold", style: { fontSize: "30px" } }, { children: stats.chapters })), _jsx("h4", __assign({ className: "text-[#FA9C10] font-medium text-sm" }, { children: "Items" }))] })), _jsxs("button", __assign({ onClick: handleSelfStudyClick, 
                                // onClick={() =>
                                //   {link ? window.open(`${link}`):handleSelfStudyClick}
                                // }
                                className: "pt-0 rounded-[15px] pl-10" }, { children: [_jsxs("div", __assign({ className: "pl-0 rounded-xl bg-white aspect-square text-black w-16 h-18 flex items-center justify-center flex-col gap-1", style: { marginTop: "-27px", border: "4px solid lightgrey" } }, { children: [_jsx(FaPlay, { style: { color: "black", marginTop: "10px" } }), _jsx("span", __assign({ style: { color: "black", fontSize: "12px", marginTop: "0px" } }, { children: "Start" }))] })), _jsx("h3", __assign({ className: "font-semibold text-green-900 text-sm text-center mt-2 ml-0 pl-0", style: { marginLeft: "-10px" } }, { children: level }))] }))] })) })) }))] })));
}
