import React from "react";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
const projectsData = [
  {
    module: "Module 1",
    totalLessons: 178,
    hours: "21 Hours of Video",
    projects: [
      "Linear Regression Model Project in Python for Beginners Part 1",
      "Linear Regression Model Project in Python for Beginners Part 2",
      "Learn to Build a Polynomial Regression Model from Scratch",
      "Build Regression Models in Python for House Price Prediction",
      "Classification Projects on Machine Learning for Beginners - 1",
      "Topic modelling using Kmeans clustering to group customer reviews",
      "Build a Logistic Regression Model in Python from Scratch",
      "Build a Customer Churn Prediction Model using Decision Trees",
      "Build a Churn Prediction Model using Ensemble Learning",
      "Isolation Forest Model and LOF for Anomaly Detection in Python",
    ],
  },
  {
    module: "Module 2",
    totalLessons: 96,
    hours: "11 Hours of Video",
    projects: [
      "Learn to Build an End-to-End Machine Learning Pipeline - Part 1",
      "House Price Prediction Project using Machine Learning in Python",
      "Build a Logistic Regression Model in Python from Scratch",
      "Loan Eligibility Prediction in Python using H2O.ai",
    ],
  },
  {
    module: "Module 3",
    totalLessons: 90,
    hours: "7 Hours of Video",
    projects: [
      "Build a Logistic Regression Model in Python from Scratch",
      "Build a Customer Churn Prediction Model using Decision Trees",
      "Build a Churn Prediction Model using Ensemble Learning",
      "Loan Default Prediction Project using Explainable AI ML Models",
    ],
  },
  {
    module: "Module 4",
    totalLessons: 32,
    hours: "4 Hours of Video",
    projects: [
      "Build a Churn Prediction Model using Ensemble Learning",
      "Classification Projects on Machine Learning for Beginners - 2",
    ],
  },
  {
    module: "Module 5",
    totalLessons: 150,
    hours: "16 Hours of Video",
    projects: [
      "Deep Learning Project for Beginners with Source Code Part 1",
      "Learn to Build a Neural Network from Scratch using NumPy",
      "Build a Text Classification Model with Attention Mechanism NLP",
      "Build Multi-Class Text Classification Models with RNN and LSTM",
      "Stock Price Prediction Project using LSTM and RNN",
      "Multi-Class Text Classification with Deep Learning using BERT",
      "Text Classification with Transformers-RoBERTa and XLNet Model",
      "BERT Text Classification using DistilBERT and ALBERT Models",
    ],
  },
  {
    module: "Module 6",
    totalLessons: 87,
    hours: "12 Hours of Video",
    projects: [
      "Learn to Build an End-to-End Machine Learning Pipeline - Part 2",
      "Learn to Build an End-to-End Machine Learning Pipeline - Part 3",
      "End-to-End ML Model Monitoring using Airflow and Docker",
    ],
  },
];

const MachineLearningProjects = () => {
  return (
    <div className="container flex flex-col my-10 mx-auto py-10 w-10/12">
      <h1 className="text-3xl font-bold mb-10 text-center">
        <span
          style={{
            background:
              "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Machine Learning Other Projects{" "}
        </span>
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projectsData.map((module, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg p-6 transition-transform duration-300 hover:shadow-lg"
          >
            <h2 className="text-xl font-semibold mb-2 ">
              {module.module} <br />
              <PlayCircleFilledWhiteOutlinedIcon /> {module.totalLessons}{" "}
              Lessons
              <br />
              <HourglassTopTwoToneIcon /> {module.hours}
            </h2>
            <hr />
            <ul className="list-disc pl-5 text-gray-700 mt-4">
              {module.projects.map((project, idx) => (
                <div className="flex gap-4 mb-2 cursor-pointer transition-all duration-300 hover:scale-[1.05]">
                  <SpaceDashboardRoundedIcon />{" "}
                  <div key={idx} className="">
                    {project}
                  </div>
                </div>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="mt-8 text-center">
        <button className="px-6 py-3 bg-color2 text-white font-semibold rounded-lg hover:bg-color3 transition">
          Start Now
        </button>
      </div>
    </div>
  );
};

export default MachineLearningProjects;
