export const dummyAuthorData={
    email: "new.author@example.com",
    name: "Name",
    profilePhoto: "https://via.placeholder.com/150",
    bio: "Auto-filled author profile.",
    About_me: "Auto-filled experience.",
    socials: {
      linkedin: `https://linkedin.com/in/`,
      github: "https://github.com/newauthor",
      instagram: "https://instagram.com/newauthor",
      facebook: "https://facebook.com/newauthor",
    },
    projects: [
      {
        name: "New Project 1",
        description: "Description of the new project 1.",
      },
      {
        name: "New Project 2",
        description: "Description of the new project 2.",
      },
      
    ],
    course : {
        id: "course_01",
        title: "Full-Stack Web Development",
        description: "Learn to build dynamic web applications with React and Node.js.",
        level: "Intermediate",
        image: "https://images.unsplash.com/photo-1528965051322-6d335c69eb86?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHNwbGFzaHxlbnwwfHwwfHx8MA%3D%3D",
        stats: {
          sections: 12,
          chapters: 48,
          projects: 5,
          certifications: 2,
        },
        hours: 120,
        progress: 75,
        background: "background-gradient-to-r from-blue-500 to-indigo-500",
      },
    pastExperience: [
      {
        role: "Frontend Developer",
        company: "Company A",
        duration: "2 years",
      },
      {
        role: "Backend Developer",
        company: "Company B",
        duration: "3 years",
      },
      {
        role: "Frontend Developer",
        company: "Company A",
        duration: "2 years",
      },
    ],
  }
