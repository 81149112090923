import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

// Import icons from Heroicons
import { PlusIcon, TrashIcon, PencilIcon } from "@heroicons/react/solid";
import VisibilityIcon from "@mui/icons-material/Visibility";
// Sample data
import { menudata } from "./menudata";
import { Snackbar, Tooltip } from "@mui/material";
import useMenuStore from "./store/MenuEditorStore";

const MenuEditor = () => {
  // Transform the nested tree data into a flat list
  const {HomeMenuData, updatemenudata, fetchMenuData } = useMenuStore((state) => ({
    HomeMenuData:state.HomeMenuData,
    updatemenudata: state.updatemenudata,
    fetchMenuData: state.fetchMenuData,
  }));

  // will be used to fetch MenuData
  // useEffect(() => {
  //   fetchMenuData("verifiedemail");
  // }, []);
  const isNotEmpty = (obj) => Object.keys(obj).length > 0;
  const [MenuData, setMenuData] = useState(
    isNotEmpty(HomeMenuData) ? HomeMenuData : menudata
  );

  const [treeData, setTreeData] = useState(
    flattenTreeFromObject(menudata.menudata)
  );

  useEffect(() => {
    const newMenuData = NewtransformToTreeData(treeData);
    console.log(treeData, "treeData new");
    setMenuData((prevMenuData) => ({
      ...prevMenuData,
      menudata: newMenuData,
    }));
  }, [treeData]);

  useEffect(() => {
    console.log(MenuData, "tree new");
    // updatemenudata("verifiedemail",MenuData);
  }, [MenuData]);

  // State for modal visibility and current node being edited
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);

  const [levelNode, setLevelNode] = useState(0);
  // Handle drag and drop
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    const newTreeData = Array.from(treeData);
    const [movedItem] = newTreeData.splice(source.index, 1);
    newTreeData.splice(destination.index, 0, movedItem);

    setTreeData(newTreeData);
  };

  // Function to add a new node
  const addNode = (parentId) => {
    const newNode = {
      id: `node-${Date.now()}`,
      title: "New Item",
      parentId: parentId,
      expanded: true,
      data: {},
    };
    setTreeData([...treeData, newNode]);
  };

  // Function to remove a node with confirmation
  const removeNode = (nodeId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this node and all its descendants?"
      )
    ) {
      // Remove node and all its descendants
      const nodesToRemove = getDescendants(treeData, nodeId);
      setTreeData(treeData.filter((node) => !nodesToRemove.includes(node.id)));
    }
  };

  const updateNode = (updatedNode) => {
    // Function to update a node's data
    setTreeData(
      treeData.map((node) => (node.id === updatedNode.id ? updatedNode : node))
    );
    
  };

  const toggleNode = (nodeId) => {
    // Function to toggle node expansion
    setTreeData(
      treeData.map((node) =>
        node.id === nodeId ? { ...node, expanded: !node.expanded } : node
      )
    );
  };

  const expandAll = () => {
    // Function to expand all nodes
    setTreeData(treeData.map((node) => ({ ...node, expanded: true })));
  };

  const collapseAll = () => {
    // Function to collapse all nodes
    setTreeData(treeData.map((node) => ({ ...node, expanded: false })));
  };

  const checkSlug = () => {
    console.log("data");
  };

  // Define text colors for different levels
  const levelColors = [
    "black", // Level 0
    "darkblue", // Level 1
    "darkgreen", // Level 2
    "darkred", // Level 3
    "darkorange", // Level 4
    "purple", // Level 5
  ];

  // Render tree nodes
  const renderTree = (nodes, parentId = null, level = 0) => {
    return nodes
      .filter((node) => node.parentId === parentId)
      .map((node, index) => (
        <React.Fragment key={node.id}>
          <Draggable draggableId={node.id} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{
                  paddingLeft: level * 20 + "px",
                  display: "flex",
                  alignItems: "center",
                  ...provided.draggableProps.style,
                }}
              >
                <span
                  {...provided.dragHandleProps}
                  style={{ marginRight: "5px", cursor: "grab" }}
                >
                  &#9776;
                </span>
                {hasChildren(nodes, node.id) && (
                  <button onClick={() => toggleNode(node.id)}>
                    {node.expanded ? "^" : ">"}
                  </button>
                )}
                <span
                  onClick={() => toggleNode(node.id)}
                  style={{
                    marginLeft: "5px",
                    color: levelColors[level % levelColors.length],
                    cursor: "pointer",
                  }}
                >
                  <Tooltip
                    title={`title :${node.title} , slug:${
                      node.data?.slug || "no_slug "
                    } , description:${
                      node.data?.description || "no_desc"
                    } , premium:${node.data?.premium || " false"}`}
                  >
                    {node.title}
                  </Tooltip>
                </span>
                <button
                  onClick={() => {
                    setCurrentNode(node);
                    setLevelNode(level);
                    setIsModalOpen(true);
                  }}
                  style={{
                    marginLeft: "5px",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                  aria-label="Edit"
                >
                  <PencilIcon
                    style={{
                      width: "16px",
                      height: "16px",
                      color: "lightgrey",
                    }}
                  />
                </button>
                {level < 2 && (
                  <button
                    onClick={() => addNode(node.id)}
                    style={{
                      marginLeft: "5px",
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                    }}
                    aria-label="Add Child"
                  >
                    <PlusIcon
                      style={{ width: "16px", height: "16px", color: "grey" }}
                    />
                  </button>
                )}
                <button
                  onClick={() => removeNode(node.id)}
                  style={{
                    marginLeft: "5px",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                  aria-label="Remove"
                >
                  <TrashIcon
                    style={{
                      width: "16px",
                      height: "16px",
                      color: "lightgrey",
                    }}
                  />
                </button>
              </div>
            )}
          </Draggable>
          {node.expanded && renderTree(nodes, node.id, level + 1)}
        </React.Fragment>
      ));
  };

  // Modal component for editing node properties
  const Modal = ({ node, onClose }) => {
    const [title, setTitle] = useState(node.title || "");
    const [slug, setSlug] = useState(node.data.slug || "");
    const [premium, setPremium] = useState(node.data.premium || false);
    const [description, setDescription] = useState(node.data.description || "");

    const handleSubmit = (e) => {
      e.preventDefault();
      const updatedNode = {
        ...node,
        title,
        data: {
          ...node.data,
          slug,
          premium,
          description,
        },
      };
      updateNode(updatedNode);
      onClose();
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-gray-100 p-6 rounded-lg w-[450px] shadow-lg">
          <h2 className="text-2xl text-gray-800 mb-2">
            Edit{" "}
            {levelNode == 0 ? "Course" : levelNode == 2 ? "Article" : "Node"}
          </h2>

          <div>
            <div className="mb-2">
              <label className="block font-bold mb-1">Title:</label>
              <input
                className="w-full px-3 py-1 border border-gray-300 rounded-md text-base focus:outline-none focus:border-blue-400"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="block font-bold mb-1">Slug:</label>
              <div className="flex items-center space-x-2">
                <input
                  className="w-9/12 px-2 py-1 border border-gray-300 rounded-md text-md focus:outline-none focus:border-blue-400"
                  type="text"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
                <button
                  className="w-3/12 px-1 py-1 bg-blue-400 text-white text-md rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  onClick={() => checkSlug()}
                >
                  Slug Check
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center justify-center">
                <label className="font-bold mr-3">Premium:</label>
                <input
                  className="transform scale-125"
                  type="checkbox"
                  checked={premium}
                  onChange={(e) => setPremium(e.target.checked)}
                />
              </div>
              <div
              className="cursor-pointer"
                onClick={() =>
                  (window.location.href = `/${
                    levelNode === 0 ? "course" : "article"
                  }/${slug}`)
                }
              >
                {levelNode !== 1 && <VisibilityIcon />}
              </div>
            </div>
            <div className="mb-2">
              <label className="block font-bold mb-2">Description:</label>
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md text-base focus:outline-none focus:border-blue-400"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="2"
              />
            </div>
            <div className="flex justify-between items-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-blue-500 text-white px-4 py-1 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onClose}
                className="bg-red-500 text-white px-4 py-1 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ height: "auto", marginTop: "100px", marginLeft: "100px" }}>
      <div style={{ marginBottom: "10px" }}>
        <button
          onClick={() => addNode(null)}
          style={{
            marginRight: "5px",
            border: "none",
            background: "none",
            cursor: "pointer",
          }}
          aria-label="Add Root Node"
        >
          <PlusIcon style={{ width: "20px", height: "20px", color: "grey" }} />
        </button>
        <button
          onClick={expandAll}
          style={{
            marginRight: "5px",
            border: "1px solid lightgrey",
            borderRadius: "20px",
            paddingLeft: "8px",
            paddingRight: "8px",
            color: "grey",
          }}
        >
          Expand All
        </button>
        <button
          onClick={collapseAll}
          style={{
            border: "1px solid lightgrey",
            borderRadius: "20px",
            paddingLeft: "8px",
            paddingRight: "8px",
            color: "grey",
          }}
        >
          Collapse All
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="menu-tree" isDropDisabled={true}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {renderTree(treeData)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isModalOpen && currentNode && (
        <Modal
          node={currentNode}
          onClose={() => {
            setIsModalOpen(false);
            setCurrentNode(null);
          }}
        />
      )}
    </div>
  );
};

export default MenuEditor;

/**
 * Helper Functions
 */

// Initialize node ID counter
let nodeIdCounter = 0;

// Flatten nested tree data into a flat list
function flattenTreeFromObject(obj, parentId = null, acc = []) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const id = `node-${nodeIdCounter++}`;
      const node = {
        id,
        title: key,
        parentId,
        expanded: false,
        data: {},
      };
      if (value && typeof value === "object") {
        // Check if it's a leaf node
        if ("slug" in value || "premium" in value || "description" in value) {
          // It's a leaf node
          node.data = value;
          acc.push(node);
        } else {
          // Not a leaf node, has children
          acc.push(node);
          flattenTreeFromObject(value, id, acc);
        }
      } else {
        acc.push(node);
      }
    }
  }
  return acc;
}
function NewtransformToTreeData(flatList) {
  const nodeMap = {};
  flatList.forEach((node) => {
    nodeMap[node.id] = { ...node, children: [] };
  });
  const rootNodes = [];
  flatList.forEach((node) => {
    if (node.parentId === null) {
      rootNodes.push(nodeMap[node.id]);
    } else {
      const parent = nodeMap[node.parentId];
      if (parent) {
        parent.children.push(nodeMap[node.id]);
      }
    }
  });

  const buildTree = (node) => {
    if (node.children.length === 0) {
      return node.data;
    }
    const result = {};
    node.children.forEach((child) => {
      result[child.title] = buildTree(child);
    });
    return result;
  };

  const result = {};
  rootNodes.forEach((rootNode) => {
    result[rootNode.title] = buildTree(rootNode);
  });

  return result;
}

// Check if a node has children
function hasChildren(nodes, nodeId) {
  return nodes.some((node) => node.parentId === nodeId);
}

// Get all descendant node IDs
function getDescendants(nodes, nodeId) {
  const descendants = [nodeId];
  nodes
    .filter((node) => node.parentId === nodeId)
    .forEach((child) => {
      descendants.push(...getDescendants(nodes, child.id));
    });
  return descendants;
}
