// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom CSS */
.no-border {
  border: none !important;
  outline: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Hero/Hero.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,uBAAuB;EACvB,wBAAwB;AAC1B","sourcesContent":["/* Custom CSS */\n.no-border {\n  border: none !important;\n  outline: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
