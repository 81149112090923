import React, {useContext, useEffect, useState} from "react";

const MCQ = ({ question, options }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  
  return (
    <div className="mcq-container p-4 border-2 border-gray-300 rounded-lg  ">
      <h3 className="font-bold mb-2">{question}</h3>
      {options.map((option, index) => (
        <div key={index} className="mb-2">
          <input
            type="radio"
            id={`option-${index}`}
            name={`mcq-${question}`} // Ensure each MCQ has a unique name
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionChange(option)}
          />
          <label htmlFor={`option-${index}`} className="ml-2">
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default MCQ;
