import { GitHub, LinkedIn } from '@mui/icons-material';
import React from 'react';

const TestimonialModal = ({ isOpen, onClose, testimonial }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg p-6 max-w-md w-full">
        <button onClick={onClose} className="text-xl absolute top-2 right-2 text-gray-500 hover:text-gray-800">
          &times;
        </button>
        <div className="flex items-start">
          <img
            className="w-16 h-16 rounded-full shadow-lg"
            src={testimonial.imageUrl}
            alt={testimonial.person}
          />
          <div className="ml-4">
            <p className="text-lg font-bold">{testimonial.person}</p>
            <p className="text-sm text-gray-500">{testimonial.designation}</p>
            <p className="text-sm text-gray-500">{testimonial.location}</p>
          </div>
        </div>
        <p className="mt-4">{testimonial.text}</p>
        <div className="absolute top-10 right-2 flex mt-4 justify-center gap-4 ">
          
            <a href={testimonial.linkedin} target="_blank" rel="noopener noreferrer">
              <LinkedIn className="text-blue-600 hover:text-blue-800" />
            </a>
        
          
            <a href={testimonial.github} target="_blank" rel="noopener noreferrer">
              <GitHub className="text-gray-900 hover:text-blue-800" />
            </a>
          
          {/* {testimonial.instagram && (
            <a href={testimonial.instagram} target="_blank" rel="noopener noreferrer">
              <PortraitIcon className="text-blue-600 hover:text-blue-800" />
            </a>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TestimonialModal;
