import create from 'zustand';
import axios from 'axios';
import {getAxiosBaseUrl} from "../../config.js";
import {handleAsync, handleSync} from "./utils";


axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;


const sharedNotesStore = create((set, get) => {
	return {
		noteOwner: '', noteRole: '', friendShips: [], roles: ['Viewer', 'Editor'],
		selections: {},
		setState: (key, value) => {
			set(state => ({...state, [key]: value}));
		},
		docRelatedFriendships: (currDocId) => {
			const {friendShips} = get();
			return friendShips.filter(doc => doc.docid === currDocId);
		},
		setOwnerAndRole: (noteIdWPrefixNote, verifiedEmail) => {
			const {friendShips} = get();
			const matchedSharedDoc = friendShips.filter(d => d.docid === noteIdWPrefixNote.split('note-')[1])[0]
			if (matchedSharedDoc) {
				const owner = matchedSharedDoc['email']
				set({'noteOwner': owner})
				set({'noteRole': matchedSharedDoc['role']})
			} else {
				set({'noteOwner': verifiedEmail})
				set({'noteRole': ''})
			}
		},
		getOwnerAndRole:
			(key, verifiedEmail) => {
				const {friendShips} = get();
				const matchedSharedDoc = friendShips.filter(d => d.docid === key.split('note-')[1])[0]
				
				if (matchedSharedDoc) {
					return [matchedSharedDoc['email'], matchedSharedDoc['role']]
				} else {
					return [verifiedEmail, '']
				}
			},
		// GETTERS FRIENDS STRUCTURE
		fetchFriendships:
			async (verifiedemail) => {
				const response = await handleSync(() => axios.post('fetchFriendship', {verifiedemail})
					.then(response => response.data.records));
				set({'friendShips': response})
			},
		
		// GETTERS NOTES
		fetchDocFromSharedColl:
			async (verifiedemail, docid) => {
				return handleAsync(() => axios.post('fetchDocumentDataFromSharedColl', {
					verifiedemail, docid
				}).then(response => response.data.record));
			},
		fetchSharedNotes:
			async (verifiedemail) => {
				try {
					const response = await axios.post('/getSharedDocs', {verifiedemail});
					const documents = response.data.documents;
					return documents.filter(doc => doc !== null)
						// .map(doc => ({
						// 	docid: doc.docid,
						// 	email: doc.email,
						// 	content: doc.content,
						// 	title: doc.title
						// }));
				} catch (error) {
					console.error('Error fetching shared notes:', error);
					return [];
				}
			},
	}
});

export default sharedNotesStore;