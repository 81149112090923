// AcehubDetailedFeature.js
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import HeadindText from "./Utils/HeadindText";
import { CheckCircleIcon } from "@heroicons/react/solid";
const detailedFeaturesData = [
    {
      id: "tailored-roadmap",
      title: "Tailored Roadmap",
      description:
        "We custom tailor a roadmap for you for the target track/tracks of job roles.",
      details: [
        "Work with experienced mentors to define clear milestones.",
        "Receive ongoing feedback on your progress.",
        "Tailored curriculum based on your goals and pace.",
        "Identify skills required for your target job roles.",
        "Set specific timelines to achieve each milestone.",
        "Adjust the roadmap based on your progress and challenges.",
        "Incorporate assessments to evaluate your skills periodically.",
        "Provide resources and materials relevant to your goals.",
        "Utilize project-based learning to solidify concepts.",
        "Regular check-ins with mentors to ensure accountability.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/creative-business-timeline-concept-with-road_23-2147852955.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "upskill-at-your-pace",
      title: "Upskill at Your Pace",
      description:
        "We guide you and upskill you at your pace, ensuring your progress is personalized.",
      details: [
        "Self-paced learning with personalized guidance.",
        "Flexible deadlines to suit your schedule.",
        "Constant support to help you stay on track.",
        "Access to a wide range of online courses and resources.",
        "Customized learning paths based on your interests.",
        "Regular feedback sessions to track your progress.",
        "Community support for peer-to-peer learning.",
        "Encourage exploration of new technologies and trends.",
        "Develop your soft skills alongside technical skills.",
        "Incorporate real-world applications into your learning.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/growing-concept-illustration_114360-506.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "free-project-videos",
      title: "Free Project Videos",
      description:
        "Gain access to free project videos to enhance your learning experience.",
      details: [
        "Access in-depth tutorials on real-world projects.",
        "Learn from industry experts through free video content.",
        "Improve your skills by working on hands-on projects.",
        "Step-by-step guidance for completing complex tasks.",
        "Projects covering various technologies and domains.",
        "Quizzes and challenges to reinforce learning.",
        "Tips and tricks from professionals in the field.",
        "Downloadable resources and project files for practice.",
        "Community forums for discussing projects and ideas.",
        "Opportunities to showcase completed projects for feedback.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/online-education-background_52683-8090.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "team-demos",
      title: "Team Demos Every Sunday",
      description:
        "Participate in team demos every Sunday evening to showcase your progress.",
      details: [
        "Collaborate with peers and mentors in weekly demo sessions.",
        "Get constructive feedback to improve your projects.",
        "Practice presenting your work in a real-world scenario.",
        "Learn effective communication and presentation skills.",
        "Network with industry professionals and peers.",
        "Showcase innovative ideas and solutions to challenges.",
        "Receive diverse perspectives on your work.",
        "Incorporate suggestions into future projects.",
        "Build confidence in discussing your work publicly.",
        "Encourage teamwork and collaborative problem-solving.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/team-work-concept-with-flat-design_23-2147849273.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "access-to-past-demos",
      title: "Access to Past Demos",
      description:
        "Access previous demos conducted by our interns to learn from their experience.",
      details: [
        "Review past presentations and demos to gain insights.",
        "Learn from mistakes and achievements of previous participants.",
        "Watch how other interns tackled similar projects.",
        "Identify best practices for effective presentations.",
        "Understand project management techniques used by peers.",
        "Gain inspiration for your own projects.",
        "Learn to adapt feedback from demos into your work.",
        "Evaluate different approaches to problem-solving.",
        "Recognize the importance of storytelling in presentations.",
        "Access recordings for convenient, on-demand viewing.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/face-recognition-personal-identification-secure-access-profile-entry-data-storage-opening-female-account-holder-cartoon-character-vector-isolated-concept-metaphor-illustration_335657-2852.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "personal-profile-page",
      title: "Personal Profile Page",
      description:
        "Get your personal profile page with your projects hosted for demo purposes.",
      details: [
        "Create a personalized profile page showcasing your portfolio.",
        "Host your projects to easily share with employers.",
        "Receive feedback on your hosted work from mentors.",
        "Highlight key skills and experiences relevant to your goals.",
        "Incorporate links to your GitHub, LinkedIn, and other profiles.",
        "Utilize SEO best practices to enhance visibility.",
        "Showcase testimonials from peers and mentors.",
        "Regularly update your profile with new projects and skills.",
        "Integrate analytics to track profile visits and engagement.",
        "Encourage potential employers to reach out directly.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/employees-cv-candidates-resume-corporate-workers-students-id-isolate-flat-design-element-job-applications-avatars-personal-information-concept-illustration_335657-1661.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "internship-opportunity",
      title: "Internship Opportunity",
      description:
        "You’ll have the opportunity to intern with us and gain valuable experience.",
      details: [
        "Get hands-on experience through internship programs.",
        "Work on real-world projects with professional guidance.",
        "Build a professional network during your internship.",
        "Receive mentorship from industry experts.",
        "Gain exposure to different areas of the business.",
        "Enhance your resume with relevant experience.",
        "Participate in team meetings and contribute to discussions.",
        "Develop problem-solving and critical-thinking skills.",
        "Learn the tools and technologies used in the industry.",
        "Potential for full-time employment upon successful completion.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/intern-mentor-working-office_23-2148748138.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
    {
      id: "full-time-job-opportunity",
      title: "Full-Time Job Opportunities",
      description:
        "Unlock the potential to get a full-time job with our partners after completion.",
      details: [
        "Get introduced to hiring partners for full-time roles.",
        "Build a strong portfolio to enhance your job applications.",
        "Receive career advice and interview preparation support.",
        "Participate in mock interviews to boost confidence.",
        "Learn about industry trends and job market demands.",
        "Access exclusive job listings and opportunities.",
        "Get personalized coaching on resume building.",
        "Network with professionals at job fairs and events.",
        "Receive feedback on your interview performance.",
        "Stay informed about the skills employers are seeking.",
      ],
      imgSrc: "https://img.freepik.com/free-vector/need-new-job-coronavirus-concept_23-2148571178.jpg?ga=GA1.1.1685508690.1727723935&semt=ais_hybrid",
    },
  ];
  
const AcehubDetailedFeature = () => {
    const { featureId } = useParams();
    
    // Create a ref for each feature card
    const featureRefs = useRef({});
    
    // Scroll to the correct feature section on page load
    useEffect(() => {
      if (featureId && featureRefs.current[featureId]) {
        featureRefs.current[featureId].scrollIntoView({ behavior: "smooth" });
      }
    }, [featureId]);
  
    return (
        <div className="py-10 px-6 max-w-7xl mx-auto mt-[90px] space-y-10">
            <h2 className="text-4xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
        <HeadindText Heading={`Our Features`} />
      </h2>
        {detailedFeaturesData.map((feature) => (
          <div
            key={feature.id}
            ref={(el) => (featureRefs.current[feature.id] = el)} // Attach ref to each feature
            className="bg-white shadow-lg rounded-lg grid md:grid-cols-2 gap-6 p-6"
          >
            {/* Left side: Image */}
            <img
              src={feature.imgSrc}
              alt={feature.title}
              className="w-full h-full object-cover rounded-lg hover:scale-[1.05] cursor-pointer transition duration-300"
            />
      
            {/* Right side: Content */}
            <div className="flex flex-col justify-center space-y-4">
              <h1 className="text-4xl font-bold text-gray-800">{feature.title}</h1>
              <p className="text-lg text-gray-600">{feature.description}</p>
              <ul className="space-y-2 text-gray-700 text-lg">
                {feature.details.map((detail, index) => (<div className="flex gap-2">
                <CheckCircleIcon className="h-6" size={20}/>
                  <div key={index}>{detail}</div>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      
    );
  };
  
  export default AcehubDetailedFeature;