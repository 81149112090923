import React, { useEffect, useRef, useState } from "react";
import { Courses, Projects } from "../particles/DataLists";
import CourseCard from "../molecules/CourseCard";
import ProjectCard from "../molecules/ProjectCard";
import Footer from "../organs/Footer";
import MemorizationCards from "./Carousels/MemorizationCards";
import trophy from "../../assets/trophy.png";
import EmailSubscribe from "./MailChamp/EmailSubscribe";
import "./home.css";
import ScrollTypingEffect from "./Helpers/ScrollTypingEffect";
import MilestoneTimeline from "./MilestoneTimeline";
import GroupClassRequest from "./Coaching/GroupClassRequest";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  cnnArchitectures,
  seqToSeq,
  reinforcementLearningModels,
  generativeModels,
  unsupervisedLearningModels,
  transferLearningModels,
  explainableAIModels,
  multimodalModels,
  audioGenerationModels,
  videoGenerationModels,
} from "./modelHistoryData";
import { useNavigate } from "react-router-dom";
import ProjectComp from "./ProjectComp";
import Pricing from "./Pricing/Pricing";
import MarkdownToSpeech from "../Projects/AudioGen/MarkdownToSpeech";
import Testimonials from "./Reviews/Testimonials";
import FaqSection from "./FAQs/FaqSection";
import PrivacyModal from "./Reviews/PrivacyModal";
import ProjectDemo from "./Reviews/ProjectDemo";
import { Gallery } from "./Gallery/Gallery";
import AcehubFeatures from "./AcehubFeatures";
import AudioChatbot from "./ModelComponent/AudioChatbot";
import Hero from "../Hero/Hero";
import Certification from "./Certifications/Certification";

import HeadindText from "./Utils/HeadindText";

export default function Home({ signedin }) {
  const [selected, setSelected] = React.useState(""); // Default all projects
  const [modalOpen, setModalOpen] = useState(false);
  const [categ, setCateg] = useState("Eng");
  const [selectedCourse, setSelectedCourse] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Do not auto switch the categories
    // const categories = ["Eng", "Data", "Sci", "Dev"];
    // let currentIndex = 0;
    // const switchCategory = () => {
    //   setCateg(categories[currentIndex]);
    //   currentIndex = (currentIndex + 1) % categories.length;
    // };
    // const intervalId = setInterval(switchCategory, 8000);
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  function onClickSelfStudy(courseId) {
    navigate(`/course/${courseId}`);
  }
  function onClickSelfStudyProject(courseId) {
    window.scrollTo(0, 0);
    navigate(`projects/${courseId}`);
  }

  function onClickGroupClass(course) {
    setSelectedCourse(course);
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  const chunk = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const courseChunks = chunk(Courses.slice(0, 8), 4);
  const projectChunks = chunk(Projects.slice(0, 8), 4);

  const emailSubscribeRef = useRef(null); // Create a ref

  const scrollToEmailSubscribe = () => {
    emailSubscribeRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [emaildata, setEmailData] = useState("");
  return (
    <>
      <main
        className="pt-1 flex flex-col items-center"
        style={{ marginTop: "86px" }}
      >
        <PrivacyModal />
        {!signedin ? (
          <Hero
            scrollToEmailSubscribe={scrollToEmailSubscribe}
            setEmailData={setEmailData}
          />
        ) : (
          <></>
        )}

        <div
          className="bg-gradient-to-tr min-h-screen from-color3 to-[#212f4c] rounded-b-3xl w-full "
          style={{
            alignItems: "start",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            overflow: "hidden",
            marginTop: "-3px",
          }}
        >
          <div className="flex flex-col gap-10 h-fit mt-6 items-center justify-start mb-12 pb-16 pt-12 md:mx-16 rounded-3xl min-h-[60vh]">
            <div
              className="font-bold text-white flex-col xl:flex-row text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-0"
              style={{ marginTop: "-20px" }}
            >
              <img
                alt="trophy"
                src={trophy}
                style={{
                  width: "40px",
                  height: "40px",
                  opacity: "0.90",
                  marginTop: "-10px",
                }}
              />
              <div className="font-bold text-white text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-0">
                {/*Let’s*/}
                <span
                  style={{
                    background:
                      "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {" "}
                  Projects &{/*Ace AI*/}
                </span>
                courses.
              </div>
            </div>
            <div
              style={{
                color: "white",
                display: "block",
                marginTop: "-30px",
              }}
            >
              <ScrollTypingEffect
                targetText={"AI will not take jobs. People knowing AI will."}
                fontSize={"1em"}
              />
            </div>
            <div className="flex items-center flex-col gap-2">
              <div className="flex gap-4 items-center justify-center mb-10">
                <div className="bg-gray-200 h-[1px] xl:w-[200px] w-[100px] flex opacity-20" />
                <div className="sticky select-none lg:top-28 top-[92vh] shadow-md lg:shadow-none z-10 flex gap-3 bg-[#D9D9D9] lg:w-fit lg:max-w-[70vw] max-w-[90vw] rounded-full p-[3px] overflow-auto">
                  <button
                    onClick={() => setCateg("Eng")}
                    className={`font-semibold select-none rounded-full w-max text-black text-lg px-5 py-2 whitespace-nowrap ${
                      !categ || categ === "Eng" ? "bg-white" : ""
                    }`}
                  >
                    AI Projects
                  </button>
                  <button
                    onClick={() => setCateg("Data")}
                    className={`font-semibold rounded-full w-max text-black text-lg px-5 max-w-none whitespace-nowrap py-2 ${
                      categ === "Data" ? "bg-white" : ""
                    }`}
                  >
                    Courses
                  </button>
                  {/*<button*/}
                  {/*    onClick={() => setCateg("Sci")}*/}
                  {/*    className={`font-semibold rounded-full w-max text-black text-lg px-5 max-w-none whitespace-nowrap py-2 ${*/}
                  {/*        categ === "Sci" ? "bg-white" : ""*/}
                  {/*    }`}*/}
                  {/*>*/}
                  {/*  Data Scientist*/}
                  {/*</button>*/}
                  {/*<button*/}
                  {/*    onClick={() => setCateg("Dev")}*/}
                  {/*    className={`font-semibold rounded-full w-max text-black text-lg px-5 max-w-none whitespace-nowrap py-2 ${*/}
                  {/*        categ === "Dev" ? "bg-white" : ""*/}
                  {/*    }`}*/}
                  {/*>*/}
                  {/*  AI ML Dev Ops*/}
                  {/*</button>*/}
                </div>
                <div className="bg-gray-200 h-[1px] xl:w-[200px] w-[100px] flex opacity-20" />
              </div>
              <Carousel
                showArrows
                showStatus={false}
                showThumbs={false}
                useKeyboardArrows
                showIndicators={false}
                interval={6000}
                swipeable
                emulateTouch
                className="relative overflow-hidden px-4"
              >
                {(categ === "Data" || categ === "Sci" || categ === "Dev"
                  ? courseChunks
                  : projectChunks
                ).map((chunk, chunkIndex) => (
                  <div
                    key={chunkIndex}
                    className="grid grid-cols-1 md:grid-cols-2 items-center lg:grid-cols-4 gap-x-6 gap-y-10 justify-center justify-items-center p-4"
                  >
                    {chunk.map((item) =>
                      categ === "Data" || categ === "Sci" || categ === "Dev" ? (
                        <CourseCard
                          key={item.courseId}
                          course={item}
                          onClickSelfStudy={onClickSelfStudy}
                          onClickGroupClass={onClickGroupClass}
                          className="flex flex-col h-full"
                        />
                      ) : (
                        <ProjectCard
                          key={item.courseId}
                          course={item}
                          onClickSelfStudyProject={onClickSelfStudyProject}
                          className="flex flex-col h-full"
                        />
                      )
                    )}
                  </div>
                ))}
              </Carousel>

              {categ === "Data" || categ === "Sci" || categ === "Dev" ? null : (
                <div className="text-center">
                  <a
                    onClick={() => navigate(`/projects`)}
                    className="pb-2 text-base font-bold leading-7 text-white transition-all duration-200 border-b-2 border-white hover:border-white font-pj mt-4"
                  >
                    See All Projects
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <AudioChatbot /> */}

        
        {!signedin ? (
          <>
            {" "}
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl mt-8">
          <HeadindText Heading={`Project Highlights & Demo`} />
        </h2>
        <div className=" flex flex-wrap items-center justify-center gap-3 text-center mb-0 mt-10">
          <ProjectDemo />
        </div>
            <Testimonials id="testimonials" />
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              <HeadindText Heading={`Acehub Community`} />
            </h2>
            <div id="gallery">
              <Gallery />
            </div>
            <AcehubFeatures />
            <div id="Certification">
              <Certification />
            </div>
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              <HeadindText Heading={`Pricing`} />
            </h2>
            <Pricing id="pricing" />
            <div id="Faqs" className="mx-auto max-w-8xl sm:text-center mt-10">
              <FaqSection />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Milestone Timelines */}
        {/* <div className="font-bold text-black text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-4 mt-10">
          <span
            style={{
              background:
                "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Our Models
          </span>
        </div>
        <div className="mx-7 md:mx-5">
          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Audio Generation Models</h2>
          </div>
          <MilestoneTimeline milestones={audioGenerationModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Video Generation Models</h2>
          </div>
          <MilestoneTimeline milestones={videoGenerationModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">CNN Architectures</h2>
          </div>
          <MilestoneTimeline milestones={cnnArchitectures} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Sequence to Sequence</h2>
          </div>
          <MilestoneTimeline milestones={seqToSeq} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Reinforcement Learning</h2>
          </div>
          <MilestoneTimeline milestones={reinforcementLearningModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Generative Models</h2>
          </div>
          <MilestoneTimeline milestones={generativeModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Unsupervised Learning Models</h2>
          </div>
          <MilestoneTimeline milestones={unsupervisedLearningModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Transfer Learning Models</h2>
          </div>
          <MilestoneTimeline milestones={transferLearningModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Explainable AI Models</h2>
          </div>
          <MilestoneTimeline milestones={explainableAIModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Multimodal Models</h2>
          </div>
          <MilestoneTimeline milestones={multimodalModels} />
        </div> */}

        {/* <div className="mt-40">
          <MemorizationCards />
        </div> */}

        <GroupClassRequest
          isOpen={modalOpen}
          onClose={closeModal}
          course={selectedCourse}
        />

        <div ref={emailSubscribeRef} className="mt-0">
          <EmailSubscribe
            buttonText="Stay Informed"
            btnBackgroundColor="#152f37"
            btnColor="white"
            emaildata={emaildata}
          />
        </div>
      </main>
      <Footer />
    </>
  );
}
