export const menudata = {
  "_id": {
    "$oid": "66cd53d09f4d23d39653aec7"
  },
  "keyName": "acehubmenu",
  "menudata": {
    // "Projects": {
    //   "Text to Audio": {
    //     "Voice Clone": {
    //       "slug": "neugence_history_ai",
    //       "premium": false,
    //       "description": "Overview of the history and evolution of AI, from early concepts to modern advancements."
    //     },
    //     "Markdown to Audio": {
    //       "slug": "neugence_types_ai",
    //       "premium": false,
    //       "description": "Understanding different types of AI, including narrow AI, general AI, and superintelligent AI."
    //     },
    //     "Tortoise TTS Explained": {
    //       "slug": "neugence_ai_vs_ml_vs_dl",
    //       "premium": false,
    //       "description": "Differentiating between AI, machine learning, and deep learning, and understanding their interconnections."
    //     },
    //     "GoogleT5 Explained": {
    //       "slug": "neugence_ethics_ai",
    //       "premium": false,
    //       "description": "Exploring ethical considerations in AI, including bias, fairness, and the societal impact of AI technologies."
    //     }
    //   },
    //   "Photo Gen Ai": {
    //     "Selfie to Professional Photo": {
    //       "slug": "neugence_text_preprocessing",
    //       "premium": false,
    //       "description": "Introduction to text preprocessing techniques such as tokenization, stemming, lemmatization, and stopword removal."
    //     },
    //     "Photo to Cool Skin": {
    //       "slug": "neugence_word_embeddings",
    //       "premium": false,
    //       "description": "Understanding word embeddings like Word2Vec, GloVe, and fastText, and their applications in NLP."
    //     },
    //     "Custom Background Gen": {
    //       "slug": "neugence_sequence_models",
    //       "premium": false,
    //       "description": "Exploring sequence models like RNNs, LSTMs, and GRUs for tasks like language modeling and text generation."
    //     }
    //   },
    //   "Deploy Ai Models": {
    //     "Pytorch Model Deployment": {
    //       "slug": "neugence_image_processing_basics",
    //       "premium": false,
    //       "description": "Introduction to image processing, including filtering, edge detection, and color space transformations."
    //     },
    //     "Docker Concepts and Model Deployment": {
    //       "slug": "neugence_cnns",
    //       "premium": false,
    //       "description": "Overview of CNNs, including convolutional layers, pooling layers, and popular architectures like AlexNet and VGG."
    //     },
    //     "Google Model Deployment": {
    //       "slug": "neugence_object_detection",
    //       "premium": false,
    //       "description": "Understanding object detection techniques like R-CNN, YOLO, and SSD for identifying and localizing objects in images."
    //     },
    //     "Azure Model Deployment": {
    //       "slug": "neugence_image_segmentation",
    //       "premium": false,
    //       "description": "Introduction to image segmentation, including semantic segmentation and instance segmentation techniques like U-Net and Mask R-CNN."
    //     },
    //     "Model Caching and Speedups": {
    //       "slug": "neugence_gans",
    //       "premium": false,
    //       "description": "Exploring GANs, their architecture, and applications in image generation, style transfer, and data augmentation."
    //     }
    //   },
    //   "Bugs, Tasks, TODOs with RL": {
    //     "Drag Drop UI": {
    //       "slug": "neugence_rl_intro",
    //       "premium": false,
    //       "description": "Basics of reinforcement learning, including key concepts like agents, environments, rewards, and policies."
    //     },
    //     "RL Agent for task prioritization": {
    //       "slug": "neugence_mdp",
    //       "premium": false,
    //       "description": "Understanding MDPs as a mathematical framework for modeling decision-making problems in RL."
    //     },
    //     "RL Agent for task ranking": {
    //       "slug": "neugence_q_learning",
    //       "premium": false,
    //       "description": "Introduction to Q-learning, a value-based reinforcement learning algorithm for finding optimal policies."
    //     },
    //     "RL model deployment and feedback flow": {
    //       "slug": "neugence_dqn",
    //       "premium": false,
    //       "description": "Overview of DQNs, which combine Q-learning with deep learning for handling complex environments."
    //     }
    //   },
    //   "Client side Model Runs": {
    //     "Recommendation model on client device": {
    //       "slug": "neugence_xai",
    //       "premium": false,
    //       "description": "Exploring techniques for making AI models more interpretable and explainable, ensuring transparency in decision-making."
    //     },
    //     "Recommendation model in Client device": {
    //       "slug": "neugence_ai_healthcare",
    //       "premium": false,
    //       "description": "Applications of AI in healthcare, including medical image analysis, drug discovery, and personalized medicine."
    //     },
    //     "Knowledge distillation": {
    //       "slug": "neugence_ai_autonomous_systems",
    //       "premium": false,
    //       "description": "Overview of AI applications in autonomous systems, such as self-driving cars, drones, and robotics."
    //     },
    //     "Transfer learning": {
    //       "slug": "neugence_nlu_advanced",
    //       "premium": false,
    //       "description": "Advanced topics in NLU, focusing on state-of-the-art models and techniques for deep language comprehension."
    //     }
    //   },
    //   "Prompt Engineering Certificate": {
    //     "Chain Of Thought": {
    //       "slug": "neugence_xai",
    //       "premium": false,
    //       "description": "Exploring techniques for making AI models more interpretable and explainable, ensuring transparency in decision-making."
    //     },
    //     "Self Reflection": {
    //       "slug": "neugence_ai_healthcare",
    //       "premium": false,
    //       "description": "Applications of AI in healthcare, including medical image analysis, drug discovery, and personalized medicine."
    //     }
    //   }
    // },
    // "Courses": {
    //   "Introduction to AI": {
    //     "History and Evolution of AI": {
    //       "slug": "neugence_history_ai",
    //       "premium": false,
    //       "description": "Overview of the history and evolution of AI, from early concepts to modern advancements."
    //     },
    //     "Types of AI": {
    //       "slug": "neugence_types_ai",
    //       "premium": false,
    //       "description": "Understanding different types of AI, including narrow AI, general AI, and superintelligent AI."
    //     },
    //     "AI vs Machine Learning vs Deep Learning": {
    //       "slug": "neugence_ai_vs_ml_vs_dl",
    //       "premium": false,
    //       "description": "Differentiating between AI, machine learning, and deep learning, and understanding their interconnections."
    //     },
    //     "Ethics in AI": {
    //       "slug": "neugence_ethics_ai",
    //       "premium": false,
    //       "description": "Exploring ethical considerations in AI, including bias, fairness, and the societal impact of AI technologies."
    //     }
    //   },
    //   "Natural Language Processing (NLP)": {
    //     "Text Preprocessing": {
    //       "slug": "neugence_text_preprocessing",
    //       "premium": false,
    //       "description": "Introduction to text preprocessing techniques such as tokenization, stemming, lemmatization, and stopword removal."
    //     },
    //     "Word Embeddings": {
    //       "slug": "neugence_word_embeddings",
    //       "premium": false,
    //       "description": "Understanding word embeddings like Word2Vec, GloVe, and fastText, and their applications in NLP."
    //     },
    //     "Sequence Models": {
    //       "slug": "neugence_sequence_models",
    //       "premium": false,
    //       "description": "Exploring sequence models like RNNs, LSTMs, and GRUs for tasks like language modeling and text generation."
    //     },
    //     "Transformers and Attention Mechanisms": {
    //       "slug": "neugence_transformers_attention",
    //       "premium": false,
    //       "description": "Introduction to transformers and attention mechanisms, including the Transformer architecture and its applications."
    //     },
    //     "BERT and GPT": {
    //       "slug": "neugence_bert_gpt",
    //       "premium": false,
    //       "description": "Overview of BERT and GPT models, their architectures, and use cases in NLP tasks."
    //     },
    //     "Natural Language Understanding (NLU)": {
    //       "slug": "neugence_nlu",
    //       "premium": false,
    //       "description": "Understanding NLU techniques for tasks like sentiment analysis, named entity recognition, and intent classification."
    //     },
    //     "Natural Language Generation (NLG)": {
    //       "slug": "neugence_nlg",
    //       "premium": false,
    //       "description": "Exploring NLG techniques for tasks like text summarization, machine translation, and conversational AI."
    //     }
    //   },
    //   "Computer Vision": {
    //     "Image Processing Basics": {
    //       "slug": "neugence_image_processing_basics",
    //       "premium": false,
    //       "description": "Introduction to image processing, including filtering, edge detection, and color space transformations."
    //     },
    //     "Convolutional Neural Networks (CNNs)": {
    //       "slug": "neugence_cnns",
    //       "premium": false,
    //       "description": "Overview of CNNs, including convolutional layers, pooling layers, and popular architectures like AlexNet and VGG."
    //     },
    //     "Object Detection": {
    //       "slug": "neugence_object_detection",
    //       "premium": false,
    //       "description": "Understanding object detection techniques like R-CNN, YOLO, and SSD for identifying and localizing objects in images."
    //     },
    //     "Image Segmentation": {
    //       "slug": "neugence_image_segmentation",
    //       "premium": false,
    //       "description": "Introduction to image segmentation, including semantic segmentation and instance segmentation techniques like U-Net and Mask R-CNN."
    //     },
    //     "Generative Adversarial Networks (GANs)": {
    //       "slug": "neugence_gans",
    //       "premium": false,
    //       "description": "Exploring GANs, their architecture, and applications in image generation, style transfer, and data augmentation."
    //     },
    //     "Facial Recognition": {
    //       "slug": "neugence_facial_recognition",
    //       "premium": false,
    //       "description": "Overview of facial recognition techniques, including feature extraction, face detection, and face matching."
    //     },
    //     "3D Vision and Reconstruction": {
    //       "slug": "neugence_3d_vision",
    //       "premium": false,
    //       "description": "Introduction to 3D vision techniques, including 3D reconstruction, depth estimation, and point cloud processing."
    //     }
    //   },
    //   "Reinforcement Learning": {
    //     "Introduction to Reinforcement Learning": {
    //       "slug": "neugence_rl_intro",
    //       "premium": false,
    //       "description": "Basics of reinforcement learning, including key concepts like agents, environments, rewards, and policies."
    //     },
    //     "Markov Decision Processes (MDP)": {
    //       "slug": "neugence_mdp",
    //       "premium": false,
    //       "description": "Understanding MDPs as a mathematical framework for modeling decision-making problems in RL."
    //     },
    //     "Q-Learning": {
    //       "slug": "neugence_q_learning",
    //       "premium": false,
    //       "description": "Introduction to Q-learning, a value-based reinforcement learning algorithm for finding optimal policies."
    //     },
    //     "Deep Q-Networks (DQN)": {
    //       "slug": "neugence_dqn",
    //       "premium": false,
    //       "description": "Overview of DQNs, which combine Q-learning with deep learning for handling complex environments."
    //     },
    //     "Policy Gradient Methods": {
    //       "slug": "neugence_policy_gradient",
    //       "premium": false,
    //       "description": "Introduction to policy gradient methods, including REINFORCE algorithm and actor-critic models."
    //     },
    //     "Proximal Policy Optimization (PPO)": {
    //       "slug": "neugence_ppo",
    //       "premium": false,
    //       "description": "Exploring PPO, a popular policy gradient method that balances exploration and exploitation in RL."
    //     },
    //     "Multi-Agent Reinforcement Learning": {
    //       "slug": "neugence_multi_agent_rl",
    //       "premium": false,
    //       "description": "Understanding multi-agent RL, where multiple agents interact in a shared environment, with applications in cooperative and competitive settings."
    //     }
    //   },
    //   "Advanced AI Topics": {
    //     "Explainable AI (XAI)": {
    //       "slug": "neugence_xai",
    //       "premium": false,
    //       "description": "Exploring techniques for making AI models more interpretable and explainable, ensuring transparency in decision-making."
    //     },
    //     "AI in Healthcare": {
    //       "slug": "neugence_ai_healthcare",
    //       "premium": false,
    //       "description": "Applications of AI in healthcare, including medical image analysis, drug discovery, and personalized medicine."
    //     },
    //     "AI in Autonomous Systems": {
    //       "slug": "neugence_ai_autonomous_systems",
    //       "premium": false,
    //       "description": "Overview of AI applications in autonomous systems, such as self-driving cars, drones, and robotics."
    //     },
    //     "Natural Language Understanding (NLU)": {
    //       "slug": "neugence_nlu_advanced",
    //       "premium": false,
    //       "description": "Advanced topics in NLU, focusing on state-of-the-art models and techniques for deep language comprehension."
    //     },
    //     "Generative Models": {
    //       "slug": "neugence_generative_models",
    //       "premium": false,
    //       "description": "Exploring advanced generative models beyond GANs, including VAEs, flow-based models, and autoregressive models."
    //     },
    //     "Quantum AI": {
    //       "slug": "neugence_quantum_ai",
    //       "premium": false,
    //       "description": "Introduction to quantum computing and its potential applications in accelerating AI algorithms."
    //     }
    //   }
    // },

    // "Mentorship ": {
    //   "🎤 1:1 Guidance ": {
    //     "link":"https://discord.com/channels/1281837569373503652/1300854206613164132"
    //   },
    //   "👨‍🏫 Mock Interviews ": {
    //     "link":"https://discord.com/channels/1281837569373503652/1300854593789493258"
    //
    //   },
    //   "🤝 Partner with us ": {
    //     "link":"https://discord.com/channels/1281837569373503652/1300854879753080902"
    //   }
    // },
    
    // "MentorshipData": {
    //   "1:1 Guidance": {
    //     "History and Evolution of AI": {
    //       "slug": "neugence_history_ai",
    //       "premium": false,
    //       "description": "Overview of the history and evolution of AI, from early concepts to modern advancements."
    //     },
    //     "Types of AI": {
    //       "slug": "neugence_types_ai",
    //       "premium": false,
    //       "description": "Understanding different types of AI, including narrow AI, general AI, and superintelligent AI."
    //     },
    //     "AI vs Machine Learning vs Deep Learning": {
    //       "slug": "neugence_ai_vs_ml_vs_dl",
    //       "premium": false,
    //       "description": "Differentiating between AI, machine learning, and deep learning, and understanding their interconnections."
    //     },
    //     "Ethics in AI": {
    //       "slug": "neugence_ethics_ai",
    //       "premium": false,
    //       "description": "Exploring ethical considerations in AI, including bias, fairness, and the societal impact of AI technologies."
    //     }
    //   },
    //   "Mock Interviews": {
    //     "History and Evolution of AI": {
    //       "slug": "neugence_history_ai",
    //       "premium": false,
    //       "description": "Overview of the history and evolution of AI, from early concepts to modern advancements."
    //     },
    //     "Types of AI": {
    //       "slug": "neugence_types_ai",
    //       "premium": false,
    //       "description": "Understanding different types of AI, including narrow AI, general AI, and superintelligent AI."
    //     },
    //     "AI vs Machine Learning vs Deep Learning": {
    //       "slug": "neugence_ai_vs_ml_vs_dl",
    //       "premium": false,
    //       "description": "Differentiating between AI, machine learning, and deep learning, and understanding their interconnections."
    //     },
    //     "Ethics in AI": {
    //       "slug": "neugence_ethics_ai",
    //       "premium": false,
    //       "description": "Exploring ethical considerations in AI, including bias, fairness, and the societal impact of AI technologies."
    //     }
    //   },
    //   "Partner with us": {
    //     "Text Preprocessing": {
    //       "slug": "neugence_text_preprocessing",
    //       "premium": false,
    //       "description": "Introduction to text preprocessing techniques such as tokenization, stemming, lemmatization, and stopword removal."
    //     },
    //     "Word Embeddings": {
    //       "slug": "neugence_word_embeddings",
    //       "premium": false,
    //       "description": "Understanding word embeddings like Word2Vec, GloVe, and fastText, and their applications in NLP."
    //     },
    //     "Sequence Models": {
    //       "slug": "neugence_sequence_models",
    //       "premium": false,
    //       "description": "Exploring sequence models like RNNs, LSTMs, and GRUs for tasks like language modeling and text generation."
    //     },
    //     "Transformers and Attention Mechanisms": {
    //       "slug": "neugence_transformers_attention",
    //       "premium": false,
    //       "description": "Introduction to transformers and attention mechanisms, including the Transformer architecture and its applications."
    //     },
    //     "BERT and GPT": {
    //       "slug": "neugence_bert_gpt",
    //       "premium": false,
    //       "description": "Overview of BERT and GPT models, their architectures, and use cases in NLP tasks."
    //     },
    //     "Natural Language Understanding (NLU)": {
    //       "slug": "neugence_nlu",
    //       "premium": false,
    //       "description": "Understanding NLU techniques for tasks like sentiment analysis, named entity recognition, and intent classification."
    //     },
    //     "Natural Language Generation (NLG)": {
    //       "slug": "neugence_nlg",
    //       "premium": false,
    //       "description": "Exploring NLG techniques for tasks like text summarization, machine translation, and conversational AI."
    //     }
    //   }
    // },
    // "Community": {
    //   "Introduction to AI": {
    //     "History and Evolution of AI": {
    //       "slug": "neugence_history_ai",
    //       "premium": false,
    //       "description": "Overview of the history and evolution of AI, from early concepts to modern advancements."
    //     },
    //     "Types of AI": {
    //       "slug": "neugence_types_ai",
    //       "premium": false,
    //       "description": "Understanding different types of AI, including narrow AI, general AI, and superintelligent AI."
    //     },
    //     "AI vs Machine Learning vs Deep Learning": {
    //       "slug": "neugence_ai_vs_ml_vs_dl",
    //       "premium": false,
    //       "description": "Differentiating between AI, machine learning, and deep learning, and understanding their interconnections."
    //     },
    //     "Ethics in AI": {
    //       "slug": "neugence_ethics_ai",
    //       "premium": false,
    //       "description": "Exploring ethical considerations in AI, including bias, fairness, and the societal impact of AI technologies."
    //     }
    //   },
    //   "Natural Language Processing (NLP)": {
    //     "Text Preprocessing": {
    //       "slug": "neugence_text_preprocessing",
    //       "premium": false,
    //       "description": "Introduction to text preprocessing techniques such as tokenization, stemming, lemmatization, and stopword removal."
    //     },
    //     "Word Embeddings": {
    //       "slug": "neugence_word_embeddings",
    //       "premium": false,
    //       "description": "Understanding word embeddings like Word2Vec, GloVe, and fastText, and their applications in NLP."
    //     },
    //     "Sequence Models": {
    //       "slug": "neugence_sequence_models",
    //       "premium": false,
    //       "description": "Exploring sequence models like RNNs, LSTMs, and GRUs for tasks like language modeling and text generation."
    //     },
    //     "Transformers and Attention Mechanisms": {
    //       "slug": "neugence_transformers_attention",
    //       "premium": false,
    //       "description": "Introduction to transformers and attention mechanisms, including the Transformer architecture and its applications."
    //     },
    //     "BERT and GPT": {
    //       "slug": "neugence_bert_gpt",
    //       "premium": false,
    //       "description": "Overview of BERT and GPT models, their architectures, and use cases in NLP tasks."
    //     },
    //     "Natural Language Understanding (NLU)": {
    //       "slug": "neugence_nlu",
    //       "premium": false,
    //       "description": "Understanding NLU techniques for tasks like sentiment analysis, named entity recognition, and intent classification."
    //     },
    //     "Natural Language Generation (NLG)": {
    //       "slug": "neugence_nlg",
    //       "premium": false,
    //       "description": "Exploring NLG techniques for tasks like text summarization, machine translation, and conversational AI."
    //     }
    //   },
    //   "Computer Vision": {
    //     "Image Processing Basics": {
    //       "slug": "neugence_image_processing_basics",
    //       "premium": false,
    //       "description": "Introduction to image processing, including filtering, edge detection, and color space transformations."
    //     },
    //     "Convolutional Neural Networks (CNNs)": {
    //       "slug": "neugence_cnns",
    //       "premium": false,
    //       "description": "Overview of CNNs, including convolutional layers, pooling layers, and popular architectures like AlexNet and VGG."
    //     },
    //     "Object Detection": {
    //       "slug": "neugence_object_detection",
    //       "premium": false,
    //       "description": "Understanding object detection techniques like R-CNN, YOLO, and SSD for identifying and localizing objects in images."
    //     },
    //     "Image Segmentation": {
    //       "slug": "neugence_image_segmentation",
    //       "premium": false,
    //       "description": "Introduction to image segmentation, including semantic segmentation and instance segmentation techniques like U-Net and Mask R-CNN."
    //     },
    //     "Generative Adversarial Networks (GANs)": {
    //       "slug": "neugence_gans",
    //       "premium": false,
    //       "description": "Exploring GANs, their architecture, and applications in image generation, style transfer, and data augmentation."
    //     },
    //     "Facial Recognition": {
    //       "slug": "neugence_facial_recognition",
    //       "premium": false,
    //       "description": "Overview of facial recognition techniques, including feature extraction, face detection, and face matching."
    //     },
    //     "3D Vision and Reconstruction": {
    //       "slug": "neugence_3d_vision",
    //       "premium": false,
    //       "description": "Introduction to 3D vision techniques, including 3D reconstruction, depth estimation, and point cloud processing."
    //     }
    //   },
    //   "Reinforcement Learning": {
    //     "Introduction to Reinforcement Learning": {
    //       "slug": "neugence_rl_intro",
    //       "premium": false,
    //       "description": "Basics of reinforcement learning, including key concepts like agents, environments, rewards, and policies."
    //     },
    //     "Markov Decision Processes (MDP)": {
    //       "slug": "neugence_mdp",
    //       "premium": false,
    //       "description": "Understanding MDPs as a mathematical framework for modeling decision-making problems in RL."
    //     },
    //     "Q-Learning": {
    //       "slug": "neugence_q_learning",
    //       "premium": false,
    //       "description": "Introduction to Q-learning, a value-based reinforcement learning algorithm for finding optimal policies."
    //     },
    //     "Deep Q-Networks (DQN)": {
    //       "slug": "neugence_dqn",
    //       "premium": false,
    //       "description": "Overview of DQNs, which combine Q-learning with deep learning for handling complex environments."
    //     },
    //     "Policy Gradient Methods": {
    //       "slug": "neugence_policy_gradient",
    //       "premium": false,
    //       "description": "Introduction to policy gradient methods, including REINFORCE algorithm and actor-critic models."
    //     },
    //     "Proximal Policy Optimization (PPO)": {
    //       "slug": "neugence_ppo",
    //       "premium": false,
    //       "description": "Exploring PPO, a popular policy gradient method that balances exploration and exploitation in RL."
    //     },
    //     "Multi-Agent Reinforcement Learning": {
    //       "slug": "neugence_multi_agent_rl",
    //       "premium": false,
    //       "description": "Understanding multi-agent RL, where multiple agents interact in a shared environment, with applications in cooperative and competitive settings."
    //     }
    //   },
    //   "Advanced AI Topics": {
    //     "Explainable AI (XAI)": {
    //       "slug": "neugence_xai",
    //       "premium": false,
    //       "description": "Exploring techniques for making AI models more interpretable and explainable, ensuring transparency in decision-making."
    //     },
    //     "AI in Healthcare": {
    //       "slug": "neugence_ai_healthcare",
    //       "premium": false,
    //       "description": "Applications of AI in healthcare, including medical image analysis, drug discovery, and personalized medicine."
    //     },
    //     "AI in Autonomous Systems": {
    //       "slug": "neugence_ai_autonomous_systems",
    //       "premium": false,
    //       "description": "Overview of AI applications in autonomous systems, such as self-driving cars, drones, and robotics."
    //     },
    //     "Natural Language Understanding (NLU)": {
    //       "slug": "neugence_nlu_advanced",
    //       "premium": false,
    //       "description": "Advanced topics in NLU, focusing on state-of-the-art models and techniques for deep language comprehension."
    //     },
    //     "Generative Models": {
    //       "slug": "neugence_generative_models",
    //       "premium": false,
    //       "description": "Exploring advanced generative models beyond GANs, including VAEs, flow-based models, and autoregressive models."
    //     },
    //     "Quantum AI": {
    //       "slug": "neugence_quantum_ai",
    //       "premium": false,
    //       "description": "Introduction to quantum computing and its potential applications in accelerating AI algorithms."
    //     }
    //   }
    // }
  }
};