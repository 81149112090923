import { useEffect, useRef } from 'react';

const usePreventBackNavigation = () => {
  const ref = useRef(null);

  useEffect(() => {
    const disableBackButton = (event) => {
      window.history.pushState(null, null, window.location.href);
    };

    const preventHorizontalScroll = (event) => {
      if (event.deltaX < 0) {
        event.preventDefault();
      }
    };

    const node = ref.current;
    if (node) {
      const enableBackButton = () => {
        window.removeEventListener('popstate', disableBackButton);
      };

      node.addEventListener('mouseenter', () => {
        window.addEventListener('popstate', disableBackButton);
        node.addEventListener('wheel', preventHorizontalScroll, { passive: false });
      });

      node.addEventListener('mouseleave', () => {
        enableBackButton();
        node.removeEventListener('wheel', preventHorizontalScroll);
      });

      return () => {
        if (node) {
          node.removeEventListener('mouseenter', () => {
            window.addEventListener('popstate', disableBackButton);
            node.addEventListener('wheel', preventHorizontalScroll, { passive: false });
          });
          node.removeEventListener('mouseleave', () => {
            window.removeEventListener('popstate', disableBackButton);
            node.removeEventListener('wheel', preventHorizontalScroll);
          });
        }
      };
    }
  }, []);

  return ref;
};

export default usePreventBackNavigation;
